import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import successPicture from "../../../../assets/img/contract/creation_success.svg";
import { SzStepper } from "@suezenv/react-theme-components";
import { Organization } from "../../../../types";
import { UsersService } from "../../../../services/UsersService";
import FormSuccessPage from "../../FormSuccessPage";
import Step1 from "./addSteps/Step1";
import Step2 from "./addSteps/Step2";
import { GiveableRoles } from "../../../../constants";
import Step0 from "./addSteps/Step0";

export type UserWithRole = {
    userEmail: string,
    role: GiveableRoles
}

const AdminUserAdd = (props: { contractId: string, organizationId?: string, refreshData?: () => void }) => {
    const { contractId, organizationId, refreshData } = props;
    const { t } = useTranslation();
    const [usersRoles, setUsersRoles] = useState<UserWithRole[]>([]);
    const [organizationsIds, setOrganizationsIds] = useState<string[]>([]);
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [step, setStep] = useState(1);
    const [stepperHeader, setStepperHeader] = useState([
        t('admin:admin.users.modal.add.step1Title'),
        t('admin:admin.users.modal.add.step2Title')
    ]);


    useEffect(() => {
        const index = stepperHeader.indexOf(t('admin:admin.users.modal.add.step0Title'));
        const tmp = stepperHeader.slice(0);
        if (!organizationId && index === -1)
        {
            tmp.unshift(t('admin:admin.users.modal.add.step0Title'));
            setStep(0);
        }
        setStepperHeader(tmp);
    }, [organizationId])

    const onValidate = () => {
        setIsSaving(true);
        const tmpOrganizationIds: string[] = organizationId ? [organizationId] : organizationsIds;
        UsersService.createUsersWithRoles(contractId, tmpOrganizationIds, usersRoles)
            .then(() => setIsSuccess(true))
            .then(() => {
                if (refreshData) {
                    refreshData();
                }
            })
            .finally(() => setIsSaving(false))
    }

    const resetForm = () => {
        setUsersRoles([]);
        setIsSaving(false);
        setIsSuccess(false);
        setStep(organizationId ? 1 : 0);
    }

    const successComponent = <FormSuccessPage
        image={successPicture}
        title={t(`admin:admin.users.modal.add.success.title`)}
        subtitle={t(`admin:admin.users.modal.add.success.subtitle`)}
        middleBottomButtonIcon={"add-circle"}
        middleBottomButtonOnclick={resetForm}
        middleBottomButtonText={t(`admin:admin.users.modal.add.success.createNew`)}
    />

    const formStepper = <SzStepper
        header={stepperHeader}
        activeStep={step}
    >
        <SzStepper.SzHeader className="bg-transparent"/>
        {!organizationId &&
        <SzStepper.SzStep index={0}>
            <Step0
                contractId={contractId}
                setOrganizationIds={setOrganizationsIds}
                goToNext={() => setStep(1)}
            />
        </SzStepper.SzStep>
        }
        <SzStepper.SzStep index={1}>
            <Step1
                userRoles={usersRoles}
                setUserRoles={setUsersRoles}
                goToPrev={!organizationId ? () => setStep(0) : undefined}
                goToNext={() => setStep(2)}
            />
        </SzStepper.SzStep>
        <SzStepper.SzStep index={2}>
            <Step2
                userRoles={usersRoles}
                setUserRoles={setUsersRoles}
                goToPrev={() => setStep(1)}
                validate={onValidate}
                isSaving={isSaving}
            />
        </SzStepper.SzStep>
    </SzStepper>

    return isSuccess ? successComponent : formStepper;
}


export default AdminUserAdd;