import Request from './Request';
import { ApiUrls } from '../constants';
import { Contract } from "../types";
import { UserWithRole } from "../components/admin/organizations/organizationUsers/AdminUserAdd";

export class UsersService {
    public static getUser(userId: string, contractId?: string) {
        const url = ApiUrls.USER.replace(":userId", userId)
        const body = {
            contractId
        }
        return Request.getWithParams(url, body, false, true)
            .then(response => response.data);
    }

    public static getUsers(contract: Contract, currentPage: number, itemsPerPage: number) {
        const url = ApiUrls.USERS.replace(":contractId", contract.id)
        const body = {
            currentPage,
            itemsPerPage
        }

        return Request.getWithParams(url, body, false, true);
    }

    public static createUsersWithRoles(contractId: string, organizationIds: string[], usersRole: UserWithRole[]) {
        const url = ApiUrls.USERS_WITH_ROLES.replace(":contractId", contractId);
        const body: any = {
            organizations: organizationIds
        };

        usersRole.forEach((userRole: UserWithRole) => {
            body[userRole.userEmail] = userRole.role;
        });

        return Request.post(url, body);
    }

    public static getUsersNbOrganizations(usersIds: string[], contractId: string, currentPage: number, itemsPerPage: number, organizationType?: string) {
        const url = ApiUrls.USERS_NB_ORGANIZATION.replace(':contractId', contractId);
        const body = {
            users: usersIds,
            countOnly: true,
            organizationType: organizationType,
            currentPage: currentPage,
            itemsPerPage: itemsPerPage
        }

        return Request.getWithParams(url, body);
    }
}
