import { config } from "../config";
import { Token as TokenService } from "./Token";
import Request from './Request';
import { store } from '../store';
import { ApiUrls, AppUrls } from '../constants';
import { Contract } from "../types";
import { ContractsService } from "./ContractsService";
import { PermissionsService } from "./PermissionsService";
import { SkillSetService } from "./SkillSetService";
import { AxiosResponse } from "axios";

export class Authenticate {
  public static initialize() {
    TokenService.createDigitalTwinTokens();
    TokenService.checkJWTValidityFromAPI();
    this.userInfo();
  }

  public static login() {
    return (window.location = `${config.apiUrl}/saml/login` as any);
  }

  public static logout() {
    localStorage.clear();
    window.location = `${config.apiUrl}/saml/logout` as any;
  }

  public static userInfo() {
      ContractsService.getContractsByUser()
          .then((response: AxiosResponse<Contract[]>) => response.data)
          .then((contracts: Contract[]) => {
              PermissionsService.setPermissions(contracts);
              if (contracts.length > 0) {
                  const firstContract = contracts.slice().shift();
                  if (firstContract) {
                      //ThemeService.setTheme(firstContract);
                      ContractsService.getContractById(firstContract.id).then((contract: any) => {
                          ContractsService.setContractDetail(contract);
                      })
                  }
              }
          });
  }
}
