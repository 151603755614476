import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import SzDualListBox, { SzDualListBoxOption } from "../../../elements/SzDualListBox";
import { useQuery } from "react-query";
import { CategoryHeimdall, Contract } from "../../../../types";
import { SkillSetService } from "../../../../services/SkillSetService";

type PropsType = {
    setSkillSets: (skillSetsIds: string[]) => void,
    skillSets: string[],
    contract: Contract
}

const Step3 = (props: PropsType) => {
    const { t } = useTranslation();
    const [options, setOptions] = useState<SzDualListBoxOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SzDualListBoxOption[]>([]);

    const fetchSkillSets = () => {
        return SkillSetService.getAll([], true);
    }

    const { data : skillSetData } = useQuery("skillsets", fetchSkillSets);

    const skillSetDataToOption = (skills: CategoryHeimdall[]) => {
        return  skills.map((skill: CategoryHeimdall) => {
            return { id: skill.id as string, label: skill.label } as SzDualListBoxOption;
        }).sort((a: SzDualListBoxOption, b: SzDualListBoxOption) => {
            return a.label.localeCompare(b.label);
        });
    }

    const filterSkillsOptions = (filter: string) => {
        if (skillSetData) {
            const skillSets: CategoryHeimdall[] = Object.values(skillSetData?.data);
            setOptions(skillSetDataToOption(skillSets).filter((option: SzDualListBoxOption) => option.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1))
        }
    }

    useEffect(() => {
        if (skillSetData) {
            const skillSets: CategoryHeimdall[] = Object.values(skillSetData?.data);
            setOptions(skillSetDataToOption(skillSets));
        }
    }, [skillSetData])

    useEffect(() => {
        props.setSkillSets(selectedOptions.map((selected: SzDualListBoxOption) => selected.id));
    }, [selectedOptions])


    return <SzDualListBox
        options={options}
        selectedOptions={selectedOptions}
        showRowIcons={true}
        onlySelectWithRowIcons={true}
        leftSideTitle={t("admin:admin.organization.modal.add.allSkills")}
        rightSideTitle={t("admin:admin.organization.modal.add.selectedSkills")}
        onChange={setSelectedOptions}
        isScrollable={true}
        onKeywordsChange={filterSkillsOptions}
        searchPlaceholder={t('search')}
        />
}

export default Step3;