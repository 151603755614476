import React, { useState } from "react";
import { Accordion, Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SzIcon } from "@suezenv/react-theme-components";
import DataNotReady from "../Utils/messages/dataNotReady";
import "./detail.scss";

interface IControl {
  "control:control_type": string;
  "control:dateOfVisit": string;
  "control:status_id": string;
  "control:status_date": string;
  "control:result": string;
  "control:controlStatus_id": string;
  "control:raccordementEuEp": string;
  "control:raccordementEpEu": string;
  "dateITV": string;
  "datePrevue": string;
  "checkType": string;
  "conformite": string;
  "statut": string;
  "status": string;
}

interface IIntervention {
  "libelleActe": string;
  "libelleFamilleIntervention": string;
  "libelleIntervention": string;
  "numeroIntervention": string;
  "dateFinIntervention": string;
  "numeroActeMetier": string;
  "dateDebutIntervention": string;
  "codeIntervention": string;
  "libelleEtatIntervention": string;
  "libelleFamilleActe": string;
  "applicationSource": string;
  "systemeExterne": string;
}

interface ISiteIndustriel {
  "branchements": any;
  "visites": any;
  "conformity": string;
  "ID_CD92": string;
}
interface IAsset {
  "dernierEtatConformite": string;
  "equipementType": string;
  "exutoire": string;
  "typeAsset": string;
  "typeReseau": string;
  "boiteBranchement": string;
  "maitreOuvrageReseau": string;
  "dateDerniereEnquete": string;
  "dateDernierEtatControle": string;
}

const History = (
  props: {
    controls?: IControl[],
    interventions?: IIntervention[],
    siteIndustriels?: ISiteIndustriel[],
    listeAsset?: IAsset[],
  },
) => {

  function compareControls(a: IControl, b: IControl) {
    return new Date(b["control:status_date"]).getTime() - new Date(a["control:status_date"]).getTime();
  }

  function compareInterventions(a: IIntervention, b: IIntervention) {
    return new Date(b.dateFinIntervention).getTime() - new Date(a.dateFinIntervention).getTime();
  }

  function propertyDefaultMajor(a: IControl) {
    let defaultMajor: string | null = "Aucun";

    if (a["control:raccordementEuEp"] === "1") {
      defaultMajor = "EU dans EP";
    }

    if (a["control:raccordementEpEu"] === "1") {
      defaultMajor = "EP dans EU";
    }

    if (a["control:raccordementEuEp"] === "1" && a["control:raccordementEpEu"] === "1") {
      defaultMajor = "EU dans EP et EP dans EU";
    }

    return defaultMajor;
  }

  const getConformityStatus = (a: IControl) => {
    return t(`conformity.` + a["conformite"], { ns: 'report'});
  };

  const getCheckType = (checkType: string) => {
    return t(`conformityCheckType.` + checkType, { ns: 'report'});
  };

  const [controlOpen, setControlOpen] = useState<boolean>(true);
  const [controlListOpen, setControlListOpen] = useState<boolean>(true);
  const [assetListOpen, setAssetListOpen] = useState<boolean>(true);
  const [interventionOpen, setInterventionOpen] = useState<boolean>(true);
  const [interventionListOpen, setInterventionListOpen] = useState<boolean>(true);
  const [siteOpen, setSiteOpen] = useState<boolean>(true);
  const [siteListOpen, setSiteListOpen] = useState<boolean>(true);
  const { t } = useTranslation();

  const orderedControls: IControl[] = props.controls ? props.controls.sort(compareControls) : [];
  const orderedInterventions: IIntervention[] = props.interventions ?
    props.interventions.sort(compareInterventions) :
    [];
  if (props.controls || props.interventions || props.siteIndustriels) {
    return (
      <>
        {props.controls &&
          <Accordion defaultActiveKey="0">
            <Card className="container-bordered">
              <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setControlOpen(!controlOpen)}>
                {t("asset:history.lastVisit")}
                {controlOpen ?
                  <SzIcon variant="bold" icon="arrow-up-1" /> :
                  <SzIcon variant="bold" icon="arrow-down-1" />
                }
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  {orderedControls.length === 0 &&
                    <DataNotReady />
                  }
                  {orderedControls.length > 0 &&
                    <Table size="sm">
                      <tbody>
                        <tr>
                          <td className="gaia-td-align-right cell-width">{t("asset:history.prevueDate")} :</td>
                          <td className="gaia-td-align-left cell-width">
                            {orderedControls[0]["datePrevue"] != null ? new Date(orderedControls[0]["datePrevue"]).toLocaleDateString(): t('no-data')}
                          </td>
                        </tr>
                        <tr>
                          <td className="gaia-td-align-right cell-width">{t("asset:history.revisionDate")} :</td>
                          <td className="gaia-td-align-left cell-width">
                            {orderedControls[0]["dateITV"] != null ? new Date(orderedControls[0]["dateITV"]).toLocaleDateString(): t('no-data')}
                          </td>
                        </tr>
                        <tr>
                          <td className="gaia-td-align-right cell-width">{t("asset:history.lastRevisionType")} :</td>
                          <td className="gaia-td-align-left cell-width">
                            {getCheckType(orderedControls[0]["checkType"])}
                          </td>
                        </tr>
                        <tr>
                          <td className="gaia-td-align-right cell-width">{t("asset:history.conformity")} :</td>
                          <td className="gaia-td-align-left cell-width">
                            {getConformityStatus(orderedControls[0])}
                          </td>
                        </tr>
                        <tr>
                          <td className="gaia-td-align-right cell-width">{t("asset:history.statut")} :</td>
                          <td className="gaia-td-align-left cell-width">
                            {orderedControls[0]["statut"]}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  }
                  {orderedControls.length > 1 &&
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="0"
                          onClick={() => setControlListOpen(!controlListOpen)}
                        >
                          {t("asset:history.lastVisitList")}
                          {controlListOpen ?
                            <SzIcon variant="bold" icon="arrow-up-1" /> :
                            <SzIcon variant="bold" icon="arrow-down-1" />
                          }
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <Table size="sm">
                              <thead>
                                <tr>
                                  <th>{t("asset:history.prevueDate")}</th>
                                  <th>{t("asset:history.revisionDate")}</th>
                                  <th>{t("asset:history.conformity")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  orderedControls.map((e: IControl, key: number) => {
                                    if (0 !== key) {
                                      return (
                                        <tr key={key}>
                                          <td className="gaia-td-align-left">
                                            {e["datePrevue"] != null ? new Date(e["datePrevue"]).toLocaleDateString() : t('no-data')}
                                          </td>
                                          <td className="gaia-td-align-left">
                                            {e["dateITV"] != null ? new Date(e["dateITV"]).toLocaleDateString() : t('no-data')}
                                          </td>
                                          <td className="gaia-td-align-left">
                                            {getConformityStatus(e)}
                                          </td>
                                        </tr>
                                      );
                                    }
                                    return <></>;
                                  })
                                }
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  }
                  {props.listeAsset &&
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="0"
                          onClick={() => setAssetListOpen(!assetListOpen)}
                        >
                          {t("asset:history.listAsset")}
                          {controlListOpen ?
                            <SzIcon variant="bold" icon="arrow-up-1" /> :
                            <SzIcon variant="bold" icon="arrow-down-1" />
                          }
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <Table size="sm">
                              <tbody>
                                {
                                  props.listeAsset.map((equipement: IAsset, key: number) => {
                                    const typeAsset = equipement["typeAsset"];
                                    const dernierEtatConformite = equipement["dernierEtatConformite"];
                                    const type = equipement.typeReseau !== undefined ? equipement.typeReseau : equipement.equipementType;
                                    const display = t(`asset:history.asset.${typeAsset}`) + ' - ' + t(`asset:history.asset.${type}`);
                                      return (
                                        <tr key={key}>
                                          <td className="gaia-td-align-left">
                                            {display}
                                          </td>
                                          <td className="gaia-td-align-left">
                                            {t(`asset:conformity.${dernierEtatConformite}`)}
                                          </td>
                                        </tr>
                                      );
                                    return <></>;
                                  })
                                }
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  }
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        }
        {props.interventions &&
          <Accordion className="container-bordered" defaultActiveKey="1">
            <Card>
              <Accordion.Toggle as={Card.Header} eventKey="1" onClick={() => setInterventionOpen(!interventionOpen)}>
                {t("asset:history.interventionList")}
                {interventionOpen ?
                  <SzIcon variant="bold" icon="arrow-up-1" /> :
                  <SzIcon variant="bold" icon="arrow-down-1" />
                }
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  {orderedInterventions.length === 0 &&
                    <DataNotReady />
                  }
                  {orderedInterventions.length > 0 &&
                    <Table size="sm">
                      <tbody>
                        <tr>
                          <td className="gaia-td-align-right cell-width">
                            {t("asset:intervention.lastMaintenanceDate")} :
                          </td>
                          <td className="gaia-td-align-left cell-width">
                            {new Date(orderedInterventions[0].dateFinIntervention).toLocaleDateString()}
                          </td>
                        </tr>
                        <tr>
                          <td className="gaia-td-align-right cell-width">
                            {t("asset:intervention.applicationSource")} :
                          </td>
                          <td className="gaia-td-align-left cell-width">
                            {orderedInterventions[0].applicationSource}
                          </td>
                        </tr>
                        <tr>
                          <td className="gaia-td-align-right cell-width">
                            {t("asset:intervention.systemeExterne")} :
                          </td>
                          <td className="gaia-td-align-left cell-width">
                            {orderedInterventions[0].systemeExterne}
                          </td>
                        </tr>
                        <tr>
                          <td className="gaia-td-align-right cell-width">
                            {t("asset:intervention.interventionFamily")} :
                          </td>
                          <td className="gaia-td-align-left cell-width">
                            {orderedInterventions[0].libelleFamilleIntervention ?
                              (orderedInterventions[0].libelleFamilleIntervention.includes("Erreur") ?
                                t("no-data") :
                                orderedInterventions[0].libelleFamilleIntervention
                              ) :
                              t("no-data")
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="gaia-td-align-right cell-width">
                            {t("asset:intervention.interventionType")} :
                          </td>
                          <td className="gaia-td-align-left cell-width">
                            {orderedInterventions[0].libelleIntervention ?
                              (orderedInterventions[0].libelleIntervention.includes("Erreur") ?
                                t("no-data") :
                                orderedInterventions[0].libelleIntervention
                              ) :
                              t("no-data")
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="gaia-td-align-right cell-width">
                            {t("asset:intervention.interventionNumber")} :
                          </td>
                          <td className="gaia-td-align-left cell-width">
                            {orderedInterventions[0].numeroIntervention}
                          </td>
                        </tr>
                        <tr>
                          <td className="gaia-td-align-right cell-width">
                            {t("asset:intervention.interventionCode")} :
                          </td>
                          <td className="gaia-td-align-left cell-width">
                            {orderedInterventions[0].codeIntervention}
                          </td>
                        </tr>
                        <tr>
                          <td className="gaia-td-align-right cell-width">
                            {t("asset:intervention.interventionDate")} :
                          </td>
                          <td className="gaia-td-align-left cell-width">
                            {new Date(orderedInterventions[0].dateDebutIntervention).toLocaleDateString()}
                          </td>
                        </tr>
                        <tr>
                          <td className="gaia-td-align-right cell-width">
                            {t("asset:intervention.interventionState")} :
                          </td>
                          <td className="gaia-td-align-left cell-width">
                            {orderedInterventions[0].libelleEtatIntervention ?
                              (orderedInterventions[0].libelleEtatIntervention.includes("Erreur") ?
                                t("no-data") :
                                orderedInterventions[0].libelleEtatIntervention
                              ) :
                              t("no-data")
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="gaia-td-align-right cell-width">
                            {t("asset:intervention.actType")} :
                          </td>
                          <td className="gaia-td-align-left cell-width">
                            {orderedInterventions[0].libelleActe.includes("Erreur") ?
                              t("no-data") :
                              orderedInterventions[0].libelleActe
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="gaia-td-align-right cell-width">
                            {t("asset:intervention.actFamilyType")} :
                          </td>
                          <td className="gaia-td-align-left cell-width">
                            {orderedInterventions[0].libelleFamilleActe.includes("Erreur") ?
                              t("no-data") :
                              orderedInterventions[0].libelleFamilleActe
                            }
                          </td>
                        </tr>
                        <tr>
                          <td className="gaia-td-align-right cell-width">
                            {t("asset:intervention.actNumber")} :
                          </td>
                          <td className="gaia-td-align-left cell-width">
                            {orderedInterventions[0].numeroActeMetier}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  }
                  {orderedInterventions.length > 1 &&
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Accordion.Toggle
                          as={Card.Header}
                          eventKey="0"
                          onClick={() => setInterventionListOpen(!interventionListOpen)}
                        >
                          {t("asset:intervention.lastInterventionList")}
                          {interventionListOpen ?
                            <SzIcon variant="bold" icon="arrow-up-1" /> :
                            <SzIcon variant="bold" icon="arrow-down-1" />
                          }
                        </Accordion.Toggle>
                        <Accordion.Collapse eventKey="0">
                          <Card.Body>
                            <Table size="sm" className="table-overflow">
                              <thead>
                                <tr>
                                  <th>{t("asset:intervention.endInterventionDate")}</th>
                                  <th>{t("asset:intervention.interventionFamily")}</th>
                                  <th>{t("asset:intervention.interventionType")}</th>
                                  <th>{t("asset:intervention.applicationSource")}</th>
                                  <th>{t("asset:intervention.systemeExterne")}</th>
                                  <th>{t("asset:intervention.interventionNumber")}</th>
                                  <th>{t("asset:intervention.interventionCode")}</th>
                                  <th>{t("asset:intervention.actNumber")}</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  orderedInterventions.map((e: IIntervention, key: number) => {
                                    if (0 !== key) {
                                      return (
                                        <tr key={key}>
                                          <td className="gaia-td-align-left">
                                            {new Date(e.dateFinIntervention).toLocaleDateString()}
                                          </td>
                                          <td className="gaia-td-align-left">
                                            {e.libelleFamilleIntervention ?
                                              (e.libelleFamilleIntervention.includes("Erreur") ?
                                                t("no-data") :
                                                e.libelleFamilleIntervention
                                              ) :
                                              t("no-data")
                                            }
                                          </td>
                                          <td className="gaia-td-align-left">
                                            {e.libelleIntervention ?
                                              (e.libelleIntervention.includes("Erreur") ?
                                                t("no-data") :
                                                e.libelleIntervention
                                              ) :
                                              t("no-data")
                                            }
                                          </td>
                                          <td className="gaia-td-align-right">
                                            {e.applicationSource}
                                          </td>
                                          <td className="gaia-td-align-right">
                                            {e.systemeExterne}
                                          </td>
                                          <td className="gaia-td-align-right">
                                            {e.numeroIntervention}
                                          </td>
                                          <td className="gaia-td-align-right">
                                            {e.codeIntervention}
                                          </td>
                                          <td className="gaia-td-align-right">
                                            {e.numeroActeMetier}
                                          </td>
                                        </tr>
                                      );
                                    }
                                    return <></>;
                                  })
                                }
                              </tbody>
                            </Table>
                          </Card.Body>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  }
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        }
        {props.siteIndustriels && props.siteIndustriels.map((e: ISiteIndustriel, key: number) => {
          return (
            <Accordion defaultActiveKey="0">
              <Card className="container-bordered">
                <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setSiteOpen(!siteOpen)}>
                  {t("asset:siteIndustriel.lastVisit")} {e.ID_CD92}
                  {siteOpen ?
                    <SzIcon variant="bold" icon="arrow-up-1" /> :
                    <SzIcon variant="bold" icon="arrow-down-1" />
                  }
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0">
                  <Card.Body>
                    {e.visites.length === 0 &&
                      <DataNotReady />
                    }
                    {e.visites.length > 0 &&
                      <Table size="sm">
                        <tbody>
                          <tr>
                            <td className="gaia-td-align-right cell-width">{t("asset:siteIndustriel.visitDate")} :</td>
                            <td className="gaia-td-align-left cell-width">
                              {new Date(e.visites[0].DATE_DEBUT).toLocaleDateString()}
                            </td>
                          </tr>
                          <tr>
                            <td className="gaia-td-align-right cell-width">{t("asset:siteIndustriel.sampling")} :</td>
                            <td className="gaia-td-align-left cell-width">
                              {e.visites[0].PRELEVEMENT === "O" ? "Oui" : "Non"}
                            </td>
                          </tr>
                          <tr>
                            <td className="gaia-td-align-right cell-width">{t("asset:siteIndustriel.conform")} :</td>
                            <td className="gaia-td-align-left cell-width">
                              {e.conformity}
                            </td>
                          </tr>
                          <tr>
                            <td className="gaia-td-align-right cell-width">
                              {t("asset:siteIndustriel.samplingType")} :
                            </td>
                            <td className="gaia-td-align-left cell-width">
                              {e.visites[0].NATURE}
                            </td>
                          </tr>
                          <tr>
                            <td className="gaia-td-align-right cell-width">
                              {t("asset:siteIndustriel.analyseNumber")} :
                            </td>
                            <td className="gaia-td-align-left cell-width">
                              {e.visites[0].NB_ANALYSE}
                            </td>
                          </tr>
                          <tr>
                            <td className="gaia-td-align-right cell-width">
                              {t("asset:siteIndustriel.nonConfomityNumber")} :
                            </td>
                            <td className="gaia-td-align-left cell-width">
                              {e.visites[0].NB_NC}
                            </td>
                          </tr>
                          <tr>
                            <td className="gaia-td-align-right cell-width">
                              {t("asset:siteIndustriel.samplingNumber")} :
                            </td>
                            <td className="gaia-td-align-left cell-width">
                              {e.visites[0].NB_PRELEVEMENT}
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    }
                    {e.visites.length > 1 &&
                      <Accordion defaultActiveKey="0">
                        <Card>
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey="0"
                            onClick={() => setSiteListOpen(!siteListOpen)}
                          >
                            {t("asset:siteIndustriel.lastVisitList")} {e.ID_CD92}
                            {siteListOpen ?
                              <SzIcon variant="bold" icon="arrow-up-1" /> :
                              <SzIcon variant="bold" icon="arrow-down-1" />
                            }
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              <Table size="sm">
                                <thead>
                                  <tr>
                                    <th>{t("asset:siteIndustriel.visitDate")}</th>
                                    <th>{t("asset:siteIndustriel.conform")}</th>
                                    <th>{t("asset:siteIndustriel.samplingType")}</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {
                                    e.visites.map((v: any, visitKey: number) => {
                                      if (0 !== visitKey) {
                                        return (
                                          <tr key={visitKey}>
                                            <td className="gaia-td-align-left">
                                              {new Date(v.DATE_DEBUT).toLocaleDateString()}
                                            </td>
                                            <td className="gaia-td-align-left">{e.conformity}</td>
                                            <td className="gaia-td-align-left">
                                              {v.NATURE}
                                            </td>
                                          </tr>
                                        );
                                      }
                                      return <></>;
                                    })
                                  }
                                </tbody>
                              </Table>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </Accordion>
                    }
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          );
        })}
      </>
    );
  }

  return <DataNotReady />;
};

export default History;
