import React from "react";
import { StepManager } from "./StepManager";
import Step3Form from "../../../components/admin/contracts/forms/Step3Form";

class ContractsCreateStep3Manager extends StepManager {

    public render() {
        const { currentStep, previousStepHandle, formData, setFormData } = this.props;

        return <Step3Form
            currentStep={currentStep}
            previousStepHandle={previousStepHandle.bind(this)}
            nextStepHandle={this.nextStepHandle.bind(this)}
            formData={formData}
            setFormData={setFormData}
        />;
    }
}

export default ContractsCreateStep3Manager;
