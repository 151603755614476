import { Formik } from "formik";
import i18next from "i18next";
import React, { useState } from "react";
import { Form } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzModal } from "@suezenv/react-theme-components";
import { bindActionCreators } from "redux";
import * as Yup from "yup";
import { initCustomLayer } from "../../../manager/map";
import { goToHome } from "../../../manager/map/navigation";
import Request from "../../../services/Request";
import {FiltersActions, ReportActions, SearchActions} from "../../../store/redux/actions";
import { ISearch } from "../../../store/redux/types";
import AutoCompleteSuggestion from "../../Utils/Suggestion/AutocompleteSuggestion";
import HistorySuggestion from "../../Utils/Suggestion/HistorySuggestion";
import "./search.scss";

const ENTER_KEY = 13;

function SearchForm(props: any) {

  const { t } = useTranslation();

  const minCharInSearch = 3;
  const {
    initSearch,
    setAutocomplete,
    setUserInputText,
    getSigObjectByIdent,
    getSearch,
    isUserInputTooShort,
    resetFilters,
    hideReport
  } = props;

  const { userInput } = props.search;

  const [displaySuggestions, setDisplaySuggestions] = useState(false);
  const [searchInputHasChanged, setSearchInputHasChanged] = useState(false);

  const hideSuggestions = () => {
    setDisplaySuggestions(false);
    setSearchInputHasChanged(false);
    hideReport();
  };

  const {
    userInputTooShort,
  } = props.search.flag;

  const schema = {
    search: {
      className: "pr-md-3",
      placeholder: "Recherche",
      schema: Yup.string()
        .min(2, "Le champ recherche doit être rempli.")
        .required("Le champ recherche doit être rempli."),
      type: "text",
    },
  };
  const clearSearch = () => {
    setUserInputText("");
    initSearch();
    initCustomLayer();
    goToHome();
    Request.cancelRequest();
  };

  const handleChange = (event: any) => {
    const textSearch = event.currentTarget.value;
    setUserInputText(textSearch);
  };

  const onKeyDown = (event: any) => {
    switch (event.keyCode) {
      case ENTER_KEY: {
        searchSubmit();
        hideReport();
        break;
      }
      default : {
        setSearchInputHasChanged(true);
      }
    }
  };

  const isNumeric = (text: any): boolean => {
    return (!isNaN(text) && !isNaN(parseFloat(text)));
  };

  const isIdent = (text: any): boolean => {
    return (!isNumeric(text) && text.match(/^[0-9 -]*$/));
  };

  const searchSubmit = () => {
    if (props.search.userInput.length < minCharInSearch && !isNumeric(props.search.userInput)) {
      isUserInputTooShort(true);
      return false;
    }
    setSearchInputHasChanged(false);
    setDisplaySuggestions(false);
    resetFilters();
    goToHome();
    setAutocomplete({
      flag: { loading: true, showSuggestions: false },
      suggestions: { activeSuggestion: 0 },
    });
    if (isIdent(props.search.userInput)) {
      getSigObjectByIdent(props.search.userInput);
    } else {
      getSearch(props.search, [], false);
    }
  };

  const handleClose = () => {
    isUserInputTooShort(false);
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={{ userInput: null }}
      onSubmit={searchSubmit}
      render={() => (
        <>
          {userInputTooShort && (
            <SzModal
              title={i18next.t("error.search.title")}
              show={true}
              handleClose={handleClose}
              size={'lg'}
            >
              {i18next.t("error.search.text")}
            </SzModal>
          )}
          <div className="sz-form-group input-group">
            <Form.Group className="gaia-search">
              <Form.Control
                autoComplete="off"
                placeholder="Recherche"
                type="text"
                name="search"
                value={userInput}
                onChange={handleChange}
                onKeyDown={onKeyDown}
                onFocus={() => setDisplaySuggestions(true)}
                onBlur={() => setTimeout(() => setDisplaySuggestions(false), 500)}
                className="gaia-search__input sz-form-group__sz-input-text
                            pl-3 sz-form-group__sz-input-text--icon form-control"
              />
              {userInput && (
                <div
                  className="gaia-search__input-clear position-absolute d-flex
                            align-items-center justify-content-center text-primary input-group-prepend"
                  onClick={clearSearch}
                >
                  <OverlayTrigger
                    placement="bottom"
                    delay={{show: 100, hide: 200}}
                    overlay={
                      <Tooltip id="tooltip">
                        {t("reset_search")}
                      </Tooltip>
                    }
                  >
                  <i className="material-icons" aria-hidden="true" >close</i>
                  </OverlayTrigger>
                </div>
              )}
              {
                <div
                  className="sz-form-group__input-group-icon d-flex
                                    align-items-center justify-content-center text-primary input-group-prepend focused"
                  onClick={searchSubmit}
                >
                  <i className="material-icons material-icons-24px" aria-hidden="true">search</i>
                </div>
              }
              {displaySuggestions &&
              (userInput.length < minCharInSearch || !searchInputHasChanged ?
                <HistorySuggestion quantity={5}  hide={hideSuggestions}/>
              : <AutoCompleteSuggestion debounce={500} hide={hideSuggestions} minAutoCompleteLength={minCharInSearch}/>
              )}
            </Form.Group>
          </div>
        </>
      )}
    />
  );
}

const mapStateToProps = (state: { search: ISearch }) => {
  return {
    search: state.search,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...FiltersActions, ...SearchActions, ...ReportActions }, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SearchForm);
