import { MAP } from "../constants";

const initialState: any = {
  features: [],
  center: null,
  extent: null,
  autoZoom: true,
  resize: true,
  zoom: 0,
  highlight: [],
  baseLayer: "osmLayer",
  assetLayers: [],
  firstLogin: true,
};

const mapReducer = (state = initialState, action: any): any => {
  switch (action.type) {
    case MAP.SET_MAP_COORDINATE:
      return {
        ...state,
        features: action.payload.data || [],
      };
    case MAP.SET_MAP_INFO:
      return {
        ...state, ...action.payload,
      };
    default:
      return state;
  }
};

export default mapReducer;
