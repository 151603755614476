import React from "react";
import { StepManager } from "./StepManager";
import Step1Form from "../../../components/admin/contracts/forms/Step1Form";

class ContractsCreateStep1Manager extends StepManager {

    public render() {
        const { currentStep, formData, setFormData } = this.props;

        return <Step1Form
            currentStep={currentStep}
            nextStepHandle={this.nextStepHandle.bind(this)}
            formData={formData}
            setFormData={setFormData}
        />;
    }
}

export default ContractsCreateStep1Manager;
