import { config } from "../config";

export enum ActionTypes {
    GET_USER_CONFIGURATION = "GET_USER_CONFIGURATION",
    SET_USER_CONFIGURATION = "SET_USER_CONFIGURATION",
}

export enum Themes {
    ONDIJON = 'onDijon',
    VILLAGILE = 'villagile',
}

export enum Constants {
    CURRENT_CONTRACT = 'current_contract',
    DATA_LOADING = "DATA_LOADING",
    DISPLAYED_WIDGETS_CONFIGURATION_CODE = "displayed_widgets",
    DEFAULT_CURRENT_PAGE = 1,
    DEFAULT_PER_PAGE = 20,
    ERROR = 'ERROR',
    ERROR_INTERVENTION = "ERROR_INTERVENTION",
    HYDRO_WIDGET_INTERVAL = 4,
    HYDRO_WIDGET_MAX = 12,
    INTERVENTION_SEARCH = 'intervention_search',
    LOG_IN = "login",
    LOG_OUT = "logout",
    PRIMARY_LOGO = "primary_logo",
    SECONDARY_LOGO = "secondary_logo",
    PRIMARY_COLOR = "primary_color",
    SECONDARY_COLOR = "secondary_color",
    NOTIFICATIONS_PER_PAGE = 20,
    PORTAL_NAME = "ZEUS",
    UI_SHOW_MODAL = "ui_show_modal",
    UI_HIDE_MODAL = "ui_hide_modal",
    USER_PERMISSIONS = "user_permissions",
    USERS_PAGINATION_PAGE_RANGE = 5,
    CONTRACTS_PAGINATION_PAGE_RANGE = 5,
    USER_SKILLS = "user_skills",
    WIDGETS_GRID_LAYOUT_CONFIGURATION_CODE = "widgets_grid_layout",
    SIGNAL_TOP_CATEGORY_LENGTH = 5
}

export enum Pagination {
    PAGINATION_TOTAL_COUNT = 'x-pagination-total-count',
    PAGINATION_PAGE_COUNT = 'x-pagination-page-count',
    PAGINATION_CURRENT_PAGE = 'x-pagination-current-page',
    PAGINATION_PER_PAGE = 'x-pagination-per-page'
}

//villagile
export enum Map {
    BOUNDS_FRANCE = "[[42.252918, -5.009766], [51.193115, 8.481445]]",
    DEFAULT_LAT = 47.322047,
    DEFAULT_LNG = 5.04148,
    OSM_URI = 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
    ZOOM_DEFAULT = 12,
    ZOOM_MIN = 6,
    ZOOM_MAX = 20,
    MAX_NATIVE_ZOOM = 18,
}

export const RoleAdminPortal = "ROLE_SUPER_ADMIN_PORTAL_" + config.portal;

export enum Roles {
    ROLE_USER = 'ROLE_USER',
    ROLE_SUPER_ADMIN_CONTRACT = 'ROLE_SUPER_ADMIN_CONTRACT',
    ROLE_SUPER_ADMIN_ORGANIZATION = 'ROLE_SUPER_ADMIN_ORGANIZATION',
    SUPER_ADMIN = 'SUPER_ADMIN',
    ROLE_ADMIN_PORTAL = 'ROLE_SUPER_ADMIN_PORTAL',
}

export type GiveableRoles = Roles.ROLE_USER | Roles.ROLE_SUPER_ADMIN_ORGANIZATION | Roles.ROLE_SUPER_ADMIN_CONTRACT;

export enum Families {
    HYDROMETRIC = 'hydrometric',
    INTERVENTIONS = 'interventions',
    WATER_CYCLE = 'waterCycle',
    SIGNAL = 'signal'
}

export enum widgetTypes {
    HYDROMETRIC = "hydrometric",
    INTERVENTIONS = 'interventions',
    SIGNAL_STAT_CONDENSED = 'signalStatCondensed',
    SIGNAL_STAT_FULL = 'SignalStatFull',
    SIGNAL_TOP_CATEGORY = 'SignalTopCategory',
    WATER_CYCLE = 'waterCycle',
    WATER_CONFORMITY = 'waterConformity',
}

export enum Services {
    CARTOGRAPHY = 'cartography',
    HYDROMETRIC = 'hydrometric',
    INTERVENTIONS = 'interventions',
    SIGNAL_NOTIFY = "SIGNAL_NOTIFY",
    WATER_CYCLE = 'waterCycle',
    WATER_CONFORMITY = 'waterConformity'
}

export enum OrganizationTypes {
    COMMUNITY = 'Community',
    EXTERNAL_ENTITY = 'ExternalEntity',
    OPERATOR = 'Operator'
}

export enum HttpCode {
    NOT_FOUND = 404,
    ACCESS_DENIED = 401,
    OOPS = 500,
    UNSUPPORTED_MEDIA = 415
}

export enum AppUrls {
    ADMIN_CONTRACTS = '/admin/contract',
    ADMIN_CONTRACTS_DETAILS = '/admin/contrat/:contractId',
    ADMIN_CONTRACTS_CREATE = '/admin/contrat/creation',
    ADMIN_CONTRACTS_CREATE_SUCCESS = '/admin/contrat/creation/validation',
    ADMIN_CONTRACTS_TOFINISH = '/admin/contrat/a-finaliser',
    ADMIN_GESTION_TERRITORIALE = '/gestion-territoriale',
    CARTO = '/cartographie',
    CGU = '/conditions-generales-d-utilisation-portail',
    HELP = '/aide',
    HOME = '/',
    LEGAL_POLICIES = '/mentions-legales-portail',
    LOGOUT = '/deconnexion',
    SAML_LOGIN = '/saml/login',
    SAML_LOGOUT = 'saml/logout',
    USER_PROFILE = '/mon-profil',
}

export enum AppAdminDetailsUrls {
    AREA_DETAILS = "/area/:areaId",
    ORGANIZATION_DETAILS = "/organisation-territoriale/:organizationId",
    OPERATOR_DETAILS = "/exploitant/:operatorId",
    USER_DETAILS = "/utilisateur/:userId"
}

export enum adminTabsEventKeys {
    CONTRACT_AREA = "contract-areas",
    CONTRACT_USERS = "contract-users",
    CONTRACT_ORGANIZATIONS = "contract-organizations",
    CONTRACT_OPERATOR = "contract-operator",
    CONTRACT_SKILLS = "contract-skills",
    CONTRACT_SERVICES = "contract-services",
    AREA_ORGANIZATIONS = "area-organizations",
    AREA_OPERATORS = "area-operators",
    AREA_SKILLS = "area-skills",
    ORGANIZATION_USERS = "organization-users",
    ORGANIZATION_AREAS = "organization-areas",
    ORGANIZATION_SERVICES = "organization-services",
    USER_ORGANIZATIONS = "user-organizations",
    USER_OPERATORS = "user-operators",
    USER_AREAS = "user-areas",
}

export enum ErrorRoutes {
    NOT_FOUND = '/error/unknown',
    ACCESS_DENIED = '/error/access-denied',
    OOPS = '/error/oops',
    SSO_ERROR = '/error/sso'
}

export enum ApiUrls {
    AREA_CATEGORIES = '/api/contract/:contractId/area/:areaId/category',
    AREA_ORGANIZATIONS = '/api/contract/:contractId/area/:areaId/organization',
    AREA_AND_CONTRACT_AREA_URL = "/api/contract/:contractId/contractArea",
    AREAS_NB_CATEGORIES = '/api/contract/:contractId/areas/skillset',
    AREAS_NB_ORGANIZATION = '/api/contract/:contractId/areas/organization',
    APPLICATION_CONFIGURATION_CREATE = '/api/external-apps/configuration',
    APPLICATION_CONFIGURATION_GET_BY_OWNER_TYPE = '/api/external-apps/configuration/:ownerType/:ownerId',
    CONTRACT_BASEMAP = 'api/contract-basemap',
    CONTRACT_INFO_URL = 'api/contract-info',
    CONTRACT_SERVICE = '/api/contract/:contractId/contractService',
    CONTRACT_SERVICE_DELETE = '/api/contractService/:contractServiceId',
    HYDRO_URL = '/api/hydrometry',
    ITV_COUNT_URL = 'api/intervention-count',
    ITV_URL = 'api/interventions',
    LEGAL_ENTITIES_BY_CONTRACT = '/api/contract/:contractId/legalEntity',
    TOKEN_INFO_URL = '/api/token-check',
    USER_AREAS = "/api/user/:userId/area",
    USER_CONFIGURATION = '/api/user/portal/:portalCode/configuration',
    USER_GEOSHAPE = "/api/user/:userId/geoshape",
    USERS_NB_ORGANIZATION = '/api/contract/:contractId/users/organization',
    USER_SKILLS = '/api/user-skill-menu',
    USERS_WITH_ROLES = '/api/contract/:contractId/user/role',
    USERS = '/api/contract/:contractId/user',
    USER = '/api/user/:userId',
    CONTRACT = '/api/contract',
    CONTRACT_BY_ID = '/api/contract/:contractId',
    CONTRACT_AREAS = '/api/contract/:contractId/areas',
    CONTRACT_AREA = '/api/contract/:contractId/area/:areaId',
    CONTRACTS = '/api/contract',
    CONTRACTS_BY_USER = '/api/user/contracts',
    MUNICIPALITIES = '/api/municipalities',
    ORGANIZATIONS = '/api/contract/:contractId/organization',
    ORGANIZATION = '/api/organization/:organizationId',
    ORGANIZATION_SERVICES = '/api/organization/:organizationId/service',
    ORGANIZATION_SERVICE_DELETE = '/api/organization/:organizationId/service/:serviceCode',
    ORGANIZATION_USERS = '/api/organization/:organizationId/user',
    ORGANIZATION_BY_USER = '/api/user/:userId/organizations',
    ORGANIZATION_AREAS = '/api/organization/:organizationId/area',
    ORGANIZATIONS_NB_USER = '/api/contract/:contractId/organizations/user',
    UPDATE_ORGANIZATION_SERVICES = '/api/organization/:organizationId/organization_contract_service',
    PERMISSIONS_ADMIN = '/api/permissions/admin',
    PERMISSIONS_USER = '/api/permissions/user',
    SERVICE = '/api/service',
    SITE_CONFIGURATION = '/api/contract/:contractId/siteConfiguration',
    SIGNAL_STATS = '/api/widget/signal/stats',
    SIGNAL_TOP_CATEGORY = '/api/widget/signal/top-category/:length',
    SKILLSETS = '/api/category',
    SKILLSETS_BY_CONTRACT = '/api/contract/:contractId/category',
    WATER_QUALITY_URL = 'api/water-quality',
    WATER_CYCLE_URL = 'api/water-cycle',
    WEATHER_URL = 'api/weather',
}

export enum VdmStatusMap {
    ONGOING = 'Enc' as any,
    PLANNED = 'Pla' as any,
    DONE = 'Rea' as any
}

export enum StatusMap {
    ONGOING = 'ongoing' as any,
    PLANNED = 'planned' as any,
    DONE = 'done' as any,
    ONGOING_SHORT = 'Enc' as any,
    PLANNED_SHORT = 'Pla' as any,
    DONE_SHORT = 'Rea' as any
}

export enum Tabs {
    DASHBOARD = 'dashboard',
    MAP = 'map',
}

export enum Interventions {
    ITV_COUNT_PER_PAGE = 9999
}

export enum ServiceMetadataField {
    GROUP = 'group',
    THEME = 'theme',
    URL = 'url',
}

export enum ReactGridLayout {
    MARGIN = 15,
    ROW_HEIGHT = 115,
    WIDTH_LG = 1200,
    WIDTH_MD = 768,
    WIDTH_XS = 0,
    COL_LG = 12,
    COL_MD = 6,
    COL_XS = 3,

}

export const ReactGridLayoutBreakpoints = {
    lg: ReactGridLayout.WIDTH_LG,
    md: ReactGridLayout.WIDTH_MD,
    xs: ReactGridLayout.WIDTH_XS,
}

export const ReactGridLayoutCols = {
    lg: ReactGridLayout.COL_LG,
    md: ReactGridLayout.COL_MD,
    xs: ReactGridLayout.COL_XS,
}

export const acceptedImageMimeTypes = [
    'image/png',
    'image/jpeg',
    'image/gif',
    'image/x-icon',
    'image/svg+xml',
    'image/tiff',
    'image/webp'
]
