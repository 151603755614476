import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SzDatePicker, SzInput, SzButton, SzTextArea, SzTypographie } from "@suezenv/react-theme-components";
import { PostContractRequest } from "../../../../types";
import BlockHeader from "../../../elements/BlockHeader";

type PropsType = {
    currentStep: number,
    nextStepHandle: () => void;
    formData: PostContractRequest;
    setFormData: (formData: PostContractRequest) => void;
}

const Step1From = (props: PropsType) => {
    const { nextStepHandle, formData, setFormData } = props;
    const { t } = useTranslation();
    const [contract, setContract] = useState(formData.contractName);
    const [contractError, setContractError] = useState(false);
    const [description, setDescription] = useState(formData.description);
    const [startDate, setStartDate] = useState<Date>(formData.startDate);
    const onChangeStartDate = (startDate: Date) => setStartDate(startDate);


    const validateStep = () => {
        const isValid = contract.length >= 3;
        if (isValid) {
            setFormData({ ...formData, contractName: contract, description: description, startDate: startDate });
            nextStepHandle();
        } else {
            setContractError(true);
        }
    }

    return <div>
        <div className="box">
            <div className="row">
                <BlockHeader title={t('admin:admin.contracts.create.new_contract')}
                             subtitle={t('admin:admin.contracts.create.general_info')}
                             icon={"business-contract-handshake-sign"}/>
            </div>
            <div className="m-4">
                <div className="my-3">
                    <div className="col">
                        <SzInput
                            error={contractError}
                            value={contract}
                            onChange={({ target }) => {
                                setContract(target.value);
                                setContractError(false);
                            }}
                            label={t('admin:forms.contractCreate.contract.label')}
                            placeholder={t('admin:forms.contractCreate.contract.placeholder')}
                            icon="pencil-write"
                        />
                        {contractError &&
                            <SzTypographie variant="caption" color={"danger"}>
                                {t('admin:admin.contracts.create.inputError')}
                            </SzTypographie>
                        }
                    </div>
                </div>
                <div className="my-4">
                    <div className="mx-4">
                        <SzDatePicker
                            startDate={startDate}
                            onChangeStartDate={onChangeStartDate}
                            format={t('admin:forms.contractCreate.startDate.format')}
                        />
                    </div>
                    <div className="col">
                        <SzTextArea
                            label={t('admin:forms.contractCreate.description.label')}
                            value={description}
                            onChange={({ target }) => {
                                setDescription(target.value);
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
        <div className="mt-3 text-right">
            <SzButton
                className="next-btn"
                onClick={validateStep}
                isDisabled={contractError}
            >
                {t('next')}
            </SzButton>
        </div>
    </div>;
}

export default Step1From;
