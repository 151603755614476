import React, { useEffect, useState } from "react";
import { useTranslation  } from "react-i18next";
import { connect } from "react-redux";
import { SzButton, SzModal } from "@suezenv/react-theme-components";
import { bindActionCreators } from "redux";
import { Authenticate } from "../../../services/Authenticate";
import { ErrorActions, SearchActions } from "../../../store/redux/actions";
import "./errorMessage.scss";

const ErrorAuthenticationMessage = (props: any) => {
  const { t } = useTranslation();
  const [show, isShow] = useState(false);

  useEffect(() => {
    isShow(props.show);
  }, [props]);

  const handleClose = () => {
    Authenticate.logout();
  };

  const handleClick = (e: any) => {
    e.preventDefault();
    Authenticate.logout();
  };

  return (
    <>
      <SzModal
        title={t("error.sessionExpired")}
        show={show}
        handleClose={handleClose}
        size={'lg'}
      >
        <p className="text-center">{t("error.connectMesssage")}</p>
        <div className="mt-1 m-auto">
          <div className="row justify-content-center">
            <SzButton className="col-9 col-md-3 m-0" onClick={handleClick}>
              {t("error.connectButton")}
            </SzButton>
          </div>
        </div>
      </SzModal>
    </>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({...ErrorActions, ...SearchActions}, dispatch),
});

export default connect(null, mapDispatchToProps)(ErrorAuthenticationMessage);
