import React, { useEffect, useState } from 'react';
import { SzAlert, SzIcon, SzModal, SzPagination, SzSpinner, SzTable } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next"
import { useQuery, useQueryClient } from "react-query";
import { OrganizationService } from "../../../../services/OrganizationService";
import { Pagination, Service } from "../../../../types";
import { Constants } from "../../../../constants";
import TabHeader from "../../adminTabs/tabHeader";
import AdminOrganizationAddService from "./AdminOrganizationAddService";
import { CommonHelper } from "../../../../helper/Common";
import ConfirmationModal from "../../../elements/ConfirmationModal";

type ServiceData = {
    actions: JSX.Element,
    label: string,
    skillSet: string,
}

const AdminOrganizationServices = (props: { organizationId: string, readonly?: boolean }) => {
    const { organizationId, readonly } = props;
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [servicesData, setServicesData] = useState<ServiceData[]>();
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState<Pagination>();
    const [queryEnabled, setQueryEnabled] = useState<boolean>(false);
    const [showAddModal, setShowAddModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [serviceToDeleteCode, setServiceToDeleteCode] = useState<string>("");
    const [serviceToDeleteName, setServiceToDeleteName] = useState<string>("");

    const fetchOrganizationServices = () => OrganizationService.getOrganizationServices(organizationId, currentPage);

    const {
        data,
        isError,
        isLoading,
        isSuccess
    } = useQuery(['organizationServices', organizationId, currentPage], fetchOrganizationServices, { enabled: queryEnabled });

    useEffect(() => {
        if ('undefined' !== typeof organizationId) {
            setQueryEnabled(true);
        }
    }, [organizationId]);

    useEffect(() => {
        if (isSuccess) {
            const services: Service[] = data?.data
            let ServiceData: ServiceData[] = [];
            services.forEach((service: Service) => ServiceData.push(formatData(service)));
            setServicesData(ServiceData);
            setPagination(CommonHelper.getPagination(services.length, data?.headers));
        }
    }, [data]);

    const deleteOrganizationContractService = () => {
        setShowConfirmationModal(false);
        OrganizationService.deleteOrganizationService(organizationId, serviceToDeleteCode)
            .then(() => {
                queryClient.invalidateQueries("organizationServices")
                    .then(hideDeleteOrganizationContractServiceConfirmationModal);
            });
    }

    const showDeleteConfirmationModal = (service: Service) => {
        setServiceToDeleteCode(service.code);
        setServiceToDeleteName(service.label);
        setShowConfirmationModal(true);
    }

    const hideDeleteOrganizationContractServiceConfirmationModal = () => {
        setShowConfirmationModal(false);
        setServiceToDeleteCode("");
        setServiceToDeleteName("");
    }

    const defaultSorted: any = [
        {
            dataField: 'name',
            order: 'desc'
        }
    ];

    const columns = [
        {
            dataField: 'label',
            text: t('admin:admin.services.name')
        },
        {
            dataField: 'skillSet',
            text: t('admin:admin.services.skillSet')
        }
    ];

    if (!readonly) {
        columns.push(
            {
                dataField: 'actions',
                text: ''
            })
    }

    const formatData = (service: Service): ServiceData => {
        return {
            label: service.label,
            skillSet: service.categories?.length > 0 ? service.categories?.length.toString() : t("admin:admin.services.transversal"),
            actions: <div className={'text-right'}>
                <SzIcon variant='line' icon='task-list-text-1' className="color-primary pr-3"/>
                <SzIcon variant='line' icon='bin-2-alternate' className="color-danger pr-5"
                        onClick={() => showDeleteConfirmationModal(service)}/>
            </div>
        }
    };

    const renderPagination = (pagination: Pagination) => {
        return <div className="d-flex justify-content-center my-4">
            <SzPagination
                totalItemsCount={pagination.totalCount}
                activePage={currentPage}
                onChange={(pageNumber: number) => setCurrentPage(pageNumber)}
                itemsCountPerPage={pagination.perPage}
                pageRangeDisplayed={+Constants.CONTRACTS_PAGINATION_PAGE_RANGE}
            />
        </div>;
    }

    if (isError) {
        return <SzAlert
            variant="warning"
            transparent={true}
            className="w-100 border-0 flex-column p-4"
        >
            {t('admin:alert.loading_error')}
        </SzAlert>
    }

    if (isLoading) {
        return <div className='p-4 text-center'>
            <SzSpinner/>
        </div>
    }
    return <div className="box">
        <TabHeader title={t('admin:admin.tabs.services')}
                   subtitle={t("admin:admin.tabs.servicesSubtitle", { count: pagination?.totalCount })}
                   onBtnClick={ !readonly ? () => setShowAddModal(true) : undefined}/>
        {servicesData && <SzTable
            data={servicesData}
            keyField={'label'}
            columns={columns}
            defaultSorted={defaultSorted}
        />}
        {pagination && renderPagination(pagination)}
        <SzModal
            show={showAddModal}
            handleClose={() => setShowAddModal(false)}
            size={"xl"}
            centered={true}
            title={t('admin:admin.users.modal.add.title')}
        >
            <AdminOrganizationAddService
                organizationId={organizationId}
                refreshServices={() => queryClient.invalidateQueries("organizationServices")}
            />
        </SzModal>
        <ConfirmationModal
            show={showConfirmationModal}
            onValidate={deleteOrganizationContractService}
            onCancel={hideDeleteOrganizationContractServiceConfirmationModal}
            displayCancelBtn={true}
            modalBody={t('admin:admin.services.modal.delete.bodyOrganization', {
                organization: organizationId,
                service: serviceToDeleteName
            })}
            modalTitle={t('admin:admin.services.modal.delete.title')}
        />
    </div>
}

export default AdminOrganizationServices;
