import React, { ReactElement } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { IProject } from "../../../store/redux/types";
import "../detail.scss";

const ProjectSynthesis = (props: { shownItem: any }): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <Table size="sm">
        <tbody>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:project.status")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {props.shownItem.status ? t(`report:status.${props.shownItem.status.label}`) : t("no-data")}
            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:project.address")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {props.shownItem.address}
            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:project.actor")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {props.shownItem.mainFullName}
            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:project.contacts")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {props.shownItem.contacts.map((item: any) => {
                return (<span>{item.firstName} {item.lastName}</span>);
              })}
            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:project.hold")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {props.shownItem.hold}
            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:project.deliveryDate")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {new Date(props.shownItem.deliveryDate).toLocaleDateString()}
            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:project.process")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              -
            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:project.zac")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {props.shownItem.zac ? props.shownItem.zac.name : ""}
            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:project.url")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {props.shownItem.url}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default ProjectSynthesis;
