import { ConnectedRouter } from "connected-react-router";
import { History } from "history";
import React, { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";
import "./App.scss";
import Container from "./components/Container";
import Profil from "./components/Profil/Profil";
import AdminTerritorialManagementPage from "./pages/adminTerritorialManagement";
import AdminContracts from "./components/admin/contracts";
import AdminContractsCreate from "./components/admin/contracts/create";
import ContractCreationSuccess from "./components/admin/contracts/forms/success";
import ContractDetails from "./components/admin/contracts/details";
import AdminUserDetails from "./components/admin/users/details";
import AdminOrganizationDetails from "./components/admin/organizations/details";
import AdminOperatorDetails from "./components/admin/exploitants/details";
import Historic from "./pages/Historic";
import Home from "./pages/Home";
import Report from "./pages/Report";
import { Authenticate  as AuthenticateService} from "./services/Authenticate";
import CurrentContractManager from "./manager/contracts/currentContract";
import { store } from "./store";
import { AppUrls, AppAdminDetailsUrls } from "./constants";
import MatomoLayout from "./components/matomo/matomoLayout";

interface IAppProps {
  history: History;
}

const AppRouter = ({ history }: IAppProps) => {
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                refetchOnWindowFocus: false,
            },
        },
    });

    const generatePrefixedPaths = (path: string, prefixes?: string[]): string[] => {
            if (prefixes) {
                const prefixedPaths = prefixes.map((prefix: string) => {
                    return prefix.concat(path)
                });

                return prefixedPaths.concat([path]);
            }

            return [path];
        }

  useEffect(() => {
      AuthenticateService.initialize();
  }, []);

  return (
    <>
        <QueryClientProvider client={queryClient}>
          <Provider store={store}>
            <ConnectedRouter history={history}>
                <CurrentContractManager>
                  <Container>
                    <MatomoLayout>
                      <Switch>
                        <Route exact={true} path="/" component={Home} />
                        <Route exact={true} path="/profil" component={Profil} />
                          <Route exact={true} path="/report" component={Report} />
                        <Route exact={true} path="/history" component={Historic} />
                        {/* <Route exact={true} path="/dashboard" component={Dashboard} /> */}
                        {/* <Route exact={true} path="/favorite" component={Favorite} /> */}
                        <Route exact={true} path={AppUrls.ADMIN_CONTRACTS} component={AdminContracts} />
                        <Route exact={true} path={AppUrls.ADMIN_CONTRACTS_CREATE} component={AdminContractsCreate} />
                        <Route exact={true} path={AppUrls.ADMIN_CONTRACTS_DETAILS} component={ContractDetails} />
                        <Route exact={true} path={AppUrls.ADMIN_CONTRACTS_CREATE_SUCCESS} component={ContractCreationSuccess} />
                        <Route exact={true} path={AppUrls.ADMIN_GESTION_TERRITORIALE} component={AdminTerritorialManagementPage} />
                        {/*<Route exact={true} path={
                            generatePrefixedPaths(
                              AppAdminDetailsUrls.USER_DETAILS, [AppUrls.ADMIN_CONTRACTS_DETAILS, AppUrls.ADMIN_GESTION_TERRITORIALE]
                            )
                        } component={AdminUserDetails} />
                        <Route exact={true} path={
                            generatePrefixedPaths(
                              AppAdminDetailsUrls.ORGANIZATION_DETAILS, [AppUrls.ADMIN_CONTRACTS_DETAILS, AppUrls.ADMIN_GESTION_TERRITORIALE]
                            )
                        } component={AdminOrganizationDetails} />
                        <Route exact={true} path={
                            generatePrefixedPaths(
                              AppAdminDetailsUrls.OPERATOR_DETAILS, [AppUrls.ADMIN_CONTRACTS_DETAILS, AppUrls.ADMIN_GESTION_TERRITORIALE]
                            )
                        } component={AdminOperatorDetails} />*/}
                        <Route render={() => <Redirect to={{pathname: "/"}} />} />
                      </Switch>
                    </MatomoLayout>
                  </Container>
              </CurrentContractManager>
            </ConnectedRouter>
          </Provider>
        </QueryClientProvider>
    </>
  );
};

export default AppRouter;
