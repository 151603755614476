import i18next from "i18next";
import React, { ReactElement } from "react";
import { IAssetDetailsTab, IAssetDetailsTabProperty } from "../../config/asset.interfaces";
import "./detail.scss";
import DetailsTable from "./detailsTable";

interface ISpecifics {
  item: any;
  getDisplayedValue: (item: any, property: IAssetDetailsTabProperty) => string;
  tabLabel: string;
  mapping?: any;
}

const Specifics = (props: ISpecifics): ReactElement => {
  const {mapping, item, tabLabel, getDisplayedValue} = props;
  const indexName = item["index:name_ori"];
  const map = mapping[indexName].find((item: IAssetDetailsTab) => item.label === tabLabel);
  if (!map || typeof map === "undefined") {
    return (<div>{i18next.t("no-data")}</div>);
  }
  const properties = map.properties;
  const values = properties.map((property: IAssetDetailsTabProperty) => {
    return [
      i18next.t(`asset:${tabLabel}.${property.label}`),
      getDisplayedValue(item, property),
    ];
  });

  return (
    <DetailsTable values={values} />
  );
};

export default Specifics;
