import { store } from "../..";
import { highlightSiteIndus } from "../../../manager/map/loader";
import { reportTabs } from "../../../pages/Report";
import { Report } from "../../../services/Report";
import { Search as SearchService } from "../../../services/Search";
import { REPORT } from "../constants";
import {
  IFavFilter,
  IParcelFilter,
  IPluFilter,
  IProjectFavFilter,
  IProjectFilter,
  IReport,
  IReportFilter,
  IReportUpdateFiltersAction,
  IReportUpdateParcelFiltersAction,
  IReportUpdatePluFiltersAction,
  IReportUpdateProjectFiltersAction,
  IReportUpdateSignalFiltersAction,
  IReportUpdateSiteIndustrielFiltersAction,
  ISignalFavFilter,
  ISignalFilter,
  ISiteIndustrielFavFilter,
  ISiteIndustrielFilter,
} from "../types";
import { launchLoading } from "./search";

const getReports = (contract: string, filters: IReportFilter, sortField: string, sortDirection: string, currentPage?: number): any => {
    currentPage = currentPage ? currentPage : 1;
    return (dispatch: any) => {
      launchLoading(dispatch, true);
      Report.getReportListPromise(contract, filters, sortField, sortDirection, 10, currentPage)
        .then((res: { data: IReport[], headers: any }) => {
          dispatch({
            payload: res,
            type: REPORT.RETRIEVE_REPORTS,
          });
          launchLoading(dispatch, false);
        });
    };
  };

const getProjects = (filters: any, sortField: string, sortDirection: string, currentPage?: number): any => {
  currentPage = currentPage ? currentPage : 1;
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    Report.getProjectListPromise(filters, sortField, sortDirection, 10, currentPage)
      .then((res: { data: any[], headers: any }) => {
        dispatch({
          payload: res,
          type: REPORT.RETRIEVE_PROJECTS,
        });
        launchLoading(dispatch, false);
      });
  };
};

const getSignals = (filters: any, sortField: string, sortDirection: string, currentPage?: number): any => {
  currentPage = currentPage ? currentPage : 1;
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    Report.getSignalListPromise(filters, sortField, sortDirection, 10, currentPage)
      .then((res: { data: any[], headers: any }) => {
        dispatch({
          payload: res,
          type: REPORT.RETRIEVE_SIGNALS,
        });
        launchLoading(dispatch, false);
      });
  };
};

const getSiteIndustriels = (filters: any, sortField: string, sortDirection: string, currentPage?: number): any => {
  currentPage = currentPage ? currentPage : 1;
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    Report.getSiteIndustrielListPromise(filters, sortField, sortDirection, 10, currentPage)
      .then((res: { data: any[], headers: any }) => {
        dispatch({
          payload: res,
          type: REPORT.RETRIEVE_SITES,
        });
        launchLoading(dispatch, false);
      });
  };
};

const getParcels = (filters: any, sortField: string, sortDirection: string, currentPage?: number): any => {
  currentPage = currentPage ? currentPage : 1;
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    Report.getParcelListPromise(filters, sortField, sortDirection, 10, currentPage)
      .then((res: { data: any[], headers: any }) => {
        dispatch({
          payload: res,
          type: REPORT.RETRIEVE_PARCEL,
        });
        launchLoading(dispatch, false);
      });
  };
};

const getPlu = (filters: any, sortField: string, sortDirection: string, currentPage?: number): any => {
  currentPage = currentPage ? currentPage : 1;
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    Report.getPluListPromise(filters, sortField, sortDirection, 10, currentPage)
      .then((res: { data: any[], headers: any }) => {
        dispatch({
          payload: res,
          type: REPORT.RETRIEVE_PLU,
        });
        launchLoading(dispatch, false);
      });
  };
};

const updateFilters = (filters: IReportFilter): IReportUpdateFiltersAction => {
    return {
      payload: {
        filters,
      },
      type: REPORT.UPDATE_FILTERS,
    };
  };

const updateProjectFilters = (filters: IProjectFilter): IReportUpdateProjectFiltersAction => {
  return {
    payload: {
      filters,
    },
    type: REPORT.UPDATE_PROJECT_FILTERS,
  };
};

const updateSignalFilters = (filters: ISignalFilter): IReportUpdateSignalFiltersAction => {
  return {
    payload: {
      filters,
    },
    type: REPORT.UPDATE_SIGNAL_FILTERS,
  };
};

const updateSiteIndustrielFilters = (filters: ISiteIndustrielFilter): IReportUpdateSiteIndustrielFiltersAction => {
  return {
    payload: {
      filters,
    },
    type: REPORT.UPDATE_SITES_FILTERS,
  };
};

const updateParcelFilters = (filters: IParcelFilter): IReportUpdateParcelFiltersAction => {
  return {
    payload: {
      filters,
    },
    type: REPORT.UPDATE_PARCEL_FILTERS,
  };
};

const updatePluFilters = (filters: IPluFilter): IReportUpdatePluFiltersAction => {
  return {
    payload: {
      filters,
    },
    type: REPORT.UPDATE_PLU_FILTERS,
  };
};

const setSort = (sortField: string): any => {
  const sortDirection: string = store.getState().report.sortDirection;
  return (dispatch: any) => {
    dispatch({
      payload: {
        sortDirection: (sortDirection === "desc") ? "asc" : "desc" ,
        sortField,
      },
      type: REPORT.SET_SORT,
    });
  };
};

const setProjectSort = (projectSortField: string): any => {
  const sortDirection: string = store.getState().report.projectSortDirection;
  return (dispatch: any) => {
    dispatch({
      payload: {
        projectSortDirection: (sortDirection === "desc") ? "asc" : "desc",
        projectSortField,
      },
      type: REPORT.SET_PROJECT_SORT,
    });
  };
};

const setSignalSort = (signalSortField: string): any => {
  const sortDirection: string = store.getState().report.signalSortDirection;
  return (dispatch: any) => {
    dispatch({
      payload: {
        signalSortDirection: (sortDirection === "desc") ? "asc" : "desc",
        signalSortField,
      },
      type: REPORT.SET_SIGNAL_SORT,
    });
  };
};

const setSiteIndustrielSort = (siteIndustrielSortField: string): any => {
  const sortDirection: string = store.getState().report.siteIndustrielSortDirection;
  return (dispatch: any) => {
    dispatch({
      payload: {
        siteIndustrielSortDirection: (sortDirection === "desc") ? "asc" : "desc",
        siteIndustrielSortField,
      },
      type: REPORT.SET_SITE_INDUS_SORT,
    });
  };
};

const getFav = (email: string) => {
    return (dispatch: any) => {
      Report.getFavFilters(email)
        .then((res: { data: IFavFilter[] }) => {
          res.data.map((item: IFavFilter) => {
            if (item.isDefault && item.id) {
              dispatch(setFav({label: item.title, value: item.id}, item.filters));
            }
          });
          dispatch({
            payload: res,
            type: REPORT.GET_FAVORITES,
          });
        });
    };
  };

const setFav = (newFav: {label: string, value: number}, filter: IReportFilter) => {
    return (dispatch: any) => {
      launchLoading(dispatch, true);
      dispatch(updateFilters(filter));
      dispatch({
        payload: {newFav},
        type: REPORT.SET_FAVORITE,
      });
      launchLoading(dispatch, false);
    };
  };

const getProjectFav = (email: string) => {
  return (dispatch: any) => {
    Report.getProjectFavFilters(email)
      .then((res: { data: IProjectFavFilter[] }) => {
        res.data.map((item: IProjectFavFilter) => {
          if (item.isDefault && item.id) {
            dispatch(setProjectFav({ label: item.title, value: item.id }, item.filters));
          }
        });
        dispatch({
          payload: res,
          type: REPORT.GET_PROJECT_FAVORITES,
        });
      });
  };
};

const setProjectFav = (newFav: { label: string, value: number }, filter: IProjectFilter) => {
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    dispatch(updateProjectFilters(filter));
    dispatch({
      payload: { newFav },
      type: REPORT.SET_PROJECT_FAVORITE,
    });
    launchLoading(dispatch, false);
  };
};

const getSignalFav = (email: string) => {
  return (dispatch: any) => {
    Report.getSignalFavFilters(email)
      .then((res: { data: ISignalFavFilter[] }) => {
        res.data.map((item: ISignalFavFilter) => {
          if (item.isDefault && item.id) {
            dispatch(setSignalFav({ label: item.title, value: item.id }, item.filters));
          }
        });
        dispatch({
          payload: res,
          type: REPORT.GET_SIGNAL_FAVORITES,
        });
      });
  };
};

const setSignalFav = (newFav: { label: string, value: number }, filter: ISignalFilter) => {
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    dispatch(updateSignalFilters(filter));
    dispatch({
      payload: { newFav },
      type: REPORT.SET_SIGNAL_FAVORITE,
    });
    launchLoading(dispatch, false);
  };
};

const getSiteIndusFav = (email: string) => {
  return (dispatch: any) => {
    Report.getSiteIndusFavFilters(email)
      .then((res: { data: ISiteIndustrielFavFilter[] }) => {
        res.data.map((item: ISiteIndustrielFavFilter) => {
          if (item.isDefault && item.id) {
            dispatch(setSiteIndusFav({ label: item.title, value: item.id }, item.filters));
          }
        });
        dispatch({
          payload: res,
          type: REPORT.GET_SITE_INDUS_FAVORITES,
        });
      });
  };
};

const setSiteIndusFav = (newFav: { label: string, value: number }, filter: ISiteIndustrielFilter) => {
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    dispatch(updateSiteIndustrielFilters(filter));
    dispatch({
      payload: { newFav },
      type: REPORT.SET_SITE_INDUS_FAVORITE,
    });
    launchLoading(dispatch, false);
  };
};

const showDetail = (itemID: string) => {
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    return SearchService.getSigObject(itemID)
      .then((data: any) => {
        data.centroid = calculateCentroid(data);
        dispatch({
          payload: {
            connection: data,
          },
          type: REPORT.SHOW_DETAIL,
        });
        return data;
      }).then((data: any) => {
        launchLoading(dispatch, false);
        return data;
      });
  };
};

const hideDetail = () => {
  return {
    type: REPORT.HIDE_DETAIL,
  };
};

const showReport = () => {
  return {
    type: REPORT.SHOW_REPORT,
  };
};

const hideReport = () => {
  return {
    type: REPORT.HIDE_REPORT,
  };
};

const showProjectDetail = (projectID: string) => {
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    return Report.getProject(projectID)
      .then((data: any) => {
        dispatch({
          payload: {
            project: data,
          },
          type: REPORT.SHOW_PROJECT_DETAIL,
        });
        return data;
      }).then((data: any) => {
        launchLoading(dispatch, false);
        return data;
      });
  };
};

const hideProjectDetail = () => {
  return {
    type: REPORT.HIDE_PROJECT_DETAIL,
  };
};

const showSignalDetail = (signalID: string) => {
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    return Report.getSignal(signalID)
      .then((data: any) => {
        dispatch({
          payload: {
            signal: data,
          },
          type: REPORT.SHOW_SIGNAL_DETAIL,
        });
        return data;
      }).then((data: any) => {
        launchLoading(dispatch, false);
        return data;
      });
  };
};

const hideSignalDetail = () => {
  return {
    type: REPORT.HIDE_SIGNAL_DETAIL,
  };
};

const showSiteIndustrielDetail = (iteIndustrielID: string) => {
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    return Report.getSiteIndustriel(iteIndustrielID)
      .then((data: any) => {
        dispatch({
          payload: {
            siteIndustriel: data,
          },
          type: REPORT.SHOW_SITE_INDUS_DETAIL,
        });
        highlightSiteIndus(data.ID_CD92, data.coordinates);
        return data;
      }).then((data: any) => {
        launchLoading(dispatch, false);
        return data;
      });
  };
};

const hideSiteIndustrielDetail = () => {
  return {
    type: REPORT.HIDE_SITE_INDUS_DETAIL,
  };
};

const changeTab = (newTab: keyof typeof reportTabs) => {
  return{
    payload: {
      newTab,
    },
    type: REPORT.CHANGE_TAB,
  };
};

const calculateCentroid = (data: any) => {
  let longitude: number = 0;
  let latitude: number = 0;
  if ("undefined" !== typeof data["sig:point"]) {
    longitude = data["sig:point"][0][0] as number;
    latitude = data["sig:point"][0][1] as number;
  } else if ("undefined" !== typeof data["sig:line"]) {
    longitude = (parseFloat(data["sig:line"][1][1]) + parseFloat(data["sig:line"][0][1])) / 2;
    latitude = (parseFloat(data["sig:line"][1][0]) + parseFloat(data["sig:line"][0][0])) / 2;
  }
  return {longitude, latitude};
};

const displayMap3d = (isDisplayed3d?: boolean) => {
  return {
    payload: isDisplayed3d,
    type: REPORT.DISPLAY_REPORT_MAP_3D,
  };
};

export const getGeoserverBV = (colors: string[]): any => {
  return (dispatch: any) => {
    launchLoading(dispatch, true);
    return Report.getGeoserverBVListPromise(colors)
      .then((data: any) => {
        dispatch({
          payload: data,
          type: REPORT.RETRIEVE_GEOSERVER_BV,
        });
        launchLoading(dispatch, false);
        return data;
      }).then((data: any) => {
        return data;
      });
  };
};

export default {
  changeTab,
  displayMap3d,
  getFav,
  getProjectFav,
  getSignalFav,
  getSiteIndusFav,
  getGeoserverBV,
  getReports,
  hideDetail,
  showReport,
  hideReport,
  hideProjectDetail,
  hideSignalDetail,
  hideSiteIndustrielDetail,
  setFav,
  setProjectFav,
  setSignalFav,
  setSiteIndusFav,
  setSort,
  setProjectSort,
  setSignalSort,
  setSiteIndustrielSort,
  showDetail,
  showProjectDetail,
  showSignalDetail,
  showSiteIndustrielDetail,
  updateFilters,
  updateProjectFilters,
  updateSignalFilters,
  updateSiteIndustrielFilters,
  updateParcelFilters,
  updatePluFilters,
  getProjects,
  getSignals,
  getSiteIndustriels,
  getParcels,
  getPlu,
};
