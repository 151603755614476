import Request from "./Request";
import { config } from "../config";
import { ApiUrls } from "../constants";
import { store } from "../store";
import { permissionsActions } from "../store/redux/actions";

export class PermissionsService {
  static CONTRACT_METADATA_CONTRACT_URL = "contractUrl";

  public static checkRedirect(permissions: []) {
    const contractUrls = this.getContractUrls(permissions);
    const { defaultUrl, environment } = config;
    const currentUrl = window.location.href.replace(/\/+$/, "");

    if (!defaultUrl) {
      return;
    }

    // If connexion to default url and no contract url, keep going without redirect
    // If connexion to default url and only one contract url, redirect to that contract url
    // If connexion to specific url and that url not belong to contract urls, redirect to first contract url
    if (
      (currentUrl.includes(defaultUrl) && 1 === contractUrls.length ||
      !currentUrl.includes(defaultUrl) && 0 === contractUrls.filter((s) => currentUrl.includes(s)).length) &&
      'production' === environment
    ) {
      const tokenDT = localStorage.getItem("tokenDigitalTwin");
      localStorage.clear();
      if (0 < contractUrls.length) {
        window.location = (contractUrls[0] + "?token=" + tokenDT ) as any;
      } else {
        window.location = defaultUrl as any;
      }
    }
  }

  public static getContractUrls(permissions: []) {
    let allContractUrl: string[] = [];
    permissions.forEach((contract: { contractMetadatas: [] }) => {
      const contractMetadatas = contract.contractMetadatas;
      contractMetadatas.forEach((contractMetadata: { key: string; value: string }) => {
        if (this.CONTRACT_METADATA_CONTRACT_URL === contractMetadata.key) {
          allContractUrl.push(contractMetadata.value);
        }
      });
    });

    return allContractUrl;
  }

  public static getPermissions() {
    return Request.get(ApiUrls.PERMISSIONS_USER, false).then((response) => response.data.permissions);
  }

  public static setPermissions(permissions: any) {
    store.dispatch(permissionsActions.permissionsData(permissions));
  }
}
