import React, { Component, ReactElement, RefObject } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { ISearch } from "../../../store/redux/types";
import { MapActions } from "../../../store/redux/actions";

interface IAssetItem {
  anchor: string;
  address: string;
  id: string;
  index: number;
  indexName: string;
  handleClick: (feature: any, tab: string) => void;
  handleZoom: (feature: any, index: number, anchor: string) => void;
  feature: any;
  selected: boolean;
  search?: ISearch;
  mapInfo?: any;
  setMapInfo?: any;
  contract?: any;
}

const getDetailTitle = (contract: any, object: any) => {
  let title = '';
  if (object['asset:nom'] !== undefined && object['asset:nom'] !== null) {
    title = object['asset:nom'] + ' || ';
  }
  let detailId = "asset:asset_id";
  if (contract.label === "SIAHVY" && object["index:name"] === "asset:A_REGARD_point") {
    detailId = "asset:ident";
  }
  title = title + object['index:name_label'] + ' ' + object[detailId];

  return title;
};

class AssetItem extends Component<IAssetItem, any> {

  public anchor: string;
  public anchorRef?: RefObject<HTMLLIElement>;
  public address: string;
  public id: string;
  public index: number;
  public indexName: string;
  public feature: any;
  public parentHandleClick: (feature: any, tab: string) => void;
  public parentHandleZoom: (feature: any, index: number, anchor: string) => void;
  public search?: ISearch;
  public selected: boolean;
  public title: ReactElement;
  public mapInfo: any;
  public setMapInfo: (payload: any) => void;
  public contract: any;
  public show3d: boolean;
  public showitv: boolean;

  constructor(props: IAssetItem) {
    super(props);

    this.anchor = props.anchor;
    if (props.search && this.anchor === props.search.detail.shownDetailAnchor) {
      this.anchorRef = React.createRef<HTMLLIElement>();
    }
    this.address = props.address;
    this.id = props.id;
    this.index = props.index;
    this.indexName = props.indexName;
    this.feature = props.feature;
    this.parentHandleClick = props.handleClick;
    this.parentHandleZoom = props.handleZoom;
    this.search = props.search;
    this.selected = props.selected;
    this.contract = props.contract;
    this.title = <>
      {getDetailTitle(this.contract, this.feature)}
    </>;
    this.mapInfo = props.mapInfo;
    this.setMapInfo = props.setMapInfo;
    this.show3d = this.feature["3dnetwork:3d_mesh_available"] || this.feature["3dstrucuture:3d_ifc_available"];
    this.showitv = this.feature["itv:itv_available"];
  }

  public handleClick(tab: string) {
    this.parentHandleClick(this.feature, tab);
    this.handleZoom();
    this.handleHighlight();
  }

  public handleHighlight() {
      this.setMapInfo({highlight: false});
      let sigPoint = this.feature?.[`sig:point`];
      let point = undefined;
      if (sigPoint !== undefined) {
        point = [sigPoint[1], sigPoint[0]];
      }
      const highlight = {
          show: true,
          type: this.feature?.[`sig:type`],
          id: this.feature?.[`asset:asset_id`],
          point: point,
      };
      this.setMapInfo({
        highlight: highlight,
        autoZoom: true
      });
  }
  public handleZoom() {
    this.parentHandleZoom(this.feature, this.index, this.anchor);
    this.handleHighlight();
  }

  public shouldComponentUpdate(nextProps: any, nextState: any) {
    if (this.search && nextProps.search && (this.search !== nextProps.search)) {
      this.anchor = nextProps.anchor;
      this.address = nextProps.address;
      this.id = nextProps.id;
      this.index = nextProps.index;
      this.indexName = nextProps.indexName;
      this.feature = nextProps.feature;
      this.search = nextProps.search;
      this.selected = nextProps.selected;
      this.title = <>
        {getDetailTitle(this.contract, this.feature)}
      </>;
      return true;
    }
    return false;
  }

  public componentDidMount() {
    setTimeout(() => {
      if (this.anchorRef && this.anchorRef.current) {
        this.anchorRef.current.scrollIntoView({
          behavior: "auto",
          block: "start",
        });
      }
    }, 500);
  }

  public render() {
    return (
      <li
        id={this.anchor}
        ref={this.search && this.anchor === this.search.detail.shownDetailAnchor ? this.anchorRef : undefined}
        className={`gaia-search-data__item ${this.selected ? "active" : ""}`}
        key={this.index}
      >
        <div className={"gaia-search-data__item-content"}>
          <span className="gaia-search-data__item-content-title">{this.title}</span>
          <em className="gaia-search-data__item-content-more">{this.address}</em>
        </div>
        <div className="gaia-search-data__item-links">
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 100, hide: 200 }}
            overlay={
              <Tooltip id="tooltip">
                <Trans>open_details</Trans>
              </Tooltip>
            }
          >
            <i
              className="gaia-search-data__item-links-icon material-icons clickable"
              onClick={() => this.handleClick("synthesis")}
            >format_list_bulleted</i>
          </OverlayTrigger>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 100, hide: 200 }}
            overlay={
              <Tooltip id="tooltip">
                <Trans>see_asset_on_map</Trans>
              </Tooltip>
            }
          >
            <i
              className="gaia-search-data__item-links-icon clickable material-icons"
              onClick={() => this.handleZoom()}
            >gps_fixed</i>
          </OverlayTrigger>
          {this.show3d &&
              <i
                  className="gaia-search-data__item-links-icon material-icons"
                  onClick={() => this.handleClick("work3d")}
              >3d_rotation</i>
          }
          {this.showitv &&
              <OverlayTrigger
                  placement="bottom"
                  delay={{ show: 100, hide: 200 }}
                  overlay={
                    <Tooltip id="tooltip">
                      <Trans>see_itv_on_map</Trans>
                    </Tooltip>
                  }
              >
                <i
                    className="gaia-search-data__item-links-icon material-icons"
                    onClick={() => this.handleClick("itvs")}
                >videocam</i>
              </OverlayTrigger>
          }
        </div>
      </li>
    );
  }
}

const mapStateToProps = (state: any, props: IAssetItem) => {
  return {
      search: state.search,
      mapInfo: state.map,
      contract: state.contract.currentContract,
      ...props
  };
};

const mapDispatchToProps = {
  setMapInfo: MapActions.setMapInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(AssetItem);
