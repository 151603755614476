import React, { useEffect, useState } from "react";
import { Accordion, Card, Nav, Tab, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SzIcon } from "@suezenv/react-theme-components";

interface IDetailContainer {
  shownItem: any;
}

const EquipementDetail = (props: IDetailContainer) => {

  const { shownItem } = props;
  const [activeKey, setActiveKey] = useState("1");
  const { t } = useTranslation();

  return (
    <>
      <Tab.Container id="category" defaultActiveKey="properties">
        <Nav className="sz-aside__list gaia-detail-result--nav">
          <Nav.Item key={0} className="gaia-search-category text-decoration-none py-2">
            <Nav.Link eventKey={"properties"}>
              <span className="gaia-search-category__item-title">{t(`asset:tabs.properties`)}</span>
            </Nav.Link>
          </Nav.Item>
          <Nav.Item key={1} className="gaia-search-category text-decoration-none py-2">
            <Nav.Link eventKey={"relation"}>
              <span className="gaia-search-category__item-title">{t(`asset:tabs.relation`)}</span>
            </Nav.Link>
          </Nav.Item>
        </Nav>
        <Tab.Content>
          <Tab.Pane key={0} eventKey={"properties"}>
            <Accordion activeKey={activeKey}>
              {Object.entries(shownItem).map((property: any, key: any) => {
                if (property[0] !== "Related Items") {
                  if (typeof property[1] === "object") {
                    return (
                      <>
                        <Card className="container-bordered">
                          <Accordion.Toggle
                            as={Card.Header}
                            eventKey={key + 1}
                            onClick={() =>
                              activeKey === key + 1 ? setActiveKey("0") : setActiveKey(key + 1)
                            }
                          >
                            {activeKey === key + 1 ?
                              <SzIcon variant="bold" icon="arrow-up-1" /> :
                              <SzIcon variant="bold" icon="arrow-down-1" />
                            }
                            {property[0]}
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={key + 1}>
                            <Card.Body>
                              <Table size="sm">
                                <tbody>
                                  {Object.entries(property[1]).map((subProperty: any, subPropertyKey: any) => {
                                    if (typeof subProperty[1] === "object") {
                                      return (
                                        <tr key={subPropertyKey}>
                                          <td className="gaia-td-align-right cell-width">
                                            {subProperty[0]} :
                                          </td>
                                          <td className="gaia-td-align-left cell-width">
                                            {subProperty[1].value}
                                          </td>
                                        </tr>
                                      );
                                    }
                                  })}
                                </tbody>
                              </Table>
                            </Card.Body>
                          </Accordion.Collapse>
                        </Card>
                      </>
                    );
                  }
                }
              })
              }
            </Accordion>
          </Tab.Pane>
          <Tab.Pane key={1} eventKey={"relation"}>
            <Accordion activeKey={activeKey}>
              {Object.entries(shownItem["Related Items"]).map((subItem: any, key: any) => {
                return (
                  <>
                    <Card className="container-bordered">
                      <Accordion.Toggle
                        as={Card.Header}
                        eventKey={key + 1}
                        onClick={() =>
                          activeKey === key + 1 ? setActiveKey("0") : setActiveKey(key + 1)
                        }
                      >
                        {activeKey === key + 1 ?
                          <SzIcon variant="bold" icon="arrow-up-1" /> :
                          <SzIcon variant="bold" icon="arrow-down-1" />
                        }
                        {subItem[0]}
                      </Accordion.Toggle>
                      <Accordion.Collapse eventKey={key + 1}>
                        <Card.Body>
                          <Table size="sm">
                            <tbody>
                              {subItem[1].map((relation: any, relationKey: any) => {
                                return (
                                  <tr key={relationKey}>
                                    <td className="gaia-td-align-left cell-width">
                                      {relation.TagLabel} - {relation.Name}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </>
                );
              })
              }
            </Accordion>
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </>
  );
};

export default EquipementDetail;
