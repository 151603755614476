import React, { useEffect, useState } from "react";
// import ReactGA from "react-ga";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { categories, mapType3D } from "../../config/constants";
import { mapLoaderConfig } from "../../manager/map/loader";
import { SearchActions, UserActions, MapActions } from "../../store/redux/actions";
import { ISearch, IUser } from "../../store/redux/types";
import NoResults from "../Utils/messages/noResults";
import Address from "./categories/address";
import Asset from "./categories/asset";
import Documents from "./categories/documents";
import "./search.scss";

export interface IAddress {
  [key: string]: { [key: string]: number | string };
}

function SearchList(props: any) {
  const [selectedIndex, setSelectedIndex] = useState<any>(null);
  const [selectedAsset, setSelectedAsset] = useState<any>(null);
  const { searchData } = props.search;
  const { showDetail, contract } = props;
  let searchListComponent;

  useEffect(() => {
    setSelectedIndex(null);
    setSelectedAsset(null);
  }, [props.search.userInput, props.search.searchData]);

  const searchResult = searchData.find((data: any) => data.category === props.category);

  const getDownloadUrl = (document: any) => {
    // return document["host"] + 'saml/login/?next=/' + document["dt:downloadUrlRel"];
    return document["dt:downloadUrlback"];
  };

  const getPreviewUrl = (document: any) => {
    return document["host"] + 'saml/login/?next=/' + document["dt:previewUrlRel"];
  };

  const handleTargetClickOfAddress = (address: IAddress, clickedIndex: number) => {
    if (address.attributes.Type === "City"
        || address.attributes.Addr_type === "street"
        || address.attributes.Addr_type === "municipality"
    ) {
        props.setMapInfo({
            center: {
                longitude: address.location.y,
                latitude: address.location.x
            },
            extent: [
                address.extent.xmin,
                address.extent.ymin,
                address.extent.xmax,
                address.extent.ymax
            ],
          autoZoom: true,
        });
    } else {
        props.setMapInfo({
          center: {longitude: address.location.y, latitude: address.location.x},
          autoZoom: true,
        });
    }
    setSelectedIndex(clickedIndex);
    // ReactGA.event({ action: `Zoom to : ${address.address}`, category: "Search" });
  };
  const handleTargetClickOfAsset = (asset: any, clickedIndex: number) => {
    const scale = (mapLoaderConfig.activeView && mapLoaderConfig.activeView.type === mapType3D) ? 500 : 250;
    if ("sig:line" in asset) {
      props.setMapInfo({
        center: calculateCentroid(asset),
        extent: asset["sig:bbox"],
        autoZoom: true,
      });
    }
    if ("sig:point" in asset) {
      props.setMapInfo({
        center: calculateCentroid(asset),
        extent: null,
        autoZoom: true,
      });
    }
    if ("sig:polygon" in asset) {
      props.setMapInfo({
        center: calculateCentroid(asset),
        extent: asset["sig:bbox"],
        autoZoom: true,
      });
    }
    const coordinates = calculateCentroid(asset);
    props.setHomeMapPosition([coordinates.latitude, coordinates.longitude, scale]);
    setSelectedAsset(clickedIndex);
    // ReactGA.event({ action: `Zoom objet id: ${asset.id}`, category: "Search" });
  };

  const calculateCentroid = (data: any) => {
    let longitude: number = 0;
    let latitude: number = 0;
    if ("undefined" !== typeof data["sig:point"]) {
      longitude = data["sig:centroid"][1] as number;
      latitude = data["sig:centroid"][0] as number;
    } else if ("undefined" !== typeof data["sig:line"]) {
      longitude = (parseFloat(data["sig:line"][1][1]) + parseFloat(data["sig:line"][0][1])) / 2;
      latitude = (parseFloat(data["sig:line"][1][0]) + parseFloat(data["sig:line"][0][0])) / 2;
    } else if ("undefined" !== typeof data["sig:bbox"]) {
      longitude = (parseFloat(data["sig:bbox"][1]) + parseFloat(data["sig:bbox"][3])) / 2;
      latitude = (parseFloat(data["sig:bbox"][0]) + parseFloat(data["sig:bbox"][2])) / 2;
    } else if ("undefined" !== typeof data.extent) {
      longitude = (data.extent.xmax + data.extent.xmin) / 2;
      latitude = (data.extent.ymax + data.extent.ymin) / 2;
    }

    return { longitude, latitude };
  };

  searchListComponent = (result: { category: string; data: any }) => {
    switch (result.category) {
      case categories.GEO:
        if (result.data.candidates) {
          return (
            <Address result={result} selectedIndex={selectedIndex} handleTargetClick={handleTargetClickOfAddress} />
          );
        }
        return <NoResults />;
      case categories.DOC:
        if (result.data.documents) {
          return <Documents
              result={result}
              previewUrl={(document: any) => getPreviewUrl(document)}
          />;
        }
        return <NoResults />;
      case categories.ASSET:
        if (result.data.objects) {
          return (
            <Asset
              result={result}
              handleClick={showDetail}
              handleTargetClick={handleTargetClickOfAsset}
              contract={contract}
            />
          );
        }
        return <NoResults />;
      default:
        return <NoResults />;
    }
  };

  return searchListComponent(searchResult);
}

const mapStateToProps = (state: { search: ISearch, user: IUser, map: any, contract: any }, props: { category: string }) => {
  return { search: state.search, user: state.user, category: props.category, map: state.map, contract: state.contract.currentContract, };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...SearchActions, ...UserActions, ...MapActions }, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(SearchList);
