import jwt from "jsonwebtoken";
import qs from "qs";
import { store } from "../store";
import { ERROR, USER } from "../store/redux/constants";
import { Authenticate as AuthenticateService } from "./Authenticate";
import Request from "./Request";

export const isUndefined = (item: string | null) => !item || item === "undefined";

export class Token {
  public static createDigitalTwinTokens() {
    let { token }: any = qs.parse(window.location.search.substring(1));
    let refreshToken: any = qs.parse(window.location.search).refresh;
    const tokenDigitalTwinFromStorage = localStorage.getItem("tokenDigitalTwin");
    const refreshTokenFromStorage = localStorage.getItem("refreshToken");

    if (isUndefined(token) && isUndefined(tokenDigitalTwinFromStorage)) {
      AuthenticateService.login();
    } else {

    token = !isUndefined(token)
      ? token
      : !isUndefined(tokenDigitalTwinFromStorage)
      ? tokenDigitalTwinFromStorage
      : AuthenticateService.login();

    refreshToken = !isUndefined(refreshToken)
      ? refreshToken
      : !isUndefined(refreshTokenFromStorage)
      ? refreshTokenFromStorage
      : null;

    const sigToken = this.getSigTokenFromPayload(token);
    const smartshapeToken = this.getSmartshapeTokenFromPayload(token);
    this.setSessionsTokens(token, refreshToken, sigToken, smartshapeToken);
    this.updateTokensInStore(token);

    window.history.pushState(null, "", window.location.pathname);
    }
  }

  public static checkJWTValidityFromAPI() {
    Request.checkJWT();
  }

  public static isJwtNotValid() {
    const token = localStorage.getItem("tokenDigitalTwin");
    const decodedToken = jwt.decode(token, { complete: true });
    // JS timestamp is expressed in milliseconds therefore we truncate it.
    const timestamp = Math.floor(Date.now() / 1000);
    return decodedToken && typeof decodedToken !== "undefined" && decodedToken.payload.exp < timestamp;
  }

  public static async refreshToken() {
    return await Request.refreshToken().then((resf: any) => {
      this.updateTokens(resf.data);
    }).catch((error: any) => {
      this.updateTokens(error.response.data);
    });
  }

  private static setSessionsTokens(token: string, refreshToken: string, sigToken: string, smartshapeToken: string) {
    if (!isUndefined(token)) {
      localStorage.setItem("tokenDigitalTwin", token);
    }
    if (!isUndefined(refreshToken)) {
      localStorage.setItem("refreshToken", refreshToken);
    }
    if (!isUndefined(sigToken)) {
      localStorage.setItem("sigToken", sigToken);
    }
    if (!isUndefined(smartshapeToken)) {
      const cookie = smartshapeToken.split("=");
      this.setCookie(cookie[0], cookie[1], 1);
    }
  }

  private static getSigTokenFromPayload(token: string) {
    const decodedToken = jwt.decode(token, { complete: true });
    if (decodedToken && typeof decodedToken !== "undefined") {
      return decodedToken.payload.sigToken;
    }
    return null;
  }

  private static getSmartshapeTokenFromPayload(token: string) {
    const decodedToken = jwt.decode(token, { complete: true });
    if (decodedToken && typeof decodedToken !== "undefined") {
      return decodedToken.payload.smartshapeToken;
    }
    return null;
  }

  private static getEmailFromPayload(token: string) {
    const decodedToken = jwt.decode(token, { complete: true });
    if (decodedToken && typeof decodedToken !== "undefined") {
      return decodedToken.payload.username;
    }
    return null;
  }

  private static getAttributeFromPayload(attribute: string, token: string) {
    const decodedToken = jwt.decode(token, { complete: true });
    if (decodedToken && typeof decodedToken !== "undefined") {
      return decodedToken.payload[attribute];
    }
    return null;
  }

  private static updateTokens(json: any) {
    if (typeof json.code !== "undefined" && json.code === 401) {
      AuthenticateService.logout();
    }

    if (!isUndefined(json.token)) {
      localStorage.setItem("tokenDigitalTwin", json.token);
      this.updateTokensInStore(json.token);

    }
    if (!isUndefined(json.refresh_token)) {
      localStorage.setItem("refreshToken", json.refresh_token);
    }
  }

  private static updateTokensInStore(token: string) {
    store.dispatch({
      payload: {
        authenticationToken: token,
        email: this.getEmailFromPayload(token),
        employeeType: this.getAttributeFromPayload("employeeType", token),
        firstName: this.getAttributeFromPayload("firstName", token),
        homePhone: this.getAttributeFromPayload("homePhone", token),
        lastName: this.getAttributeFromPayload("lastName", token),
        logged: true,
        mobile: this.getAttributeFromPayload("mobile", token),
        roles: this.getAttributeFromPayload("roles", token),
      },
      type: USER.LOG_IN,
    });
  }

  private static setCookie(cname: string, cvalue: string, exdays: number) {
    const date = new Date();
    date.setTime(date.getTime() + (exdays * 24 * 60 * 60 * 1000));
    const expires = "expires=" + date.toUTCString();
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/;domain=.digital-twin.3slab.fr";
  }
}
