import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import organizationPicture from '../../../../assets/img/contract/demenagement.svg'
import { SzInput, SzButton, SzTypographie } from "@suezenv/react-theme-components";
import { PostContractRequest } from "../../../../types";
import BlockHeader from "../../../elements/BlockHeader";

type PropsType = {
    currentStep: number,
    nextStepHandle: () => void;
    previousStepHandle: () => void;
    formData: PostContractRequest;
    setFormData: (formData: PostContractRequest) => void;
}

const Step2From = (props: PropsType) => {
    const { nextStepHandle, previousStepHandle, formData, setFormData } = props;
    const { t } = useTranslation();

    const [organization, setOrganization] = useState('');
    const [organizationError, setOrganizationError] = useState(false);

    const validateStep = () => {
        const isValid = organization.length >= 3;
        if (isValid) {
            setFormData({ ...formData, organizationName: organization });
            nextStepHandle();
        } else {
            setOrganizationError(true);
        }
    }

    return <div>
        <div className="box">
            <div className="row">
                <BlockHeader title={t('admin:admin.contracts.create.new_contract')}
                             subtitle={t('admin:admin.contracts.create.base_organization')}
                             icon={"business-contract-handshake-sign"}/>
            </div>
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <SzInput
                        error={organizationError}
                        value={organization}
                        onChange={({ target }) => {
                            setOrganization(target.value);
                            setOrganizationError(false);
                        }}
                        label={t('admin:forms.contractCreate.organization.label')}
                        placeholder={t('admin:forms.contractCreate.organization.placeholder')}
                        icon="pencil-write"
                        valid
                    />
                    {organizationError &&
                        <SzTypographie variant="caption" color={"danger"}>
                            {t('admin:admin.contracts.create.inputError')}
                        </SzTypographie>
                    }
                </div>
            </div>
            <div className="d-flex align-items-center mt-4">
                <div className="col-md-2 offset-md-3">
                    <SzTypographie variant="h2" className="mb-0">
                        {t('admin:admin.contracts.create.organization.main_organization')}
                    </SzTypographie>
                    <SzTypographie variant="caption">
                        {t('admin:admin.contracts.create.organization.description')}
                    </SzTypographie>
                </div>
                <div className="col-md-3">
                    <img src={organizationPicture} alt=""/>
                </div>
            </div>
        </div>
        <div className="mt-3 text-right">
            <SzButton
                className="previous-btn mr-2"
                variant="secondary"
                onClick={previousStepHandle}
            >
                {t('previous')}
            </SzButton>
            <SzButton
                className="next-btn"
                onClick={validateStep}
                isDisabled={organizationError}
            >
                {t('next')}
            </SzButton>
        </div>
    </div>;
}

export default Step2From;
