import i18next from "i18next";
import React, { useState } from "react";
import { Accordion, Card, Col, Row } from "react-bootstrap";
// import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzIcon } from "@suezenv/react-theme-components";
import { bindActionCreators } from "redux";
import { icons } from "../../config";
import { SearchActions } from "../../store/redux/actions";
import DataNotReady from "../Utils/messages/dataNotReady";
import PdfViewer from "../Utils/pdfViewer";
import "./detail.scss";

interface IDocumentsType {
  [key: string]: { [key: string]: any };
}

interface IDocumentsProps {
  documents: IDocumentsType;
  getDocumentDownloadUrl: (downloadUrl: string) => any;
}

const Documents = (props: IDocumentsProps) => {
  const { t } = useTranslation();
  const { documents } = props;
  const [titlePDF, setTitlePDF] = useState("");
  const [uriPDF, setUriPDF] = useState("");
  const [documentLength, setDocumentLength] = useState(0);
  const [PDFViewerIsActive, setPDFViewerIsActive] = useState(false);
  const [documentOpen, setDocumentOpen] = useState<boolean>(true);

  const showPDFViewer = (document: any) => {
    setTitlePDF(getDocumentTitle(document));
    setUriPDF(getDownloadUrl(document["dt:downloadUrlback"]));
    setPDFViewerIsActive(!PDFViewerIsActive);
    setDocumentLength(document["size"]);
    // ReactGA.event({ action: `Pdf viewer: ${getDocumentTitle(document)}`, category: "PDF" });
  };

  const isPDF = (uri: string, mimeType: string): boolean => {
    return (
      !!getDownloadUrl(uri) &&
      mimeType === "application/pdf"
    );
  };

  const getDownloadUrl = (downloadUrl: string) => {
    return SearchActions.getDocumentDownloadUrl(downloadUrl).payload;
  };

  const handleClick = (document: any) => {
    if (isPDF(document["dt:downloadUrl"], document["mimetype"])) {
      showPDFViewer(document);
    } else {
      const url = document["host"] + 'saml/login/?next=/' + document["dt:previewUrlRel"];
      window.open(url, "_blank");
    }
  };

  const getFileIcon = (filename: string) => {
    const parts = filename.split(".");
    switch (parts[parts.length - 1]) {
      case "pdf" :
        return icons["icon-pdf"];
      case "doc" :
      case "docx" :
        return icons["icon-doc"];
      case "mp4" :
        return icons["icon-itv"];
      default :
        return icons["icon-file"];
    }
  };

  if (!props.documents || Object.keys(props.documents).length === 0) {
    return (<DataNotReady />);
  }

  const documentTypes = Object.keys(documents);

  return (
        <>
          {PDFViewerIsActive && (
            <PdfViewer
              title={titlePDF}
              uri={uriPDF}
              visible={PDFViewerIsActive}
              handleClosePdfViewer={() => setPDFViewerIsActive(!PDFViewerIsActive)}
              documentLength={documentLength ? documentLength : 0}
            />
          )}
          <Accordion defaultActiveKey="0">
            {documentTypes.map((type: string) => {
                const typeDocuments = documents[type];
                return (
                 <Card className="container-bordered">
                   <Accordion.Toggle as={Card.Header} eventKey="0" onClick={() => setDocumentOpen(!documentOpen)}>
                     {type}
                     {documentOpen ?
                       <SzIcon variant="bold" icon="arrow-up-1" /> :
                       <SzIcon variant="bold" icon="arrow-down-1" />
                     }
                   </Accordion.Toggle>
                   <Accordion.Collapse eventKey="0">
                     <Card.Body as={Row}>
                       <>
                        {Object.keys(typeDocuments).map((key: any) => {
                            const doc = typeDocuments[key];
                            return (
                               <Col
                                 onClick={() => handleClick(doc)}
                                 sm="3"
                                 className="text-center gaia-detail-result--documents-container--doc"
                               >
                                 <img
                                   className="gaia-detail-result--documents-container--doc-icon"
                                   alt=""
                                   src={getFileIcon(getDocumentTitle(doc))}
                                 />
                                 <span className="gaia-detail-result--documents-container--doc-title">
                                   {getDocumentTitle(doc)}
                                 </span>
                               </Col>
                             );
                            })
                        }
                        </>
                     </Card.Body>
                   </Accordion.Collapse>
                 </Card>
                 );
               })
             }
           </Accordion>
        </>
    );
};

export const getDocumentTitle =
  (document: {"cmis:name"?: string, "cmis:contentStreamFileName"?: string, "cm:title"?: string, "label"?: string}): string => {
    // if (document["cmis:name"] && document["cmis:name"].length > 0) {
    //   return document["cmis:name"];
    // } else if (document["cmis:contentStreamFileName"] && document["cmis:contentStreamFileName"].length > 0) {
    //   return document["cmis:contentStreamFileName"];
    // } else if (document["cm:title"] && document["cm:title"].length > 0) {
    //   return document["cm:title"];
    // }
    if (document["label"] && document["label"].length > 0) {
      return document["label"];
    }
    return i18next.t("no-title-document");
  };

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(SearchActions, dispatch),
});

export default connect(null, mapDispatchToProps)(Documents);
