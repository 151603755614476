import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SzButton, SzTypographie } from "@suezenv/react-theme-components";
import ReactTags, { Tag } from 'react-tag-autocomplete'
import Switch from "react-switch";
import { PostContractRequest } from "../../../../types";
import BlockHeader from "../../../elements/BlockHeader";
import "./contractForm.scss";

type PropsType = {
    currentStep: number,
    previousStepHandle: () => void;
    nextStepHandle: () => void;
    formData: PostContractRequest;
    setFormData: (formData: PostContractRequest) => void;
}

const Step3From = (props: PropsType) => {
    const { previousStepHandle, nextStepHandle, formData, setFormData } = props;
    const { t } = useTranslation();

    const [adminEmails, setAdminEmails] = useState<Tag[]>([]);
    const [adminError, setAdminError] = useState<boolean>(false);
    const [userEmails, setUserEmails] = useState<Tag[]>([]);
    const [userError, setUserError] = useState<boolean>(false);
    const [showUsersInput, setShowUserInput] = useState<boolean>(false);

    // updating form data right before sending form result in not updated data sent so we updated on change.
    const onAdminDelete = (i: number) => {
        const tags = adminEmails.slice(0);
        tags.splice(i, 1);
        setAdminEmails(tags);
    }

    const onAdminAdd = (tag: Tag) => {
        setAdminEmails(adminEmails.concat([tag]));
    }

    const onUserDelete = (i: number) => {
        const tags = userEmails.slice(0);
        tags.splice(i, 1);
        setUserEmails(tags);
    }

    const onUserAdd = (tag: Tag) => {
        setUserEmails(userEmails.concat([tag]));
    }

    const validateEmail = (tag: Tag, adminOrUser: string) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValid = regex.test(String(tag.name).toLowerCase());
        if (!isValid) {
            if (adminOrUser === "admin") {
                setAdminError(true);
            } else setUserError(true);
        }

        return isValid;
    }

    useEffect(() => {
        updateFormData();
    }, [adminEmails, userEmails])

    const updateFormData = () => {
        const adminStringEmails = adminEmails.map((adminEmail: Tag) => adminEmail.name);
        const userStringEmails = userEmails.map((userEmail: Tag) => userEmail.name);
        setFormData({ ...formData, admins: adminStringEmails, users: userStringEmails });
    }

    return <div>
        <div className="box">
            <div className="row">
                <BlockHeader title={t('admin:admin.contracts.create.new_contract')}
                             subtitle={t('admin:admin.contracts.create.invite_members')}
                             icon={"business-contract-handshake-sign"}/>
            </div>
            <div className="mb-4">
                <div className="col-md-8 offset-md-2">
                    <SzTypographie variant="h2" weight={"bold"} color={"disabled"}>
                        {t('admin:admin.contracts.create.invite_admins')}
                    </SzTypographie>
                    <ReactTags
                        addOnBlur={true}
                        id={"reactTagsAdmin"}
                        inputAttributes={{ "class": "tag-textarea" }}
                        allowNew={true}
                        allowBackspace={false}
                        tags={adminEmails}
                        placeholderText={t("admin:admin.contracts.create.enter_mail")}
                        onAddition={onAdminAdd}
                        onDelete={onAdminDelete}
                        onInput={() => setAdminError(false)}
                        onValidate={(tag: Tag) => validateEmail(tag, "admin")}
                    />
                    {adminError &&
                        <SzTypographie variant="caption" color={"danger"}>
                            {t('admin:admin.contracts.create.emailError')}
                        </SzTypographie>
                    }
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 offset-md-2 d-flex align-items-end mb-2">
                    <SzTypographie variant="h2" weight="bold" color="disabled" className="mr-4">
                        {t('admin:admin.contracts.create.invite_users')}
                    </SzTypographie>
                    <Switch
                        onChange={() => setShowUserInput(!showUsersInput)}
                        checked={showUsersInput}
                        onColor={"#030F40"}
                    />
                </div>
            </div>
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    {showUsersInput && <>
                        <ReactTags
                            addOnBlur={true}
                            id={"reactTagsUser"}
                            inputAttributes={{ "class": "tag-textarea" }}
                            allowNew={true}
                            allowBackspace={false}
                            tags={userEmails}
                            placeholderText={t("admin:admin.contracts.create.enter_mail")}
                            onAddition={onUserAdd}
                            onDelete={onUserDelete}
                            onInput={() => setUserError(false)}
                            onValidate={(tag: Tag) => validateEmail(tag, "user")}
                        />
                        {userError &&
                            <SzTypographie variant="caption" color={"danger"}>
                                {t('admin:admin.contracts.create.emailError')}
                            </SzTypographie>
                        }
                    </>
                    }
                </div>
            </div>
        </div>
        <div className="mt-4 text-right">
            <SzButton
                className="previous-btn mr-2"
                variant="secondary"
                onClick={previousStepHandle}
            >
                {t('previous')}
            </SzButton>
            <SzButton
                className="next-btn"
                onClick={nextStepHandle}
            >
                {t('next')}
            </SzButton>
        </div>
    </div>;
}

export default Step3From;
