// action strings

export enum USER {
  GET_MAP_PREFERENCES = "GET_MAP_PREFERENCES",
  LOG_IN = "APP_LOGIN",
  LOG_OUT = "APP_LOGOUT",
  SET_HOME_MAP_POSITION = "SET_HOME_MAP_POSITION",
  SET_REPORT_MAP_POSITION = "SET_REPORT_MAP_POSITION",
  SET_THEMATIC_MAP_POSITION = "SET_THEMATIC_MAP_POSITION",
  SET_MAP_PREFERENCES = "SET_MAP_PREFERENCES",
  UPDATE_FORCED_LAYER = "UPDATE_FORCED_LAYER",
  UPDATE_VISIBLE_LAYER = "UPDATE_VISIBLE_LAYER",
  SET_REPORT_LIST_TYPE = "SET_REPORT_LIST_TYPE",
}

export enum SEARCH {
  RESULT = "SEARCH_GET_RESULT",
  SUGGEST = "SEARCH_GET_SUGGEST",
  INPUT_VALUE = "SEARCH_SET_INPUT_VALUE",
  AUTO_COMPLETE = "SEARCH_AUTO_COMPLETE",
  LOADING = "SEARCH_LOADING",
  DETAIL = "detail",
  DETAIL_OBJECT = "DETAIL_OBJECT",
  DETAIL_TAB = "DETAIL_TAB",
  DOCUMENT = "document",
  BACK_TO_SEARCH = "SEARCH_BACK_TO_SEARCH",
  SEARCH_EMPTY_ERROR = "SEARCH_USER_INPUT_TOO_SHORT",
  SEARCH_INIT = "SEARCH_INIT",
  CLOSE_ALL = "CLOSE_ALL",
  GET_HISTORY = "GET_HISTORY",
  GET_SUGGESTION_HISTORY = "GET_SUGGESTION_HISTORY",
  DISPLAY_SMARTSHAPE_FULLSCREEN = "DISPLAY_SMARTSHAPE_FULLSCREEN",
  DISPLAY_MAP_3D = "DISPLAY_MAP_3D",
  DISPLAY_MAP_FULLSCREEN = "DISPLAY_MAP_FULLSCREEN",
  DISPLAY_EQUIPEMENT_FROM_SMARTSHAPE = "DISPLAY_EQUIPEMENT_FROM_SMARTSHAPE",
}

export enum REPORT {
  RETRIEVE_REPORTS = "RETRIEVE_REPORTS",
  RETRIEVE_PROJECTS = "RETRIEVE_PROJECTS",
  RETRIEVE_SIGNALS = "RETRIEVE_SIGNALS",
  RETRIEVE_SITES = "RETRIEVE_SITES",
  RETRIEVE_PARCEL = "RETRIEVE_PARCEL",
  RETRIEVE_PLU = "RETRIEVE_PLU",
  UPDATE_FILTERS = "UPDATE_FILTERS",
  UPDATE_PROJECT_FILTERS = "UPDATE_PROJECT_FILTERS",
  UPDATE_SIGNAL_FILTERS = "UPDATE_SIGNAL_FILTERS",
  UPDATE_SITES_FILTERS = "UPDATE_SITES_FILTERS",
  UPDATE_PARCEL_FILTERS = "UPDATE_PARCEL_FILTERS",
  UPDATE_PLU_FILTERS = "UPDATE_PLU_FILTERS",
  GET_FAVORITES = "GET_FAVORITES",
  SET_FAVORITE = "SET_FAVORITE",
  GET_PROJECT_FAVORITES = "GET_PROJECT_FAVORITES",
  SET_PROJECT_FAVORITE = "SET_PROJECT_FAVORITE",
  GET_SIGNAL_FAVORITES = "GET_SIGNAL_FAVORITES",
  SET_SIGNAL_FAVORITE = "SET_SIGNAL_FAVORITE",
  GET_SITE_INDUS_FAVORITES = "GET_SITE_INDUS_FAVORITES",
  SET_SITE_INDUS_FAVORITE = "SET_SITE_INDUS_FAVORITE",
  SHOW_REPORT = "SHOW_REPORT",
  HIDE_REPORT = "HIDE_REPORT",
  SHOW_DETAIL = "SHOW_DETAIL",
  HIDE_DETAIL = "HIDE_DETAIL",
  SHOW_PROJECT_DETAIL = "SHOW_PROJECT_DETAIL",
  HIDE_PROJECT_DETAIL = "HIDE_PROJECT_DETAIL",
  SHOW_SIGNAL_DETAIL = "SHOW_SIGNAL_DETAIL",
  HIDE_SIGNAL_DETAIL = "HIDE_SIGNAL_DETAIL",
  SHOW_SITE_INDUS_DETAIL = "SHOW_SITE_INDUS_DETAIL",
  HIDE_SITE_INDUS_DETAIL = "HIDE_SITE_INDUS_DETAIL",
  CHANGE_TAB = "CHANGE_TAB",
  DISPLAY_REPORT_MAP_3D = "DISPLAY_REPORT_MAP_3D",
  SET_SORT = "SET_SORT",
  SET_PROJECT_SORT = "SET_PROJECT_SORT",
  SET_SIGNAL_SORT = "SET_SIGNAL_SORT",
  SET_SITE_INDUS_SORT = "SET_SITE_INDUS_SORT",
  RETRIEVE_GEOSERVER_BV = "RETRIEVE_GEOSERVER_BV",
}

export enum THEMATICMAP {
  RETRIEVE_REPORTS = "THEMATICMAP_RETRIEVE_REPORTS",
  UPDATE_FILTERS = "THEMATICMAP_UPDATE_FILTERS",
  SHOW_DETAIL = "THEMATICMAP_SHOW_DETAIL",
  HIDE_DETAIL = "THEMATICMAP_HIDE_DETAIL",
}

export enum FILTERS {
  UPDATE = "SEARCH_UPDATE_FILTERS",
  RESET = "SEARCH_RESET_FILTERS",
  SET = "SEARCH_SET_FILTERS",
}

export enum ERROR {
  SET = "ERROR.SET",
  RESET = "ERROR.RESET",
}

export enum BREADCRUMB {
  SET = "BREADCRUMB_SET",
  SET_SEARCH_KEYWORDS = "BREADCRUMB_SET_SEARCH_KEYWORDS",
  BACK_TO_SEARCH = "BREADCRUMB_BACK_TO_SEARCH",
}

export enum LAYER {
  CONNECTION = "Branchements",
}

export enum CONTRACT {
  CURRENT_CONTRACT = 'current_contract',
}

export enum MAP {
  SET_MAP_COORDINATE = "SET_MAP_COORDINATE",
  SET_MAP_INFO = "SET_MAP_INFO",
}
