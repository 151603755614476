import React, { ReactElement, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzDatePicker, SzInput, SzSelect } from "@suezenv/react-theme-components";
import { bindActionCreators } from "redux";
import { projectCities, siteConformities, siteDomains } from "../../../config/report";
import { defaultProperties } from "../../../manager/map/config";
import { goToHome, zoomToCoordinates } from "../../../manager/map/navigation";
import { reportTabs } from "../../../pages/Report";
import { Report as ReportService } from "../../../services/Report";
import { Search as SearchService } from "../../../services/Search";
import { ReportActions, UserActions } from "../../../store/redux/actions";
import { mapOrigin } from "../../../store/redux/reducers/user";
import {
  IReportData,
  ISiteIndustrielFilter,
} from "../../../store/redux/types";
import AutoCompleteInput from "../../Utils/AutoCompleteInput/AutoCompleteInput";
import ListHeader from "./ListHeader";

const SiteIndustrielListFilters = (props: any): ReactElement => {

  const { t } = useTranslation();

  const autoCompleteMinLength = 3;
  const [autocompleteOptions, setAutocompleteOptions] = useState([""]);

  const cityOptions = Object.entries(projectCities).map((item: any) => {
    return { label: item[1], value: item[1] };
  });

  const domainOptions = Object.entries(siteDomains).map((item: any) => {
    return { label: item[1], value: item[1] };
  });

  const conformityOptions = Object.entries(siteConformities).map((item: any) => {
    return { label: item[0], value: item[1] };
  });

  const [localId, setId] = useState<string>(props.siteIndustrielFilters.id);
  const [localAddress, setAddress] = useState<string>(props.siteIndustrielFilters.address);
  const [localName, setName] = useState<string>(props.siteIndustrielFilters.name);
  const [localConnectionNumber, setConnectionNumber] = useState(props.siteIndustrielFilters.connectionNumber);
  const [localConformity, setConformity] = useState<any[]>(props.siteIndustrielFilters.conformity);
  const [localCity, setCity] = useState<any[]>(props.siteIndustrielFilters.city);
  const [localDomain, setDomain] = useState<any[]>(props.siteIndustrielFilters.domain);
  const [visitStartDate, setVisitStartDate] = useState<Date | undefined>(props.siteIndustrielFilters.visitStartDate);
  const [visitEndDate, setVisitEndDate] = useState<Date | undefined>(props.siteIndustrielFilters.visitEndDate);
  const [startDate, setStartDate] = useState<Date | undefined>(props.siteIndustrielFilters.startDate);
  const [endDate, setEndDate] = useState<Date | undefined>(props.siteIndustrielFilters.endDate);

  const updateDate = (newDate: Date, startOrEnd: string) => {
    if (startDate && endDate) {
      if ("start" === startOrEnd && newDate.getTime() <= endDate.getTime()) {
        setStartDate(newDate);
      } else if (newDate.getTime() >= startDate.getTime()) {
        setEndDate(newDate);
      }
    } else {
      if ("start" === startOrEnd) {
        setStartDate(newDate);
      } else {
        setEndDate(newDate);
      }
    }
  };

  const updateVisitDate = (newDate: Date, startOrEnd: string) => {
    if (startDate && endDate) {
      if ("start" === startOrEnd && newDate.getTime() <= endDate.getTime()) {
        setVisitStartDate(newDate);
      } else if (newDate.getTime() >= startDate.getTime()) {
        setVisitEndDate(newDate);
      }
    } else {
      if ("start" === startOrEnd) {
        setVisitStartDate(newDate);
      } else {
        setVisitEndDate(newDate);
      }
    }
  };

  useEffect(() => {
    if (props.siteIndustrielFilters.id) {
      setId(props.siteIndustrielFilters.id);
    }
    if (props.siteIndustrielFilters.address) {
      setAddress(props.siteIndustrielFilters.address);
    }
    if (props.siteIndustrielFilters.name) {
      setName(props.siteIndustrielFilters.name);
    }
    if (props.siteIndustrielFilters.connectionNumber) {
      setConnectionNumber(props.siteIndustrielFilters.connectionNumber);
    }
    if (props.siteIndustrielFilters.city) {
      const cityValues = cityOptions.filter((item: any) => {
        return props.siteIndustrielFilters.city.includes(item.value);
      });
      setCity(cityValues);
    }
    if (props.siteIndustrielFilters.domain) {
      const domainValues = domainOptions.filter((item: any) => {
        return props.siteIndustrielFilters.domain.includes(item.value);
      });
      setDomain(domainValues);
    }
    if (props.siteIndustrielFilters.conformity) {
      const conformityValues = conformityOptions.filter((item: any) => {
        return props.siteIndustrielFilters.conformity.includes(item.value);
      });
      setConformity(conformityValues);
    }
    if (props.siteIndustrielFilters.visitStartDate) {
      if (typeof props.siteIndustrielFilters.visitStartDate !== "object") {
        const startingDate = new Date(props.siteIndustrielFilters.visitStartDate);
        setVisitStartDate(startingDate);
      }
    }
    if (props.siteIndustrielFilters.visitEndDate) {
      if (typeof props.siteIndustrielFilters.visitEndDate !== "object") {
        const endingDate = new Date(props.siteIndustrielFilters.visitEndDate);
        setVisitEndDate(endingDate);
      }
    }
    if (props.siteIndustrielFilters.startDate) {
      if (typeof props.siteIndustrielFilters.startDate !== "object") {
        const startingDate = new Date(props.siteIndustrielFilters.startDate);
        setStartDate(startingDate);
      }
    }
    if (props.siteIndustrielFilters.endDate) {
      if (typeof props.siteIndustrielFilters.endDate !== "object") {
        const endingDate = new Date(props.siteIndustrielFilters.endDate);
        setEndDate(endingDate);
      }
    }
  }, [props.currentSiteIndustrielFavFilter, props.listType]);

  const updateValues = (values: [] | null, callback: any) => {
    if (null !== values) {
      callback(values.map((item: any) => {
        return item;
      }));
    } else {
      callback([]);
    }
  };

  const isFiltersEmpty = () => {
    return ((!localAddress || localAddress === "") &&
      (!localId || localId === "") &&
      (!localName || localName === "") &&
      (!localConnectionNumber || localConnectionNumber === "") &&
      (!localCity || localCity.length === 0) &&
      (!localDomain || localDomain.length === 0) &&
      (!localConformity || localConformity.length === 0) &&
      (!visitStartDate) && (!visitEndDate) &&
      (!startDate) && (!endDate));
  };
  const [areFiltersEmpty, setAreFiltersEmpty] = useState(isFiltersEmpty());

  useEffect(() => {
    setAreFiltersEmpty(
      isFiltersEmpty(),
    );
  }, [
    localId,
    localAddress,
    localName,
    localConnectionNumber,
    localCity,
    localDomain,
    localConformity,
    visitStartDate,
    visitEndDate,
    startDate,
    endDate,
  ]);

  useEffect(() => {
    if (areFiltersEmpty) {
      resetAction();
    }
  }, [areFiltersEmpty]);

  const filterAction = () => {
    const filters: ISiteIndustrielFilter = {
      id: localId,
      address: localAddress,
      name: localName,
      connectionNumber: localConnectionNumber,
      city: localCity.map((item) => item.value),
      domain: localDomain.map((item) => item.value),
      conformity: localConformity.map((item) => item.value),
      visitStartDate,
      visitEndDate,
      startDate,
      endDate,
    };
    if (filters.address) {
      SearchService.getAddressCoordinates(filters.address).then((res: any) => {
        if (res.coordinates && res.cityCode.match(/92/g)) {
          props.setReportMapPosition([res.coordinates[1], res.coordinates[0], defaultProperties.scale]);
          if (props.currentTab === reportTabs.map) {
            zoomToCoordinates(res.coordinates[1], res.coordinates[0]);
          }
        } else {
          props.setReportMapPosition(mapOrigin);
          if (props.currentTab === reportTabs.map) {
            zoomToCoordinates(mapOrigin[0], mapOrigin[1], mapOrigin[2]);
          }
        }

      });
    }
    props.updateSiteIndustrielFilters(filters);
  };

  const resetAction = () => {
    setId("");
    setAddress("");
    setName("");
    setConnectionNumber("");
    setCity([]);
    setDomain([]);
    setConformity([]);
    setVisitStartDate(undefined);
    setVisitEndDate(undefined);
    setStartDate(undefined);
    setEndDate(undefined);
    const filters: ISiteIndustrielFilter = {
      id: "",
      address: "",
      name: "",
      connectionNumber: "",
      city: [],
      domain: [],
      conformity: [],
      visitStartDate: undefined,
      visitEndDate: undefined,
      startDate: undefined,
      endDate: undefined,
    };
    props.updateSiteIndustrielFilters(filters);
    goToHome();
    props.setReportMapPosition(mapOrigin);
    ReactGA.event({ action: `Reset Site Industriel filters`, category: "Report" });
  };

  const autocompleteAddress = (address: string) => {
    return ReportService.getSiteIndustrielAddressAutocomplete(address)
      .then((data: Array<{ address: string }>) => {
        const options = data.map((item: { address: string }) => {
          return item.address;
        });
        setAutocompleteOptions(options);
      });
  };
  return (
    <div className={"report--menu--tabs_list"}>
      <ListHeader
        tableHeader={props.tableHeader}
        setWeight={props.setWeight}
        filterAction={filterAction}
        resetAction={resetAction}
        areFiltersEmpty={areFiltersEmpty}
      />
      <div className={"report--menu--tabs_list--filter-container"}>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:siteIndustriel.name")}</span>
          </div>
          <SzInput
            className="mb-0 border-secondary name"
            placeholder={""}
            value={localName}
            onChange={(event: { target: { value: string } }) => setName(event.target.value)}
            handleClear={() => setName("")}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:siteIndustriel.idCD92")}</span>
          </div>
          <SzInput
            className="mb-0 border-secondary name"
            placeholder={""}
            value={localId}
            onChange={(event: { target: { value: string } }) => setId(event.target.value)}
            handleClear={() => setId("")}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>
              {t("report:siteIndustriel.connectionNumber")}
            </span>
          </div>
          <SzInput
            className="mb-0 border-secondary connectionNumber"
            placeholder={""}
            value={localConnectionNumber}
            onChange={(event: { target: { value: string } }) => setConnectionNumber(event.target.value)}
            handleClear={() => setConnectionNumber("")}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:siteIndustriel.visitDate")}</span>
          </div>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.from")}</span>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.to")}</span>
          </div>
          <SzDatePicker
            dateRange
            endDate={visitEndDate}
            endMinDate={undefined}
            startDate={visitStartDate}
            startMinDate={undefined}
            onChangeEndDate={(value: Date) => {
              value.setHours(12, 0, 0, 0);
              updateVisitDate(value, "end");
            }}
            onChangeStartDate={(value: Date) => {
              value.setHours(12, 0, 0, 0);
              updateVisitDate(value, "start");
            }}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:city")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary city"
            isSearchable
            isClearable
            isMulti
            id="city"
            name="city"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setCity);
            }}
            options={cityOptions}
            value={localCity}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:address")}</span>
          </div>
          <AutoCompleteInput
            autoCompleteOptions={autocompleteOptions}
            className={"address"}
            debounce={500}
            handler={setAddress}
            placeholder={""}
            minLength={autoCompleteMinLength}
            searchHandler={autocompleteAddress}
            value={localAddress}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>
              {t("report:siteIndustriel.conformity.label")}
            </span>
          </div>
          <SzSelect
            className="mb-0 border-secondary city"
            isSearchable
            isClearable
            isMulti
            id="conformity"
            name="conformity"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setConformity);
            }}
            options={conformityOptions}
            value={localConformity}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:siteIndustriel.domain")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary city"
            isSearchable
            isClearable
            isMulti
            id="domain"
            name="domain"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setDomain);
            }}
            options={domainOptions}
            value={localDomain}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:siteIndustriel.updatedAt")}</span>
          </div>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.from")}</span>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.to")}</span>
          </div>
          <SzDatePicker
            dateRange
            endDate={endDate}
            endMinDate={undefined}
            startDate={startDate}
            startMinDate={undefined}
            onChangeEndDate={(value: Date) => {
              value.setHours(12, 0, 0, 0);
              updateDate(value, "end");
            }}
            onChangeStartDate={(value: Date) => {
              value.setHours(12, 0, 0, 0);
              updateDate(value, "start");
            }}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: { report: IReportData }) => {
  return {
    currentSiteIndustrielFavFilter: state.report.currentSiteIndustrielFavFilter,
    siteIndustrielCurrentPage: state.report.siteIndustrielCurrentPage,
    currentTab: state.report.currentTab,
    siteIndustrielFilters: state.report.siteIndustrielFilters,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...ReportActions, ...UserActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteIndustrielListFilters);
