import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import thunk, { ThunkMiddleware } from "redux-thunk";
import rootReducer from "./redux/reducers";
import { AppActions } from "./redux/types";
import permissionsServiceMiddleWare from "./Middleware/permissionsServiceMiddleWare";

export const history = createBrowserHistory();

export type AppState = ReturnType<typeof rootReducer>;

const composeEnhancer: typeof compose =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(
  rootReducer(history),
  composeEnhancer(
    applyMiddleware(
      routerMiddleware(history),
      thunk as ThunkMiddleware<AppState, AppActions>,
      permissionsServiceMiddleWare
    ),
  ),
);
