import { mdiLoading } from "@mdi/js";
import Icon from "@mdi/react";
import React, { ReactElement } from "react";
// import ReactGA from "react-ga";
import { connect } from "react-redux";
import { SzIcon } from "@suezenv/react-theme-components";
import { bindActionCreators } from "redux";
import { SearchActions } from "../../../store/redux/actions";
import { IFilter, ISearch } from "../../../store/redux/types";
import "./suggestions.scss";

export enum suggestionType {
  HISTORY = "HISTORY",
  AUTOCOMPLETE = "AUTOCOMPLETE",
}

interface ISuggestions {
  title: string;
  icon: any;
  getSearch: (search: ISearch, filters?: IFilter[]) => any;
  getSigObjectByIdent: (ident: string) => any;
  loading?: boolean;
  search: ISearch;
  suggestions: Array<{keywords: string, date: Date}>;
  type: keyof typeof suggestionType;
  hide: () => void;
}

const Suggestions = (props: ISuggestions): ReactElement => {

  const isNumeric = (text: any): boolean => {
    return (!isNaN(text) && !isNaN(parseFloat(text)));
  };

  const isIdent = (text: any): boolean => {
    return (!isNumeric(text) && text.match(/^[0-9 -]*$/));
  };

  function handleClick(keywords: string): void {
    // ReactGA.event({
    //   action: `Search from Suggestions`,
    //   category: props.type,
    //   label: `: "recherche: "${keywords}"`,
    // });
    const search = props.search;
    search.userInput = keywords;
    if (isIdent(keywords)) {
      props.getSigObjectByIdent(keywords);
    } else {
      props.getSearch(search);
    }
    props.hide();
  }

  return (<div className={"search-suggestions"}>
      <div className={"search-suggestions-header"}>
        <span className={"search-suggestions-title"}>{props.title}</span>
        <span className={"search-suggestions-loader"}>
          { props.loading && (
            <Icon path={mdiLoading} title="Loader" size={1} spin />
          )}
        </span>
      </div>
      {props.suggestions && (
        props.suggestions.map((item: { keywords: string, date: Date }, key: any) => {
          return <div key={key} className={"search-suggestions-item"} onMouseDown={() => {
            handleClick(item.keywords);
          }}>
            <div className={"search-suggestions-item-container"}>
              <SzIcon className={"suggestion-icon"} icon={props.icon} variant={"line"}/> {item.keywords}
            </div>
          </div>;
        })
      )}
    </div>);
};

const mapStateToProps = (state: { search: ISearch }) => {
  return {
    search: state.search,
    searchHistory: state.search.history.searches,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({...SearchActions}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Suggestions);
