import React from "react";
import { useTranslation } from "react-i18next";
import { SzTypographie } from "@suezenv/react-theme-components";
import AdminTabs from "../../components/admin/adminTabs";
import { adminTabsEventKeys } from "../../constants";
import { Contract } from "../../types";
import { connect } from "react-redux";

const AdminTerritorialManagementPage = (props: { currentContract: Contract }) => {
    const { currentContract } = props;
    const { t } = useTranslation();
    const tabsDisplayed = [
        adminTabsEventKeys.CONTRACT_AREA,
        adminTabsEventKeys.CONTRACT_USERS,
        adminTabsEventKeys.CONTRACT_ORGANIZATIONS,
        adminTabsEventKeys.CONTRACT_OPERATOR,
        adminTabsEventKeys.CONTRACT_SKILLS
    ];

//{t('admin:admin.territorialManagement.title', { 'contractName': currentContract.label})}

    return <div className='container-fluid my-3 overflow-auto h-100 admin'>
        <div className="row">
            <div className="col">
                <SzTypographie variant="h1" className="mb-0">
                    {t('admin:admin.territorialManagement.title', { 'contractName': currentContract.label})}
                </SzTypographie>
                <SzTypographie variant="caption" className="title-decoration">
                    {t('admin:admin.territorialManagement.subtitle')}
                </SzTypographie>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <div className="box box-no-padding my-3 px-4">
                    <AdminTabs tabsDisplayed={tabsDisplayed}/>
                </div>
            </div>
        </div>
    </div>;
}

const mapStateToProps = (state: { contract: { currentContract: Contract } }) => ({
    currentContract: state.contract.currentContract
});

export default connect(mapStateToProps)(AdminTerritorialManagementPage);
