import { Constants } from "../../../constants";

const permissionsReducer = (state = {}, action: any) => {
  switch (action.type) {
    case Constants.USER_PERMISSIONS:
      return { ...state, permissions: action.payload };
    default:
      return state;
  }
};

export default permissionsReducer;
