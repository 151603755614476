import React from 'react';
import { useTranslation } from 'react-i18next';
import { SzButton, SzTypographie } from "@suezenv/react-theme-components";
import successPicture from "../../../../assets/img/contract/creation_success.svg";
import { RouteComponentProps, withRouter } from "react-router";
import { AppUrls } from "../../../../constants";

const ContractCreationSuccess = (props: RouteComponentProps) => {
    const { t } = useTranslation();

    return <div className="row">
        <div className={"col-md-8 offset-md-2 bg-white mt-5 p-5"}>
            <div className={"pt-5 pb-5"}>
                <div className={"col-md-4 offset-md-3 d-flex"}>
                    <img src={successPicture} alt=""/>
                </div>
                <div className={"col-md-2 d-flex justify-content-end flex-md-column p-0"}>
                    <SzTypographie variant="h2" weight={"bold"} className="mb-4">
                        {t('admin:admin.contracts.create.success.contract_created')}
                    </SzTypographie>
                    <SzTypographie variant="caption" color={"disabled"} className="mb-3">
                        {t('admin:admin.contracts.create.success.invitations_sent')}
                    </SzTypographie>
                    <SzTypographie variant="caption" color={"disabled"} className="mb-3">
                        {t('admin:admin.contracts.create.success.check_contract_to_finish')}
                    </SzTypographie>
                </div>
                <div className="row">
                    <div className={"col-md-8 offset-md-2 d-flex justify-content-center mt-5"}>
                        <SzButton
                            variant={"tertiary"}
                            icon={"arrow-right"}
                            alignIcon={"right"}
                            onClick={() => props.history.push(AppUrls.ADMIN_CONTRACTS_TOFINISH)}
                        >
                            {t('admin:admin.contracts.create.success.go_to_contract_to_finish')}
                        </SzButton>
                    </div>
                </div>
            </div>
        </div>
    </div>
}

export default withRouter(ContractCreationSuccess);