import React, { useEffect, useState } from "react";
import { OrganizationService } from "../../../../../services/OrganizationService";
import { useQuery } from "react-query";
import SzDualListBox, { SzDualListBoxOption } from "../../../../elements/SzDualListBox";
import { Organization } from "../../../../../types";
import { SzButton } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";

type PropsType = {
    contractId: string,
    setOrganizationIds: (organizationIds: string[]) => void,
    goToNext: () => void
}

const Step0 = (props: PropsType) => {
    const { contractId, setOrganizationIds, goToNext } = props;
    const [options, setOptions] = useState<SzDualListBoxOption[]>([]);
    const [selectedOptions, setSelectedOptions] = useState<SzDualListBoxOption[]>([]);
    const { t } = useTranslation();

    const fetchOrganizations = () => OrganizationService.getOrganizations(contractId, undefined, undefined, 1, 9999);
    const { data } = useQuery(['contractOrganizations', contractId], fetchOrganizations);

    const organizationDataToOption = (organizations: Organization[]) => {
        return organizations.map((organization: Organization) => {
            return { id: organization.id as string, label: organization.label };
        });
    }

    const filterOrganizationsOptions = (filter: string) => {
        if (data) {
            const organizations: Organization[] = Object.values(data?.data);
            setOptions(organizationDataToOption(organizations).filter((option: SzDualListBoxOption) => option.label.toLowerCase().indexOf(filter.toLowerCase()) !== -1))
        }
    }

    useEffect(() => {
        if (data) {
            const organizations: Organization[] = Object.values(data?.data);
            setOptions(organizationDataToOption(organizations));
        }
    }, [data])

    useEffect(() => {
        setOrganizationIds(selectedOptions.map((selected: SzDualListBoxOption) => selected.id));
    }, [selectedOptions]);

    return <div>
        <div className="row">
            <div className="col">
                <SzDualListBox
                    options={options}
                    selectedOptions={selectedOptions}
                    leftSideTitle={t('admin:admin.users.modal.add.organizationsOption')}
                    rightSideTitle={t('admin:admin.users.modal.add.organizationsSelected')}
                    onChange={setSelectedOptions}
                    onKeywordsChange={filterOrganizationsOptions}
                    searchPlaceholder={t('search')}
                />
            </div>
        </div>
        <div className="mt-4 text-right">
            <SzButton
                className="next-btn"
                onClick={goToNext}
            >
                {t('next')}
            </SzButton>
        </div>
    </div>
}

export default Step0;
