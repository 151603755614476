import React from 'react';
import { MatomoProvider, useMatomo } from '@datapunt/matomo-tracker-react';
import { Fragment, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from "react-redux";
import { Contract } from "../../types";
import { config } from '../../config';
import { createInstance } from '@datapunt/matomo-tracker-react';

type Props = {
	children: JSX.Element | JSX.Element[],
	currentContract: Contract
}
const MatomoLayout = (props: { children: any, currentContract: Contract }) => {
	const { currentContract, children } = props;

	let matomoInstance = createInstance(
		{
			urlBase: config.matomoUrlBase,
			siteId: parseInt(currentContract.contractMetadatas['matomoSiteId']),
		}
	);

	return (
		<MatomoProvider value={matomoInstance}>
			<MatomoEventSender />
			{children}
		</MatomoProvider>
	);
}

function MatomoEventSender() {
	/** @typescript-eslint/no-unused-vars */
	const { trackPageView, trackEvent } = useMatomo();
	const location = useLocation()

	useEffect(() => {
		trackPageView({});
	}, [location, trackPageView]);

	return <Fragment />
}

const mapStateToProps = (state: { contract: { currentContract: Contract }}) => ({
	currentContract: state.contract.currentContract
});

export default connect(mapStateToProps)(MatomoLayout);
