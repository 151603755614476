import * as esriLoader from "esri-loader";
import itvMarker from "../../assets/img/itv-pin.svg";
import { config } from "../../config";
import { setMapPreferences, updateForcedLayers, updateVisibleLayers } from "../../store/redux/actions/user";
import { mapOrigin } from "../../store/redux/reducers/user";
import { CONF } from "./config";

export let groupLayer: any;
export let loadedMapView: any;
export let loadedSceneView: any;
export let loadedPoint: any;
export let loadedExtent: any;
export let loadedFeatureLayer: any;
export let loadedWatchUtils: any;
export let loadedGraphic: any;
export let loadedGraphicsLayer: any;
export let loadedField: any;
export let layerList: any;
export let highlightObjects: any;
export let addItvGraphics: any;
export let addItvLayer: () => any;
export let itvGraphicsLayer: any;
export let highlightSiteIndus: any;
export let clearItvGraphics: any;
export let chooseLayers: (layersIDs: Array<string | number>) => void;
export let forceLayersOnLoad: (layerList: string[]) => void;
export let allBaseMap: any;
export let changeBaseMap: (baseMapIndex: number) => void;
export let getMapPosition: () => [number, number, number];
export let getScenePosition: () => [number, number, number];
export let getVisibleLayersList: () => string[];
let baseMapOpacity: number = 40;
const defaultScale: number = 2256.994353;
const defaultCenterPoint: [number, number] = [2.368460, 48.272214];
// The id of the patrimoine layer. When map is updated and things don't work anymore, check it hasn't changed.
export const patrimoineLayerTitle = "Patrimoine";
export const limitLayerTitle = "Limites Administratives";
export const conformityLayerTitle: string = "Conformité des conduites de branchements";
export const bvLayerTitle: string = "Conformité du bassin versant";
export const bvSubLayerTitle: string = "Conduites de branchements";
export const bvParcelLayerTitle: string = "Déconnexion des eaux pluviales";
export const pluLayerTitle: string = "Infiltrabilité des sols en relation avec le PLU";
export const majorFaultTypesLayerTitle: string = "Non conforme avec défaut majeur identifié";
export const localStorageAllVisibleVar = "allVisibleLayers";
export const projectLayerTitle = "Projets d'aménagements urbains";
export const signalLayerTitle = "Signalements";
export const siteIndustrielLayerTitle = "Conformité des rejets des industriels";
export let updateVisibleLayer: boolean = true;
export let switchButton: HTMLInputElement;
export let zoomToObjectsScene: any;
export let highlightObjectsScene: any;
export let zoomToCoordinatesScene: any;
export let removeHighlightsScene: any;
export let highlightProject: any;
export let mapLoaderConfig: { activeView: any, container: string | null, mapView: any, sceneView: any } = {
  activeView: null,
  container: "viewDiv", // use same container for views
  mapView: null,
  sceneView: null,
};

export let resetHighlights: () => void;
export let initSceneWatchingForHighlight: () => void;

export function loadMap() {
  const options: esriLoader.ILoadScriptOptions = {
    dojoConfig: {
      portalUrl: CONF.PORTAL_URL,
    },
    version: "4.21",
  };

  return esriLoader
    .loadModules(
      [
        "esri/views/SceneView",
        "esri/WebScene",
        "esri/core/watchUtils",
        "esri/WebMap",
        "esri/views/MapView",
        "esri/config",
        "esri/identity/IdentityManager",
        "esri/Basemap",
        "esri/layers/GraphicsLayer",
        "esri/Graphic",
        "esri/layers/TileLayer",
        "esri/layers/VectorTileLayer",
        "esri/layers/MapImageLayer",
        "esri/widgets/Sketch/SketchViewModel",
        "esri/widgets/ScaleBar",
        "esri/widgets/Compass",
        "esri/widgets/Locate",
        "esri/widgets/Home",
        "esri/widgets/Expand",
        "esri/widgets/Legend",
        "esri/widgets/LayerList",
        "esri/widgets/BasemapGallery",
        "esri/widgets/DistanceMeasurement2D",
        "esri/widgets/AreaMeasurement2D",
        "esri/widgets/Zoom",
        "esri/widgets/Slider",
        "esri/geometry/Point",
        "esri/layers/FeatureLayer",
        "esri/layers/GroupLayer",
        "esri/geometry/Extent",
        "dojo/promise/all",
        "dojo/Deferred",
        "dojo/dom-construct",
        "esri/widgets/NavigationToggle",
        "esri/widgets/AreaMeasurement3D",
        "esri/widgets/DirectLineMeasurement3D",
        "esri/symbols/PictureMarkerSymbol",
        "esri/rest/support/Query",
        "esri/layers/support/Field",
      ],
      options,
    )
    .then(
      ([
        SceneView,
        WebScene,
        watchUtils,
        WebMap,
        MapView,
        esriConfig,
        esriId,
        Basemap,
        GraphicsLayer,
        Graphic,
        TileLayer,
        VectorTileLayer,
        MapImageLayer,
        SketchViewModel,
        ScaleBar,
        Compass,
        Locate,
        Home,
        Expand,
        Legend,
        LayerList,
        BasemapGallery,
        DistanceMeasurement2D,
        AreaMeasurement2D,
        Zoom,
        Slider,
        Point,
        FeatureLayer,
        GroupLayer,
        Extent,
        all,
        Deferred,
        domConstruct,
        NavigationToggle,
        AreaMeasurement3D,
        DirectLineMeasurement3D,
        PictureMarkerSymbol,
        Query,
        Field,
      ]) => {
        loadedPoint = Point;
        loadedExtent = Extent;
        loadedFeatureLayer = FeatureLayer;
        loadedGraphic = Graphic;
        loadedGraphicsLayer = GraphicsLayer;
        groupLayer = GroupLayer;
        esriConfig.portalUrl = CONF.PORTAL_URL;
        loadedWatchUtils = watchUtils;
        loadedField = Field;
        const token = localStorage.getItem("sigToken");
        esriId.registerToken({ token, server: CONF.SERVICE_URL });
        esriId.registerToken({ token, server: CONF.PORTAL_URL });

        switchButton = document.getElementById("switch-btn") as HTMLInputElement;

        const initialViewParams: { constraints: any, container: any, map: any } = {
          constraints: {
            lods: CONF.LODS,
            maxScale: 0,
          },
          container: mapLoaderConfig.container,
          map: null,
        };

        // load a webmap
        const webmap = new WebMap({
          portalItem: {
            id: CONF.WEBMAP_ID,
          },
        });

        const webScene = new WebScene({
          ground: "world-elevation",
          portalItem: {
            id: CONF.WEBSCENE_ID,
          },
        });

        // create 2D view and and set active
        mapLoaderConfig.mapView = createView(initialViewParams, "2d");
        mapLoaderConfig.mapView.map = webmap;
        mapLoaderConfig.activeView = mapLoaderConfig.mapView;
        loadedMapView = mapLoaderConfig.mapView;

        initialViewParams.map = webScene;
        initialViewParams.container = null;
        mapLoaderConfig.sceneView = createView(initialViewParams, "3d");
        loadedSceneView = mapLoaderConfig.sceneView;

        // convenience function for creating a 2D or 3D view
        function createView(params: any, type: string) {
          let view: any;
          const is2D = type === "2d";
          if (is2D) {
            view = new MapView(params);
            const basemapOrtho = new Basemap({
              baseLayers: [
                new TileLayer({
                  url: "https://geoserviceweb.hauts-de-seine.fr/agspublic/rest/services/Public/Orthophoto_2018/MapServer",
                }),
              ],
              thumbnailUrl: "https://js.arcgis.com/4.21/esri/images/basemap/satellite.jpg", // TODO
              title: "Orthophoto départementale 2018",
            });

            const basemapCustomOSM = new Basemap({
              baseLayers: [
                new VectorTileLayer({
                  url: "./layout/OpenStreetMap.json",
                }),
              ],
              thumbnailUrl: "https://js.arcgis.com/4.21/esri/images/basemap/osm.jpg",
              title: "Plan de ville OSM simplifié", // TODO
            });
            const basemapCustomOSMNB = new Basemap({
              baseLayers: [
                new VectorTileLayer({
                  url: "./layout/OpenStreetMapNB.json",
                }),
              ],
              thumbnailUrl: "https://js.arcgis.com/4.21/esri/images/basemap/osm.jpg", // TODO
              title: "Plan de ville OSM nuances de gris",
            });

            const basemapCadastre = new Basemap({
              baseLayers: [
                new MapImageLayer({
                  url: "https://geoserviceweb.hauts-de-seine.fr/agspublic/rest/services/Public/Cadastre/MapServer",
                }),
              ],
              thumbnailUrl: "https://geoserviceweb.hauts-de-seine.fr/agspublic/rest/services/Public/Cadastre/MapServer/info/thumbnail", // TODO
              title: "Cadastre",
            });

            const basemapOSM = Basemap.fromId("osm");
            basemapOSM.when().then(() => {
              basemapOSM.title = "Plan de ville Open Street Map (OSM)";
            });
            const basemapTOPO = Basemap.fromId("topo");
            basemapTOPO.when().then(() => {
              basemapTOPO.title = "Fond de carte – Topographie";
            });
            const basemapSatellite = Basemap.fromId("satellite");
            basemapSatellite.when().then(() => {
              basemapSatellite.title = "Vue aérienne";
            });
            const basemapTOPONB = Basemap.fromId("gray");
            basemapTOPONB.when().then(() => {
              basemapTOPONB.title = "Fond de carte - Toponymie nuances de gris";
            });
            allBaseMap = [
              basemapOSM,
              basemapCustomOSM,
              basemapCustomOSMNB,
              basemapTOPO,
              basemapTOPONB,
              basemapOrtho,
              basemapSatellite,
              basemapCadastre,
            ];
            const PATRIMOINE = "PATRIMOINE";

            // Outils en bas a droite
            const compass = new Compass({
              view,
            });

            view.ui.add(compass, "top-left");
            view.ui.empty("top-left");

            const locate = new Locate({
              view,
              goToOverride(mapView: any) {
                return mapView.goTo(mapOrigin);
              },
            });
            const homeWidget = new Home({
              view,
            });
            const zoomWidget = new Zoom({
              view,
            });

            view.ui.add(zoomWidget, "top-left");
            view.ui.add(homeWidget, "top-left");
            view.ui.add(compass, "top-left");
            view.ui.add(locate, "top-left");

            const scaleBar = new ScaleBar({
              unit: "metric",
              view,
            });

            // Add the widget to the bottom left corner of the view
            view.ui.add(scaleBar, "bottom-left");

            // layerList actions
            const defineActions = (event: any) => {
              // The event object contains an item property.
              // is is a ListItem referencing the associated layer
              // and other properties. You can control the visibility of the
              // item, its title, and actions using this object.

              const item = event.item;
              if (item.layer.type === "map-image") {
                item.actionsSections = [
                  [
                    {
                      className: "esri-icon-up",
                      id: "increase-opacity",
                      title: "Augementer l'opacité",
                    },
                    {
                      className: "esri-icon-down",
                      id: "decrease-opacity",
                      title: "Diminuer l'opacité",
                    },
                  ],
                ];
              } else if (item.layer.minScale) {
                item.actionsSections = [
                  [
                    {
                      className: "esri-icon-zoom-out-fixed",
                      id: "all-visibility",
                      title: "Afficher à toutes les échelles",
                    },
                  ],
                ];
              } else if (item.layer._allVisibility) {
                item.actionsSections = [
                  [
                    {
                      className: "esri-icon-zoom-in-fixed",
                      id: "all-visibility",
                      title: "Afficher aux échelles standards",
                    },
                  ],
                ];
              }
            };
            // Outils à droite
            layerList = new LayerList({
              listItemCreatedFunction: defineActions,
              view,
            });

            const legend = new Legend({
              view,
            });

            // Change opactiy of defaultmap
            Basemap.fromId("osm").resourceInfo.data.baseMapLayers[0].opacity = 0.4;
            const basemapWidget = new BasemapGallery({
              source: allBaseMap,
              view,
            });

            const expandLayerList = new Expand({
              content: layerList,
              expandTooltip: "Liste des couches",
              expanded: true,
              view,
            });
            const expandLegend = new Expand({
              content: legend,
              expandTooltip: "Légende",
              expanded: false,
              view,
            });
            const expandBasemap = new Expand({
              content: basemapWidget,
              expandTooltip: "Bibliothèque de fond de plan",
              expanded: false,
              view,
            });

            // add the toolbar for the measurement widgets
            // view.ui.add("topbar", "top-right");

            // Mesures 2D
            const measurementWidget = new DistanceMeasurement2D({
              unit: "metric",
              view,
            });
            const expandMesureDistance = new Expand({
              content: measurementWidget,
              expandIconClass: "esri-icon-line-chart",
              expandTooltip: "Mesure de distance",
              expanded: false,
              view,
            });

            const areaMeasurement2D = new AreaMeasurement2D({
              unit: "metric",
              view,
            });
            const expandMesureArea = new Expand({
              content: areaMeasurement2D,
              expandIconClass: "esri-icon-polygon",
              expandTooltip: "Mesure de surface",
              expanded: false,
              view,
            });

            let firstOpenBasemap = false;

            const highlightGraphicsLayer = new GraphicsLayer();
            highlightGraphicsLayer.listMode = "hide";
            highlightGraphicsLayer.popupEnabled = false;

            // itv graphics
            itvGraphicsLayer = new GraphicsLayer({
              id: "itvGraphics",
              listMode: "hide",
              popupEnabled: false,
            });

            view.ui.add(expandLayerList, "top-right");
            view.ui.add(expandLegend, "top-right");
            view.ui.add(expandBasemap, "top-right");
            view.ui.add(expandMesureDistance, "top-right");
            view.ui.add(expandMesureArea, "top-right");

            const allExpands = [expandLayerList, expandLegend, expandBasemap, expandMesureDistance, expandMesureArea];

            allExpands.forEach((item) => {
              item.watch("expanded", (newValue: any, oldValue: any, property: any, object: any) => {
                if (newValue === true) {
                  allExpands.forEach((item2) => {
                    if (item !== item2) {
                      item2.expanded = false;
                    }
                  });
                }
                measurementWidget.viewModel.clear();
                areaMeasurement2D.viewModel.clear();
              });
            });

            view.when(() => {
              view.map.layers.forEach((layer: any) => {
                // Watch change on service/FeatureLayer
                watchUtils.watch(layer, "visible", () => {
                  if (patrimoineLayerTitle === layer.title || limitLayerTitle === layer.title) {
                    if (updateVisibleLayer) {
                      updateVisibleLayers(getVisibleLayersList());
                    }
                  }
                });
                if (layer.type === "map-image") {
                  // Attente que le service soit chargÃ©
                  watchUtils.watch(layer, "loaded", () => {

                    // Recuperer la liste des sous-groupes/sous-couches
                    layer.sublayers.flatten((item: any) => {
                      return item.sublayers;
                    }).forEach((item: any) => {
                      // Watch on subLayer visibility
                      watchUtils.watch(item, "visible", () => {
                        if (
                          patrimoineLayerTitle === item.parent.title ||
                          patrimoineLayerTitle === item.parent.parent.title ||
                          limitLayerTitle === item.parent.title
                        ) {
                          if (updateVisibleLayer) {
                            updateVisibleLayers(getVisibleLayersList());
                          }
                        }
                      });
                    });
                  });
                }
              });

              view.map.layers.add(highlightGraphicsLayer);

              addItvLayer();

              expandBasemap.watch("expanded", () => {
                if (!firstOpenBasemap) {
                  firstOpenBasemap = true;
                  domConstruct.place("<div id='titleSlider'>Opacité du fond de plan</div><div id='sliderDiv'></div>",
                    document.getElementsByClassName("esri-basemap-gallery__item-container")[0],
                    "before",
                  );

                  const slider = new Slider({
                    container: "sliderDiv",
                    max: 100,
                    min: 0,
                    snapOnClickEnabled: false,
                    values: [view.map.basemap.baseLayers.items[0].opacity * 100],
                    visibleElements: {
                      label: true,
                      rangeLabels: true,
                    },
                  });

                  slider.on(
                    ["thumb-change", "thumb-drag"],
                    pointsizeValueChanged,
                  );

                  basemapWidget.watch("activeBasemap", () => {
                    view.map.basemap.baseLayers.items[0].opacity = (slider.values[0] / 100);
                    setMapPreferences({ baseMap: getBaseMapKey(view.map.basemap) });
                  });
                }
              });
            });

            changeBaseMap = (baseMapIndex: number) => {
              view.map.basemap = allBaseMap[baseMapIndex];
              view.map.basemap.when(() => {
                view.map.basemap.baseLayers.items[0].opacity = (baseMapOpacity / 100);
              });
            };

            const getBaseMapKey = (basemap: typeof Basemap): number => {
              let arrayKey: number = 0;
              allBaseMap.forEach((item: { id: string }, key: number) => {
                if (basemap.id === item.id) {
                  arrayKey = key;
                }
              });
              return arrayKey;
            };

            const pointsizeValueChanged = (event: { value: number }) => {
              baseMapOpacity = event.value;
              view.map.basemap.baseLayers.items[0].opacity = (baseMapOpacity / 100);
            };

            layerList.on("trigger-action", (event: any) => {
              // Capture the action id.
              const id = event.action.id;
              const visibleLayer = event.item.layer;

              if (id === "increase-opacity") {
                if (visibleLayer.opacity < 1) {
                  visibleLayer.opacity += 0.25;
                }
              } else if (id === "decrease-opacity") {
                if (visibleLayer.opacity > 0) {
                  visibleLayer.opacity -= 0.25;
                }
              } else if (id === "all-visibility") {
                if (visibleLayer._allVisibility !== true) {
                  visibleLayer._oldMinScale = visibleLayer.minScale;
                  visibleLayer._oldMaxScale = visibleLayer.maxScale;
                  visibleLayer.minScale = 0;
                  visibleLayer.maxScale = 0;
                  visibleLayer._allVisibility = true;
                  event.item.actionsSections = [
                    [
                      {
                        className: "esri-icon-zoom-in-fixed",
                        id: "all-visibility",
                        title: "Afficher aux échelles standards",
                      },
                    ]];
                } else {
                  visibleLayer.minScale = visibleLayer._oldMinScale;
                  visibleLayer.maxScale = visibleLayer._oldMaxScale;
                  visibleLayer._allVisibility = false;

                  event.item.actionsSections = [
                    [
                      {
                        className: "esri-icon-zoom-out-fixed",
                        id: "all-visibility",
                        title: "Afficher à toutes les échelles",
                      },
                    ],
                  ];
                }
                updateForcedLayers(getForcedLayersList());
              }
            });
            addItvLayer = () => {
              view.map.add(itvGraphicsLayer);
            };
            getMapPosition = () => {
              return [loadedMapView.center.latitude, loadedMapView.center.longitude, loadedMapView.scale];
            };

            const getForcedLayersList = () => {
              let layers: string[] = [];
              const limitLayers: string[] = [];
              const mainLayer = view.map.allLayers.find((item: { title: string }) => {
                return patrimoineLayerTitle === item.title;
              });
              if (mainLayer) {
                layers = mainLayer.allSublayers.items
                  .filter((item: any) => item._allVisibility === true)
                  .map((item: any) => {
                    return item.id;
                  });
              }
              return layers;
            };

            forceLayersOnLoad = (layerIDs: string[]) => {
              const mainLayer = view.map.allLayers.find((item: { title: string }) => {
                return patrimoineLayerTitle === item.title;
              });
              if (mainLayer) {
                mainLayer.when().then(() => {
                  const layers = mainLayer.allSublayers.items
                    .filter((item: any) => {
                      return layerIDs.indexOf(item.id.toString()) !== -1;
                    });
                  layers.forEach((layer: any) => {
                    layer._oldMinScale = layer.minScale;
                    layer._oldMaxScale = layer.maxScale;
                    layer.minScale = 0;
                    layer.maxScale = 0;
                    layer._allVisibility = true;
                  });
                });
              }
            };

            getVisibleLayersList = () => {
              let layers: string[] = [];
              let layersConformity: string[] = [];
              let layersMajorFaultTypes: string[] = [];
              let layersBv: string[] = [];
              let subLayersBv: string[] = [];
              let parcelLayersBv: string[] = [];
              let pluLayers: string[] = [];
              let limitLayers: string[] = [];
              const mainLayer = view.map.allLayers.find((item: { title: string }) => {
                return patrimoineLayerTitle === item.title;
              });
              const limitMainLayer = view.map.allLayers.find((item: { title: string }) => {
                return limitLayerTitle === item.title;
              });
              const mainLayerBv = loadedMapView.map.allLayers.find((item: { title: string }) => {
                return bvLayerTitle === item.title;
              });
              if (mainLayerBv) {
                layersBv = mainLayerBv.layers.items
                  .filter((item: any) => item.visible === true)
                  .map((item: any) => {
                    return item.title;
                  });
                if (mainLayerBv.visible) {
                  layersBv.push(mainLayerBv.title);
                }
              }
              const mainBvSubLayer = loadedMapView.map.allLayers.find((item: { title: string }) => {
                return bvSubLayerTitle === item.title;
              });
              if (mainBvSubLayer) {
                subLayersBv = mainBvSubLayer.layers.items
                  .filter((item: any) => item.visible === true)
                  .map((item: any) => {
                    return item.title;
                  });
              }
              const mainBvParcelLayer = loadedMapView.map.allLayers.find((item: { title: string }) => {
                return bvParcelLayerTitle === item.title;
              });
              if (mainBvParcelLayer) {
                parcelLayersBv = mainBvParcelLayer.layers.items
                  .filter((item: any) => item.visible === true)
                  .map((item: any) => {
                    return item.title;
                  });
              }

              const mainPluLayer = loadedMapView.map.allLayers.find((item: { title: string }) => {
                return pluLayerTitle === item.title;
              });
              if (mainPluLayer) {
                pluLayers = mainPluLayer.layers.items
                  .filter((item: any) => item.visible === true)
                  .map((item: any) => {
                    return item.title;
                  });
              }

              const mainLayerConformity = loadedMapView.map.allLayers.find((item: { title: string }) => {
                return conformityLayerTitle === item.title;
              });
              if (mainLayerConformity) {
                layersConformity = mainLayerConformity.layers.items
                  .filter((item: any) => item.visible === true)
                  .map((item: any) => {
                    return item.title;
                  });
                if (mainLayerConformity.visible) {
                  layersConformity.push(mainLayerConformity.title);
                }
              }
              const mainLayersMajorFaultTypes = loadedMapView.map.allLayers.find((item: { title: string }) => {
                return majorFaultTypesLayerTitle === item.title;
              });
              if (mainLayersMajorFaultTypes) {
                layersMajorFaultTypes = mainLayersMajorFaultTypes.layers.items
                  .filter((item: any) => item.visible === true)
                  .map((item: any) => {
                    return item.title;
                  });
              }

              if (mainLayer) {
                layers = mainLayer.allSublayers.items
                  .filter((item: any) => item.visible === true)
                  .map((item: any) => {
                    return item.id;
                  });
                if (mainLayer.visible) {
                  layers.push(mainLayer.id);
                }
              }
              if (limitMainLayer) {
                limitLayers = limitMainLayer.allSublayers.items
                  .filter((item: any) => item.visible === true)
                  .map((item: any) => {
                    return item.title;
                  });
                if (limitMainLayer.visible) {
                  limitLayers.push(limitMainLayer.title);
                }
              }
              return layers
                .concat(limitLayers)
                .concat(layersConformity)
                .concat(layersMajorFaultTypes)
                .concat(layersBv)
                .concat(subLayersBv)
                .concat(parcelLayersBv)
                .concat(pluLayers);
            };

            // Selection
            // polygonGraphicsLayer will be used by the sketchviewmodel
            // show the polygon being drawn on the view
            const polygonGraphicsLayer = new GraphicsLayer({
              id: "selection",
              listMode: "hide",
            });
            // view.map.add(polygonGraphicsLayer);

            // create a new sketch view model set its layer
            const sketchViewModel = new SketchViewModel({
              layer: polygonGraphicsLayer,
              view,
            });

            // add the select by polygon button the view
            const selectButton = document.getElementById("select-by-polygon");
            const saveViewElement = document.getElementById("save-view");
            const loadViewElement = document.getElementById("load-view");

            // click event for the button
            if (selectButton) {
              selectButton.addEventListener("click", () => {
                // clearUpSelection();
                view.popup.close();
                // ready to draw a polygon
                // sketchViewModel.create("rectangle");
              });
            }

            if (saveViewElement) {
              saveViewElement.addEventListener("click", () => {
                saveView();
              });
            }

            if (loadViewElement) {
              loadViewElement.addEventListener("click", () => {
                loadView();
              });
            }

            sketchViewModel.on("create", (event: any) => {
              if (event.state === "compconste") {
                // this polygon will be used to query features that intersect it
                polygonGraphicsLayer.remove(event.graphic);
                selectFeatures(event.graphic.geometry);
              }
            });

            highlightObjects = (objects: Array<{ layerName: string, objectId: string }>) => {
              highlightGraphicsLayer.removeAll();
              queryMultipleFeatures(objects, null, false, true);
            };

            highlightSiteIndus = (idCD92: any, coordinates: any) => {
              highlightGraphicsLayer.removeAll();
              const symbolType = {
                color: [255, 0, 0, 0],
                outline: {
                  color: [102, 255, 255, 0.8],
                  width: "2px",
                },
                type: "simple-fill",
              };
              const polygonAtt = {
                idCD92,
                coordinates,
                layerName: siteIndustrielLayerTitle,
              };
              const rings = coordinates.map((coordinate: any) => {
                return coordinate.reverse();
              });
              const polygon = {
                type: "polygon",
                rings: coordinates,
              };
              const g = new Graphic({
                attributes: polygonAtt,
                geometry: polygon,
                symbol: symbolType,
              });
              highlightGraphicsLayer.graphics.add(g);
            };

            const queryMultipleFeatures =
              (
                objects:
                  Array<{
                    layerName: string,
                    objectId: string,
                  }>,
                scale: any,
                zoom: boolean,
                highlight: boolean,
              ) => {
                const allDef = objects.map((object: { layerName: string, objectId: string }) => {
                  const allLayersAndSublayers = view.map.layers.filter(
                    (item: { type: string, id: string, layers: any, sublayers: any, title: string }) => {
                      return (item.type === "map-image" && item.title.toUpperCase().indexOf(PATRIMOINE) > -1);
                    }).flatten((item: { type: string, id: string, layers: any, sublayers: any, title: string }) => {
                      return item.layers || item.sublayers;
                    }).filter((item: { type: string, id: string, layers: any, sublayers: any, title: string }) => {
                      return (item.title && item.title === object.layerName.split("_").join(" "));
                    });
                  if (allLayersAndSublayers.length > 0) {
                    const featureLayer = allLayersAndSublayers.items[0];
                    const query = featureLayer.createQuery();
                    query.returnGeometry = true;
                    query.outSpatialReference = view.spatialReference;
                    query.where = "layer_id = '" + object.objectId + "'";
                    return featureLayer.queryFeatures(query);
                  } else {
                    return null;
                  }
                });
                all(allDef).then((results: any[]) => {
                  if (results.length === 1) {
                    if (results[0].features.length > 0 && results[0].features[0] != null) {
                      if (zoom) {
                        if (results[0].features[0].geometry.type === "point") {
                          view.goTo({ target: results[0].features[0].geometry, scale });
                        } else {
                          view.goTo({ target: results[0].features[0].geometry });
                        }
                      }
                      if (highlight) {
                        addHighlightGraphics(results[0].features[0]);
                      }
                    }
                  } else if (results.length > 1) {
                    const listGeom: any[] = [];
                    results.forEach((result: any) => {
                      if (result.features.length > 0 && result.features[0] != null) {
                        listGeom.push(result.features[0].geometry);
                        if (highlight) {
                          addHighlightGraphics(result.features[0]);
                        }
                      }
                    });
                    if (listGeom.length > 0 && zoom) {
                      view.goTo(listGeom);
                    }
                  }
                });
              };

            addItvGraphics = (latitude: number, longitude: number) => {
              clearItvGraphics();
              const point = {
                latitude,
                longitude,
                type: "point",
              };
              const markerSymbol = new PictureMarkerSymbol({
                height: "25px",
                url: `${config.host}${itvMarker}`,
                width: "25px",
                yoffset: "10px",
              });
              const g = new Graphic({
                geometry: point,
                symbol: markerSymbol,
              });
              itvGraphicsLayer.graphics.add(g);
            };

            clearItvGraphics = () => {
              itvGraphicsLayer.removeAll();
            };
            const addHighlightGraphics = (result: any) => {
              clearItvGraphics();
              let symbolType = null;
              if (result.geometry.type === "point") {
                symbolType = {
                  color: [102, 255, 255, 1],
                  outline: {
                    color: [102, 255, 255, 1],
                    width: "4px",
                  },
                  size: "10px",
                  style: "x",
                  type: "simple-marker",
                };
              } else if (result.geometry.type === "polygon") {
                symbolType = {
                  color: [102, 255, 255, 0.8],
                  outline: {
                    color: [128, 128, 128, 0.8],
                    width: "0.5px",
                  },
                  type: "simple-fill",
                };
              } else if (result.geometry.type === "polyline") {
                symbolType = {
                  color: [102, 255, 255, 1],
                  style: "solid",
                  type: "simple-line",  // autocasts as new SimpleLineSymbol()
                  width: "4px",
                };
              }
              if (symbolType !== null) {
                const g = new Graphic({
                  attributes: result.attributes,
                  geometry: result.geometry,
                  symbol: symbolType,
                });
                highlightGraphicsLayer.graphics.add(g);
              }
            };

            const selectFeatures = (geometry: any) => {
              const allDef: any[] = [];
              const allLayersAndSublayers = view.map.layers.filter(
                (item: {
                  type: string, id: string, layers: any, sublayers: any, visible: boolean, parent: { visible: boolean },
                  minscale: number,
                  maxScale: number,
                }) => {
                  return (item.type === "map-image" && item.visible && item.id.toUpperCase().indexOf(PATRIMOINE) > -1);
                }).flatten((item: {
                  layers: any,
                  sublayers: any,
                  visible: boolean,
                  parent: { visible: boolean },
                  minscale: number,
                  maxScale: number,
                }) => {
                  return item.layers || item.sublayers;
                }).filter((item: {
                  layers: any,
                  sublayers: any,
                  visible: boolean,
                  parent: { visible: boolean },
                  minScale: number,
                  maxScale: number,
                }) => {
                  return (
                    item.visible && item.sublayers === null && item.parent.visible &&
                    (item.minScale === 0 || item.minScale > view.scale) &&
                    (item.maxScale === 0 || item.maxScale < view.scale)
                  );
                }).filter((item: {
                  fields: any,
                }) => {
                  // Remove layer without layer_id field
                  return item.fields == null || item.fields.filter((field: any) => {
                    return field.name.toLowerCase() === "layer_id";
                  }).length > 0;
                });
              if (allLayersAndSublayers.length > 0) {
                allLayersAndSublayers.forEach((subLayer: any) => {
                  const query = subLayer.createQuery();
                  query.returnGeometry = false;
                  query.geometry = geometry;
                  query.spatialRelationship = "intersects";
                  query.outFields = ["layer_id"];
                  allDef.push(subLayer.queryFeatures(query));
                });
              } else {
                return null;
              }

              waitAll(allDef).then((results: any[]) => {
                const resultsSelection: any[] = [];
                results.forEach((result: any) => {
                  if (result.success && result.features.length > 0) {
                    result.features.forEach(
                      (feature: { sourceLayer: { title: string }, attributes: { layer_id: string } }) => {
                        const selectedElement = {
                          layerName: feature.sourceLayer.title.split(" ").join("_"),
                          objectId: feature.attributes.layer_id,
                        };
                        resultsSelection.push(selectedElement);
                      });
                    highlightObjects(resultsSelection);
                  }
                });
              });
            };

            const waitAll = (promises: any) => { // assuming "dojo/promise/all" and "dojo/Deferred"
              const d = new Deferred();
              let countdownLatch = promises.length;
              const results: any = [];
              promises.forEach((p: any, i: any) => {
                p.then((v: any) => {
                  results[i] = { success: true, features: v.features };
                  countdownLatch--;
                  if (countdownLatch === 0) { d.resolve(results); }
                }, (err: any) => {
                  results[i] = { success: false, err };
                  countdownLatch--;
                  if (countdownLatch === 0) { d.resolve(results); }
                });
              });
              return d; // dojo mixes deferred and promises because it's an older API
            };

            const saveView = () => {
              localStorage.setItem("viewExtent", JSON.stringify(view.extent));

              const allLayersAndSublayers = view.map.layers.filter((item: { type: string }) => {
                return (item.type === "map-image");
              }).flatten((item: { layers: any, sublayers: any }) => {
                return item.layers || item.sublayers;
              }).filter((item: { visible: boolean, type: string }) => {
                return (item.visible || item.type === "map-image");
              });

              const dictSubLayersVisible: { [key: string]: string[]; } = {};
              const listServiceVisible: string[] = [];
              if (allLayersAndSublayers.length > 0) {
                let serviceID = "";
                allLayersAndSublayers.items.forEach((item: { type: string, id: string, visible: boolean }) => {
                  if (item.type && item.type === "map-image") {
                    dictSubLayersVisible[item.id] = [];
                    serviceID = item.id;
                    if (item.visible) {
                      listServiceVisible.push(item.id);
                    }
                  } else {
                    dictSubLayersVisible[serviceID].push(item.id);
                  }
                });
              }
              const dictLayersVisible = {
                services: listServiceVisible,
                sublayers: dictSubLayersVisible,
              };
              localStorage.setItem("viewLayerVisible", JSON.stringify(dictLayersVisible));
            };

            const loadView = () => {
              if (localStorage.viewExtent) {
                view.extent = JSON.parse(localStorage.viewExtent);
              }
              if (localStorage.viewLayerVisible) {

                const dictLayersVisible = JSON.parse(localStorage.viewLayerVisible);

                const allLayersAndSublayers = view.map.layers.filter(
                  (item: { type: string, layers: any, sublayers: any }) => {
                    return (item.type === "map-image");
                  }).flatten((item: { type: string, layers: any, sublayers: any }) => {
                    return item.layers || item.sublayers;
                  }).filter((item: { type: string, layers: any, sublayers: any }) => {
                    return (item);
                  });

                if (allLayersAndSublayers.length > 0) {
                  let serviceID = "";
                  allLayersAndSublayers.items.forEach(
                    (item: { type: string, layers: any, sublayers: any, id: string, visible: boolean }) => {
                      if (item.type && item.type === "map-image") {
                        serviceID = item.id;
                        item.visible = dictLayersVisible.services.indexOf(item.id) !== -1;
                      } else {
                        item.visible = dictLayersVisible.sublayers[serviceID].indexOf(item.id) !== -1;
                      }
                    });
                }
              }
            };

            chooseLayers = (layersIDs: Array<string | number>) => {
              const mainLayer = view.map.allLayers.find((item: { title: string }) => {
                return patrimoineLayerTitle === item.title;
              });
              const mainLayerLimits = view.map.allLayers.find((item: { title: string }) => {
                return limitLayerTitle === item.title;
              });
              if (mainLayer) {
                updateVisibleLayer = false;
                mainLayer.when().then(() => {
                  // Use this when you need to find ID of a certain layer
                  // mainLayer.allSublayers.items.forEach((item: any) =>  {
                  //   console.log(item.id, item.title);
                  // });
                  mainLayer.visible = layersIDs.includes(mainLayer.id);
                  const layers = mainLayer.allSublayers.items
                    .filter((item: any) => {
                      return layersIDs.indexOf(item.id.toString()) === -1;
                    });
                  layers.forEach((layer: any) => (layer.visible = false));
                }).then(() => {
                  // updateVisibleLayer = true;
                });
              }
              if (mainLayerLimits) {
                updateVisibleLayer = false;
                mainLayerLimits.when().then(() => {
                  mainLayerLimits.visible = layersIDs.includes(mainLayerLimits.title);
                  const limitLayers = mainLayerLimits.allSublayers.items
                    .filter((item: any) => {
                      return layersIDs.indexOf(item.title.toString()) === -1;
                    });
                  limitLayers.forEach((layer: any) => (layer.visible = false));
                }).then(() => {
                  updateVisibleLayer = true;
                });
              }
            };
            view.popup.watch("features", (features: any) => {
              if (features.length > 0) {
                const objects = view.popup.features.map((item: any) => {
                  if (item.attributes.layer_id) {
                    return {
                      objectId: item.attributes.layer_id,
                      layerName: item.sourceLayer.title.split(" ").join("_"),
                    };
                  }
                });
                view.popup.close();
                if (objects.length > 0 && objects[0] !== undefined) {
                  highlightObjects(objects);
                }
                const clickedObjects = view.popup.features.map((item: any) => {
                  if (item.attributes.layer_id) {
                    return {
                      id: item.attributes.layer_id,
                      layerId: item.sourceLayer.title ? item.sourceLayer.title : "",
                    };
                  }
                });
                view.popup.close();
                if (clickedObjects.length > 0 && clickedObjects[0] !== undefined) {
                  const event = new CustomEvent("clickedObjects", {
                    detail: {
                      clickedObjects,
                    },
                  });
                  document.dispatchEvent(event);
                }
                const projects = features.filter((item: any) => {
                  if (item.layer) {
                    return projectLayerTitle === item.layer.parent.title;
                  }
                });
                if (projects.length > 0) {
                  const projectId = projects[0].attributes.Project;
                  const Evt = new CustomEvent("clickedProject", {
                    detail: {
                      projectId,
                    },
                  });
                  document.dispatchEvent(Evt);
                }
                const signals = features.filter((item: any) => {
                  if (item.layer) {
                    return signalLayerTitle === item.layer.parent.title;
                  }
                });
                if (signals.length > 0) {
                  const signalId = signals[0].attributes.Signal;
                  const Evt = new CustomEvent("clickedSignal", {
                    detail: {
                      signalId,
                    },
                  });
                  document.dispatchEvent(Evt);
                }
                const siteIndustriels = features.filter((item: any) => {
                  if (item.layer) {
                    return siteIndustrielLayerTitle === item.layer.parent.title;
                  }
                });
                if (siteIndustriels.length > 0) {
                  const idCD92 = siteIndustriels[0].attributes.idCD92;
                  const Evt = new CustomEvent("clickedSiteIndustriel", {
                    detail: {
                      idCD92,
                    },
                  });
                  document.dispatchEvent(Evt);
                }
              }
            });
            return view;
          } else {
            view = new SceneView(params);
            let currentHighlights: Array<{ layerName: string, objectsIds: number[], highlights: any }> = [];

            view.when(() => {
              // Refresh highlight on each scene update
              initSceneWatchingForHighlight();

              // allow navigation above and below the ground
              view.map.ground.navigationConstraint = {
                type: "none",
              };
              // the webscene has no basemap, so set a surfaceColor on the ground
              // map.ground.surfaceColor = "#fff";
              // to see through the ground, set the ground opacity to 0.4
              view.map.ground.opacity = 0.4; // CONF.3D_BASEMAP_OPACITY;

              expandBasemap.watch("expanded", () => {
                if (firstOpenBasemap === false) {
                  firstOpenBasemap = true;
                  domConstruct.place(
                    "<div id='titleSlider'>Opacité du fond de plan</div><div id='sliderDiv'></div>",
                    document.getElementsByClassName("esri-basemap-gallery__item-container")[0],
                    "before",
                  );

                  const slider = new Slider({
                    container: "sliderDiv",
                    labelsVisible: true,
                    max: 100,
                    min: 0,
                    rangeLabelsVisible: true,
                    snapOnClickEnabled: false,
                    values: [view.map.ground.opacity * 100],
                  });

                  slider.on(
                    ["thumb-change", "thumb-drag"],
                    pointsizeValueChanged,
                  );

                  basemapWidget.watch("activeBasemap", (newValue: any, oldValue: any, property: any, object: any) => {
                    view.map.ground.opacity = (slider.values[0] / 100);
                  });
                }
              });

            });

            // Outils à gauche

            const locate = new Locate({
              view,
            });
            const homeWidget = new Home({
              view,
            });

            // view.ui.add(compass, "top-left"); deja present par defaut
            view.ui.add(locate, "top-left");
            view.ui.add(homeWidget, "top-left");

            const scaleBar = new ScaleBar({ view, unit: "metric" });

            // Add the widget to the bottom left corner of the view
            view.ui.add(scaleBar, "bottom-left");

            const basemapOrtho = new Basemap({
              baseLayers: [
                new TileLayer({
                  url: "https://geoserviceweb.hauts-de-seine.fr/agspublic/rest/services/Public/Orthophoto_2018/MapServer",
                }),
              ],
              thumbnailUrl: "https://js.arcgis.com/4.21/esri/images/basemap/satellite.jpg", // TODO
              title: "Ortho 2018 HD",
            });

            // layerList actions
            const defineActions = (event: any) => {
              const item = event.item;

              if (item.layer.type === "map-image") {
                item.actionsSections = [
                  [{
                    className: "esri-icon-up",
                    id: "increase-opacity",
                    title: "Augementer l'opacité",
                  }, {
                    className: "esri-icon-down",
                    id: "decrease-opacity",
                    title: "Diminuer l'opacité",
                  },
                  ],
                ];
              }
            };

            // Outils à droite
            const layerListScene = new LayerList({ view, listItemCreatedFunction: defineActions });
            const legend = new Legend({
              view,
            });
            const basemapOSMScene = Basemap.fromId("osm");
            basemapOSMScene.when().then(() => {
              basemapOSMScene.title = "Plan de ville Open Street Map (OSM)";
            });
            const basemapTOPOScene = Basemap.fromId("topo");
            basemapTOPOScene.when().then(() => {
              basemapTOPOScene.title = "Fond de carte – Topographie";
            });
            const basemapSatelliteScene = Basemap.fromId("satellite");
            basemapSatelliteScene.when().then(() => {
              basemapSatelliteScene.title = "Vue aérienne";
            });
            const basemapTOPONBScene = Basemap.fromId("gray");
            basemapTOPONBScene.when().then(() => {
              basemapTOPONBScene.title = "Fond de carte - Toponymie nuances de gris";
            });
            const allBaseMapScene = [
              basemapOSMScene,
              basemapTOPOScene,
              basemapSatelliteScene,
              basemapOrtho,
              basemapTOPONBScene,
            ];
            const basemapWidget = new BasemapGallery({
              source: allBaseMapScene,
              view,
            });

            const expandLayerList = new Expand({
              content: layerListScene,
              expandTooltip: "Liste des couches",
              expanded: true,
              view,
            });
            const expandLegend = new Expand({
              content: legend,
              expandTooltip: "Légende",
              expanded: false,
              view,
            });
            const expandBasemap = new Expand({
              content: basemapWidget,
              expandTooltip: "Bibliothèque de fond de plan",
              expanded: false,
              view,
            });

            // Mesures 3D
            const measurementWidget = new DirectLineMeasurement3D({
              unit: "metric",
              view,
            });
            const expandMesureDistance = new Expand({
              content: measurementWidget,
              expandIconClass: "esri-icon-line-chart",
              expandTooltip: "Mesure de distance",
              expanded: false,
              view,
            });

            const areaMeasurement3D = new AreaMeasurement3D({
              unit: "metric",
              view,
            });
            const expandMesureArea = new Expand({
              content: areaMeasurement3D,
              expandIconClass: "esri-icon-polygon",
              expandTooltip: "Mesure de surface",
              expanded: false,
              view,
            });

            let firstOpenBasemap = false;

            view.ui.add(expandLayerList, "top-right");
            view.ui.add(expandLegend, "top-right");
            view.ui.add(expandBasemap, "top-right");
            view.ui.add(expandMesureDistance, "top-right");
            view.ui.add(expandMesureArea, "top-right");

            const allExpands = [
              expandLayerList,
              expandLegend,
              expandBasemap,
              expandMesureDistance,
              expandMesureArea,
            ];

            allExpands.forEach((item: any) => {
              item.watch("expanded", (newValue: any, oldValue: any, property: any, object: any) => {
                if (newValue === true) {
                  allExpands.forEach((item2: any) => {
                    if (item !== item2) {
                      item2.expanded = false;
                    }
                  });
                }
                measurementWidget.viewModel.clear();
                areaMeasurement3D.viewModel.clear();
              });
            });

            const pointsizeValueChanged = (event: any) => {
              view.map.basemap.baseLayers.items[0].opacity = (event.value / 100);
            };

            layerListScene.on("trigger-action", (event: any) => {
              const id = event.action.id;
              const visibleLayer = event.item.layer;

              if (id === "increase-opacity") {
                if (visibleLayer.opacity < 1) {
                  visibleLayer.opacity += 0.25;
                }
              } else if (id === "decrease-opacity") {
                if (visibleLayer.opacity > 0) {
                  visibleLayer.opacity -= 0.25;
                }
              }
            });

            view.popup.watch("features", (features: any) => {
              if (features.length > 0) {
                const objects = view.popup.features.map((item: any) => {
                  return {
                    layerName: item.sourceLayer.title.split(" ").join("_"),
                    objectId: item.attributes.layer_id,
                  };
                });
                view.popup.close();
                highlightObjectsScene(objects);
                const clickedObjects = view.popup.features.map((item: any) => {
                  return {
                    id: item.attributes.layer_id,
                    layerId: item.sourceLayer.title,
                  };
                });
                view.popup.close();
                const event = new CustomEvent("clickedObjects", {
                  detail: {
                    clickedObjects,
                  },
                });
                document.dispatchEvent(event);
              }
            });

            const zoomToCoordinates = (lat: any, lon: any, scale: any) => {
              const point = new Point({
                spatialReference: {
                  wkid: 4326,
                },
                x: lon,
                y: lat,
              });
              view.goTo({
                scale,
                target: point,
              });
            };

            zoomToObjectsScene = (objects: any, scale: any) => {
              queryMultipleFeatures(objects, scale, true, false);
            };

            highlightObjectsScene = (objects: any) => {
              resetHighlights();
              const layersToHighlight = prepareObjectsToHighlight(objects);
              layersToHighlight.forEach(async (layerToHighlight) => {
                const layerWithObjectsIds = await retrieveObjectsIds(
                  layerToHighlight.layerName,
                  layerToHighlight.id_pics,
                );
                if (layerWithObjectsIds) {
                  currentHighlights.push(
                    {
                      highlights: null,
                      layerName: layerWithObjectsIds.layerName,
                      objectsIds: layerWithObjectsIds.objectsIds,
                    },
                  );
                }
              });
              // https://community.esri.com/thread/208678-arcgis-javascript-imagery-layer-refresh-method
              const clonedExtent = view.extent.clone();
              setTimeout(() => {
                view.extent = clonedExtent;
              }, 1000);
              // view.extent = clonedExtent;
              // view.updating = true;
            };

            (window as any).removeHighlights = () => {
              resetHighlights();
            };

            resetHighlights = (): void => {
              currentHighlights.forEach((highlight) => {
                if (highlight.highlights) {
                  highlight.highlights.remove();
                }
                highlight.highlights = null;
              });
              currentHighlights = [];
            };

            const prepareObjectsToHighlight = (objects: any[]): Array<{ layerName: string, id_pics: number[] }> => {
              const layersToQuery: Array<{ layerName: string, id_pics: number[] }> = [];
              objects.forEach((obj) => {
                if (obj.layerName) {
                  const layerToQueryIndex = layersToQuery.findIndex((layer) => {
                    return layer.layerName === obj.layerName.split("_").join(" ");
                  });
                  if (layerToQueryIndex < 0) {
                    layersToQuery.push({ layerName: obj.layerName.split("_").join(" "), id_pics: [obj.objectId] });
                  } else {
                    layersToQuery[layerToQueryIndex].id_pics.push(obj.objectId);
                  }
                }
              });

              return layersToQuery;
            };

            const retrieveObjectsIds = (
              layerName: string,
              idsPics: number[],
            ) => {
              const viewLayer = view.map.allLayers.items.find((item: any) => {
                return item.type === "scene" && item.title === layerName;
              });
              if (viewLayer && viewLayer.queryFeatures) {
                const query = new Query();
                query.outFields = ["objectid"];
                query.where = "layer_id IN ('" + idsPics.join("','") + "')";
                return new Promise<{ layerName: string, objectsIds: number[] }>((resolve, reject) => {
                  viewLayer.queryFeatures(query).then((result: any) => {
                    const ids: any = [];
                    result.features.forEach((feature: any) => {
                      ids.push(feature.attributes.objectid);
                    });
                    resolve({ layerName, objectsIds: ids });
                  });
                });
              }
            };

            const queryMultipleFeatures = (objects: any, scale: any, zoom: any, highlight: any) => {
              const allDef = objects.map((object: any) => {
                const viewLayer = view.map.allLayers.items.find((item: any) => {
                  return item.type === "scene" && item.title === object.layerName.split("_").join(" ");
                });

                if (viewLayer) {
                  const featureLayer = viewLayer;
                  const query = featureLayer.createQuery();
                  query.returnGeometry = true;
                  query.outSpatialReference = view.spatialReference;
                  query.where = "layer_id = '" + object.objectId + "'";
                  return featureLayer.queryExtent(query);
                } else {
                  return null;
                }
              });

              all(allDef).then((results: any) => {
                if (results.length === 1) {
                  if (results[0].count > 0 && results[0].extent != null) {
                    if (zoom) {
                      view.goTo(results[0].extent);
                    }
                  }
                } else if (results.length > 1) {
                  const listGeom: any = [];
                  results.forEach((result: any) => {
                    if (result.count > 0 && result.extent != null) {
                      listGeom.push(result.extent);
                    }
                  });
                  if (listGeom.length > 0 && zoom) {
                    view.goTo(listGeom);
                  }
                }
              });
            };

            initSceneWatchingForHighlight = (): void => {
              view.map.allLayers.items.forEach((item: any) => {
                view.whenLayerView(item).then((layerView: any) => {
                  layerView.watch("updating", (val: any) => {
                    if (!val) {
                      const layer = currentHighlights.find((layerWithIds) => {
                        if (layerView && layerView.layer && layerView.layer.title) {
                          return layerView.layer.type === "scene" && layerWithIds.layerName === layerView.layer.title;
                        }
                      });
                      if (layer) {

                        if (layer.highlights) {
                          layer.highlights.remove();
                        }
                        layer.highlights = layerView.highlight(layer.objectsIds);
                      }
                    }
                  });
                });
              });
            };
            getScenePosition = () => {
              return [loadedSceneView.center.latitude, loadedSceneView.center.longitude, loadedSceneView.scale];
            };

          }
          return view;
        }
      },
    );
}
