import { Formik } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzButton, SzCheckbox, SzInput, SzModal } from "@suezenv/react-theme-components";
import { bindActionCreators } from "redux";
import * as Yup from "yup";
import { Report } from "../../../services/Report";
import { ErrorActions, ReportActions } from "../../../store/redux/actions";
import { ISignalFavFilter } from "../../../store/redux/types";
import "./favFilter.scss";

function SignalFavFilterForm(props: any) {
  const { t } = useTranslation();
  const { handleCloseUserForm } = props;
  const { resetErrorMessage } = props;
  const FavFilterSchema = Yup.object().shape({
    favFilterName: Yup.string()
      .min(2, "Le champ Nom doit être rempli.")
      .required("Le champ Nom doit être rempli."),
  });

  const handleClose = () => {
    handleCloseUserForm();
    resetErrorMessage();
  };

  const handleFormSubmit = (values: { defaultFavFilter: boolean, favFilterName: string }) => {
    const favFilter: ISignalFavFilter = {
      columns: [],
      email: props.email,
      filters: props.signalFilters,
      isDefault: values.defaultFavFilter,
      title: values.favFilterName,
    };
    if (false) {
    postFav(favFilter, props.handleCloseUserForm);
    }
  };

  const postFav = (signalFavFilter: ISignalFavFilter, onSuccess: () => void) => {
    Report.postSignalFavFilter({ ...signalFavFilter }).then(() => {
      onSuccess();
      if (typeof signalFavFilter.email === "string") {
        props.getSignalFav(signalFavFilter.email);
      }
    },
    ).catch((error) => console.log(error));
  };

  return (
    <SzModal
      className="gaia-group-form"
      title={t("report:modal:favFilterModalTitle")}
      show={props.visible}
      handleClose={handleClose}
      size={'lg'}
    >
      <Formik
        validationSchema={FavFilterSchema}
        onSubmit={(values, { setSubmitting }) => {
          setSubmitting(false);
          handleFormSubmit(values);
        }}
        initialValues={{ favFilterName: "", defaultFavFilter: false }}
        validateOnBlur
        validateOnChange={false}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <form onSubmit={handleSubmit}>
            <SzInput
              key="favFilterName"
              label={t("report:modal:favFilterName")}
              name="favFilterName"
              required
              valid={!errors.favFilterName && touched.favFilterName}
              error={touched.favFilterName && errors.favFilterName!== undefined}
              value={values.favFilterName}
              onChange={handleChange}
              onBlur={handleBlur}
              type="text"
            />
            <SzCheckbox
              label={t("report:modal:defaultFavFilter")}
              name="defaultFavFilter"
              onChange={handleChange}
              type="checkbox"
              checked={false}
            />
            <div className="mt-1 m-auto">
              <div className="row">
                <SzButton
                  className="col-9 col-md-3 ml-1"
                  type="submit"
                  isDisabled={!FavFilterSchema.isValidSync(values)}
                >
                  {t("report:modal:validateFavFilter")}
                </SzButton>
                <SzButton className="col-9 col-md-3 ml-1" onClick={handleClose}>
                  {t("report:modal:cancel")}
                </SzButton>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </SzModal>
  );
}
const mapStateToProps = (state: any) => {
  return {
    email: state.user.email,
    errorsApi: state.error,
    signalFilters: state.report.signalFilters,
  };
};
const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...ReportActions, ...ErrorActions }, dispatch),
});
export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(SignalFavFilterForm);
