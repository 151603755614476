import { Constants } from "../constants";

// actions 
export const login = (userInfo: any) => ({
    type: Constants.LOG_IN,
    payload: userInfo
});

export const logout = () => ({
    type: Constants.LOG_OUT
});