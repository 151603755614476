import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { categories, filterTypes, searchPrefix } from "../../config/constants";
import { calculateCityCentroid, citiesBoundingBoxes, removeAccents } from "../../config/report";
import {
  getAssetsData,
  getDocsData,
  initSelectedFacetFromSearch,
  initSelectedIndiceFromSearch,
  isIndicesAndFacetsEmpty,
} from "../../manager/filters";
import { zoomToCoordinates } from "../../manager/map/navigation";
import { FiltersActions, SearchActions } from "../../store/redux/actions";
import { IFilter, ISearch } from "../../store/redux/types";
import ControlButton from "../Utils/buttons/controlButtons";
import FiltersRender from "./filtersRender";
import IndicesRender from "./indicesRender";

interface IFiltersProps {
  updateFilters: any;
  selectedFilters: IFilter[];
  searchCategory: string;
  getSearch: (search: ISearch, filters?: IFilter[], cancelOption?: boolean, backToHome?: boolean) => any;
  search: ISearch;
  facets: any;
  resetFilters: () => any;
  resetCategoryFilter: (category: string) => any;
  setFilters: any;
}

function Filters(props: IFiltersProps) {
  const [isDisable, setIsDisable] = useState(true);
  const { updateFilters, selectedFilters, searchCategory, resetFilters, getSearch, setFilters } = props;
  const prefix = searchCategory === categories.ASSET ? searchPrefix.assets : searchPrefix.docs;

  useEffect(() => {
    const assetSearchData = getAssetsData(props.search.searchData);
    const docsSearchData = getDocsData(props.search.searchData);
    const selectedAssetIndice = initSelectedIndiceFromSearch(searchPrefix.assets, assetSearchData);
    const selectedDocIndice = initSelectedIndiceFromSearch(searchPrefix.docs, docsSearchData);
    const selectedAssetFacet = initSelectedFacetFromSearch(searchPrefix.assets, assetSearchData);
    const selectedDocFacet = initSelectedFacetFromSearch(searchPrefix.docs, docsSearchData);
    setFilters(selectedAssetIndice.concat(selectedDocIndice).concat(selectedAssetFacet).concat(selectedDocFacet));
  }, [props.search.flag.showSearchResult]);
  useEffect(() => {
    setIsDisable(
      !(
        selectedFilters.length > 0 ||
        !isIndicesAndFacetsEmpty(getAssetsData(props.search.searchData)) ||
        !isIndicesAndFacetsEmpty(getDocsData(props.search.searchData))
      ),
    );
  }, [props.selectedFilters]);

  useEffect(() => {
    const assetSearchData = getAssetsData(props.search.searchData);
    const docsSearchData = getDocsData(props.search.searchData);
    if (isIndicesAndFacetsEmpty(assetSearchData) && isIndicesAndFacetsEmpty(docsSearchData)) {
      resetFilters();
    }
  }, []);

  const handleChange = (event: any, category: string, subcategory: string, filterType?: string): void => {
    filterType = typeof filterType === "undefined" ? filterTypes.facet : filterType;

    updateFilters({
      isChecked: event.currentTarget.checked,
      key: category,
      type: filterType,
      value: subcategory,
    });
  };

  const resetCategoryFilter = (category: string): void => {
    let filters = props.selectedFilters;
    filters = filters.filter((filter) => filter.key !== category);
    setFilters(filters);
  };

  const isChecked = (filterName: string, subFilterName?: string, prefixFilter?: string, type?: string): boolean => {
    const selectedFilter: IFilter | undefined = selectedFilters.find((filter: IFilter) => {
      if (
        (type === undefined && filter.key === filterName) ||
        (subFilterName !== undefined &&
          filter.key === `${filterName}` &&
          filter.value === subFilterName &&
          filter.type === filterTypes.facet)
      ) {
        return filter;
      }
      return false;
    });

    return !!selectedFilter;
  };

  const zoomToCity = (filters: IFilter[]): boolean => {
    const cityFilter = filters.find((filter: IFilter) => {
      return filter.key === "ASSET:sig:ville.keyword";
    });

    if (cityFilter && citiesBoundingBoxes[removeAccents(cityFilter.value)]) {
      const coordinates = calculateCityCentroid(citiesBoundingBoxes[removeAccents(cityFilter.value)].extent);
      zoomToCoordinates(coordinates.latitude, coordinates.longitude, 10000);
      return true;
    }
    return false;
  };

  const handleReset = () => {
    if (
      selectedFilters.length > 0 &&
      (!isIndicesAndFacetsEmpty(getAssetsData(props.search.searchData)) ||
        !isIndicesAndFacetsEmpty(getDocsData(props.search.searchData)))
    ) {
      getSearch(props.search);
    }
    resetFilters();
    setIsDisable(true);
  };

  const handleSubmit = (e: any) => {
    let hasZoomedToCity: boolean;
    e.preventDefault();
    setIsDisable(false);
    hasZoomedToCity = zoomToCity(props.selectedFilters);
    getSearch(props.search, props.selectedFilters, false, !hasZoomedToCity);
  };
  return (
    <>
      <form onSubmit={(event: any) => handleSubmit(event)} onReset={handleReset}>
        <ControlButton isDisable={isDisable} />
        <div className="gaia-filter-side">
          {searchCategory === categories.ASSET && (
            <IndicesRender
              facets={props.facets}
              onChange={handleChange}
              isChecked={isChecked}
              prefix={prefix}
            />
          )}
          <FiltersRender
            facets={props.facets}
            onChange={handleChange}
            isChecked={isChecked}
            prefix={prefix}
            resetCategoryFilter={resetCategoryFilter}
          />
        </div>
      </form>
    </>
  );
}

const mapStateToProps = (state: any, props: any) => {
  return {
    disabled: props.disabled,
    facets: props.facets,
    search: state.search,
    selectedFilters: state.filters,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...FiltersActions, ...SearchActions }, dispatch),
});
export default connect(mapStateToProps, mapDispatchToProps)(Filters);
