import React from "react";
import { SzButton, SzTypographie } from "@suezenv/react-theme-components";

type PropsType = {
    image: string
    title: string,
    subtitle?: string,
    secondSubtitle?: string,
    middleBottomButtonText?: string,
    middleBottomButtonIcon?: string,
    middleBottomButtonOnclick?: () => void,
    rightBottomButtonText?: string,
    rightBottomButtonIcon?: string,
    rightBottomButtonOnclick?: () => void,
}

const FormSuccessPage = (props: PropsType) => {
    const {
        image,
        title,
        subtitle,
        secondSubtitle,
        middleBottomButtonText,
        middleBottomButtonIcon,
        middleBottomButtonOnclick,
        rightBottomButtonText,
        rightBottomButtonIcon,
        rightBottomButtonOnclick
    } = props

    return <div className="row">
        <div className={"col-md-10 offset-md-1 bg-white"}>
            <div className={"pt-5 pb-5"}>
                <div className={"col-md-4 offset-md-3 d-flex"}>
                    <img src={image} alt=""/>
                </div>
                <div className={"col-md-2 d-flex justify-content-end flex-md-column p-0"}>
                    <SzTypographie variant="h2" weight={"bold"} className="mb-4">
                        {title}
                    </SzTypographie>
                    {subtitle &&
                        <SzTypographie variant="caption" color={"disabled"} className="mb-3">
                            {subtitle}
                        </SzTypographie>
                    }
                    {secondSubtitle &&
                        <SzTypographie variant="caption" color={"disabled"} className="mb-3">
                            {secondSubtitle}
                        </SzTypographie>
                    }
                </div>
                <div className="row">
                    <div className={"col-md-6 offset-md-3 d-flex justify-content-center mt-5"}>
                        {middleBottomButtonText &&
                            <SzButton
                                variant={"tertiary"}
                                icon={middleBottomButtonIcon}
                                alignIcon={"right"}
                                onClick={middleBottomButtonOnclick}
                            >
                                {middleBottomButtonText}
                            </SzButton>
                        }
                    </div>
                    <div className="col-md-3 d-flex justify-content-center mt-5">
                        {rightBottomButtonText &&
                            <SzButton
                                variant={"tertiary"}
                                icon={rightBottomButtonIcon}
                                alignIcon={"right"}
                                onClick={rightBottomButtonOnclick}
                            >
                                {rightBottomButtonText}
                            </SzButton>
                        }

                    </div>
                </div>
            </div>
        </div>
    </div>;
}

export default FormSuccessPage;
