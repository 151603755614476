import React from "react";
import "./loading.scss";

const Loading: React.FunctionComponent<any> = () => {

    return (
        <div className="loading">
            <div className="loading-spinner">
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
        </div>
    );
};

export default Loading;
