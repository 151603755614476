import React from "react";
import { Pathname } from "history";
import tinycolor from "tinycolor2";
import moment from "moment";
import { SzSpinner } from "@suezenv/react-theme-components";
import { AppUrls, Constants, Pagination } from "../constants";
import {
    Metadatas,
    Pagination as PaginationType,
    PaginationHeaders,
    UserConfigurationWidgetDisplay,
    Widget
} from '../types';
import { GeoJSON, Position } from "../types/geojson";
import L from "leaflet";

export class CommonHelper {
    public static formatDate = (date: string, format: string) => {
        return moment(new Date(date)).format(format);
    }

    public static getEnumKeyByValue(myEnum: any, enumValue: string) {
        let keys = Object.keys(myEnum).filter(x => myEnum[x] === enumValue);
        return keys.length > 0 ? keys[0] : null;
    }

    public static getMetadataByField(metadatas: Metadatas, field: string) {
        return metadatas[field] ?? null;
    }

    public static getModuleFromUserRole(userRole: string) {
        return userRole.replace('ROLE_', '').toLowerCase();
    }

    public static getUserRoleFromModule(module: string) {
        return ('ROLE_' + module).toUpperCase();
    }

    public static getPaginationInfo(headers: any): any {
        let pagination: any = [];
        Object.entries(headers).map((header: any) => {
                if (Object.values(Pagination).includes(header[0])) {
                    pagination[header[0]] = header[1];
                }
                return pagination;
            }
        );

        return pagination;
    }

    public static getUserWidgetsDisplayed(widgets: Widget[], userConfigurationWidgetDisplay: UserConfigurationWidgetDisplay): Widget[] {
        return widgets.filter(widget => !userConfigurationWidgetDisplay.hasOwnProperty(widget.id) || userConfigurationWidgetDisplay[widget.id]);
    }

    public static getColorShades(hex: string) {
        const lightness = tinycolor(hex).toHsl().l * 100;
        let lightShadeFactor, darkShadeFactor: number;
        switch (true) {
            case (lightness < 6):
                lightShadeFactor = 25;
                darkShadeFactor = 0;
                break;
            case (lightness < 15):
                lightShadeFactor = 25;
                darkShadeFactor = 5;
                break;
            case (lightness < 26):
                lightShadeFactor = 25;
                darkShadeFactor = 10;
                break;
            case (lightness < 76):
                lightShadeFactor = 20;
                darkShadeFactor = 20;
                break;
            case (lightness < 85):
                lightShadeFactor = 10;
                darkShadeFactor = 25;
                break;
            case (lightness < 95):
                lightShadeFactor = 5;
                darkShadeFactor = 25;
                break;
            case (lightness >= 95):
                lightShadeFactor = 0;
                darkShadeFactor = 25;
                break;
            default:
                lightShadeFactor = 20;
                darkShadeFactor = 20;
                break;
        }

        return {
            light: tinycolor(hex).lighten(lightShadeFactor).toHexString(),
            dark: tinycolor(hex).darken(darkShadeFactor).toHexString(),
        }
    }

    public static generateAdminDetailsSubRoute(currentLocation: Pathname, target: string) {
        if (currentLocation.includes(AppUrls.ADMIN_GESTION_TERRITORIALE)) {

            return AppUrls.ADMIN_GESTION_TERRITORIALE.concat(target);
        }

        return AppUrls.ADMIN_CONTRACTS_DETAILS.concat(target);
    }

    public static backToContractsRoute(currentLocation: Pathname, anchor?: string) {
        let url: string;
        if (currentLocation.includes(AppUrls.ADMIN_GESTION_TERRITORIALE)) {

            url = AppUrls.ADMIN_GESTION_TERRITORIALE;
        } else {
            url = AppUrls.ADMIN_CONTRACTS_DETAILS;
        }

        if (anchor) {
            return url.concat(`#${anchor}`);
        }

        return url;
    }

    public static findEntityCount(entitiesList: [string, string][] | undefined, isError: boolean, entityId: string): string | JSX.Element {
        if (isError) {
            return '-';
        }

        if (entitiesList) {
            const match = entitiesList.find((entity: [string, string]) => entity[0] === entityId)
            return match && match[1] ? match[1] : <SzSpinner/>;
        }

        return <SzSpinner/>;
    }

    public static getPagination(entitiesCount: number, headers?: PaginationHeaders): PaginationType {
        if (headers) {
            return {
                pageCount: parseInt(headers[Pagination.PAGINATION_CURRENT_PAGE] ?? Constants.DEFAULT_CURRENT_PAGE),
                perPage: parseInt(headers[Pagination.PAGINATION_PER_PAGE] ?? Constants.DEFAULT_PER_PAGE),
                totalCount: parseInt(headers[Pagination.PAGINATION_TOTAL_COUNT] ?? entitiesCount),
            }
        }

        return {
            pageCount: Constants.DEFAULT_CURRENT_PAGE as number,
            perPage: Constants.DEFAULT_PER_PAGE as number,
            totalCount: entitiesCount
        }
    }

    public static coordsToLatLngs(geoJson: GeoJSON): Position[][] | Position[][][] | null {
        if (!(geoJson.type === 'Feature'
            && (geoJson.geometry.type === 'MultiPolygon' || geoJson.geometry.type === 'Polygon')
            && geoJson.geometry.coordinates)) {
            return null;
        }

        const levelsDeep = geoJson.geometry.type === 'Polygon' ? 1 : 2;

        return L.GeoJSON.coordsToLatLngs(geoJson.geometry.coordinates, levelsDeep);
    }
}
