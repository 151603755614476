// import ReactGA from "react-ga";
import { store } from "../..";
import { categories, filterTypes, layers, mapType3D, sceneLayers } from "../../../config/constants";
import i18n from "../../../i18n";
import { generateCustomAddressLayer, initCustomLayer } from "../../../manager/map";
import {
  clearItvGraphics,
  highlightObjects,
  highlightObjectsScene,
  mapLoaderConfig,
} from "../../../manager/map/loader";
import { goToHome, zoomToCoordinates, zoomToExtent } from "../../../manager/map/navigation";
import Request from "../../../services/Request";
import { Search as SearchService } from "../../../services/Search";
import { stringToSlug } from "../../../utils";
import { history } from "../../index";
import { ERROR, SEARCH, USER } from "../constants";
import { IAutocompleteAction, IFilter, IInputTextAction, ISearch, ISearchData, ISearchInitAction } from "../types";
import Breadcrumb from "./breadcrumb";

const find = (array: [], id: number) => array.find((object: any) => object.id === id);
const isNotUndefined = (object: any) => object !== undefined;

export default {
  setAutocomplete(search: ISearch): IAutocompleteAction {
    return {
      payload: { ...search },
      type: SEARCH.AUTO_COMPLETE,
    };
  },
  setUserInputText(textInput: string): IInputTextAction {
    return {
      payload: textInput,
      type: SEARCH.INPUT_VALUE,
    };
  },
  initSearch(): ISearchInitAction {
    // ReactGA.event({ action: "Initialisation Search", category: "Search" });
    return {
      type: SEARCH.SEARCH_INIT,
    };
  },
  closeAll() {
    return {
      type: SEARCH.CLOSE_ALL,
    };
  },
  openDetailObject() {
    return {
      type: SEARCH.DETAIL_OBJECT
    };
  },
  openDetailTab(tab: string) {
    return (dispatch: any) => {
        dispatch({
          payload: {
            detail: {
              defaultTab: tab,
            },
          },
          type: SEARCH.DETAIL_TAB,
        });
    };
  },
  showDetail(itemID: string, search: ISearch, itemName: string, category: string, tab: string, fromMap= false): any {
    const rootState = store.getState();
    // -- Comment google analytics
    //const slug = `/${stringToSlug(category)}/detail/${stringToSlug(itemName)}`;
    //ReactGA.pageview(slug);
    //clearItvGraphics();
    return (dispatch: any) => {
      launchLoading(dispatch, true);
      return SearchService.getSigObject(itemID)
        .then((responseData: any) => {
          // const coordinates = calculateCentroid(responseData);
          //dispatch({
          //  payload: [coordinates.latitude, coordinates.longitude, 250],
          //  type: USER.SET_HOME_MAP_POSITION,
          //});
          const breadcrumb = {
            categoryName: category,
            clickable: true,
            clickedItem: itemName,
            fromMap: fromMap,
            isOnItemDetails: true,
            searchKeywords: responseData["sig:adressecomplete"],
          };
          dispatch(Breadcrumb.setBreadcrumb(breadcrumb));
          dispatch({
            payload: {
              ...search,
              detail: {
                shownDetailAnchor: `${responseData["index:name"]}-${itemID}`,
                shownDetailCategory: categories.ASSET,
                shownDetailId: responseData["asset:layer_asset_id"],
                shownDetailItem: responseData,
                defaultTab: tab,
              },
              searchData: rootState.search.searchData,
            },
            type: SEARCH.DETAIL,
          });
          return responseData;
        }).then((responseData: any) => {
          // -- comment esri zoom
          //launchLoading(dispatch, false);
          //const searchData: any = search.searchData;
          //const objectId: string = search.detail.shownDetailId;
          //const categoryResultIndex = searchData.findIndex((data: any) => data.category === categories.ASSET);
          //const layerName = Object.keys(layers).find((key) => layers[key] === responseData["index:name"]);
          //const sceneLayerName = Object.keys(sceneLayers).find((key) => sceneLayers[key].includes(responseData["index:name"]));
          //const asset = searchData[categoryResultIndex].data.objects.find(
          //  (object: any) =>
          //    object["asset:layer_asset_id"] === objectId,
          //);
          //const scale = (mapLoaderConfig.activeView && mapLoaderConfig.activeView.type === mapType3D) ? 500 : 250;
          //setTimeout(() => {
          //  if (asset !== undefined) {
          //    if ("sig:line" in asset) {
          //      console.log('zoom to extend search.ts 100');
          //      //zoomToExtent(asset["sig:line"], scale);
          //    }
          //
          //    if ("sig:point" in asset) {
          //      console.log('zoom to point search.ts 105');
          //      //zoomToCoordinates(asset["sig:point"][0][0], asset["sig:point"][0][1], scale);
          //    }
          //  }
          //}, 3000);
          //if (layerName && objectId && typeof layerName !== "undefined" && typeof objectId !== "undefined") {
          //  //highlightObjects([{ layerName, objectId: objectId as unknown as string }]);
          //  if (mapLoaderConfig.activeView && mapLoaderConfig.activeView.type === mapType3D) {
          //    //highlightObjectsScene([{ layerName: sceneLayerName, objectId: objectId as unknown as string }]);
          //  }
          //}
        }).then(() => launchLoading(dispatch, false))
          .catch(() => launchLoading(dispatch, false));
    };
  },
  showDetailControl(idPDC: string, search: ISearch, itemName: string, category: string, clickable: any): any {
    const rootState = store.getState();
    return (dispatch: any) => {
      launchLoading(dispatch, true);
      const breadcrumb = {
        categoryName: category,
        clickable: clickable,
        clickedItem: itemName,
        fromMap: true,
        isOnItemDetails: true,
        searchKeywords: "",
      };
      dispatch(Breadcrumb.setBreadcrumb(breadcrumb));

      return SearchService.getControlObject(idPDC)
        .then((responseData: any) => {
          dispatch({
            payload: {
              ...search,
              detail: {
                ...search.detail,
                shownDetailAnchor: `${responseData["index:name"]}-${idPDC}`,
                shownDetailCategory: categories.REPORT,
                shownDetailId: responseData["idPDC"],
                shownDetailItem: responseData,
              },
              searchData: rootState.search.searchData,
            },
            type: SEARCH.DETAIL,
          });
          return responseData;
        }).then((e) => {
          launchLoading(dispatch, false)
        })
          .catch((e) => {
            console.log(e)
            launchLoading(dispatch, false)
          });
    };
  },
  getDocumentDownloadUrl(downloadUrl: string): any {
    const token = localStorage.getItem("tokenDigitalTwin");
    return {
      payload: downloadUrl + "?token=" + token,
      type: SEARCH.DOCUMENT,
    };
  },
  getSigObject(objectId: string): any {
    const searchData: any = store.getState().search.searchData;
    // ReactGA.event({ action: `Detail objet id: ${objectId} depuis la carte `, category: "Carte" });
    return (dispatch: any) => {
      launchLoading(dispatch, true);
      return SearchService.getSigObject(objectId)
        .then((responseData: any) => {
          if (isNotUndefined(responseData)) {
            const categoryResultIndex = searchData.findIndex((data: any) => data.category === categories.ASSET);
            const newAsset = isNewAsset(searchData[categoryResultIndex].data.objects, responseData);
            if (newAsset) {
              store.getState().search.searchData = searchData;
            }
            const breadcrumb = generateBreadcrumb(store.getState().search, responseData);
            let meshAvailable: boolean = false;

            if (responseData["3dnetwork:3d_mesh_available"] || responseData["3dstrucuture:3d_ifc_available"]) {
              meshAvailable = responseData["3dnetwork:3d_mesh_available"] || responseData["3dstrucuture:3d_ifc_available"];
            }
            dispatch(Breadcrumb.setBreadcrumb(breadcrumb));
            dispatch({
              payload: {
                detail: {
                  shownDetailCategory: categories.ASSET,
                  shownDetailId: responseData["asset:layer_asset_id"],
                  shownDetailItem: responseData,
                },
                flag: {
                  showSmartshape: meshAvailable,
                },
                searchData,
              },
              type: SEARCH.DETAIL,
            });
          }
        })
        .then(() => launchLoading(dispatch, false))
        .catch((thrown) => {
          console.log(thrown);
          launchLoading(dispatch, false);
        });
    };
  },
  getSigObjectByIdent(ident: string): any {
    const searchData: any = store.getState().search.searchData;
    // ReactGA.event({ action: `Detail objet nomexplo: ${ident} depuis la carte `, category: "Carte" });
    clearItvGraphics();
    return (dispatch: any) => {
      launchLoading(dispatch, true);
      return SearchService.getSigObjectByIdent(ident)
        .then((responseData: any) => {
          if (isNotUndefined(responseData)) {
            const categoryResultIndex = searchData.findIndex((data: any) => data.category === categories.ASSET);
            const categoryAddressResultIndex = searchData.findIndex((data: any) => data.category === categories.GEO);
            const categoryDocResultIndex = searchData.findIndex((data: any) => data.category === categories.DOC);
            searchData[categoryResultIndex].data = responseData;
            searchData[categoryAddressResultIndex].data = { candidates: [] };
            searchData[categoryDocResultIndex].data = { documents: [], selectedFacets: {} };
            dispatch(Breadcrumb.setSearchKeyWordBreadcrumb(ident));
            if (responseData.objects.length > 1) {
              dispatch({
                payload: {
                  maxResult: store.getState().search.maxResult,
                  searchData,
                  userInput: store.getState().search.userInput,
                },
                type: SEARCH.RESULT,
              });
            } else if (responseData.objects[0]) {

              const breadcrumb = generateBreadcrumb(store.getState().search, responseData.objects[0]);
              let meshAvailable: boolean = false;

              if (responseData.objects[0]["3dnetwork:3d_mesh_available"] || responseData.objects[0]["3dstrucuture:3d_ifc_available"]) {
                meshAvailable = responseData.objects[0]["3dnetwork:3d_mesh_available"] || responseData.objects[0]["3dstrucuture:3d_ifc_available"];
              }
              dispatch(Breadcrumb.setBreadcrumb(breadcrumb));
              dispatch({
                payload: {
                  detail: {
                    shownDetailCategory: categories.ASSET,
                    shownDetailId: responseData.objects[0]["asset:layer_asset_id"],
                    shownDetailItem: responseData.objects[0],
                  },
                  flag: {
                    showSmartshape: meshAvailable,
                  },
                  searchData,
                },
                type: SEARCH.DETAIL,
              });
            }
          }
          history.push("/");
          return responseData;
        })
        .then((responseData: any) => {
          launchLoading(dispatch, false);
          const categoryResultIndex = searchData.findIndex((data: any) => data.category === categories.ASSET);
          const objectId: string|undefined = store.getState().search.detail.shownDetailId;
          const layerName = Object.keys(layers).find(
            (key) => layers[key] === responseData.objects[0]["index:name"],
          );
          const sceneLayerName = Object.keys(sceneLayers).find(
            (key) => sceneLayers[key].includes(responseData.objects[0]["index:name"]),
          );
          const asset = searchData[categoryResultIndex].data.objects.find(
            (object: any) =>
              object["asset:layer_asset_id"] === objectId,
          );
          const scale = (mapLoaderConfig.activeView && mapLoaderConfig.activeView.type === mapType3D) ? 500 : 250;
          setTimeout(() => {
            if (layerName && objectId && typeof layerName !== "undefined" && typeof objectId !== "undefined") {
              //highlightObjects([{ layerName, objectId: objectId as unknown as string }]);
              if (mapLoaderConfig.activeView && mapLoaderConfig.activeView.type === mapType3D) {
                //highlightObjectsScene([{ layerName: sceneLayerName, objectId: objectId as unknown as string }]);
              }
            }
          }, 2000);
          setTimeout(() => {
            if (asset !== undefined) {
              if ("sig:line" in asset) {
                zoomToExtent(asset["sig:line"] as unknown as number[][], scale);
              }

              if ("sig:point" in asset) {
                zoomToCoordinates(
                  asset["sig:point"][0][0] as unknown as number,
                  asset["sig:point"][0][1] as unknown as number,
                  scale,
                );
              }
            }
          }, 5000);
        })
        .catch((thrown) => {
          console.log(thrown);
          if (thrown.response.status && thrown.response.status === 404) {
            const defaultSearchResult: ISearchData[] = [
              { category: categories.GEO, data: { candidates: [] } },
              { category: categories.DOC, data: { documents: [], selectedFacets: {} } },
              { category: categories.ASSET, data: { objects: [], selectedIndices: [], selectedFacets: {} } },
            ];
            dispatch({
              payload: { jwtCheck: false, status: 0, message: "" },
              type: ERROR.SET,
            });
            dispatch({
              payload: {
                maxResult: store.getState().search.maxResult,
                searchData: defaultSearchResult,
                userInput: store.getState().search.userInput,
              },
              type: SEARCH.RESULT,
            });
            launchLoading(dispatch, false);
          }
        });
    };
  },
  getSearch(search: ISearch, filters?: IFilter[], cancelOption: boolean = true, backToHome: boolean = true): any {
    const contract: any = store.getState().contract;
    let contractId = contract?.currentContract.id;
    if (contractId) {
      let url = `/api/${contractId}/search/byCategory?text=${search.userInput}&limit=${search.maxResult}`;
      let slug = `/api/${contractId}/search/byCategory?text=${stringToSlug(search.userInput)}&limit=${search.maxResult}`;
      if (filters && filters.length) {
        const facets: string = getUrlParamFromFilters(filters, filterTypes.facet, "facets");
        const indices: string = getUrlParamFromFilters(filters, filterTypes.indice, "indices");
        // ReactGA.event({ action: `Recherche par Filtres: ${facets}, ${indices}`, category: "Filtre" });
        url = `/api/${contractId}/search/byCategory?text=${search.userInput}&limit=${search.maxResult}&${facets}&${indices}`;
        slug = `/api/${contractId}/search/byCategory?text=${stringToSlug(search.userInput)}&limit=${search.maxResult}&${facets}&${indices}`;
      }
      // ReactGA.pageview(slug);
      // ReactGA.event({ action: `Search: ${search.userInput}`, category: "Search" });

      return (dispatch: any) => {
        dispatch(Breadcrumb.setSearchKeyWordBreadcrumb(search.userInput));
        launchLoading(dispatch, true);
        return Request.get(url, cancelOption)
          .then((response: any) => {
            const address = response.data.results.find((data: any) => data.category === categories.GEO);
            if (address && address.data && address.data.candidates.length > 0) {
              generateCustomAddressLayer(address.data.candidates);
            }
            if (backToHome) {
              goToHome();
            }
            dispatch({
              payload: {
                maxResult: response.data.limit,
                searchData: response.data.results,
                userInput: search.userInput,
              },
              type: SEARCH.RESULT,
            });
            history.push("/");
          })
            .then(() => launchLoading(dispatch, false))
            .catch(() => launchLoading(dispatch, false));
      };
    }
  },
  getSuggestions(textInput: string): any {
    const url = `/search/suggest?text=${textInput}`;
    return (dispatch: any) => {
      return Request.get(url).then((response: any) => {
        dispatch({
          payload: {
            activeSuggestion: 0,
            filteredSuggestions: response.data.suggestions,
            loading: false,
            showSuggestions: true,
            userInput: textInput,
          },
          type: SEARCH.SUGGEST,
        });
      });
    };
  },
  goBackToSearch() {
    const rootState = store.getState();
    const contract: any = rootState.contract;
    if (rootState.breadcrumb.fromMap && rootState.search.userInput !== rootState.breadcrumb.searchKeywords) {
      let contractId = contract?.currentContract.id;
      const url = `/api/${contractId}/search/byCategory?text=${rootState.breadcrumb.searchKeywords}&limit=${rootState.search.maxResult}`;
      return (dispatch: any) => {
        launchLoading(dispatch, true);
        initCustomLayer();
        return Request.get(url, true)
          .then((response: any) => {
            const address = response.data.results.find((data: any) => data.category === categories.GEO);
            if (address && address.data && address.data.candidates.length > 0) {
              generateCustomAddressLayer(address.data.candidates);
            }
            if (!rootState.search.flag.showSmartshape) {
              goToHome();
            }
            dispatch({
              payload: {
                maxResult: response.data.limit,
                searchData: response.data.results,
                userInput: rootState.breadcrumb.searchKeywords,
              },
              type: SEARCH.RESULT,
            });
          })
          .then(() => launchLoading(dispatch, false))
          .catch(() => launchLoading(dispatch, false));
      };
    } else {
      return {
        type: SEARCH.BACK_TO_SEARCH,
      };
    }
  },
  isUserInputTooShort(inputTooShort: boolean) {
    return {
      payload: inputTooShort,
      type: SEARCH.SEARCH_EMPTY_ERROR,
    };
  },
  getHistory(itemsPerPage?: number, currentPage?: number) {
    return (dispatch: any) => {
      return SearchService.getHistory(itemsPerPage, currentPage).then((response: any) => {
        dispatch({
          payload: {
            currentPage: response.headers["x-pagination-current-page"],
            results: response.headers["x-pagination-total-count"],
            searches: response.data.data.map((item: any) => formatHistory(item)),
          },
          type: SEARCH.GET_HISTORY,
        });
      });
    };
  },
  getSuggestionHistory(itemsPerPage?: number, currentPage?: number) {
    return (dispatch: any) => {
      return SearchService.getHistory(itemsPerPage, currentPage).then((response: any) => {
        dispatch({
          payload: {
            suggestionSearches: response.data.data.map((item: any) => formatHistory(item)),
          },
          type: SEARCH.GET_SUGGESTION_HISTORY,
        });
      });
    };
  },
  displaySmartshapeFullScreen(isDisplayed?: boolean) {
    return {
      payload: isDisplayed,
      type: SEARCH.DISPLAY_SMARTSHAPE_FULLSCREEN,
    };
  },
  displayEquipementFromSmartshapePlayer(equipementId: string) {
    return {
      payload: equipementId,
      type: SEARCH.DISPLAY_EQUIPEMENT_FROM_SMARTSHAPE,
    };
  },
  displayMap3d(isDisplayed3d?: boolean) {
    return {
      payload: isDisplayed3d,
      type: SEARCH.DISPLAY_MAP_3D,
    };
  },
  displayMapFullScreen(isMApDisplayedFullscreen?: boolean) {
    return {
      payload: isMApDisplayedFullscreen,
      type: SEARCH.DISPLAY_MAP_FULLSCREEN,
    };
  },
};

function getUrlParamFromFilters(filters: IFilter[], filterType: string, filterName: string): string {
  return filters
    .filter((filter: IFilter) => filter.type === filterType)
    .map((filter: IFilter) => {
      return `${filterName}[]=${filter.key}${filterType === filterTypes.indice ? "" : `=${filter.value}`}`;
    })
    .join("&");
}

function isNewAsset(listOfAsset: any, selectedAsset: any) {
  if (isNotUndefined(listOfAsset) && !find(listOfAsset, selectedAsset.id)) {
    listOfAsset.push(selectedAsset);
    return true;
  }
  return false;
}

function generateBreadcrumb(search: ISearch, selectedAsset: any) {
  const breadcrumb = {
    categoryName: i18n.t("category.asset"),
    clickable: true,
    clickedItem: `${i18n.t(selectedAsset["index:name"])} ${selectedAsset["asset:id_apic"]}`,
    fromMap: true,
    isOnItemDetails: false,
    searchKeywords: selectedAsset["sig:adressecomplete"],
  };
  const slug = `/${stringToSlug(breadcrumb.categoryName)}/detail/${stringToSlug(breadcrumb.clickedItem)}`;
  // ReactGA.pageview(slug);
  return breadcrumb;
}

function formatHistory(searchHistory: { searchDate: string, searchText: string, searchDateText: string }): { keywords: string, date: Date, searchDateText: string } {
  return { keywords: searchHistory.searchText, date: new Date(searchHistory.searchDate), searchDateText: new Date(searchHistory.searchDate).toUTCString() };
}

export function launchLoading(dispatch: any, loading: boolean) {
  return dispatch({ payload: { flag: { loading } }, type: SEARCH.LOADING });
}

const calculateCentroid = (data: any) => {
  let longitude: number = 0;
  let latitude: number = 0;
  if ("undefined" !== typeof data["sig:point"]) {
    longitude = data["sig:point"][0][0] as number;
    latitude = data["sig:point"][0][1] as number;
  } else if ("undefined" !== typeof data["sig:line"]) {
    longitude = (parseFloat(data["sig:line"][1][1]) + parseFloat(data["sig:line"][0][1])) / 2;
    latitude = (parseFloat(data["sig:line"][1][0]) + parseFloat(data["sig:line"][0][0])) / 2;
  }
  return { longitude, latitude };
};
