import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { SzAlert, SzIcon, SzPagination, SzSpinner, SzTable } from "@suezenv/react-theme-components";
import { ContractsService } from "../../../../services/ContractsService";
import { AppUrls, Constants } from "../../../../constants";
import { Pagination, ContractHeimdall } from "../../../../types";
import { RouteComponentProps, useHistory, withRouter } from "react-router";
import TabHeader from "../../adminTabs/tabHeader";
import { CommonHelper } from "../../../../helper/Common";

const AdminContractsAll = (props: RouteComponentProps) => {
    type ContractData = {
        label: string
        beginDate: string
        members: string
        actions: JSX.Element
    }

    const { t } = useTranslation();
    const history = useHistory();
    const [currentPage, setCurrentPage] = useState(1);
    const [ContractsData, setContractsData] = useState<ContractData[]>();
    const [pagination, setPagination] = useState<Pagination>();

    const fetchContracts = () => ContractsService.getContracts(currentPage);

    const { data, isError, isLoading, isSuccess } = useQuery('contracts' + currentPage, fetchContracts);

    useEffect(() => {
        if (isSuccess) {
            const contracts: ContractHeimdall[] = data?.data
            let ContractsData: ContractData[] = [];
            contracts.forEach((contract: ContractHeimdall) => ContractsData.push(formatContractData(contract)));
            setContractsData(ContractsData);
            setPagination(CommonHelper.getPagination(contracts.length, data?.headers));
        }
    }, [data]);

    const defaultSorted: any = [
        {
            dataField: 'label',
            order: 'desc'
        }
    ];

    const columns = [
        {
            dataField: 'label',
            text: t('admin:admin.contracts.label')
        },
        {
            dataField: 'beginDate',
            text: t('admin:admin.contracts.beginDate')
        },
        {
            dataField: 'members',
            text: t('admin:admin.contracts.members')
        },
        {
            dataField: 'actions',
            text: ''
        }
    ];

    const seeContractDetails = (contractId: string) => {
        props.history.push(AppUrls.ADMIN_CONTRACTS_DETAILS.replace(":contractId", contractId));
    }

    const formatContractData = (contract: ContractHeimdall): ContractData => {
        return {
            label: contract.label,
            beginDate: "2021-07-23",
            members: '0',
            actions: <SzIcon variant='line' icon='task-list-text-1' className="color-primary" onClick={() => {
                seeContractDetails(contract.id)
            }}/>
        }
    };

    const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);

    const renderPagination = (pagination: Pagination) => {
        return <div className="d-flex justify-content-center my-4">
            <SzPagination
                totalItemsCount={pagination.totalCount}
                activePage={currentPage}
                onChange={handlePageChange}
                itemsCountPerPage={pagination.perPage}
                pageRangeDisplayed={+Constants.CONTRACTS_PAGINATION_PAGE_RANGE}
            />
        </div>;
    }

    if (isError) {
        return <SzAlert
            variant="warning"
            transparent={true}
            className="w-100 border-0 flex-column p-4"
        >
            {t('admin:alert.loading_error')}
        </SzAlert>
    }

    if (isLoading) {
        return <div className='p-4 text-center'>
            <SzSpinner/>
        </div>
    }

    return <>
        <TabHeader
            title={t("admin:admin.tabs.contract")}
            subtitle={t("admin:admin.tabs.contractSubtitle", { count: pagination?.totalCount })}
            onBtnClick={() => history.push(AppUrls.ADMIN_CONTRACTS_CREATE)}
        />
        {ContractsData && <SzTable
            data={ContractsData}
            keyField={'label'}
            columns={columns}
            defaultSorted={defaultSorted}
        />}
        {pagination && renderPagination(pagination)}
    </>;
}

export default withRouter(AdminContractsAll);
