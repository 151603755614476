import React, { ReactElement, useEffect, useRef, useState } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import SzTable from "@suezenv/react-theme-components/build/components/SzTable/SzTable";
import { bindActionCreators } from "redux";
import { ReportActions } from "../../store/redux/actions";
import { IReportData, IUser } from "../../store/redux/types";
import Pagination from "../Utils/Pagination/Pagination";
import "./NetworkTable.scss";
import {Contract} from "../../types";
import CollapseToggle from "../Utils/buttons/CollapseToggle";

const NetworkTable = (props: any): ReactElement => {
  const { t } = useTranslation();
  const {
      getReports,
      // getProjects,
      // getSignals,
      // getSiteIndustriels,
      controlTableHeader,
      // projectTableHeader,
      // signalTableHeader,
      // siteIndustrielTableHeader,
      isHidden,
      setIsHidden,
      showClose,
      show,
      closeHandler
  } = props;
  const filters = props.report.filters;
  const [currentPage, setCurrentPage]: [number, (n: number) => void] = useState(props.report.currentPage);
  const [filteredData, setFilteredData] = useState(props.report.reports);
  const projectFilters = props.report.projectFilters;
  const signalFilters = props.report.signalFilters;
  const [
    signalCurrentPage,
    setSignalCurrentPage,
  ]: [number, (n: number) => void] = useState(props.report.signalCurrentPage);
  const siteIndustrielFilters = props.report.siteIndustrielFilters;
  const [
    siteIndustrielCurrentPage,
    setSiteIndustrielCurrentPage,
  ]: [number, (n: number) => void] = useState(props.report.siteIndustrielCurrentPage);
  const [
    projectCurrentPage,
    setProjectCurrentPage,
  ]: [number, (n: number) => void] = useState(props.report.projectCurrentPage);
  const [projectFilteredData, setProjectFilteredData] = useState(props.report.projects);
  const [signalFilteredData, setSignalFilteredData] = useState(props.report.signals);
  const [siteIndustrielFilteredData, setSiteIndustrielFilteredData] = useState(props.report.siteIndustriels);
  const [parcelFilteredData, setParcelFilteredData] = useState(props.report.parcels);
  const [pluFilteredData, setPluFilteredData] = useState(props.report.plu);

  const usePrevious = <T extends {}>(value: T): T | undefined => {
    const ref = useRef<T>();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevValue = usePrevious(
    {
      filters,
      currentPage,
      projectFilters,
      projectCurrentPage,
      signalFilters,
      signalCurrentPage,
      siteIndustrielFilters,
      siteIndustrielCurrentPage,
    },
  );

  useEffect(() => {
    props.report.reports.map((item: any, key: any) => item.key = key);
    setFilteredData(props.report.reports);
  }, [props.report.reports, props.report.sortField, props.report.sortDirection]);

  // useEffect(() => {
  //   props.report.projects.map((item: any, key: any) => item.key = key);
  //   setProjectFilteredData(props.report.projects);
  // }, [props.report.projects, props.report.projectSortField, props.report.projectSortDirection]);
  //
  // useEffect(() => {
  //   props.report.signals.map((item: any, key: any) => item.key = key);
  //   setSignalFilteredData(props.report.signals);
  // }, [props.report.signals, props.report.signalSortField, props.report.signalSortDirection]);
  //
  // useEffect(() => {
  //   props.report.siteIndustriels.map((item: any, key: any) => item.key = key);
  //   setSiteIndustrielFilteredData(props.report.siteIndustriels);
  // }, [props.report.siteIndustriels, props.report.siteIndustrielSortField, props.report.siteIndustrielSortDirection]);
  //
  // useEffect(() => {
  //   props.report.parcels.map((item: any, key: any) => item.key = key);
  //   setParcelFilteredData(props.report.parcels);
  // }, [props.report.parcels]);
  //
  // useEffect(() => {
  //   props.report.plu.map((item: any, key: any) => item.key = key);
  //   setPluFilteredData(props.report.plu);
  // }, [props.report.plu]);

  useEffect(() => {
    if ("undefined" !== typeof getReports) {
      // If currentPage is updated, useEffect will be called again and then, search will happen.
      // Therefore, to avoid double search, we prevent it when currentPage.
      if (prevValue && (JSON.stringify(prevValue.filters) !== JSON.stringify(filters)) && 1 !== currentPage) {
        setCurrentPage(1);
      } else {
        setFilteredData([]);
        getReports(props.contract.id, filters, props.report.sortField, props.report.sortDirection, currentPage);
      }
    }
  }, [filters, props.report.sortField, props.report.sortDirection, currentPage]);

  // useEffect(() => {
  //   if ("undefined" !== typeof getProjects) {
  //     // If currentPage is updated, useEffect will be called again and then, search will happen.
  //     // Therefore, to avoid double search, we prevent it when currentPage.
  //     if (
  //       prevValue &&
  //       (JSON.stringify(prevValue.projectFilters) !== JSON.stringify(projectFilters)) &&
  //       1 !== projectCurrentPage
  //     ) {
  //       setProjectCurrentPage(1);
  //     } else {
  //       setProjectFilteredData([]);
  //       getProjects(
  //         projectFilters,
  //         props.report.projectSortField,
  //         props.report.projectSortDirection,
  //         projectCurrentPage,
  //       );
  //     }
  //   }
  // }, [projectFilters, props.report.projectSortField, props.report.projectSortDirection, projectCurrentPage]);

  // useEffect(() => {
  //   if ("undefined" !== typeof getSignals) {
  //     // If currentPage is updated, useEffect will be called again and then, search will happen.
  //     // Therefore, to avoid double search, we prevent it when currentPage.
  //     if (
  //       prevValue &&
  //       (JSON.stringify(prevValue.signalFilters) !== JSON.stringify(signalFilters)) &&
  //       1 !== signalCurrentPage
  //     ) {
  //       setSignalCurrentPage(1);
  //     } else {
  //       setSignalFilteredData([]);
  //       getSignals(
  //         signalFilters,
  //         props.report.signalSortField,
  //         props.report.signalSortDirection,
  //         signalCurrentPage,
  //       );
  //     }
  //   }
  // }, [signalFilters, props.report.signalSortField, props.report.signalSortDirection, signalCurrentPage]);

  // useEffect(() => {
  //   if ("undefined" !== typeof getSiteIndustriels) {
  //     // If currentPage is updated, useEffect will be called again and then, search will happen.
  //     // Therefore, to avoid double search, we prevent it when currentPage.
  //     if (
  //       prevValue &&
  //       (JSON.stringify(prevValue.siteIndustrielFilters) !== JSON.stringify(siteIndustrielFilters)) &&
  //       1 !== siteIndustrielCurrentPage
  //     ) {
  //       setSiteIndustrielCurrentPage(1);
  //     } else {
  //       setSiteIndustrielFilteredData([]);
  //       getSiteIndustriels(
  //         siteIndustrielFilters,
  //         props.report.siteIndustrielSortField,
  //         props.report.siteIndustrielSortDirection,
  //         siteIndustrielCurrentPage,
  //       );
  //     }
  //   }
  // }, [
  //   siteIndustrielFilters,
  //   props.report.siteIndustrielSortField,
  //   props.report.siteIndustrielSortDirection,
  //   siteIndustrielCurrentPage,
  // ]);

  if (props.listType === "control") {
    if (props.report.reports.length > 0) {
      return (
          show &&
            <>
              {!isHidden &&
                  <div className={"network-table"}>
                    <div className={"network-table--element_count"}>
                      <span className={"network-table--element_count--number"}> {props.report.totalItem} </span>
                      <span className={"network-table--element_count--label"}> {t("report:totalResults")} </span>
                    </div>
                    <SzTable
                        className={"report-table"}
                        columns={controlTableHeader}
                        data={filteredData}
                        keyField={"key"}/>
                    <Pagination
                        totalPage={props.report.totalPage as number}
                        currentPage={props.report.currentPage as number}
                        handler={setCurrentPage}
                    />
                  </div>
              }
              <CollapseToggle toggled={isHidden} handler={setIsHidden} showClose={showClose} show={show} closeHandler={closeHandler}/>
            </>
      );
    }
    return (
      <>
        <div className={"network-table"}>
          <div className="text-center">
            <Table striped bordered hover responsive className="sz-table">
              <thead>
                <tr className="bg-light-blue">
                  {controlTableHeader.map((header: any, key: any) => (
                    <th key={key}>{header.text}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colSpan={controlTableHeader.length as number}>{t("no-results-found")}</td>
                </tr>
              </tbody>
            </Table>
          </div>
        </div>
        <CollapseToggle toggled={isHidden} handler={setIsHidden} showClose={showClose} show={show} closeHandler={closeHandler}/>
      </>
    );
  } else {
    return <></>;
  }

  // else if (props.listType === "project")  {
  //   if (props.report.projects.length > 0) {
  //     return (
  //       <>
  //         <div className={"network-table"}>
  //           <div className={"network-table--element_count"}>
  //             <span className={"network-table--element_count--number"}> {props.report.projectTotalItem} </span>
  //             <span className={"network-table--element_count--label"}> {t("report:totalResults")} </span>
  //           </div>
  //           <SzTable
  //             className={"report-table"}
  //             columns={projectTableHeader}
  //             data={projectFilteredData}
  //             keyField={"key"} />
  //           <Pagination
  //             totalPage={props.report.projectTotalPage as number}
  //             currentPage={props.report.projectCurrentPage as number}
  //             handler={setProjectCurrentPage}
  //           />
  //         </div>
  //       </>
  //     );
  //   }
  //   return (
  //     <>
  //       <div className={"network-table"}>
  //         <div className="text-center">
  //           <Table striped bordered hover responsive className="sz-table">
  //             <thead>
  //               <tr className="bg-light-blue">
  //                 {projectTableHeader.map((header: any, key: any) => (
  //                   <th key={key}>{header.text}</th>
  //                 ))}
  //               </tr>
  //             </thead>
  //             <tbody>
  //               <tr>
  //                 <td colSpan={projectTableHeader.length as number}>{t("no-results-found")}</td>
  //               </tr>
  //             </tbody>
  //           </Table>
  //         </div>
  //       </div>
  //     </>
  //   );
  // } else if (props.listType === "siteIndustriel") {
  //   if (props.report.siteIndustriels.length > 0) {
  //     return (
  //       <>
  //         <div className={"network-table"}>
  //           <div className={"network-table--element_count"}>
  //             <span className={"network-table--element_count--number"}> {props.report.siteIndustrielTotalItem} </span>
  //             <span className={"network-table--element_count--label"}> {t("report:totalResults")} </span>
  //           </div>
  //           <SzTable
  //             className={"report-table"}
  //             columns={siteIndustrielTableHeader}
  //             data={siteIndustrielFilteredData}
  //             keyField={"key"} />
  //           <Pagination
  //             totalPage={props.report.siteIndustrielTotalPage as number}
  //             currentPage={props.report.siteIndustrielCurrentPage as number}
  //             handler={setSiteIndustrielCurrentPage}
  //           />
  //         </div>
  //       </>
  //     );
  //   }
  //   return (
  //     <>
  //       <div className={"network-table"}>
  //         <div className="text-center">
  //           <Table striped bordered hover responsive className="sz-table">
  //             <thead>
  //               <tr className="bg-light-blue">
  //                 {projectTableHeader.map((header: any, key: any) => (
  //                   <th key={key}>{header.text}</th>
  //                 ))}
  //               </tr>
  //             </thead>
  //             <tbody>
  //               <tr>
  //                 <td colSpan={projectTableHeader.length as number}>{t("no-results-found")}</td>
  //               </tr>
  //             </tbody>
  //           </Table>
  //         </div>
  //       </div>
  //     </>
  //   );
  // } else {
  //   if (props.report.signals.length > 0) {
  //     return (
  //       <>
  //         <div className={"network-table"}>
  //           <div className={"network-table--element_count"}>
  //             <span className={"network-table--element_count--number"}> {props.report.signalTotalItem} </span>
  //             <span className={"network-table--element_count--label"}> {t("report:totalResults")} </span>
  //           </div>
  //           <SzTable
  //             className={"report-table"}
  //             columns={signalTableHeader}
  //             data={signalFilteredData}
  //             keyField={"key"} />
  //           <Pagination
  //             totalPage={props.report.signalTotalPage as number}
  //             currentPage={props.report.signalCurrentPage as number}
  //             handler={setSignalCurrentPage}
  //           />
  //         </div>
  //       </>
  //     );
  //   }
  //   return (
  //     <>
  //       <div className={"network-table"}>
  //         <div className="text-center">
  //           <Table striped bordered hover responsive className="sz-table">
  //             <thead>
  //               <tr className="bg-light-blue">
  //                 {signalTableHeader.map((header: any, key: any) => (
  //                   <th key={key}>{header.text}</th>
  //                 ))}
  //               </tr>
  //             </thead>
  //             <tbody>
  //               <tr>
  //                 <td colSpan={signalTableHeader.length as number}>{t("no-results-found")}</td>
  //               </tr>
  //             </tbody>
  //           </Table>
  //         </div>
  //       </div>
  //     </>
  //   );
  // }
};

const mapStateToProps = (state: { report: IReportData, user: IUser, contract: { currentContract: Contract } }) => {
  return {
    report: state.report,
    listType: state.user.listType,
    contract: state.contract.currentContract,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(ReportActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NetworkTable);
