import { IUser } from '../store/redux/types';
import {Roles} from "../constants";

export class RightsHelper {
    public static isGranted(user: IUser, serviceRoles: any[]): boolean {
        const { roles } = user;

        if (roles === undefined) {
            return false;
        }
        let intersection = roles.filter(x => serviceRoles.includes(x));

        return intersection.length > 0;
    }
}
