import {useMapEvents} from "react-leaflet";
import {setMapPreferences} from "../../../../store/redux/actions/user";
import {LeafletMapConfig} from "../../../../manager/map/config";
import Request from "../../../../services/Request";

export function GetBounds(props: any) {
    const { setLatLng, setBounds, setBoundsNE, setBoundsSW, setZoom, setMapInfo } = props;
    const map = useMapEvents({
        zoomend: () => {
            const zoom = map.getZoom();
            // console.log(zoom)
            setZoom(zoom);
            // console.log('setmapinfo')
            setMapInfo({
                zoom: zoom,
                autoZoom: false,
            });
            setTimeout(() => {
                setMapPreferences({ zoom: map.getZoom() });
            }, 300);
        },
        moveend: () => {
            try {
                const center = map.getCenter();
                const lat = center.lat;
                const lng = center.lng;
                const bounds = map.getBounds();
                const zoom = map.getZoom();
                setZoom(zoom);
                setLatLng(center);
                setBounds(bounds);
                // console.log('setmapinfo')
                setMapInfo({
                    center: {
                        lat: lat,
                        lng: lng,
                    },
                    autoZoom: false,
                });
                setTimeout(() => {
                    setMapPreferences({
                        center: {
                            lat: lat,
                            lng: lng,
                        },
                    });
                }, 600);
                map.invalidateSize();

                if (bounds) {
                    const urlEpsgNE = LeafletMapConfig.API_HOST + "/geoserver/srs/transform/"
                        + mathCeilFloorWithRoundingPrecision(bounds.getNorthEast().lat, 'ceil') + "/"
                        + mathCeilFloorWithRoundingPrecision(bounds.getNorthEast().lng, 'ceil');
                    Request.get(urlEpsgNE).then((responseData: any) => {
                        setBoundsNE(responseData.data);
                    });
                    const urlEpsgSW = LeafletMapConfig.API_HOST + "/geoserver/srs/transform/"
                        + mathCeilFloorWithRoundingPrecision(bounds.getSouthWest().lat) + "/"
                        + mathCeilFloorWithRoundingPrecision(bounds.getSouthWest().lng);
                    Request.get(urlEpsgSW).then((responseData: any) => {
                        setBoundsSW(responseData.data);
                    });
                }
            } catch (e) {
                console.log('srs transform error')

                return;
            }
        },
    });

    return null;
}

function mathCeilFloorWithRoundingPrecision(value: number, operation = 'floor', valIncrement = 0.01) {
    if ('ceil' === operation) {
        return Math.ceil(value / valIncrement) * valIncrement;
    } else {
        return Math.floor(value / valIncrement) * valIncrement;
    }
}