import circleGreen from "../../assets/img/circle-green.svg";
import circleGrey from "../../assets/img/circle-grey.svg";
import circleOrange from "../../assets/img/circle-orange.svg";
import circleRedOutlineBlack from "../../assets/img/circle-red-outline-black.svg";
import circleRedOutlinePurple from "../../assets/img/circle-red-outline-purple.svg";
import circleRedOutlineRed from "../../assets/img/circle-red-outline-red.svg";
import circleRed from "../../assets/img/circle-red.svg";
import circleMap from "../../assets/img/circle.svg";
import freeIcon from "../../assets/img/free_signal.svg";
import harmfulIcon from "../../assets/img/harmful_signal.svg";
import confirmedIcon from "../../assets/img/icons/project-confirmed.svg";
import suspecteIcon from "../../assets/img/icons/project-suspecte.svg";
import travauxIcon from "../../assets/img/icons/project-travaux.svg";
import markerMap from "../../assets/img/marker-map.svg";
import noiseIcon from "../../assets/img/noise_signal.svg";
import odourIcon from "../../assets/img/odour_signal.svg";
import overflowIcon from "../../assets/img/overflow_signal.svg";
import rejectionIcon from "../../assets/img/rejection_signal.svg";
import roadIcon from "../../assets/img/road_signal.svg";
import workIcon from "../../assets/img/work_signal.svg";
import { config, map3dConfig, mapConfig } from "../../config";

export const defaultProperties = {
  duration: 1000,
  scale: 1000,
};

export const CONF = {
  LODS: [
    {
      level: 11,
      resolution: 76.4370282850732,
      scale: 288895.277144,
    },
    {
      level: 12,
      resolution: 38.2185141425366,
      scale: 144447.638572,
    },
    {
      level: 13,
      resolution: 19.1092570712683,
      scale: 72223.819286,
    },
    {
      level: 14,
      resolution: 9.55462853563415,
      scale: 36111.909643,
    },
    {
      level: 15,
      resolution: 4.77731426794937,
      scale: 18055.954822,
    },
    {
      level: 16,
      resolution: 2.38865713397468,
      scale: 9027.977411,
    },
    {
      level: 17,
      resolution: 1.19432856685505,
      scale: 4513.988705,
    },
    {
      level: 18,
      resolution: 0.597164283559817,
      scale: 2256.994353,
    },
    {
      level: 19,
      resolution: 0.298582141647617,
      scale: 1128.497176,
    },
    {
      level: 20,
      resolution: 0.1492910708238085,
      scale: 564.248588,
    },
    {
      level: 21,
      resolution: 0.17464553541190425,
      scale: 282.124294,
    },
    {
      level: 22,
      resolution: 0.7464553541190425,
      scale: 141.062147,
    },
    {
      level: 21,
      resolution: 0.37322767705952125,
      scale: 70.5310735,
    },
  ],
  PORTAL_URL: mapConfig.PORTAL_URL,
  SERVICE_URL: mapConfig.SERVICE_URL,
  WEBMAP_ID: mapConfig.WEBMAP_ID, // 2d
  WEBSCENE_ID: map3dConfig.WEBSCENE3D_ID, // 3d
  // GLOBALWEBSCENE_ID: "d2702d29244548afaf1f3245b1678d49",
};

export const LeafletMapConfig = {
    BOUNDS_FRANCE : "[[42.252918, -5.009766], [51.193115, 8.481445]]",
    DEFAULT_LAT : mapConfig.DEFAULT_LAT,
    DEFAULT_LNG : mapConfig.DEFAULT_LNG,
    GEOSERVER_HOST : config.geoserver,
    GEOSERVER_WMS_HOST : config.geoserverWms,
    GEOSERVER_WFS_HOST : config.geoserverWfs,
    GEOSERVER1_HOST : config.geoserver1,
    GEOSERVER2_HOST : config.geoserver2,
    GEOSERVER3_HOST : config.geoserver3,
    API_HOST : config.apiUrl,
    OSM_URI : 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
    ZOOM_DEFAULT : 12,
    ZOOM_MIN : 6,
    ZOOM_MAX : 20,
    MAX_NATIVE_ZOOM : 18,
    MIX_MAX_WMS_ZOOM : 16,
    BBOX_SIDE_LENGTH : mapConfig.BBOX_SIDE_LENGTH,
}

export interface IRenderer {
  label: string;
  symbol: {
    height: string,
    type: string,
    url: string,
    width: string,
  };
  type: string;
}

export const confirmedRenderer: IRenderer = {
  label: "Confirmed project",
  symbol: {
    height: "50px",
    type: "picture-marker",
    url: `${config.host}${confirmedIcon}`,
    width: "50px",
  },
  type: "simple",
};

export const suspecteRenderer: IRenderer = {
  label: "suspecte project",
  symbol: {
    height: "50px",
    type: "picture-marker",
    url: `${config.host}${suspecteIcon}`,
    width: "50px",
  },
  type: "simple",
};

export const travauxRenderer: IRenderer = {
  label: "Travaux project",
  symbol: {
    height: "50px",
    type: "picture-marker",
    url: `${config.host}${travauxIcon}`,
    width: "50px",
  },
  type: "simple",
};

export const freeRenderer: IRenderer = {
  label: "Free signal",
  symbol: {
    height: "50px",
    type: "picture-marker",
    url: `${config.host}${freeIcon}`,
    width: "50px",
  },
  type: "simple",
};

export const harmfulRenderer: IRenderer = {
  label: "harmful signal",
  symbol: {
    height: "50px",
    type: "picture-marker",
    url: `${config.host}${harmfulIcon}`,
    width: "50px",
  },
  type: "simple",
};
export const noiseRenderer: IRenderer = {
  label: "noise signal",
  symbol: {
    height: "50px",
    type: "picture-marker",
    url: `${config.host}${noiseIcon}`,
    width: "50px",
  },
  type: "simple",
};
export const odourRenderer: IRenderer = {
  label: "odour signal",
  symbol: {
    height: "50px",
    type: "picture-marker",
    url: `${config.host}${odourIcon}`,
    width: "50px",
  },
  type: "simple",
};
export const overflowRenderer: IRenderer = {
  label: "overflow signal",
  symbol: {
    height: "50px",
    type: "picture-marker",
    url: `${config.host}${overflowIcon}`,
    width: "50px",
  },
  type: "simple",
};
export const rejectionRenderer: IRenderer = {
  label: "rejection signal",
  symbol: {
    height: "50px",
    type: "picture-marker",
    url: `${config.host}${rejectionIcon}`,
    width: "50px",
  },
  type: "simple",
};
export const roadRenderer: IRenderer = {
  label: "road signal",
  symbol: {
    height: "50px",
    type: "picture-marker",
    url: `${config.host}${roadIcon}`,
    width: "50px",
  },
  type: "simple",
};
export const workRenderer: IRenderer = {
  label: "work signal",
  symbol: {
    height: "50px",
    type: "picture-marker",
    url: `${config.host}${workIcon}`,
    width: "50px",
  },
  type: "simple",
};

export const markerRenderer: IRenderer = {
  label: "Adresse",
  symbol: {
    height: "20px",
    type: "picture-marker",
    url: `${config.host}${markerMap}`,
    width: "18px",
  },
  type: "simple",
};

export const circleRenderer: IRenderer = {
  label: "Asset",
  symbol: {
    height: "12px",
    type: "picture-marker",
    url: `${config.host}${circleMap}`,
    width: "12px",
  },
  type: "simple",
};

export const redCircleRenderer: IRenderer = {
  label: "Non Conforme",
  symbol: {
    height: "25px",
    type: "picture-marker",
    url: `${config.host}${circleRed}`,
    width: "25px",
  },
  type: "simple",
};

export const greenCircleRenderer: IRenderer = {
  label: "Conforme",
  symbol: {
    height: "25px",
    type: "picture-marker",
    url: `${config.host}${circleGreen}`,
    width: "25px",
  },
  type: "simple",
};

export const orangeCircleRenderer: IRenderer = {
  label: "Non Conforme Sans Dysfonctionnement",
  symbol: {
    height: "25px",
    type: "picture-marker",
    url: `${config.host}${circleOrange}`,
    width: "25px",
  },
  type: "simple",
};

export const greyCircleRenderer: IRenderer = {
  label: "A Déterminer",
  symbol: {
    height: "25px",
    type: "picture-marker",
    url: `${config.host}${circleGrey}`,
    width: "25px",
  },
  type: "simple",
};

export const redOutlinedRedCircleRenderer: IRenderer = {
  label: "Non Conforme (EU dans EP)",
  symbol: {
    height: "25px",
    type: "picture-marker",
    url: `${config.host}${circleRedOutlineRed}`,
    width: "25px",
  },
  type: "simple",
};

export const purpleOutlinedRedCircleRenderer: IRenderer = {
  label: "Non Conforme (EP dans EU)",
  symbol: {
    height: "25px",
    type: "picture-marker",
    url: `${config.host}${circleRedOutlinePurple}`,
    width: "25px",
  },
  type: "simple",
};

export const blackOutlinedRedCircleRenderer: IRenderer = {
  label: "Non Conforme (EU dans EP et EP dans EU)",
  symbol: {
    height: "25px",
    type: "picture-marker",
    url: `${config.host}${circleRedOutlineBlack}`,
    width: "25px",
  },
  type: "simple",
};

export const reportCircleRenderers = {
  green: greenCircleRenderer,
  grey: greyCircleRenderer,
  orange: orangeCircleRenderer,
  red: redCircleRenderer,
  redOutlineBlack: blackOutlinedRedCircleRenderer,
  redOutlinePurple: purpleOutlinedRedCircleRenderer,
  redOutlineRed: redOutlinedRedCircleRenderer,
};

export const projectIconRenderers = {
  confirmed: confirmedRenderer,
  suspecte: suspecteRenderer,
  travaux: travauxRenderer,
};

export const signalIconRenderers = {
  free: freeRenderer,
  harmful: harmfulRenderer,
  noise: noiseRenderer,
  odour: odourRenderer,
  overflow: overflowRenderer,
  rejection: rejectionRenderer,
  road: roadRenderer,
  work: workRenderer,
};
