import React, {ReactElement, useEffect, useState} from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import "./CollapseToggle.scss";
import { connect } from "react-redux";
import { MapActions } from "../../../store/redux/actions";
import { SzIcon } from "@suezenv/react-theme-components";

interface ICollapseToggle {
    collapseDirection?: "top" | "bottom" | "left" | "right";
    handler?: (b: boolean) => void;
    toggled: boolean;
    mapInfo: any,
    setMapInfo: (payload: any) => void;
    showClose?: boolean;
    show?: boolean;
    closeHandler?: (b: boolean) => void;
}

function CollapseToggle(props: ICollapseToggle): ReactElement {
    const { t } = useTranslation();
    const collapseDirection: string = props.collapseDirection || "left";
    let reverse: string;
    const [toggled, setToggled] = useState(props.toggled);
    const [show, setShow] = useState(props.show || false);
    useEffect(() => {
        setToggled(props.toggled);
    }, [props.toggled]);
    const clickHandler = () => {
        const { setMapInfo } = props;

        setToggled(!toggled);
        setMapInfo({
            resize: true,
        });
        if (typeof props.handler === "function") {
            props.handler(!toggled);
        }
    };
    const closeHandler = () => {
        setShow(false);
        if (typeof props.closeHandler === "function") {
            props.closeHandler(false);
        }
    }

    switch (collapseDirection) {
        case "bottom":
            reverse = "top";
            break;
        case "right":
            reverse = "left";
            break;
        case "top":
            reverse = "bottom";
            break;
        case "left":
        default:
            reverse = "right";
            break;
    }
    return (
        <>
            <div className={`collapse-toggle`} onClick={clickHandler}>
                {props.showClose && <SzIcon variant="bold" icon="add-circle" className={"collapse-close"} onClick={closeHandler}/>}
                <div className="collapse-toggle-hover-box">
                    <div className="collapse-toggle-container">
                        <div className="collapse-toggle-default">
                            <OverlayTrigger
                              placement="right"
                              delay={{show: 100, hide: 200}}
                              overlay={
                                  <Tooltip id="tooltip">
                                      {toggled ? t("open_panel") : t("close_panel")}
                                  </Tooltip>
                              }
                            >
                            <i className={`material-icons material-icons-24px`}>
                                chevron_{toggled ? reverse : collapseDirection}
                            </i>
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state: any) => {
    return {
        mapInfo: state.map,
    };
};

const mapDispatchToProps = {
    setMapInfo: MapActions.setMapInfo,
};

export default connect(mapStateToProps, mapDispatchToProps)(CollapseToggle);

