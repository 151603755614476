import React, { CSSProperties, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import moment from "moment";
import tinycolor from "tinycolor2";
import { SzAlert, SzButton, SzCheckbox, SzSpinner, SzTypographie } from "@suezenv/react-theme-components";
import { adminTabsEventKeys, AppUrls } from "../../../../constants";
import { Contract, SiteConfiguration } from "../../../../types";
import { ContractsService } from "../../../../services/ContractsService";
import AdminTabs from "../../adminTabs";
import BlockHeader from "../../../elements/BlockHeader";
import SzColorPicker from "../../../elements/SzColorPicker/SzColorPicker";
import ImgDropZone from "../../../elements/SzDropzone/imgDropZone";
import { CommonHelper } from "../../../../helper/Common";
import './details.scss';

type Base64LogoImages = {
    squareLogo?: string | null,
    rectangleLogo?: string | null,
    squareLogoDarkMode?: string | null,
    rectangleLogoDarkMode?: string | null,
}

const ContractDetails = () => {
    const history = useHistory();
    const { contractId } = useParams();
    const { t } = useTranslation();
    const queryClient = useQueryClient();

    const tabsDisplayed = [
        adminTabsEventKeys.CONTRACT_AREA,
        adminTabsEventKeys.CONTRACT_SKILLS,
        adminTabsEventKeys.CONTRACT_ORGANIZATIONS,
        adminTabsEventKeys.CONTRACT_OPERATOR,
        adminTabsEventKeys.CONTRACT_SERVICES,
        adminTabsEventKeys.CONTRACT_USERS
    ];
    const darkFontColor = "#030F40";
    const lightFontColor = "#FFFFFF";

    const [portalColorsChecked, setPortalColorsChecked] = useState('1');
    const [primaryColor, setPrimaryColor] = useState('');
    const [primaryColorShades, setPrimaryColorShades] = useState(CommonHelper.getColorShades(primaryColor));
    const [secondaryColor, setSecondaryColor] = useState(primaryColor);
    const [secondaryColorShades, setSecondaryColorShades] = useState(CommonHelper.getColorShades(secondaryColor));
    const [contrastTextColor, setContrastTextColor] = useState(tinycolor.mostReadable(primaryColor, [darkFontColor, lightFontColor]).toHexString());
    const [base64, setBase64] = useState<Base64LogoImages>({});
    const [isSavingDisabled, setIsSavingDisabled] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
//     const [siteConfiguration, setSiteConfiguration] = useState<SiteConfiguration>();
//     const [errorSiteConfiguration, setErrorSiteConfiguration] = useState({ show: false, success: false, msg: '' });
//
//     const ErrorSiteConfiguration = (success: boolean, msg: string, show?: boolean) => {
//         setErrorSiteConfiguration({ show: show ?? true, success: success, msg: msg });
//     };
    const backToContracts = () => history.push(AppUrls.ADMIN_CONTRACTS);
//
//     const updateBase64 = (base64Img: string, index: any) => {
//         const siteConfigurationData: SiteConfiguration = siteConfiguration?.data
//         if (base64Img === '' && siteConfigurationData && base64[index as keyof Base64LogoImages] !== siteConfigurationData[index as keyof SiteConfiguration]) {
//             setBase64({
//                 ...base64,
//                 [index as keyof Base64LogoImages]: siteConfigurationData[index as keyof SiteConfiguration]
//             });
//         } else {
//             setBase64({ ...base64, [index as keyof Base64LogoImages]: base64Img });
//         }
//         setIsSavingDisabled(false);
//     }

    const fetchContract = () => ContractsService.getContractById(contractId);
    const { data: contractDetails, isError, isLoading } = useQuery<Contract>(['contract', contractId], fetchContract);

    const saveContract = () => {
        if (contractDetails) {
            setIsSaving(true);

//             const siteConfigurationTmp: SiteConfiguration = {
//                 contract: contractDetails.id,
//                 primaryColor: primaryColor ?? '',
//                 primaryColorLight: primaryColor ? primaryColorShades.light : '',
//                 primaryColorDark: primaryColor ? primaryColorShades.dark : '',
//                 secondaryColor: secondaryColor ?? '',
//                 secondaryColorLight: secondaryColor ? secondaryColorShades.light : '',
//                 secondaryColorDark: secondaryColor ? secondaryColorShades.dark : '',
//                 contrastTextColor: contrastTextColor ?? '',
//                 squareLogo: base64.squareLogo ?? '',
//                 rectangleLogo: base64.rectangleLogo ?? '',
//                 squareLogoDarkMode: base64.squareLogoDarkMode ?? '',
//                 rectangleLogoDarkMode: base64.rectangleLogoDarkMode ?? ''
//             }

//             if (siteConfiguration) {
//                 ContractsService.updateSiteConfiguration(siteConfigurationTmp)
//                     .then(() => queryClient.invalidateQueries(['siteConfiguration', contractDetails.id]))
//                     .catch(() => ErrorSiteConfiguration(false, t('Erreur updateSiteConfiguration')))
//                     .finally(() => {
//                         setIsSaving(false)
//                         queryClient.invalidateQueries(["siteConfiguration", contractDetails?.id])
//                     });
//             } else {
//                 ContractsService.createSiteConfiguration(siteConfigurationTmp)
//                     .then(() => queryClient.invalidateQueries(['siteConfiguration', contractDetails.id]))
//                     .catch(() => ErrorSiteConfiguration(false, t('Erreur createSiteConfiguration')))
//                     .finally(() => {
//                         setIsSaving(false)
//                         queryClient.invalidateQueries(["siteConfiguration", contractDetails?.id])
//                     });
//             }
        }
    }

    const onChangePortalColors = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPortalColorsChecked(event.currentTarget.value);
        setIsSavingDisabled(false);
    };
    const onChangePrimaryColor = (color: string) => {
        setPrimaryColor(color);
        if ('1' === portalColorsChecked) {
            setSecondaryColor(color);
        }
        setIsSavingDisabled(false);
    };
    const onChangeSecondaryColor = (color: string) => {
        setSecondaryColor(color);
        setIsSavingDisabled(false);
    };

//     const fetchContractDetails = () => ContractsService.getSiteConfiguration(contractDetails?.id ?? "");
//
//     const {
//         data: siteConfiguration,
//         isLoading: isLoadingSiteConfiguration,
//         isIdle: isIdleSiteConfiguration
//     } = useQuery(
//         ["siteConfiguration", contractDetails?.id],
//         fetchContractDetails,
//         {
//             enabled: !!contractDetails,
//             retry: 1
//         }
//     )

//     useEffect(() => {
//         const siteConfigurationData: SiteConfiguration = siteConfiguration?.data;
//         if (siteConfigurationData) {
//             if (siteConfigurationData.primaryColor && siteConfigurationData.secondaryColor) {
//                 setPrimaryColor(siteConfigurationData.primaryColor);
//                 setSecondaryColor(siteConfigurationData.secondaryColor);
//                 setPortalColorsChecked(siteConfigurationData.primaryColor === siteConfigurationData.secondaryColor ? '1' : '2');
//             }
//             setBase64({
//                 squareLogo: siteConfigurationData?.squareLogo,
//                 rectangleLogo: siteConfigurationData?.rectangleLogo,
//                 squareLogoDarkMode: siteConfigurationData?.squareLogoDarkMode,
//                 rectangleLogoDarkMode: siteConfigurationData?.rectangleLogoDarkMode
//             });
//         }
//
//     }, [siteConfiguration]);

    useEffect(() => {
        setPrimaryColorShades(CommonHelper.getColorShades(primaryColor));
        if (primaryColor === secondaryColor) {
            setSecondaryColorShades(primaryColorShades);
        } else {
            setSecondaryColorShades(CommonHelper.getColorShades(secondaryColor));
        }
        setContrastTextColor(tinycolor.mostReadable(primaryColor, [darkFontColor, lightFontColor]).toHexString());
    }, [primaryColor, secondaryColor]);

    const renderContractInfos = () => {
        if (isLoading) {
            return <SzSpinner className="m-auto"/>;
        }
        if (isError || !contractDetails) {
            return <SzAlert variant="danger" transparent={true}>{t('alert.loading_contract_error')}</SzAlert>;
        }

        return <div className="row">
            <div className="col-md-7">
                <div className="row">
                    <div className="col-12">
                        <div className="box box-no-padding box-no-shadow box-dark px-4">
                            <div className="row">
                                <BlockHeader className="col"
                                             title={t("admin:admin.contracts.update.opening_date")}
                                             subtitle={contractDetails.openingDate ? moment(contractDetails.openingDate).format(t('DATE_FORMAT')) : '-'}
                                             variant={"reverse"}
                                             icon={"calendar-3"}/>
                                <BlockHeader className="col"
                                             title={t("admin:admin.contracts.update.billing")}
                                             subtitle={contractDetails.billing ? t(`billingTypes.${contractDetails.billing}`) : '-'}
                                             variant={"reverse"}
                                             icon={"accounting-document"}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-5">
                        <div className="box box-no-padding box-no-shadow box-dark px-4">
                            <div className="row">
                                <BlockHeader className="col"
                                             title={t("admin:admin.contracts.update.main_organization")}
                                             subtitle={contractDetails?.organization?.label ?? '-'}
                                             variant={"reverse"}
                                             icon={"network-users"}/>
                                <BlockHeader className="col"
                                             title={t("admin:admin.contracts.update.admin_count")}
                                             subtitle={contractDetails.adminCount?.toString() ?? '-'}
                                             variant={"reverse"}
                                             icon={"login-keys"}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-5">
                <SzTypographie variant={"body"} weight={"bold"}>
                    {t("admin:admin.contracts.update.description")}
                </SzTypographie>
                <SzTypographie variant={"caption"} color={"disabled"}>
                    {contractDetails.description ?? ''}
                </SzTypographie>
            </div>
        </div>;
    }

//     const renderSiteConfigurationBox = () => {
//         return <>
//             <div className="row">
//                 <div className="col-md-4">
//                     <SzTypographie variant="h2" weight="bold">
//                         {t('admin:admin.contracts.detail.portal_colors')}
//                     </SzTypographie>
//                     <div className="row">
//                         <div className="col">
//                             <SzCheckbox id="portalColorsPrimary"
//                                         name="portalColors"
//                                         type="radio"
//                                         value="1"
//                                         label={t('admin:admin.contracts.detail.one_color')}
//                                         checked={'1' === portalColorsChecked}
//                                         onChange={onChangePortalColors}/>
//                         </div>
//                         <div className="col">
//                             <SzCheckbox id="portalColorsSecondary"
//                                         name="portalColors"
//                                         type="radio"
//                                         value="2"
//                                         label={t('admin:admin.contracts.detail.two_colors')}
//                                         checked={'2' === portalColorsChecked}
//                                         onChange={onChangePortalColors}/>
//                         </div>
//                     </div>
//                     <div className="mt-3">
//                         <div className="col">
//                             <SzColorPicker name="primaryColor" color={primaryColor}
//                                            onChange={onChangePrimaryColor}/>
//                         </div>
//                         {'2' === portalColorsChecked && <div className="col">
//                             <SzColorPicker name="secondaryColor" color={secondaryColor}
//                                            onChange={onChangeSecondaryColor}/>
//                         </div>}
//                     </div>
//                 </div>
//                 <div className="col-md-4">
//                     <div className={"contract-details-logo-container"}>
//                         <SzTypographie variant={"h2"}
//                                        weight={"bold"}>{t('admin:admin.contracts.update.territoryLogo')}</SzTypographie>
//                         <div className={"contract-details--logo-drop-zones"}>
//                             <div className="col-md-3 d-flex flex-column justify-content-end">
//                                 <SzTypographie variant={"caption"} color={"inactive"}>
//                                     {t('admin:admin.contracts.update.squareLightLogo')}
//                                 </SzTypographie>
//                                 <ImgDropZone
//                                     onBase64Update={(base64: string) => updateBase64(base64, "squareLogo")}
//                                     currentImage={base64.squareLogo}/>
//                             </div>
//                             <div className="col-md-3">
//                                 <SzTypographie variant={"caption"} color={"inactive"}>
//                                     {t('admin:admin.contracts.update.rectangleLightLogo')}
//                                 </SzTypographie>
//                                 <ImgDropZone
//                                     onBase64Update={(base64: string) => updateBase64(base64, "rectangleLogo")}
//                                     currentImage={base64.rectangleLogo}/>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//                 <div className="col-md-4">
//                     <div
//                         className={"h-100 p-4 contract-details-preview"}
//                         style={{
//                             "--primary-brand": primaryColor,
//                             "--title-decoration": primaryColor,
//                             "--primary-contrast": contrastTextColor,
//                             "--brand-light": primaryColorShades.light,
//                             "--brand-dark": primaryColorShades.dark,
//                         } as CSSProperties}
//                     >
//                         <div className={"box"}>
//                             <SzTypographie variant="h1" className="mb-0">
//                                 {t('admin:admin.contracts.update.preview')}
//                             </SzTypographie>
//                             <SzTypographie variant="caption"
//                                            className="title-decoration">
//                                 {t('admin:admin.contracts.update.portalName')}
//                             </SzTypographie>
//                             <div className={"d-flex pt-5 justify-content-between"}>
//                                 <SzButton variant={"secondary"}>
//                                     {t('admin:admin.contracts.update.previous')}
//                                 </SzButton>
//                                 <SzButton>
//                                     {t('admin:admin.contracts.update.next')}
//                                 </SzButton>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//             {errorSiteConfiguration.show &&
//                 <SzAlert variant={errorSiteConfiguration.success ? 'success' : 'danger'}
//                          transparent={true}>{errorSiteConfiguration.msg}</SzAlert>}
//             <div className={"d-flex justify-content-end"}>
//                 <SzButton icon={"floppy-disk"}
//                           isDisabled={isSavingDisabled}
//                           onClick={saveContract}
//                           loader={isSaving}>
//                     {t('admin:admin.contracts.update.save')}
//                 </SzButton>
//             </div>
//         </>;
//     }

    return <div className="container-fluid py-3 overflow-auto h-100">
        <div className="row">
            <div className="col">
                <SzButton variant={"tertiary"} icon={"keyboard-arrow-left"} onClick={backToContracts}>
                    {t("admin:admin.contracts.update.back_to_contracts")}
                </SzButton>
                <SzTypographie className={"mt-3"} variant={"h1"}>
                    {isLoading || !contractDetails ? '-' : contractDetails.label}
                </SzTypographie>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <div className="box">
                    {renderContractInfos()}
                </div>
            </div>
        </div>
        <div className="mt-4">
            <div className="admin-tabs">
                {contractDetails && <AdminTabs contractSelected={contractDetails} tabsDisplayed={tabsDisplayed}/>}
            </div>
        </div>
    </div>;
}

export default ContractDetails;
