import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzSelect } from "@suezenv/react-theme-components";
import { bindActionCreators } from "redux";
import { listType } from "../../../config/report";
import { ReportActions, UserActions } from "../../../store/redux/actions";
import { IReportData, IUser } from "../../../store/redux/types";
import ControlListHeader from "./ControlListHeader";
import ParcelListHeader from "./ParcelListHeader";
import PluListHeader from "./PluListHeader";
import ProjectListHeader from "./ProjectListHeader";
import SignalListHeader from "./SignalListHeader";
import SiteIndustrielListHeader from "./SiteIndustrielListHeader";

function ListHeader(props: any) {
  const { t } = useTranslation();
  const listTypeOptions = Object.entries(listType).map((item: any) => {
    return { label: t(`report:${item[1]}`), value: item[0] };
  });
  const [localListType, setLocalListType] = useState<any>("");

  useEffect(() => {
    if (props.listType) {
      const listTypeValues = listTypeOptions.filter((item: any) => {
        return props.listType === item.value;
      });
      setLocalListType(listTypeValues);
    }
  }, [props.listType]);

  const updateValues = (values: [] | null, callback: any) => {
    if (null !== values) {
      callback(values);
    } else {
      callback([]);
    }
  };

  return (
    <>
      <div className={"report--menu--tabs_list--header"}>
        <div className={"report--menu--tabs_list--header_title"}>
          <SzSelect
            className="mb-0 border-secondary listType"
            id="listType"
            name="listType"
            isSearchable={false}
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setLocalListType);
              props.setReportListType(values.value);
            }}
            options={listTypeOptions}
            value={localListType}
          />
        </div>
        {props.listType === "control" &&
          <ControlListHeader
            filterAction={props.filterAction}
            resetAction={props.resetAction}
            areFiltersEmpty={props.areFiltersEmpty}
          />
        }
        {props.listType === "project" &&
          <ProjectListHeader
            filterAction={props.filterAction}
            resetAction={props.resetAction}
            areFiltersEmpty={props.areFiltersEmpty}
          />
        }
        {props.listType === "signal" &&
          <SignalListHeader
            filterAction={props.filterAction}
            resetAction={props.resetAction}
            areFiltersEmpty={props.areFiltersEmpty}
          />
        }
        {props.listType === "siteIndustriel" &&
          <SiteIndustrielListHeader
            filterAction={props.filterAction}
            resetAction={props.resetAction}
            areFiltersEmpty={props.areFiltersEmpty}
          />
        }
        {props.listType === "parcel" &&
          <ParcelListHeader
            filterAction={props.filterAction}
            resetAction={props.resetAction}
            areFiltersEmpty={props.areFiltersEmpty}
          />
        }
        {props.listType === "plu" &&
          <PluListHeader
            filterAction={props.filterAction}
            resetAction={props.resetAction}
            areFiltersEmpty={props.areFiltersEmpty}
          />
        }
      </div>
    </>
  );
}

const mapStateToProps = (state: { report: IReportData, user: IUser }) => {
  return {
    currentPage: state.report.currentPage,
    filters: state.report.filters,
    listType: state.user.listType,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...ReportActions, ...UserActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ListHeader);
