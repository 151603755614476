import {useMap, useMapEvents} from "react-leaflet";
import L from "leaflet";
export function LocateComponent(props: ILeafletLocateComponent) {
    const map1 = useMap();
    if (props.locateClicked > 0) {
        map1.locate();
        props.setLocateClicked(0);
    }
    const map = useMapEvents({
        locationfound: (location) => {
            map.flyTo(location.latlng);
            const radius = location.accuracy;
            const circle = L.circle(location.latlng, radius);
            circle.addTo(map);
        },
        locationerror: (error) => {
            console.log(error.message);
        },
    });
    return null;
}

interface ILeafletLocateComponent {
    locateClicked: number,
    setLocateClicked: (locateClicked: any) => void;
}