import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useQuery, useQueryClient } from "react-query";
import {
    SzAlert,
    SzIcon,
    SzModal,
    SzPagination,
    SzSpinner,
    SzTable,
    SzTypographie
} from "@suezenv/react-theme-components";
import { Pagination, UserHeimdall } from "../../../../types";
import { OrganizationService } from "../../../../services/OrganizationService";
import TabHeader from "../../adminTabs/tabHeader";
import AdminUserAdd from "./AdminUserAdd";
import { AppAdminDetailsUrls, AppUrls, Constants } from "../../../../constants";
import { CommonHelper } from "../../../../helper/Common";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router";

type OrganizationUsersData = {
    label: JSX.Element,
    profile: string,
    lastLogin: string,
    organization: string,
    operator: string,
    actions: JSX.Element,
}

const AdminOrganizationUser = (props: { contractId: string, organizationId: string, readonly?: boolean }) => {
    const { organizationId, contractId, readonly } = props;
    const history = useHistory();
    const location = useLocation();
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const [organizationUsersData, setOrganizationUsersData] = useState<OrganizationUsersData[]>();
    const [showAddModal, setShowAddModal] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pagination, setPagination] = useState<Pagination>();

    const fetchOrganizationUsers = () => OrganizationService.getOrganizationUsers(organizationId, currentPage, Constants.DEFAULT_PER_PAGE);
    const {
        data,
        isError,
        isLoading,
        isSuccess
    } = useQuery(['organizationUsers', organizationId, currentPage], fetchOrganizationUsers);

    const onAddFormValidate = () => {
        queryClient.invalidateQueries(['organizationUsers', organizationId]);
    }

    useEffect(() => {
        if (isSuccess) {
            const users: UserHeimdall[] = data?.data;
            let organizationUsersData: OrganizationUsersData[] = [];
            users.forEach((user: UserHeimdall) => organizationUsersData.push(formatData(user)));
            setOrganizationUsersData(organizationUsersData);
            setPagination(CommonHelper.getPagination(users.length, data?.headers));
        }
    }, [data]);

    const defaultSorted: any = [
        {
            dataField: 'name',
            order: 'desc'
        }
    ];

    const columns = [
        {
            dataField: 'label',
            text: t('admin:admin.users.name')
        },
        {
            dataField: 'profile',
            text: t('admin:admin.users.profile')
        },
        {
            dataField: 'lastLogin',
            text: t('admin:admin.users.last_login')
        },
        {
            dataField: 'organization',
            text: t('admin:admin.users.organization')
        },
        {
            dataField: 'operator',
            text: t('admin:admin.users.operator')
        }
    ];

    if (!readonly) {
        columns.push(
            {
                dataField: 'actions',
                text: ''
            })
    }

    const goToUserDetails = (contractId: string, userId: string) => {
        history.push(CommonHelper.generateAdminDetailsSubRoute(location.pathname, AppAdminDetailsUrls.USER_DETAILS)
            .replace(':contractId', contractId)
            .replace(':userId', userId)
        );
    }

    const formatData = (user: UserHeimdall): OrganizationUsersData => {
        return {
            label: <>
                <SzTypographie weight="bold"
                               className="color-primary mb-0">{user.firstName} {user.lastName}</SzTypographie>
                <SzTypographie variant="caption">{user.username}</SzTypographie>
            </>,
            profile: 'Utilisateur',
            organization: '0',
            operator: '0',
            lastLogin: 'Inconnu',
            actions: <SzIcon variant='line' icon='task-list-text-1' className="color-primary"
                             onClick={() => goToUserDetails(contractId, user.id)}/>
        }
    };

    const handlePageChange = (pageNumber: number) => setCurrentPage(pageNumber);

    const renderPagination = (pagination: Pagination) => {
        return <div className="d-flex justify-content-center my-4">
            <SzPagination
                totalItemsCount={pagination.totalCount}
                activePage={currentPage}
                onChange={handlePageChange}
                itemsCountPerPage={pagination.perPage}
                pageRangeDisplayed={+Constants.USERS_PAGINATION_PAGE_RANGE}
            />
        </div>;
    }

    if (isError) {
        return <SzAlert
            variant="warning"
            transparent={true}
            className="w-100 border-0 flex-column p-4"
        >
            {t('admin:alert.loading_error')}
        </SzAlert>
    }

    if (isLoading) {
        return <div className='p-4 text-center'>
            <SzSpinner/>
        </div>
    }

    return <div className="box">
        <TabHeader title={t('admin:admin.tabs.users')}
                   subtitle={t("admin:admin.tabs.usersSubtitle", { count: pagination?.totalCount })}
                   onBtnClick={location.pathname.includes(AppUrls.ADMIN_GESTION_TERRITORIALE) && !readonly ? undefined : () => setShowAddModal(true)}/>
        {organizationUsersData && <SzTable
            data={organizationUsersData}
            keyField={'label'}
            columns={columns}
            defaultSorted={defaultSorted}
        />}
        <SzModal
            show={showAddModal}
            handleClose={() => setShowAddModal(false)}
            size={"xl"}
            centered={true}
            title={t('admin:admin.users.modal.add.title')}
        >
            <AdminUserAdd
                contractId={contractId}
                organizationId={organizationId}
                refreshData={onAddFormValidate}
            />
        </SzModal>
        {pagination && renderPagination(pagination)}
    </div>
}

export default AdminOrganizationUser;
