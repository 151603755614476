import React from "react";
import { connect } from "react-redux";
import { Roles } from "../../constants";
import { Contract } from "../../types";
import { IUser } from "../../store/redux/types";
import { RightsHelper } from "../../helper/RightsHelper";
import LoadingPage from "../../pages/loadingPage";

const CurrentContractManager = (props: { children: any, currentContract: Contract, user: IUser }) => {
    const { currentContract, user } = props;
//     const rights = [Roles.ROLE_SUPER_ADMIN_CONTRACT];
//     RightsHelper.isGranted(user, rights)

    if (!user.logged || !currentContract) {
        return <LoadingPage/>;
    }

    return props.children;
};

const mapStateToProps = (state: { contract: { currentContract: Contract }, user: IUser }) => ({
    currentContract: state.contract.currentContract,
    user: state.user
});

export default connect(mapStateToProps)(CurrentContractManager);
