import Request from './Request';
import { ApiUrls, Constants } from '../constants';
import { Contract } from "../types";

type PostContractOrganization = {
    category: string[],
    area: string[],
    label: string,
    type: string,
    organizationContractService: string[]
};

export class OrganizationService {
    public static getOrganization(organizationId: string) {
        const url = ApiUrls.ORGANIZATION.replace(":organizationId", organizationId);
        return Request.get(url, false, true)
            .then(response => response.data);
    }

    public static getOrganizations(contractId: string, type?: string, q?: string, currentPage: number = Constants.DEFAULT_CURRENT_PAGE, itemsPerPage: number = Constants.DEFAULT_PER_PAGE) {
        const body = {
            q,
            type,
            currentPage,
            itemsPerPage
        }
        const url = ApiUrls.ORGANIZATIONS.replace(":contractId", contractId);
        return Request.getWithParams(url, body, false, true);
    }

    public static createOrganization(contractId: string, organization: PostContractOrganization) {
        const url = ApiUrls.ORGANIZATIONS.replace(":contractId", contractId);
        return Request.post(url, organization, false, true);
    }

    public static getOrganizationUsers(organizationId: string, currentPage : number, itemsPerPage: number) {
        const url = ApiUrls.ORGANIZATION_USERS.replace(":organizationId", organizationId);
        const body = {
            itemsPerPage,
            currentPage
        }
        return Request.getWithParams(url, body, false, true);
    }

    public static getOrganizationAreas(organizationId: string, currentPage : number, itemsPerPage: number) {
        const url = ApiUrls.ORGANIZATION_AREAS.replace(":organizationId", organizationId);
        const body = {
            itemsPerPage,
            currentPage
        }
        return Request.getWithParams(url, body, false, true);
    }

    public static getOrganizationServices(organizationId: string, currentPage: number = 1, itemsPerPage: number = 20) {
        const url = ApiUrls.ORGANIZATION_SERVICES.replace(":organizationId", organizationId);
        const body = {
            currentPage: currentPage,
            itemsPerPage: itemsPerPage
        }
        return Request.getWithParams(url, body, false, true);
    }

    public static updateOrganizationAreas(organizationId: string, areas: string[]) {
        const url = ApiUrls.ORGANIZATION_AREAS.replace(":organizationId", organizationId);
        const body = {
            area: areas
        }

        return Request.put(url, body);
    }

    public static updateOrganizationServices(organizationId: string, contractServices: string[]) {
        const url = ApiUrls.UPDATE_ORGANIZATION_SERVICES.replace(":organizationId", organizationId);
        const body = {
            contractService: contractServices
        }

        return Request.post(url, body);
    }

    public static getOrganizationNbUsers(organizationIds: string[], contractId: string)
    {
        const url = ApiUrls.ORGANIZATIONS_NB_USER.replace(':contractId', contractId);
        const body = {
            organizations: organizationIds,
            countOnly: true,
        }

        return Request.getWithParams(url, body);
    }

    public static deleteOrganizationService(organizationId: string, serviceCode: string)
    {
        const url = ApiUrls.ORGANIZATION_SERVICE_DELETE.replace(':organizationId', organizationId).replace(':serviceCode', serviceCode);
        return Request.delete(url, [], false, false);
    }

    public static deleteOrganization(organizationId: string)
    {
        const url = ApiUrls.ORGANIZATION.replace(":organizationId", organizationId);
        return Request.delete(url, [], false, false);
    }

    public static getOrganizationsByUserId(userId: string, organizationType?: string, currentPage?: number, itemsPerPage?: number, contractId?: string)
    {
        const url = ApiUrls.ORGANIZATION_BY_USER.replace(':userId', userId);
        const body = {
            organizationType: organizationType ?? null,
            currentPage: currentPage ?? 1,
            itemsPerPage: itemsPerPage ?? Constants.DEFAULT_PER_PAGE,
            contractId: contractId ?? null
        }

        return Request.getWithParams(url, body);
    }
}
