import i18next from "i18next";
import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { icons } from "../../../config";
import { mimeMap } from "../../../config/constants";
import { SearchActions } from "../../../store/redux/actions";
import DataNotReady from "../../Utils/messages/dataNotReady";
import PdfViewer from "../../Utils/pdfViewer";
import "../detail.scss";

interface IAttachmentsProps {
  attachments: Array<{
    file: any,
  }>;
}

const SignalDocuments = (props: IAttachmentsProps) => {
  const { t } = useTranslation();
  const [titlePDF, setTitlePDF] = useState("");
  const [uriPDF, setUriPDF] = useState("");
  const [documentLength, setDocumentLength] = useState(0);
  const [PDFViewerIsActive, setPDFViewerIsActive] = useState(false);

  const showPDFViewer = (document: any) => {
    setTitlePDF(getDocumentTitle(document.file));
    setUriPDF(getDownloadUrl(document));
    setPDFViewerIsActive(!PDFViewerIsActive);
    setDocumentLength(document.file.size);
    ReactGA.event({ action: `Pdf viewer: ${getDocumentTitle(document.file)}`, category: "PDF" });
  };

  const isPDF = (document: any, mimeType: string): boolean => {
    return (
      !!getDownloadUrl(document) &&
      mimeType === "application/pdf"
    );
  };

  const getDownloadUrl = (document: any) => {
    const ext = transformMimeToExt(document.file.mimeType) ? "." + transformMimeToExt(document.file.mimeType) : "";
    const filename = document.file.originalFileName + ext;
    const token = localStorage.getItem("tokenDigitalTwin");
    return document.downloadUrl + "?filename=" + filename + "&token=" + token;
  };

  const handleClick = (document: any) => {
    if (isPDF(document, document.file.mimeType)) {
      showPDFViewer(document);
    } else {
      window.open(getDownloadUrl(document), "_blank");
    }
  };

  const getFileIcon = (mimeType: string) => {
    const ext = transformMimeToExt(mimeType);
    switch (ext) {
      case "pdf":
        return icons["icon-pdf"];
      case "doc":
      case "docx":
        return icons["icon-doc"];
      case "mp4":
        return icons["icon-itv"];
      default:
        return icons["icon-file"];
    }
  };

  const transformMimeToExt = (mimeType: string) => {
    const ext = Object.keys(mimeMap).find(
      (key) => key === mimeType,
    );

    return typeof ext !== "undefined" ? mimeMap[ext] : null;
  };

  if (!props.attachments || props.attachments.length === 0) {
    return (<DataNotReady />);
  }

  return (
    <>
      {PDFViewerIsActive && (
        <PdfViewer
          title={titlePDF}
          uri={uriPDF}
          visible={PDFViewerIsActive}
          handleClosePdfViewer={() => setPDFViewerIsActive(!PDFViewerIsActive)}
          documentLength={documentLength ? documentLength : 0}
        />
      )}
      <Row className={"gaia-detail-result--documents-container"}>
        {props.attachments.map((doc: any) => {
          return (
            <Col
              onClick={() => handleClick(doc)}
              sm="3"
              className="text-center gaia-detail-result--documents-container--doc"
            >
              <img
                className="gaia-detail-result--documents-container--doc-icon"
                alt=""
                src={getFileIcon(doc.file.mimeType)}
              />
              <span className="gaia-detail-result--documents-container--doc-title">{getDocumentTitle(doc.file)}</span>
            </Col>
          );
        })}

      </Row>
    </>
  );
};

export const getDocumentTitle =
  (document: { "originalFileName"?: string }): string => {
    if (document.originalFileName && document.originalFileName.length > 0) {
      return document.originalFileName;
    }
    return i18next.t("no-title-document");
  };

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(SearchActions, dispatch),
});

export default connect(null, mapDispatchToProps)(SignalDocuments);
