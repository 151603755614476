import React, { ReactElement, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzAlert, SzCheckbox, SzDatePicker, SzInput, SzSelect } from "@suezenv/react-theme-components";
import { bindActionCreators } from "redux";
import {
  calculateCityCentroid,
  cities, citiesBoundingBoxes,
  conformity,
  conformityCheckType,
  controlType,
  currentState,
  emOperatingSector,
  majorFaultType,
  networkOperatingSector,
  nonConformOther,
  propertyType,
  thematicMapGeoserverBVsColors,
  veConformity,
  vptConformity,
} from "../../../config/report";
import { defaultProperties } from "../../../manager/map/config";
import { goToHome, zoomToCoordinates } from "../../../manager/map/navigation";
import { reportTabs } from "../../../pages/Report";
import { Report as ReportService } from "../../../services/Report";
import { Search as SearchService } from "../../../services/Search";
import { ReportActions, UserActions } from "../../../store/redux/actions";
import { mapOrigin } from "../../../store/redux/reducers/user";
import {
  IReportData,
  IReportFilter,
  IUser,
} from "../../../store/redux/types";
import AutoCompleteInput from "../../Utils/AutoCompleteInput/AutoCompleteInput";
import ListHeader from "./ListHeader";
import {Contract} from "../../../types";
import contract from "../../../store/redux/reducers/contract";

const ControlListFilters = (props: any): ReactElement => {

  const { t } = useTranslation();

  let conformityCheckTypeOptions = Object.entries(conformityCheckType).map((item: any) => {
    if (isNaN(parseInt(item[1]))) {
      return {label: t(`report:conformityCheckType:${item[1]}`), value: item[1]};
    }
  });
  conformityCheckTypeOptions =  conformityCheckTypeOptions.filter(function(e) { return e !== undefined })
  const currentStateOptions = Object.entries(currentState).map((item: any) => {
    return { label: t(`report:conformityStatus:${item[1]}`), value: item[1] };
  });

  const cityOptions = Object.entries(props.currentContract.cities).map((item: any) => {
    return { label: item[1]['name'], value: item[1]['name'] };
  });

  const controlTypeOptions = Object.entries(controlType).map((item: any, key: any) => {
    return { label: t(`report:controlType:${item[0]}`), value: item[1] };
  });

  const conformityOptions = Object.entries(conformity).map((item: any, key: any) => {
    return { label: t(`report:conformity:${item[1]}`), value: item[1] };
  });

  const veConformityOptions = Object.entries(veConformity).map((item: any, key: any) => {
    return { label: t(`report:conformity:${item[0]}`), value: item[1] };
  });

  const vptConformityOptions = Object.entries(vptConformity).map((item: any, key: any) => {
    return { label: t(`report:conformity:${item[0]}`), value: item[1] };
  });

  const propertyTypeOptions = Object.entries(propertyType).map((item: any) => {
    return { label: t(`report:propertyType:${item[0]}`), value: item[1] };
  });

  const emOperatingSectorOptions = Object.entries(emOperatingSector).map((item: any) => {
    return { label: item[1].replace(/_|;/g, " "), value: item[1] };
  });

  const networkOperatingSectorOptions = Object.entries(networkOperatingSector).map((item: any) => {
    return { label: t(`report:networkOperatingSector:${item[0]}`), value: item[1] };
  });

  const majorFaultTypeOptions = Object.entries(majorFaultType).map((item: any) => {
    return { label: t(`report:majorFaultType:${item[0]}`), value: item[0] };
  });

  const geoserverBVsColorsOptions = Object.entries(thematicMapGeoserverBVsColors).map((item: any) => {
    return { label: t(`thematicMap:geoserverBVs:${item[0]}`), value: item[1] };
  });

  const autoCompleteMinLength = 3;
  const [autocompleteOptions, setAutocompleteOptions] = useState([""]);

  const [localAddress, setAddress] = useState(props.filters.address);
  const [localConnectionNumber, setConnectionNumber] = useState(props.filters.connectionNumber);
  const [localEquipment, setEquipment] = useState(props.filters.equipment);
  const [localConformity, setConformity] = useState<any[]>(props.filters.conformity);
  const [localControlType, setControlType] = useState<any[]>(props.filters.controlType);
  const [localVptConformity, setVptConformity] = useState<any[]>(props.filters.vptConformity);
  const [localVeConformity, setVeConformity] = useState<any[]>(props.filters.veConformity);
  const [localNonConformOther, setNonConformOther] = useState<boolean>(false);
  const [localConformityCheckType, setConformityCheckType] = useState<any[]>(props.filters.conformityCheckType);
  const [localCurrentState, setCurrentState] = useState<any[]>(props.filters.currentState);
  const [localCity, setCity] = useState<any[]>(props.filters.city);
  const [localMajorFaultType, setMajorFaultType] = useState<any[]>(props.filters.majorFaultType);
  const [localPropertyType, setPropertyType] = useState<any[]>(props.filters.propertyType);
  const [localEmOperatingSector, setEmOperatingSector] = useState<any[]>(props.filters.emOperatingSector);
  const [
    localNetworkOperatingSector,
    setNetworkOperatingSector,
  ] = useState<any[]>(props.filters.networkOperatingSector);
  const [startDate, setStartDate] = useState<Date | undefined>(props.filters.startDate);
  const [endDate, setEndDate] = useState<Date | undefined>(props.filters.endDate);
  const [errorShow, setErrorShow] = useState(false);
  const [localColors, setLocalColors] = useState<any[]>(props.geoserverBVsColors);
  const [
    plannedStartDate,
    setPlannedStartDate,
  ] = useState<Date | undefined>(props.filters.plannedStartDate);
  const [
    plannedEndDate,
    setPlannedEndDate,
  ] = useState<Date | undefined>(props.filters.plannedEndDate);

  const [
    conformityIsDisabled,
    setConformityIsDisabled,
  ] = useState(!(localControlType.length === 1 && localControlType[0].value === "CONFORMITE"));
  const [
    veConformityIsDisabled,
    setVeConformityIsDisabled,
  ] = useState(!(localControlType.length === 1 && localControlType[0].value === "VE"));
  const [
    vptConformityIsDisabled,
    setVptConformityIsDisabled,
  ] = useState(!(localControlType.length === 1 && localControlType[0].value === "VPT"));

  const [
    majorFaultTypeIsDisabled,
    setMajorFaultTypeIsDisabled,
  ] = useState(!(localConformity.length === 1 && localConformity[0].value === "non-conforme"));

  const isFiltersEmpty = () => {
    return ((!localCity || localCity.length === 0) &&
      (!localConformityCheckType || localConformityCheckType.length === 0) &&
      (!localConformity || localConformity.length === 0) &&
      (!localVeConformity || localVeConformity.length === 0) &&
      (!localVptConformity || localVptConformity.length === 0) &&
      (!localControlType || localControlType.length === 0) &&
      (localNonConformOther === false) &&
      (!localCurrentState || localCurrentState.length === 0) &&
      (!localMajorFaultType || localMajorFaultType.length === 0) &&
      (!localPropertyType || localPropertyType.length === 0) &&
      (!localNetworkOperatingSector || localNetworkOperatingSector.length === 0) &&
      (!localEmOperatingSector || localEmOperatingSector.length === 0) &&
      (!localConnectionNumber || localConnectionNumber === "") &&
      (!localAddress || localAddress === "") &&
      (!startDate) && (!endDate) &&
      (!plannedStartDate) && (!plannedEndDate) &&
      (!localColors || localColors.length === 0));
  };
  const [areFiltersEmpty, setAreFiltersEmpty] = useState(isFiltersEmpty());
  useEffect(() => {
    setAreFiltersEmpty(
      isFiltersEmpty(),
    );
  }, [
    localCity,
    localConformityCheckType,
    localConformity,
    localNonConformOther,
    localCurrentState,
    localMajorFaultType,
    localPropertyType,
    localNetworkOperatingSector,
    localEmOperatingSector,
    localConnectionNumber,
    localAddress,
    startDate,
    endDate,
    plannedStartDate,
    plannedEndDate,
    localColors,
    localVeConformity,
    localVptConformity,
    localControlType,
  ]);

  const updateDate = (newDate: Date, startOrEnd: string) => {
    if (startDate && endDate) {
      if ("start" === startOrEnd && newDate.getTime() <= endDate.getTime()) {
        setStartDate(newDate);
      } else if (newDate.getTime() >= startDate.getTime()) {
        setEndDate(newDate);
      }
    } else {
      if ("start" === startOrEnd) {
        setStartDate(newDate);
      } else {
        setEndDate(newDate);
      }
    }
  };

  const updatePlannedDate = (newDate: Date, startOrEnd: string) => {
    if (plannedStartDate && plannedEndDate) {
      if ("start" === startOrEnd && newDate.getTime() <= plannedEndDate.getTime()) {
        setPlannedStartDate(newDate);
      } else if (newDate.getTime() >= plannedStartDate.getTime()) {
        setPlannedEndDate(newDate);
      }
    } else {
      if ("start" === startOrEnd) {
        setPlannedStartDate(newDate);
      } else {
        setPlannedEndDate(newDate);
      }
    }
  };

  useEffect(() => {
    if (props.filters.address) {
      setAddress(props.filters.address);
    }
    if (props.filters.conformity) {
      const conformityValues = conformityOptions.filter((item: any) => {
        return props.filters.conformity.includes(item.value);
      });
      setConformity(conformityValues);
      setMajorFaultTypeIsDisabled(
        !(
          conformityValues.length === 1 &&
          conformityValues[0].value === "non-conforme"
        ),
      );
    }
    if (props.filters.controlType) {
      const controlTypeValues = controlTypeOptions.filter((item: any) => {
        return props.filters.controlType.includes(item.value);
      });
      setControlType(controlTypeValues);
      setConformityIsDisabled(
        !(controlTypeValues.length === 1 && controlTypeValues[0].value === "CONFORMITE"),
      );
      setVeConformityIsDisabled(
        !(controlTypeValues.length === 1 && controlTypeValues[0].value === "VE"),
      );
      setVptConformityIsDisabled(
        !(controlTypeValues.length === 1 && controlTypeValues[0].value === "VPT"),
      );
    }
    if (props.filters.veConformity) {
      const veConformityValues = veConformityOptions.filter((item: any) => {
        return props.filters.veConformity.includes(item.value);
      });
      setVeConformity(veConformityValues);
    }
    if (props.filters.vptConformity) {
      const vptConformityValues = vptConformityOptions.filter((item: any) => {
        return props.filters.vptConformity.includes(item.value);
      });
      setVptConformity(vptConformityValues);
    }
    if (props.filters.conformityCheckType) {
      const conformityCheckTypeValues = conformityCheckTypeOptions.filter((item: any) => {
        if (undefined !== item) {
          return props.filters.conformityCheckType.includes(item.value);
        }
      });
      setConformityCheckType(conformityCheckTypeValues);
    }
    if (props.filters.currentState) {
      const currentStateValues = currentStateOptions.filter((item: any) => {
        return props.filters.currentState.includes(item.value);
      });
      setCurrentState(currentStateValues);
    }
    if (props.filters.city) {
      const cityValues = cityOptions.filter((item: any) => {
        return props.filters.city.includes(item.value);
      });
      setCity(cityValues);
    }
    if (props.filters.majorFaultType) {
      const majorFaultTypeValues = majorFaultTypeOptions.filter((item: any) => {
        return props.filters.majorFaultType.includes(item.value);
      });
      setMajorFaultType(majorFaultTypeValues);
      setNonConformOther(props.filters.majorFaultType.includes("none") ? true : false);
    }
    if (props.filters.propertyType) {
      const propertyTypeValues = propertyTypeOptions.filter((item: any) => {
        return props.filters.propertyType.includes(item.value);
      });
      setPropertyType(propertyTypeValues);
    }

    if (props.filters.emOperatingSector) {
      const emOperatingSectorValues = emOperatingSectorOptions.filter((item: any) => {
        return props.filters.emOperatingSector.includes(item.value);
      });
      setEmOperatingSector(emOperatingSectorValues);
    }

    if (props.filters.networkOperatingSector) {
      const networkOperatingSectorValues = networkOperatingSectorOptions.filter((item: any) => {
        return props.filters.networkOperatingSector.includes(item.value);
      });
      setNetworkOperatingSector(networkOperatingSectorValues);
    }

    if (props.filters.startDate) {
      if (typeof props.filters.startDate !== "object") {
        const startingDate = new Date(props.filters.startDate);
        setStartDate(startingDate);
      }
    }
    if (props.filters.endDate) {
      if (typeof props.filters.endDate !== "object") {
        const endingDate = new Date(props.filters.endDate);
        setEndDate(endingDate);
      }
    }

    if (props.filters.plannedStartDate) {
      if (typeof props.filters.plannedStartDate !== "object") {
        const startingDate = new Date(props.filters.plannedStartDate);
        setPlannedStartDate(startingDate);
      }
    }
    if (props.filters.plannedEndDate) {
      if (typeof props.filters.plannedEndDate !== "object") {
        const endingDate = new Date(props.filters.plannedEndDate);
        setPlannedEndDate(endingDate);
      }
    }
    if (props.geoserverBVsColors) {
      const geoserverBVsColorsValues = geoserverBVsColorsOptions.filter((item: any) => {
        return props.geoserverBVsColors.includes(item.value);
      });
      setLocalColors(geoserverBVsColorsValues);
    }
  }, [props.currentFavFilter, props.listType]);

  const updateValues = (values: [] | null, callback: any) => {
    if (null !== values) {
      callback(values.map((item: any) => {
        return item;
      }));
    } else {
      callback([]);
    }
  };

  useEffect(() => {
    if (areFiltersEmpty) {
      resetAction();
    }
  }, [areFiltersEmpty]);

  const filterAction = () => {
    if (startDate && endDate && endDate < startDate) {
      setErrorShow(true);
    } else if (plannedStartDate && plannedEndDate && plannedEndDate < plannedStartDate) {
      setErrorShow(true);
    } else {
      goToHome();
      const filters: {
        vptConformity: any[];
        address: string;
        conformityCheckType: any[];
        city: any[];
        endDate: Date | undefined;
        plannedEndDate: Date | undefined;
        emOperatingSector: any[];
        controlType: any[];
        connectionNumber: unknown;
        equipment: unknown;
        plannedStartDate: Date | undefined;
        veConformity: any[];
        propertyType: any[];
        conformity: any[];
        majorFaultType: any[];
        currentState: any[];
        networkOperatingSector: any[];
        startDate: Date | undefined
      } = {
        address: localAddress,
        city: localCity.map((item) => item.value.replace(/-/g, " ").toUpperCase()),
        controlType: localControlType.map((item) => item.value),
        conformity: localConformity.map((item) => item.value),
        veConformity: localVeConformity.map((item) => item.value),
        vptConformity: localVptConformity.map((item) => item.value),
        conformityCheckType: localConformityCheckType.map((item) => item.value),
        connectionNumber: localConnectionNumber,
        equipment: localEquipment,
        currentState: localCurrentState.map((item) => item.value),
        endDate,
        plannedEndDate,
        plannedStartDate,
        majorFaultType: localMajorFaultType.map((item) => item.value)
          .concat(localNonConformOther ? ["none"] : []),
        propertyType: localPropertyType.map((item) => item.value),
        emOperatingSector: localEmOperatingSector.map((item) => item.value),
        networkOperatingSector: localNetworkOperatingSector.map((item) => item.value),
        startDate,
      };
      let colors = [];
      if (localColors && localColors.length === 1) {
        colors = localColors.map((item) => item.value);
      }
      if (filters.city && filters.city.length === 1) {
        // const coordinates = calculateCityCentroid(citiesBoundingBoxes[filters.city[0]].extent);
        // props.setReportMapPosition([coordinates.latitude, coordinates.longitude, 10000]);
        // if (props.currentTab === reportTabs.map) {
        //   zoomToCoordinates(coordinates.latitude, coordinates.longitude, 10000);
        // }
      } else if (filters.city && filters.city.length > 1) {
        props.setReportMapPosition(mapOrigin);
        // if (props.currentTab === reportTabs.map) {
        //   zoomToCoordinates(mapOrigin[0], mapOrigin[1], mapOrigin[2]);
        // }
      } else if (filters.address && (
        (!filters.city || filters.city.length === 0) &&
        (!filters.veConformity || filters.veConformity.length === 0) &&
        (!filters.vptConformity || filters.vptConformity.length === 0) &&
        (!filters.controlType || filters.controlType.length === 0) &&
        (!filters.conformityCheckType || filters.conformityCheckType.length === 0) &&
        (!filters.conformity || filters.conformity.length === 0) &&
        (!filters.currentState || filters.currentState.length === 0) &&
        (!filters.majorFaultType || filters.majorFaultType.length === 0) &&
        (!filters.propertyType || filters.propertyType.length === 0) &&
        (!filters.emOperatingSector || filters.emOperatingSector.length === 0) &&
        (!filters.networkOperatingSector || filters.networkOperatingSector.length === 0) &&
        (!filters.connectionNumber || filters.connectionNumber === "")
      )
      ) {
        // SearchService.getAddressCoordinates(filters.address).then((res: any) => {
        //   if (res.coordinates && res.cityCode.match(/92/g)) {
        //     props.setReportMapPosition([res.coordinates[1], res.coordinates[0], defaultProperties.scale]);
        //     // if (props.currentTab === reportTabs.map) {
        //     //   zoomToCoordinates(res.coordinates[1], res.coordinates[0]);
        //     // }
        //   } else {
        //     props.setReportMapPosition(mapOrigin);
        //     // if (props.currentTab === reportTabs.map) {
        //     //   zoomToCoordinates(mapOrigin[0], mapOrigin[1], mapOrigin[2]);
        //     // }
        //   }
        //
        // });
      }
      props.updateFilters(filters);
      if (colors) {
        // props.getGeoserverBV(colors);
      }
    }
  };

  const resetAction = () => {
    setAddress("");
    setControlType([]);
    setConformity([]);
    setVeConformity([]);
    setVptConformity([]);
    setNonConformOther(false);
    setConformityCheckType([]);
    setConnectionNumber("");
    setCurrentState([]);
    setCity([]);
    setMajorFaultType([]);
    setPropertyType([]);
    setStartDate(undefined);
    setEndDate(undefined);
    setPlannedStartDate(undefined);
    setPlannedEndDate(undefined);
    setLocalColors([]);
    setNetworkOperatingSector([]);
    setEmOperatingSector([]);
    setConformityIsDisabled(true);
    setVeConformityIsDisabled(true);
    setVptConformityIsDisabled(true);
    setMajorFaultTypeIsDisabled(true);
    const filters: IReportFilter = {
      address: "",
      city: [],
      controlType: [],
      conformity: [],
      veConformity: [],
      vptConformity: [],
      conformityCheckType: [],
      connectionNumber: "",
      currentState: [],
      emOperatingSector: [],
      endDate: null,
      plannedEndDate: undefined,
      plannedStartDate: undefined,
      majorFaultType: [],
      networkOperatingSector: [],
      propertyType: [],
      startDate: null,
    };
    // props.getGeoserverBV();
    props.updateFilters(filters);
    goToHome();
    props.setReportMapPosition(mapOrigin);
    ReactGA.event({ action: `Reset filters`, category: "Report" });
  };

  const autocompleteAddress = (address: string) => {
    return ReportService.getReportAddressAutocomplete(address, props.currentContract.id)
      .then((data: Array<{ address: string }>) => {
        const options = data.map((item: { address: string }) => {
          return item.address;
        });
        setAutocompleteOptions(options);
      });
  };

  return (
    <div className={"report--menu--tabs_list"}>
      <ListHeader
        tableHeader={props.tableHeader}
        setWeight={props.setWeight}
        filterAction={filterAction}
        resetAction={resetAction}
        areFiltersEmpty={areFiltersEmpty}
      />
      <div className={"report--menu--tabs_list--filter-container"}>
        <div className={"input_group pt-15 pb-0"}>
          {errorShow &&
            <SzAlert variant="danger">
                the end date must be set and greater than the start date
            </SzAlert>
          }
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:control.numberPdcLong")}</span>
          </div>
          <SzInput
            className="mb-0 border-secondary connectionNumber"
            placeholder={""}
            value={localConnectionNumber}
            onChange={(event: { target: { value: string } }) => setConnectionNumber(event.target.value)}
            handleClear={() => setConnectionNumber("")}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:control.datePrevue")}</span>
          </div>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.from")}</span>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.to")}</span>
          </div>
          <SzDatePicker
            dateRange
            endDate={plannedEndDate}
            endMinDate={undefined}
            startDate={plannedStartDate}
            startMinDate={undefined}
            onChangeEndDate={(value: Date) => {
              value.setHours(12, 0, 0, 0);
              updatePlannedDate(value, "end");
            }}
            onChangeStartDate={(value: Date) => {
              value.setHours(12, 0, 0, 0);
              updatePlannedDate(value, "start");
            }}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:network.conformityCheckDate")}</span>
          </div>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.from")}</span>
            <span className={"report--menu--tabs_list--filter-sub-label"}>{t("report:date.to")}</span>
          </div>
          <SzDatePicker
            dateRange
            endDate={endDate}
            endMinDate={undefined}
            startDate={startDate}
            startMinDate={undefined}
            onChangeEndDate={(value: Date) => {
              value.setHours(12, 0, 0, 0);
              updateDate(value, "end");
            }}
            onChangeStartDate={(value: Date) => {
              value.setHours(12, 0, 0, 0);
              updateDate(value, "start");
            }}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:city")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary city"
            isSearchable
            isClearable
            isMulti
            id="city"
            name="city"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setCity);
            }}
            options={cityOptions}
            value={localCity}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:address")}</span>
          </div>
          <AutoCompleteInput
            autoCompleteOptions={autocompleteOptions}
            className={"address"}
            debounce={500}
            handler={setAddress}
            placeholder={""}
            minLength={autoCompleteMinLength}
            searchHandler={autocompleteAddress}
            value={localAddress}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:propertyType.label")}</span>
          </div>
          <SzSelect
              className="mb-0 border-secondary propertyType"
              isSearchable
              isClearable
              isMulti
              id="propertyType"
              name="propertyType"
              placeholder={""}
              onChange={(values: any) => {
                updateValues(values, setPropertyType);
              }}
              options={propertyTypeOptions}
              value={localPropertyType}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span className={"report--menu--tabs_list--filter-label"}>{t("report:control.ecaTypeCode")}</span>
          </div>
          <SzSelect
            className="mb-0 border-secondary visitType"
            id="visitType"
            isClearable
            isMulti
            isSearchable
            name="visitType"
            placeholder={""}
            onChange={(values: any) => {
              updateValues(values, setConformityCheckType);
            }}
            options={conformityCheckTypeOptions}
            value={localConformityCheckType}
          />
        </div>
        <div className={"input_group pt-15 pb-0"}>
          <div className={"report--menu--tabs_list--filter-label_container"}>
            <span
                className={`report--menu--tabs_list--filter-label`}
            >
              {t("report:control.conformite")}
            </span>
          </div>
          <SzSelect
              className="mb-0 border-secondary conformity"
              isSearchable
              isClearable
              isMulti
              id="conformity"
              name="conformity"
              placeholder={""}
              onChange={(values: any) => {
                updateValues(values, setConformity);
              }}
              options={conformityOptions}
              value={localConformity}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: { report: IReportData, user: IUser, contract: { currentContract: Contract }  }) => {
  return {
    currentFavFilter: state.report.currentFavFilter,
    currentPage: state.report.currentPage,
    currentTab: state.report.currentTab,
    filters: state.report.filters,
    listType: state.user.listType,
    currentContract: state.contract.currentContract
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...ReportActions, ...UserActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ControlListFilters);
