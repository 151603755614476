import React, { ReactElement, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Search as SearchService } from "../../../services/Search";
import { SearchActions } from "../../../store/redux/actions";
import { IFilter, ISearch } from "../../../store/redux/types";
import Suggestions, { suggestionType } from "./suggestions";
import useDebouncedSearch from "./useDebouncedSearch";

interface IAutocompleteSuggestions {
  debounce: number;
  getSearch: (search: ISearch, filters?: IFilter[]) => any;
  search: ISearch;
  minAutoCompleteLength: number;
  hide: () => void;
}

const AutoCompleteSuggestion = (props: IAutocompleteSuggestions): ReactElement => {

  const {t} = useTranslation();
  const [suggestions, setSuggestions] = useState<Array<{keywords: string, date: Date}>>([]);
  const [loading, setLoading] = useState(false);

  const autocompleteAddress = (address: string) => {
    if (address.length >= props.minAutoCompleteLength) {
      setLoading(true);
      SearchService.getAddressAutoComplete(address)
        .then((data: Array<{ address: string }>) => {
          const options = data.map((item: { address: string }) => {
            return {keywords : item.address, date: new Date()};
          });
          setSuggestions(options);
          setLoading(false);
        }).catch(e => {
          setSuggestions([]);
        });
    }
  };

  useEffect(() => {
    setInputText(props.search.userInput);
  }, [props.search.userInput]);

  const useAutocomplete = () => useDebouncedSearch(autocompleteAddress, props.debounce);
  const { setInputText } = useAutocomplete();

  return <Suggestions
    title={t("suggestions")}
    type={suggestionType.AUTOCOMPLETE}
    hide={props.hide}
    loading={loading}
    icon={"search"}
    suggestions={suggestions}
  />;
};

const mapStateToProps = (state: { search: ISearch }) => {
  return {
    search: state.search,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({...SearchActions}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AutoCompleteSuggestion);
