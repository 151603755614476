import i18next from "i18next";
import React, { useEffect, useState } from "react";
import { Nav, Tab } from "react-bootstrap";
// import ReactGA from "react-ga";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { IAssetDetailsTabProperty } from "../../config/asset.interfaces";
import { ISearch } from "../../store/redux/types";
import { Contract } from "../../types";
import Curage from "./curage";
import Documents from "./documents";
import History from "./history";
import Itv from "./itv";
import RelatedAssets from "./related-assets";
import SiteIndustriel from "./siteIndustriel";
import Specifics from "./specifics";
import Synthesis from "./synthesis";
import Work3d from "./work3d";

interface IDetailContainer {
  shownItem: any;
  shownEquipementId?: string;
  detail?: any;
  mapping?: any;
  contract?: any;
}

const DetailContainer = (props: IDetailContainer) => {
  const { shownItem, mapping, contract, detail } = props;
  const { t } = useTranslation();
  const [tabKey, setTabKey] = useState(detail.defaultTab ? detail.defaultTab : "synthesis");
  const itemId = shownItem["asset:idApid"] || 0;
  const ifcFileId = shownItem["3dstrucuture:3d_ifc_list"] ? shownItem["3dstrucuture:3d_ifc_list"][0]["3dstrucuture:3d_ifc_file_id"] : null;
  const itemMap = mapping[shownItem["index:name_ori"]];
  const [ shownItemTitle, setShownItemTitle] = useState('');

  useEffect(() => {
    if (detail.defaultTab) {
      setTabKey(detail.defaultTab);
    }
  }, [detail.defaultTab]);

  useEffect(() => {
    var shownItemTitle = getDetailTitle();
    setShownItemTitle(shownItemTitle);
  }, [props]);

  const unique = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };

  const getConformityStatus = (a: any) => {
    switch (a["control:control_type"]) {
      case "CONFORMITE":
        return t(`asset:conformity.${a["control:controlStatus_id"]}`);
        break;

      default:
        if (a["control:result"] && a["control:result"] === "1") {
          return t(`asset:conformity.conforme`);
        } else {
          return t(`asset:conformity.non-conforme`);
        }
        break;
    }
  };
  const getSiteIndusNumber = (a: any) => {
    const siteIndusNumber: any = [];
    a.forEach((site: any) => {
      siteIndusNumber.push(site.ID_CD92);
    });
    return siteIndusNumber.filter(unique).join("|");
  };
  const getSiteIndusConformity = (a: any) => {
    const siteIndusConformity: any = [];
    a.forEach((site: any) => {
      siteIndusConformity.push(site.NomEtab + ":" + t(`asset:siteIndustriel.conformity.${site.conformity}`));
    });
    return siteIndusConformity.filter(unique).join("|");
  };

  const getDisplayedValue = (item: any, property: IAssetDetailsTabProperty): string => {
    let val = "";
    let callback = (e: string) => '';
    if (property && property.callback !== undefined) {
      callback = (value: string) => property.callback.replace('${value}', value);
    }
    switch (property.type) {
      case "calculated":
        val =
          typeof callback !== "undefined" &&
            typeof property.value === "string" &&
            typeof property.attribute === "undefined" &&
            item[property.value] !== null ? i18next.t(callback(item[property.value]))
            : (
              typeof callback !== "undefined" &&
                typeof property.value === "string" &&
                typeof property.attribute !== "undefined" &&
                typeof item[property.value][0] !== "undefined" ?
                i18next.t(
                  callback(item[property.value][0][property.attribute] !== "" ?
                    item[property.value][0][property.attribute] :
                    "",
                  ),
                )
                : ""
            );
        break;
      case "conformity":
        val = item.controls[0] ? getConformityStatus(item.controls[0]) : "";
        break;
      case "childrenArray":
        val = "";
        if (typeof property.value === "string" && item["asset:plt"] !== undefined) {
          val = typeof item["asset:plt"][property.value] !== undefined ?
              item["asset:plt"][property.value] : '';
          val = Number(val).toFixed(2);
        }
        break;
      case "siteIndusNumber":
        val = item.sites[0] ? getSiteIndusNumber(item.sites) : "";
        break;
      case "siteIndusConformity":
        val = item.sites[0] ? getSiteIndusConformity(item.sites) : "";
        break;
      case "string":
        val =
          typeof property.value === "string" && item[property.value] !== null
            ? i18next.t(item[property.value])
            : "";
            // : property.value.map((str: string) => i18next.t(`asset:${item[str]}`)).join(" ");
        break;
      default:
        break;
    }
    if (val === "") {
      return "";
    }
    return (
      (typeof property.prefix !== "undefined" ? property.prefix + " " : "") +
      val +
      (typeof property.suffix !== "undefined" ? " " + property.suffix : "")
    );
  };

  const handleSelect = (eventKey: string, test: any) => {
    // ReactGA.event({
    //   action: `Voir onglet: ${eventKey} pour l'objet ${itemId} `,
    //   category: "Search",
    // });
  };

  const getDetailTitle = () => {
    if (shownItem === '') {
      return t("loading");
    }
    if ("conformity" !== shownItem["index:name_ori"]) {
      let detailId = "asset:asset_id";
      if (contract.label === "SIAHVY" && shownItem["index:name"] === "asset:A_REGARD_point") {
        detailId = "asset:ident";
      }
      let label = shownItem["index:name_ori"];
      if (contract.label === "SIAHVY" && shownItem["index:name_ori"] === "asset:a_traphy_point") {
        label = 'asset:a_traphy_point_siahvy';
      }
      return t(label) + " " + shownItem[detailId];
    } else {
      return t("pdc") + " " + shownItem["idPDC"];
    }
  };

  return (<>
    <div className={"gaia-detail-result--header"}>
      <div className={"item-header"}>
        {shownItem["asset:nom"] && (
          <h1 className="gaia-detail-result--header--primary-title primary-title">
            {shownItem["asset:nom"]}
          </h1>
        )}
        <h1 className="gaia-detail-result--header--primary-title primary-title">
          {shownItemTitle}
        </h1>
        <h3 className="gaia-detail-result--header--secondary-title secondary-title">
          {shownItem["sig:adressecomplete"]}
          {shownItem["adresseComplete"]}
        </h3>
      </div>
      <div className={"item-type"}>
        {(shownItem["3dnetwork:3d_mesh_available"] || shownItem["3dstrucuture:3d_ifc_available"]) &&
          <i
            className="gaia-search-data__item-links-icon material-icons"
          >3d_rotation</i>
        }
        {shownItem["itv:itv_available"] &&
          <i
            className="gaia-search-data__item-links-icon material-icons"
          >videocam</i>
        }
      </div>
    </div>
    <Tab.Container
      id="category"
      activeKey={tabKey}
      onSelect={(k) => {
        setTabKey(k);
      }}
    >
      <Nav className="sz-aside__list gaia-detail-result--nav" onSelect={handleSelect}>
        {itemMap &&
          itemMap.map(({ label }: { label: string }, key: number) => (
            <Nav.Item key={key} className="gaia-search-category text-decoration-none py-2">
              <Nav.Link eventKey={label}>
                <span className="gaia-search-category__item-title">{t(`asset:tabs.${label}`)}</span>
              </Nav.Link>
            </Nav.Item>
          ))}
      </Nav>
      <Tab.Content>
        {itemMap &&
          itemMap.map(({ label }: { label: string }, key: number) => (
            <Tab.Pane key={key} eventKey={label}>
              {(() => {
                switch (label) {
                  case "synthesis":
                    return (
                      <Synthesis
                        item={shownItem}
                        getDisplayedValue={getDisplayedValue}
                        tabLabel={label}
                        mapping={mapping}
                      />
                    );
                  case "specifics":
                    return (
                      <Specifics
                        item={shownItem}
                        getDisplayedValue={getDisplayedValue}
                        tabLabel={label}
                        mapping={mapping}
                      />
                    );
                  case "history":
                    return (
                      <History
                        controls={shownItem.controls}
                        interventions={shownItem.interventions}
                        siteIndustriels={shownItem.sites}
                        listeAsset={shownItem.listeAsset}
                      />
                    );
                  case "documents":
                    return <Documents documents={shownItem.documents} />;
                  case "curage":
                    return <Curage interventions={shownItem.interventions} />;
                  case "relatedAsset":
                    return <RelatedAssets />;
                  case "itvs":
                    return <Itv object={shownItem} />;
                  case "work3d":
                    return (
                      <Work3d
                        ppv3dData={shownItem.ppv3dData}
                        ifcFileId={ifcFileId}
                      />
                    );
                  case "siteIndustriel":
                    return (
                      <SiteIndustriel
                        siteIndustriels={shownItem.sites}
                      />
                    );
                  default:
                    return "";
                }
              })()}
            </Tab.Pane>
          ))}
        {!itemMap && <span className={"tab-nodata"}>{t("no-data")}</span>}
      </Tab.Content>
    </Tab.Container>
  </>
  );
};

const mapStateToProps = (state: { search: ISearch, contract: { currentContract: Contract } }) => {
  return {
    shownEquipementId: state.search.detail.shownEquipementId,
    detail: state.search.detail,
    contract: state.contract.currentContract,
  };
};

export default connect(mapStateToProps)(DetailContainer);
