import React, { useEffect } from "react";
import { connect } from "react-redux";
import { SzButton } from "@suezenv/react-theme-components";
import { bindActionCreators } from "redux";
import { smartshapeConfig } from "../../config/";
import { Smartshape as SmartshapeService } from "../../services/Smartshape";
import { SearchActions } from "../../store/redux/actions";
import "./smartshape.scss";

function Smartshape(props: any) {
    useEffect(() => {
        if (props.shownDetailItem["3dstrucuture:3d_ifc_available"] && !props.showResultDetail) {
            const iframe = document.getElementById("smartshape") as HTMLIFrameElement;
            window.addEventListener("message", smartShapeMessageHandler, false);
            setTimeout(() => {
                if (iframe.contentWindow) {
                    iframe.contentWindow.postMessage(
                        "",
                        smartshapeConfig.host ? smartshapeConfig.host : "",
                    );
                }
            }, 12000);
        } else {
            window.removeEventListener("message", smartShapeMessageHandler);
        }
        return () => {
            window.removeEventListener("message", smartShapeMessageHandler);
        };
    }, [props.showResultDetail]);

    const smartShapeMessageHandler = (event: any) => {
        if (!event || !event.data || props.showResultDetail) {
            return;
        }
        const message = JSON.parse(event.data);
        const showEquipementDetail = false;
        if (message[0] === "selectedNodes") {
            if (message[1]) {
                const selectedNodes = message[1].split(";");
                // Here showEquipementDetail = false will prevent call to getNodesByEquipement which calls getNodesById to backend /smartshape/
                if (showEquipementDetail) {
                return SmartshapeService.getNodesById(props.shownDetailItem["3dstrucuture:3d_ifc_list"][0]["3dstrucuture:3d_ifc_file_id"], selectedNodes[0])
                    .then((response: any) => {
                        if (response.attributes) {
                            const result = response.attributes.attributes.filter(
                                (attribute: any) => attribute.key === "CodEquip",
                            );
                            if (result[0]) {
                                props.displayEquipementFromSmartshapePlayer(result[0].value);

                            }
                        }
                    });
                }
            }
        }
    };
    const handleClick = () => {
        props.displaySmartshapeFullScreen(!props.isDisplayed);
    };
    const getIdSharedFolder = () => {
        let idSharedFolder = "false";
        if (
            props.shownDetailItem["3dstrucuture:3d_ifc_available"] &&
            props.shownDetailItem["3dstrucuture:3d_ifc_list"]
        ) {
            idSharedFolder =
                props.shownDetailItem["3dstrucuture:3d_ifc_list"][0]["3dstrucuture:3d_ifc_shared_folder_id"];
        } else if (
            props.shownDetailItem["3dnetwork:3d_mesh_available"] &&
            props.shownDetailItem["3dnetwork:3d_mesh_list"]
        ) {
            idSharedFolder = props.shownDetailItem["3dnetwork:3d_mesh_list"][0]["3dnetwork:3d_mesh_shared_folder_id"];
        }
        return idSharedFolder;
    };
    const getIframeUrl = () => {
        let iframeUrl = "false";
        if (
            props.shownDetailItem["3dstrucuture:3d_ifc_available"] &&
            props.shownDetailItem["3dstrucuture:3d_ifc_list"]
        ) {
            iframeUrl = props.shownDetailItem["3dstrucuture:3d_ifc_list"][0]["3dstrucuture:3d_ifc_file_path"];
        } else if (
            props.shownDetailItem["3dnetwork:3d_mesh_available"] &&
            props.shownDetailItem["3dnetwork:3d_mesh_list"]
        ) {
            iframeUrl = props.shownDetailItem["3dnetwork:3d_mesh_list"][0]["3dnetwork:3d_mesh_file_path"];
        }
        return iframeUrl;
    };

    return (
        <>
            <SzButton className="btn-fullscreen"
                onClick={handleClick}
                type="button">
                <i className="material-icons icon-resize">
                    {props.isDisplayed ? "fullscreen_exit" : "fullscreen"}
                </i>
            </SzButton>
            <iframe id="smartshape"
                title="Test smartshape"
                width="100%"
                height="100%"
                src={smartshapeConfig.host + getIdSharedFolder() + getIframeUrl() + "?rel=0&amp;controls=0&amp;showinfo=0&amp;autoplay=1#autoplay=true"}
                allow="autoplay"
                allowFullScreen={true}
                frameBorder="0"
            >
            </iframe>
        </>
    );
}

const mapStateToProps = (state: any) => {
    return {
        isDisplayed: state.search.isDisplayed,
        showResultDetail: state.search.flag.showResultDetail,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(SearchActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Smartshape);
