import { Constants } from "../../../constants";

// actions
const  permissionsData = (permissionsData: any) => ({
  type: Constants.USER_PERMISSIONS,
  payload: permissionsData,
});


export default {
  permissionsData,
};