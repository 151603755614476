import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { SzInput } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { ApplicationConfigurationFormProps } from "../../../../types";


type ApplicationParameters = {
    login: string,
    password: string,
    btnLabel: string
};

const serviceName = "VA_EXTERNAL_SITE_SIGRENEA";

export const VA_EXTERNAL_SITE_SIGRENEA = (props: ApplicationConfigurationFormProps) => {
    const { serviceConfigurations, setServiceConfigurations, owner, ownerType } = props;
    const { t } = useTranslation();
    const [login, setLogin] = useState<string>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.login ?? "" : "");
    const [password, setPassword] = useState<string>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.password ?? "" : "");
    const [btnLabel, setBtnLabel] = useState<string>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.btnLabel ?? "" : "");

    const parameters = useRef<ApplicationParameters>({
        login: login,
        password: password,
        btnLabel: btnLabel
    });

    useEffect(() => {
        parameters.current = { ...parameters.current, login, password, btnLabel }
        if ("undefined" === typeof serviceConfigurations[serviceName] ||
            "undefined" !== typeof serviceConfigurations[serviceName] && (
                parameters.current.login !== serviceConfigurations[serviceName].parameters?.login ||
                parameters.current.password !== serviceConfigurations[serviceName].parameters?.password ||
                parameters.current.btnLabel !== serviceConfigurations[serviceName].parameters?.btnLabel
            )) {
            setServiceConfigurations({
                ...serviceConfigurations, [serviceName]: {
                    name: serviceName,
                    owner: owner,
                    ownerType: ownerType,
                    parameters: {
                        login: parameters.current.login,
                        password: parameters.current.password,
                        btnLabel: parameters.current.btnLabel
                    }
                }
            });
        }
    }, [login, password, btnLabel]);

    return <div>
        <SzInput
            value={login}
            name={`${serviceName}.login`}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setLogin(e.target.value)}
            label={t('admin:admin.services.configuration.form.login')}
            required={true}
        />
        <SzInput
            value={password}
            name={`${serviceName}.password`}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}
            type={"password"}
            showPassword={true}
            label={t('admin:admin.services.configuration.form.password')}
            required={true}
        />
        <SzInput
            value={btnLabel}
            name={`${serviceName}.btnLabel`}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setBtnLabel(e.target.value)}
            label={t('admin:admin.services.configuration.form.btnLabel')}
            required={true}
        />
    </div>

}
