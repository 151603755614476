import React, { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzIcon, SzInput, SzSelect } from "@suezenv/react-theme-components";
import { bindActionCreators } from "redux";
import { ReportActions } from "../../../store/redux/actions";
import { IReportData, ISiteIndustrielFavFilter, ISiteIndustrielFilter, IUser } from "../../../store/redux/types";
import SiteIndustrielFavFilterForm from "../../Forms/SiteIndustriel";
import UpdateSiteIndustrielFavFilters from "../../Forms/SiteIndustriel/updateSiteIndustrielFavFilters";

interface ISiteIndustrielFavFilters {
  favFilterFormIsActive: boolean;
  handleCloseFavFilterForm: () => void;
  setSiteIndusFav: (newFav: { label: string, value: number }, filter: ISiteIndustrielFilter) => void;
  getSiteIndusFav?: (s: string) => void;
  email?: string;
  siteIndustrielFavFilters?: ISiteIndustrielFavFilter[];
  currentSiteIndustrielFavFilter: { label: string, value: number };
}

function SiteIndustrielFavFilters(props: ISiteIndustrielFavFilters) {

  useEffect(() => {
    if (props.getSiteIndusFav && props.email) {
      props.getSiteIndusFav(props.email);
    }
  }, [props.getSiteIndusFav, props.email]);

  const {t} = useTranslation();
  const [favFilterUpdateFormIsActive, setFavFilterUpdateFormIsActive] = useState(false);

  const handleCloseFavFilterUpdateForm = () => {
    setFavFilterUpdateFormIsActive(false);
  };

  let filtersOptions: any[] = [{label: t("report:favFilters"), value: 0}];
  if (props.siteIndustrielFavFilters && props.siteIndustrielFavFilters.length > 0) {
    filtersOptions = filtersOptions.concat(props.siteIndustrielFavFilters.map((item: ISiteIndustrielFavFilter) => {
      return {label: item.title, value: item.id};
    }));
  }

  const updateFavFilter = (newFav: { label: string, value: number }): void => {
    if (newFav.value && props.siteIndustrielFavFilters) {
      const filter = props.siteIndustrielFavFilters.find((item: ISiteIndustrielFavFilter) => {
        return item.id === newFav.value;
      });
      if (filter) {
        props.setSiteIndusFav(newFav, filter.filters);
      }
    }
  };

  return (
    <>
      {props.favFilterFormIsActive && (
        <SiteIndustrielFavFilterForm
          visible={props.favFilterFormIsActive}
          handleCloseUserForm={props.handleCloseFavFilterForm}
        />
      )}
      {favFilterUpdateFormIsActive && (
        <UpdateSiteIndustrielFavFilters
          visible={favFilterUpdateFormIsActive}
          handleCloseUpdateForm={handleCloseFavFilterUpdateForm}
        />
      )}
      <div className={"report--menu--tabs_list--fav"}>
        <div className={"report--menu--tabs_list--input-container"}>
          {(!props.siteIndustrielFavFilters || props.siteIndustrielFavFilters.length === 0) &&
          <SzInput placeholder={t("report:noFavFilters")}/>
          }
          {(props.siteIndustrielFavFilters && props.siteIndustrielFavFilters.length > 0) && (
            <><SzSelect
              className="border-secondary fav-filters"
              isSearchable
              id="siteIndustrielFavFilters"
              name="siteIndustrielFavFilters"
              placeholder={t("report:favFilter")}
              onChange={(newFav: { label: string, value: number }) => {
                updateFavFilter(newFav);
              }}
              options={filtersOptions}
              value={props.currentSiteIndustrielFavFilter}
            />
              <OverlayTrigger
                placement="bottom"
                delay={{show: 100, hide: 200}}
                overlay={
                  <Tooltip id="tooltip">
                    {t("set_my_fav_filters")}
                  </Tooltip>
                }
              >
                <div className={"fav-filters_cog-wheel"}>
                <SzIcon
                  className={"cursor-pointer"}
                  onClick={() => setFavFilterUpdateFormIsActive(true)}
                  variant={"line"}
                  icon={"cog"}
                />
                </div>
              </OverlayTrigger>
            </>
          )
          }

        </div>
        {/*<div className={"report--menu--tabs_list--fav--button-container"}>*/}
        {/*  */}
        {/*</div>*/}
      </div>
    </>
  );
}

const mapStateToProps = (state: { report: IReportData, user: IUser }) => {
  return {
    currentSiteIndustrielFavFilter: state.report.currentSiteIndustrielFavFilter,
    email: state.user.email,
    siteIndustrielFavFilters: state.report.siteIndustrielFavFilters,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(ReportActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SiteIndustrielFavFilters);
