import React, { ReactElement } from "react";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import "../detail.scss";

const SiteIndustrielSynthesis = (props: { shownItem: any }): ReactElement => {
  const { t } = useTranslation();

  return (
    <>
      <Table size="sm">
        <tbody>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:siteIndustriel.address")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {props.shownItem.ADRESSE}
            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:siteIndustriel.idCD92")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {props.shownItem.ID_CD92}
            </td>
          </tr>
          <tr>
            <td className="gaia-td-align-right cell-width">
              {t("report:siteIndustriel.domain")} :
            </td>
            <td className="gaia-td-align-left cell-width">
              {props.shownItem.domaine ? props.shownItem.domaine[0].DOMAINE_CD92 : t("no-data")}
            </td>
          </tr>
        </tbody>
      </Table>
    </>
  );
};

export default SiteIndustrielSynthesis;
