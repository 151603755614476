import Request from './Request';
import { ApiUrls } from '../constants';
import { ApplicationConfiguration } from "../types";

export class ApplicationConfigurationService {
    public static saveApplicationConfiguration(conf: ApplicationConfiguration) {
        return Request.post(ApiUrls.APPLICATION_CONFIGURATION_CREATE, conf);
    }

    public static getByOwnerAndType(ownerId: string, ownerType: string) {
        const url = ApiUrls.APPLICATION_CONFIGURATION_GET_BY_OWNER_TYPE.replace(':ownerType', ownerType).replace(':ownerId', ownerId);

        return Request.get(url, false, true);
    }
}