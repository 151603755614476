import { mdiArrowLeftBoldCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React, { useEffect, useState } from "react";
import { Breadcrumb, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { smartshapeConfig } from "../../config";
import { Ppv3d as Ppv3dService } from "../../services/Ppv3d";
import { Smartshape as SmartshapeService } from "../../services/Smartshape";
import { store } from "../../store";
import { SearchActions } from "../../store/redux/actions";
import { SEARCH } from "../../store/redux/constants";
import { ISearch } from "../../store/redux/types";
import DataNotReady from "../Utils/messages/dataNotReady";
import "./detail.scss";
import EquipementDetail from "./equipementDetail";

declare global {
    interface Window {
        Minko: any;
    }
}

interface IWork3dProps {
    ppv3dData?: any;
    ifcFileId?: any;
    shownEquipementId?: string;
    displayEquipementFromSmartshapePlayer: (equipementId: string) => void;
}

const Work3d = (props: IWork3dProps) => {

    const { t } = useTranslation();
    const [equipement, setEquipment] = useState<any>(null);
    const [equipementId, setEquipmentId] = useState<any>(null);
    const [showEquipement, setShowEquipment] = useState(false);
    const [showEquipementDetail, setShowEquipmentDetail] = useState(false);

    const equipementDetail = (equipmentId: string, fromList: boolean) => {
    // Here showEquipementDetail = false will prevent call to getNodesByEquipement which calls getNodesByEquipement to backend /smartshape/
    if (showEquipementDetail) {
        store.dispatch({ payload: { flag: { loading: true } }, type: SEARCH.LOADING });
        return Ppv3dService.getEquipement(equipmentId)
            .then((data: any) => {
                setEquipment(data);
                setEquipmentId(equipmentId);
                props.displayEquipementFromSmartshapePlayer(equipmentId);
                store.dispatch({ payload: { flag: { loading: false } }, type: SEARCH.LOADING });
                if (props.ifcFileId && fromList) {
                    return SmartshapeService.getNodesByEquipement(props.ifcFileId, equipmentId)
                        .then((response: any) => {
                            if (response) {
                                let nodes: string = "";
                                response.forEach((node: any, index: any) => {
                                    if (index === 0) {
                                        nodes += `${node.id}`;
                                    } else {
                                        nodes += `;${node.id}`;
                                    }
                                });
                                if (nodes) {
                                    const iframe = document.getElementById("smartshape") as HTMLIFrameElement;
                                    if (iframe.contentWindow) {
                                        if (iframe.contentWindow) {
                                            iframe.contentWindow.postMessage(
                                                JSON.stringify(["sceneSelectedNodes", nodes]),
                                                smartshapeConfig.host ? smartshapeConfig.host : "",
                                            );
                                            iframe.contentWindow.postMessage(
                                                JSON.stringify(["sceneFocusNode", nodes]),
                                                smartshapeConfig.host ? smartshapeConfig.host : "",
                                            );
                                        }
                                    }
                                }
                            }
                        });
                }
            }).catch((error) => {
                store.dispatch({ payload: { flag: { loading: false } }, type: SEARCH.LOADING });
                console.log(error);
            });
        }
    };

    useEffect(() => {
        if (equipement) {
            setShowEquipment(true);
        }
    }, [equipement]);

    useEffect(() => {
        if (props.shownEquipementId && props.shownEquipementId !== equipementId) {
            equipementDetail(props.shownEquipementId, true);
        }
    }, [props.shownEquipementId]);

    useEffect(() => {
        if (props.ifcFileId) {
            window.removeEventListener("message", smartShapeMessageHandler);
            const iframe = document.getElementById("smartshape") as HTMLIFrameElement;
            iframe.onload = () => {
                window.addEventListener("message", smartShapeMessageHandler, false);
                setTimeout(() => {
                    if (iframe.contentWindow) {
                        iframe.contentWindow.postMessage(
                            "",
                            smartshapeConfig.host ? smartshapeConfig.host : "",
                        );
                    }
                }, 12000);
            };

        }
        return () => {
            window.removeEventListener("message", smartShapeMessageHandler);
        };
    }, [props.ifcFileId]);

    const smartShapeMessageHandler = (event: any) => {
        if (!event || !event.data) {
            return;
        }
        const message = JSON.parse(event.data);
        if (message[0] === "selectedNodes") {
            if (message[1]) {
                // Here showEquipementDetail = false will prevent call to getNodesByEquipement which calls getNodesById to backend /smartshape/
                if (showEquipementDetail) {
                const selectedNodes = message[1].split(";");
                return SmartshapeService.getNodesById(props.ifcFileId, selectedNodes[0])
                    .then((response: any) => {
                        if (response.attributes) {
                            const result = response.attributes.attributes.filter(
                                (attribute: any) => attribute.key === "CodEquip",
                            );
                            if (result[0]) {
                                equipementDetail(result[0].value, false);
                                if (response.id !== selectedNodes[0]) {
                                    const iframe = document.getElementById("smartshape") as HTMLIFrameElement;
                                    if (iframe.contentWindow) {
                                        if (iframe.contentWindow) {
                                            iframe.contentWindow.postMessage(
                                                JSON.stringify(["sceneSelectedNodes", response.id]),
                                                smartshapeConfig.host ? smartshapeConfig.host : "",
                                            );
                                            iframe.contentWindow.postMessage(
                                                JSON.stringify(["sceneFocusNode", response.id]),
                                                smartshapeConfig.host ? smartshapeConfig.host : "",
                                            );
                                        }
                                    }
                                }
                            } else {
                                props.displayEquipementFromSmartshapePlayer("");
                            }
                        }
                    });
                }
            }
        }
    };

    const handleBreadcrumbClick = (): void => {
        setEquipment(null);
        setShowEquipment(false);
        setTimeout(() => {
            const elements = document.getElementsByClassName("active");
            for (const element of elements as any) {
                element.classList.remove("selected");
            }
            document.getElementsByClassName(`equipementId-${equipementId}`)[0].classList.add("active");
        }, 1000);
        setEquipmentId(null);
        props.displayEquipementFromSmartshapePlayer("");
    };

    if (props.ppv3dData) {

    // Here showEquipement = false will prevent call to <EquipementDetail> which calls backend /ppv3d/{equipementId}
        return (
            <>
                <div className="work3d">
                    {(showEquipement && equipement) ?
                        (
                            <>
                                <Breadcrumb className={"gaia_breadcrumbs"}>
                                    <Breadcrumb.Item
                                        className={"gaia_breadcrumb search_keywords clickable"}
                                        onClick={() => handleBreadcrumbClick()}
                                    >
                                        <OverlayTrigger
                                            placement="bottom"
                                            delay={{ show: 100, hide: 200 }}
                                            overlay={
                                                <Tooltip id="tooltip">
                                                    {t("back_to_work")}
                                                </Tooltip>
                                            }
                                        >
                                            <Icon path={mdiArrowLeftBoldCircleOutline} size={1} />
                                        </OverlayTrigger>
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item
                                        className={"gaia_breadcrumb search_keywords clickable"}
                                        onClick={() => handleBreadcrumbClick()}
                                    >
                                        {props.ppv3dData.tagLabel}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item className={"gaia_breadcrumb estate_object_name"}>
                                        {equipement.tag} - {equipement.tagLabel}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                                <EquipementDetail shownItem={equipement} />
                            </>
                        ) :
                        (
                            <>
                                <h3 className="gaia-detail-result--header--primary-title primary-title-category">
                                    {t("asset:explore_3d_work")}
                                </h3>
                                <ul>
                                    <li>{props.ppv3dData.tagLabel} - {props.ppv3dData.tag}
                                        <ul>
                                            {props.ppv3dData["Related Items"]["est le site de"].map(
                                                (e: any, key: number) => {
                                                    return (
                                                        <li key={key} className={`gaia-search-data__item equipementId-${e.Name}`}>
                                                            <span className="gaia-search-data__item-content">
                                                                {e.TagLabel} - {e.Name}
                                                            </span>
                                                            <div className="gaia-search-data__item-links">
                                                                <OverlayTrigger
                                                                    placement="bottom"
                                                                    delay={{ show: 100, hide: 200 }}
                                                                    overlay={
                                                                        <Tooltip id="tooltip">
                                                                            <Trans>open_details</Trans>
                                                                        </Tooltip>
                                                                    }
                                                                >
                                                                    <i
                                                                        className="gaia-search-data__item-links-icon material-icons clickable"
                                                                        onClick={() => equipementDetail(e.Name, true)}
                                                                    >format_list_bulleted</i>
                                                                </OverlayTrigger>
                                                            </div>
                                                        </li>
                                                    );
                                                })
                                            }
                                        </ul>
                                    </li>
                                </ul>
                            </>
                        )}
                </div>
            </>
        );
    }

    return <DataNotReady />;
};

const mapStateToProps = (state: { search: ISearch }) => {
    return {
        shownEquipementId: state.search.detail.shownEquipementId,
    };
};

const mapDispatchToProps = (dispatch: any) => ({
    ...bindActionCreators(SearchActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Work3d);
