import React, { useEffect, useState } from "react";
import ReactTags, { Tag } from 'react-tag-autocomplete'
import { UserWithRole } from "../AdminUserAdd";
import { Roles } from "../../../../../constants";
import { SzButton, SzTypographie } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import '../../../contracts/forms/contractForm.scss';
import classnames from "classnames";

type PropsType = {
    userRoles: UserWithRole[],
    setUserRoles: (userRoles: UserWithRole[]) => void,
    goToNext: () => void
    goToPrev?: () => void
}

const Step1 = (props: PropsType) => {
    const { t } = useTranslation();
    const { userRoles, setUserRoles, goToNext, goToPrev } = props;
    const [userEmailTags, setUserEmailTags] = useState<Tag[]>([]);
    const [emailError, setEmailError] = useState(false);
    const [emptyError, setEmptyError] = useState(false);

    useEffect(() => {
        const emailsTags = userRoles.map((userRole: UserWithRole, index: number): Tag => {
            return { id: index, name: userRole.userEmail }
        });
        setUserEmailTags(emailsTags)
    }, []);

    const validateEmail = (tag: Tag) => {
        const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        const isValid = regex.test(String(tag.name).toLowerCase());
        if (!isValid) {
            setEmailError(true);
        }
        return isValid;
    }

    const onUserAdd = (tag: Tag) => {
        setEmptyError(false);
        setUserEmailTags(userEmailTags.concat(tag));
    }

    const onUserDelete = (i: number) => {
        const tags = userEmailTags.slice(0);
        tags.splice(i, 1);
        setUserEmailTags(tags);
    }

    const nextStepHandle = () => {
        if (userEmailTags.length === 0) {
            setEmptyError(true);
            return;
        }
        const users: UserWithRole[] = userEmailTags.map((tag: Tag): UserWithRole => {
            return { role: Roles.ROLE_USER, userEmail: tag.name }
        });
        setUserRoles(users);
        goToNext();
    }

    const buttonsClassname = classnames(
        "mt-4",
        "d-flex",
        goToPrev ? "justify-content-between" : "justify-content-end"
    )

    return <div>
        <div className="box">
            <div className="row">
                <div className="col-md-8 offset-md-2">
                    <SzTypographie variant={"body"} color={"inactive"}>
                        {t('admin:admin.users.modal.add.emailInputLabel')}
                    </SzTypographie>
                    <ReactTags
                        addOnBlur={true}
                        id={"reactTagsUser"}
                        inputAttributes={{ "class": "tag-textarea" }}
                        allowNew={true}
                        allowBackspace={false}
                        tags={userEmailTags}
                        placeholderText={t("admin:admin.contracts.create.enter_mail")}
                        onAddition={onUserAdd}
                        onDelete={onUserDelete}
                        onInput={() => setEmailError(false)}
                        onValidate={(tag: Tag) => validateEmail(tag)}
                    />
                    {emailError &&
                        <SzTypographie variant="caption" color={"danger"}>
                            {t('admin:admin.users.modal.add.emailError')}
                        </SzTypographie>
                    }
                    {emptyError &&
                        <SzTypographie variant="caption" color={"danger"}>
                            {t('admin:admin.users.modal.add.emptyError')}
                        </SzTypographie>
                    }
                </div>
            </div>
            <div className={buttonsClassname}>
                {goToPrev ? <SzButton
                    className="previous-btn mr-2"
                    variant="secondary"
                    onClick={goToPrev}
                >
                    {t('previous')}
                </SzButton> : <></>
                }
                <SzButton
                    className="next-btn"
                    onClick={nextStepHandle}
                >
                    {t('next')}
                </SzButton>
            </div>
        </div>
    </div>
}

export default Step1;
