import { mdiArrowLeftBoldCircleOutline } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { default as BreadcrumbAction } from "../../store/redux/actions/breadcrumb";
import "./breadcrumb.scss";
import {ReportActions, SearchActions} from "../../store/redux/actions";

interface IBreadcrumbProps {
  searchKeywords: string;
  categoryName: string;
  clickable: boolean;
  clickedItem: string;
  backToSearch: any;
}

function Breadcrumb(props: IBreadcrumbProps) {
  const {t} = useTranslation();
  const adresseText = t("category.address");
  const {searchKeywords, categoryName, clickedItem, clickable} = props;
  const {backToSearch} = props;
  return (
    <div className="gaia_breadcrumbs">
      {searchKeywords && clickable && (
        <>
          <div
            className={`gaia_breadcrumb gaia_breadcrumb--previous_button ${clickable ? " clickable " : ""}`}
            onClick={() => clickable ? backToSearch(adresseText) : ""}
          ><OverlayTrigger
            placement="bottom"
            delay={{show: 100, hide: 200}}
            overlay={
              <Tooltip id="tooltip">
                {t("back_to_results")}
              </Tooltip>
            }
          >
            <Icon path={mdiArrowLeftBoldCircleOutline} size={1}/>
          </OverlayTrigger>
          </div>
          <span className="gaia_breadcrumb--separator">/</span>
          <div
            onClick={() => clickable ? backToSearch(adresseText) : ""}
            className={`gaia_breadcrumb search_keywords ${clickable ? "clickable" : ""}`}
          >
            {searchKeywords}
          </div>
          <span className="gaia_breadcrumb--separator"> /</span>
        </>
      )}
      <div
        onClick={typeof clickable === 'function' ? clickable : () => backToSearch(categoryName)}
        className={`gaia_breadcrumb category_name ${clickable ? " clickable" : ""}`}
      >
        {categoryName}
      </div>
      <span className="gaia_breadcrumb--separator"> /</span>
      <div className="gaia_breadcrumb estate_object_name">{clickedItem}</div>
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    categoryName: state.breadcrumb.categoryName,
    clickable: state.breadcrumb.clickable,
    clickedItem: state.breadcrumb.clickedItem,
    searchKeywords: state.breadcrumb.searchKeywords,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...ReportActions, ...BreadcrumbAction }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumb);
