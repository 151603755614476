import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzAccordion, SzCheckbox } from "@suezenv/react-theme-components";
import { bindActionCreators } from "redux";
import { filterTypes, indicesMapping } from "../../config/constants";
import { IIndice, ISubIndice } from "../../config/indice.interfaces";
import { FiltersActions } from "../../store/redux/actions";
import { IFilter, ISetFilterAction, IUpdateFilterAction } from "../../store/redux/types";
import "./filters.scss";

interface IIndicesRender {
  facets: any;
  isChecked: (indice: string, indiceName: string, prefix: string) => boolean;
  onChange: (event: any, category: string, subcategory: string, filterType?: string) => void;
  prefix: string;
  currentContract: any;
  selectedFilters: IFilter[];
  setFilters: (filters: IFilter[]) => ISetFilterAction;
  updateFilters: (filter: IFilter) => IUpdateFilterAction;
}

function IndicesRender(props: IIndicesRender) {
  const {facets, onChange, isChecked, prefix, selectedFilters, currentContract} = props;
  const {t} = useTranslation();
  const [activeKey, setActiveKey] = useState('0');
  const contractId = currentContract.id;

  const updateSubFilters = (checked: boolean, subIndices: string[]) => {
    if (!checked) {
      const NewFilters = selectedFilters.filter((filter: IFilter) => {
        return subIndices.indexOf(filter.key) === -1;
      });
      props.setFilters(NewFilters);
    } else {
      subIndices.forEach((filterKey: string) => {
        if (typeof selectedFilters.find((filter: IFilter) => {
          return filter.key === filterKey;
        }) === "undefined") {
          props.updateFilters({isChecked: true, key: filterKey, type: "indice", value: "1"});
        }
      });
    }
  };

  const areAllSubIndicesChecked = (subIndices: string[]) => {
    let AllChecked: boolean = true;
    subIndices.forEach((filterKey: string) => {
      if (typeof selectedFilters.find((filter: IFilter) => {
        return filter.key === filterKey;
      }) === "undefined") {
        AllChecked = false;
      }
    });
    return AllChecked;
  };

  let layers = currentContract.assetLayers.map((assetLayer: any) => {
    return assetLayer.layer.toLowerCase();
  });

  return (
    <>
      {Object.entries(indicesMapping).map((indice: [string, IIndice | string], index: number) => {
        const subIndices: IIndice = indice[1] as IIndice;
        let subIndicesTitle = subIndices.title;
        if ('420d59de-c3ed-11ed-a0a0-0242ad15150b' === contractId && 'object' === typeof subIndices.a_traphy_point) {
          subIndices.a_traphy_point.title='indices.patrimoine.a_traphy_point_siahvy';
        }
        const indiceTitle: string = t(subIndicesTitle as string);
        let disabled: boolean = true;
        let active: boolean = false;
        let totalCount: number = 0;
        const subIndicesTitles: string[] = [];

        const body = (
          <>
            <ul className="gaia-search-filters__subcategory">
              {Object.entries(subIndices)
                .filter((item: [string, ISubIndice | string]) => typeof item[1] !== "string")
                .map((item: [string, ISubIndice | string], indexSubIndice: number) => {
                  const subIndice: ISubIndice = item[1] as ISubIndice;
                  if (-1 === layers.indexOf(subIndice.indiceName)) { return false }
                  let nbResultInIndex: number = 0;
                  let checkboxState: boolean = false;
                  facets._index.buckets
                    .filter((facet: { key: string }) => facet.key.split('__')[2] === subIndice.indiceName)
                    .map((facet: { key: string; doc_count: number }) => {
                      nbResultInIndex = facet.doc_count;
                      totalCount = totalCount + nbResultInIndex;
                      disabled = false;
                      active = isChecked(`${prefix}${contractId}__asset__${subIndice.indiceName}`, subIndice.indiceName, prefix) || active;
                      checkboxState = nbResultInIndex === 0 && !active;
                      return 0;
                    });
                  subIndicesTitles.push(`${prefix}${contractId}__asset__${subIndice.indiceName}`);

                  return (
                    <li className="gaia-search-filters__subcategory-item" key={indexSubIndice}>
                      <SzCheckbox
                        label={`${t(subIndice.title)} (${nbResultInIndex})`}
                        type="checkbox"
                        disabled={checkboxState}
                        checked={isChecked(`${prefix}${contractId}__asset__${subIndice.indiceName}`, subIndice.indiceName, prefix)}
                        name={t(subIndice.title)}
                        onChange={(event: any) =>
                          onChange(event, `${prefix}${contractId}__asset__${subIndice.indiceName}`, "1", filterTypes.indice)
                        }
                      />
                    </li>
                  );
                })}
            </ul>
          </>
        );

        const header = <>
          <SzCheckbox
            className={"gaia-search-filters__category-check"}
            checked={areAllSubIndicesChecked(subIndicesTitles)}
            id={indiceTitle}
            name={indiceTitle}
            label={indiceTitle}
            disabled={disabled}
            onChange={(event: any) => {
              updateSubFilters(event.target.checked, subIndicesTitles);
            }}
            type="checkbox"
          />
          <span className="gaia-search-filters__category-title">({totalCount})</span>
        </>;
        const categoryClassName = disabled ? "gaia-search-filters__no-subcategories" : "gaia-search-filters__category";
//         const onClickCollapse = (key: string) => setActiveKey(key !== activeKey ? key : "-1");
        return (
          <div
            className={`gaia-search-filters__indice ${categoryClassName}`}
            key={index}
          >
            <SzAccordion activeKey={activeKey} variant="basic">
                <SzAccordion.SzCollapse
                   header={header}
                   body={body}
                   eventKey="0"
                   onClick={() => {}}
                 />
            </SzAccordion>
          </div>
        );
      })}
    </>
  );
}

const mapStateToProps = (state: any) => {
  return {
    selectedFilters: state.filters,
    currentContract: state.contract.currentContract,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({...FiltersActions}, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IndicesRender);
