import Request from './Request';
import { ApiUrls } from '../constants';
import { store } from "../store";
import { skillsData } from "../actions";
import { Contract } from "../types";

export class SkillSetService {
    public static getByContract(contractId: string, currentPage: number, itemsPerPage: number) {
        const url = ApiUrls.SKILLSETS_BY_CONTRACT.replace(':contractId', contractId)
        const body = {
            currentPage,
            itemsPerPage,
        }

        return Request.getWithParams(url, body, true, false);
    }

    public static getAll(filters?: string[], noParent?: boolean) {
        return Request.getWithParams(ApiUrls.SKILLSETS, { query: filters, noParent: noParent });
    }

    public static initUserSkills() {
        Request.get(ApiUrls.USER_SKILLS, false, true)
            .then(response => store.dispatch(skillsData(response.data)))
            .catch(() => store.dispatch(skillsData({ 'error': true })));
    }
}
