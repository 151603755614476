import React, { useEffect, useState } from "react";
import { UserWithRole } from "../AdminUserAdd";
import { SzButton, SzSelect, SzTable, SzTypographie } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { IUser } from "../../../../../store/redux/types";
import { connect } from "react-redux";
import { RightsHelper } from "../../../../../helper/RightsHelper";
import { GiveableRoles, Roles, RoleAdminPortal } from "../../../../../constants";

type PropsType = {
    userRoles: UserWithRole[],
    setUserRoles: (usersWithRole: UserWithRole[]) => void,
    goToPrev: () => void,
    validate: () => void,
    isSaving: boolean,
    user?: IUser,
}

const Step2 = (props: PropsType) => {
    const { userRoles, setUserRoles, validate, goToPrev, isSaving, user } = props;
    const [options, setOptions] = useState<{value: string, label: string}[]>([]);
    const { t } = useTranslation();

    useEffect(() => {
        let roleOptions = [{
            value: Roles.ROLE_USER as string,
            label: t(`admin:admin.users.roles.${Roles.ROLE_USER}`)
        }];
        if (user) {
            if (RightsHelper.isGranted(user, [Roles.ROLE_SUPER_ADMIN_CONTRACT as string, RoleAdminPortal as string])) {
                roleOptions = roleOptions.concat({
                    value: Roles.ROLE_SUPER_ADMIN_ORGANIZATION as string,
                    label: t(`admin:admin.users.roles.${Roles.ROLE_SUPER_ADMIN_ORGANIZATION}`)
                });
            }
            if (RightsHelper.isGranted(user, [RoleAdminPortal as string])) {
                roleOptions = roleOptions.concat({
                    value: Roles.ROLE_SUPER_ADMIN_CONTRACT as string,
                    label: t(`admin:admin.users.roles.${Roles.ROLE_SUPER_ADMIN_CONTRACT}`)
                });
            }
        }
        setOptions(roleOptions);
    }, [user]);

    const userRolesDataMap = (userRole: UserWithRole) => {
        return {
            userEmail: userRole.userEmail,
            role: <SzSelect
                options={options}
                onChange={(selectedOption: { value:string, label:string }) => setUserRole(selectedOption.value as GiveableRoles, userRole.userEmail)}
                defaultValue={{value: Roles.ROLE_USER as string, label: t(`admin:admin.users.roles.${Roles.ROLE_USER}`)}}
            />
        }
    }

    const setUserRole = (role: GiveableRoles, userEmail: string) => {
        const userRole = userRoles.find((user: UserWithRole) => user.userEmail === userEmail)
        const tmpUserRoles = userRoles.filter((user: UserWithRole) => user.userEmail !== userEmail)
        if (userRole) {
            userRole.role = role;
            setUserRoles(tmpUserRoles.concat(userRole));
        }
    }

    const [data, setData] = useState(userRoles.map(userRolesDataMap));

    useEffect(() => {
        setData(userRoles.map(userRolesDataMap));
    }, [userRoles]);

    const columns = [
        {
            dataField: 'userEmail',
            text: t('admin:admin.users.emailAddress')
        },
        {
            dataField: 'role',
            text: t('admin:admin.users.role')
        }
    ]

    const defaultSorted: any = [{
        dataField: "userEmail",
        order: "desc"
    }];

    return <div>
        <SzTypographie>
            {t('admin:admin.users.modal.add.roleInputLabel')}
        </SzTypographie>
        <SzTable data={data} columns={columns} keyField={"userEmail"} defaultSorted={defaultSorted}/>
        <div className="mt-4 d-flex justify-content-between">
            <SzButton
                className="previous-btn mr-2"
                variant="secondary"
                onClick={goToPrev}
            >
                {t('previous')}
            </SzButton>
            <SzButton
                className="next-btn"
                onClick={validate}
                loader={isSaving}
            >
                {t('validate')}
            </SzButton>
        </div>
    </div>
}


const mapStateToProps = (state: { user: IUser }) => ({
    user: state.user
});

export default connect(mapStateToProps)(Step2);