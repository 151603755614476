import React, { useState } from "react";
import { Accordion, Card, Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { SzIcon } from "@suezenv/react-theme-components";
import DataNotReady from "../Utils/messages/dataNotReady";
import "./detail.scss";

interface ISiteIndustriel {
  "branchements": any;
}

const SiteIndustriel = (props: { siteIndustriels?: ISiteIndustriel[] }) => {

  const [activeKey, setActiveKey] = useState("site-0");
  const { t } = useTranslation();

  const unique = (value: any, index: any, self: any) => {
    return self.indexOf(value) === index;
  };

  const getSamplingPoint = (a: ISiteIndustriel) => {
    const samplingPoint: any = [];
    a.branchements.forEach((branchement: any) => {
      samplingPoint.push(branchement.ADRESSE);
    });
    return samplingPoint.filter(unique).join();
  };

  const getConnectionNumber = (a: ISiteIndustriel) => {
    const connectionNumber: any = [];
    a.branchements.forEach((branchement: any) => {
      connectionNumber.push(branchement.NOMEXPLO);
    });
    return connectionNumber.filter(unique).join();
  };

  const getSamplingPointCity = (a: ISiteIndustriel) => {
    const samplingPointCity: any = [];
    a.branchements.forEach((branchement: any) => {
      samplingPointCity.push(branchement.CODINSEE);
    });
    return samplingPointCity.filter(unique).join();
  };

  if (props.siteIndustriels) {
    return (
      <>
        {props.siteIndustriels &&
          <Accordion defaultActiveKey={activeKey}>
            {props.siteIndustriels.map((element: any, key: any) => {
              return (
                <>
                  <Card className="container-bordered">
                    <Accordion.Toggle
                      as={Card.Header}
                      eventKey={"site-" + key}
                      onClick={() =>
                        activeKey === "site-" + key ? setActiveKey("") : setActiveKey("site-" + key)
                      }
                    >
                      {element.NomEtab}
                      {activeKey === "site-" + key ?
                        <SzIcon variant="bold" icon="arrow-up-1" /> :
                        <SzIcon variant="bold" icon="arrow-down-1" />
                      }
                    </Accordion.Toggle>
                    <Accordion.Collapse eventKey={"site-" + key}>

                      <Card.Body>
                        <Table size="sm">
                          <tbody>
                            <tr>
                              <td className="gaia-td-align-right cell-width">
                                {t("report:siteIndustriel.address")} :
                              </td>
                              <td className="gaia-td-align-left cell-width">
                                {element.ADRESSE}
                              </td>
                            </tr>
                            <tr>
                              <td className="gaia-td-align-right cell-width">
                                {t("report:siteIndustriel.city")} :
                              </td>
                              <td className="gaia-td-align-left cell-width">
                                {element.commune}
                              </td>
                            </tr>
                            <tr>
                              <td className="gaia-td-align-right cell-width">
                                {t("report:siteIndustriel.domain")} :
                              </td>
                              <td className="gaia-td-align-left cell-width">
                                {element.domaine ? element.domaine[0].DOMAINE_CD92 : t("no-data")}
                              </td>
                            </tr>
                            <tr>
                              <td className="gaia-td-align-right cell-width">
                                {t("report:siteIndustriel.samplingPoint")} :
                              </td>
                              <td className="gaia-td-align-left cell-width">
                                {getSamplingPoint(element)}
                              </td>
                            </tr>
                            <tr>
                              <td className="gaia-td-align-right cell-width">
                                {t("report:siteIndustriel.connectionNumber")} :
                              </td>
                              <td className="gaia-td-align-left cell-width">
                                {getConnectionNumber(element)}
                              </td>
                            </tr>
                            <tr>
                              <td className="gaia-td-align-right cell-width">
                                {t("report:siteIndustriel.samplingPointCity")} :
                              </td>
                              <td className="gaia-td-align-left cell-width">
                                {getSamplingPointCity(element)}
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </>
              );
            })
            }
          </Accordion>
        }
      </>
    );
  }

  return <DataNotReady />;
};

export default SiteIndustriel;
