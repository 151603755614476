import React, { useEffect, useState } from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { SzIcon, SzInput, SzSelect } from "@suezenv/react-theme-components";
import { bindActionCreators } from "redux";
import { ReportActions } from "../../../store/redux/actions";
import { IFavFilter, IReportData, IReportFilter, IUser } from "../../../store/redux/types";
import FavFilterForm from "../../Forms/Report";
import UpdateFavFilters from "../../Forms/Report/updateFavFilters";

interface IFavFilters {
  favFilterFormIsActive: boolean;
  handleCloseFavFilterForm: () => void;
  setFav: (newFav: { label: string, value: number }, filter: IReportFilter) => void;
  getFav?: (s: string) => void;
  email?: string;
  favFilters?: IFavFilter[];
  currentFilter: { label: string, value: number };
}

function FavFilters(props: IFavFilters) {

  useEffect(() => {
    if (props.getFav && props.email) {
      props.getFav(props.email);
    }
  }, [props.getFav, props.email]);

  const {t} = useTranslation();
  const [favFilterUpdateFormIsActive, setFavFilterUpdateFormIsActive] = useState(false);

  const handleCloseFavFilterUpdateForm = () => {
    setFavFilterUpdateFormIsActive(false);
  };

  let filtersOptions: any[] = [{label: t("report:favFilters"), value: 0}];
  if (props.favFilters && props.favFilters.length > 0) {
    filtersOptions = filtersOptions.concat(props.favFilters.map((item: IFavFilter) => {
      return {label: item.title, value: item.id};
    }));
  }

  const updateFavFilter = (newFav: { label: string, value: number }): void => {
    if (newFav.value && props.favFilters) {
      const filter = props.favFilters.find((item: IFavFilter) => {
        return item.id === newFav.value;
      });
      if (filter) {
        props.setFav(newFav, filter.filters);
      }
    }
  };

  return (
    <>
      {props.favFilterFormIsActive && (
        <FavFilterForm visible={props.favFilterFormIsActive} handleCloseUserForm={props.handleCloseFavFilterForm}/>
      )}
      {favFilterUpdateFormIsActive && (
        <UpdateFavFilters visible={favFilterUpdateFormIsActive} handleCloseUpdateForm={handleCloseFavFilterUpdateForm}/>
      )}
      <div className={"report--menu--tabs_list--fav"}>
        <div className={"report--menu--tabs_list--input-container"}>
          {(!props.favFilters || props.favFilters.length === 0) &&
            <SzInput readOnly placeholder={t("report:noFavFilters")}/>
          }
          {(props.favFilters && props.favFilters.length > 0) && (
            <><SzSelect
              className="border-secondary fav-filters"
              isSearchable
              id="favFilter"
              name="favFilter"
              placeholder={t("report:favFilter")}
              onChange={(newFav: { label: string, value: number }) => {
                updateFavFilter(newFav);
              }}
              options={filtersOptions}
              value={props.currentFilter}
            />
              <OverlayTrigger
                placement="bottom"
                delay={{show: 100, hide: 200}}
                overlay={
                  <Tooltip id="tooltip">
                    {t("set_my_fav_filters")}
                  </Tooltip>
                }
              >
                <div className={"fav-filters_cog-wheel"}>
                <SzIcon
                  className={"cursor-pointer"}
                  onClick={() => setFavFilterUpdateFormIsActive(true)}
                  variant={"line"}
                  icon={"cog"}
                />
                </div>
              </OverlayTrigger>
            </>
          )
          }

        </div>
        {/*<div className={"report--menu--tabs_list--fav--button-container"}>*/}
        {/*  */}
        {/*</div>*/}
      </div>
    </>
  );
}

const mapStateToProps = (state: { report: IReportData, user: IUser }) => {
  return {
    currentFilter: state.report.currentFavFilter,
    email: state.user.email,
    favFilters: state.report.favFilters,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators(ReportActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(FavFilters);
