export { ODIVEA_PORTAL } from './ODIVEA_PORTAL';
export { TSMS_PORTAL } from './TSMS_PORTAL';
export { TSMD_PORTAL } from './TSMD_PORTAL';
export { VA_EXTERNAL_SITE_SIGRENEA } from './VA_EXTERNAL_SITE_SIGRENEA';
export { PHAREENLIGNE_PORTAL } from './PHAREENLIGNE_PORTAL';

export const existingServiceForms = [
    'ODIVEA_PORTAL',
    'TSMS_PORTAL',
    'TSMD_PORTAL',
    'VA_EXTERNAL_SITE_SIGRENEA',
    'PHAREENLIGNE_PORTAL',
];
