import Request from './Request';
import { ApiUrls } from '../constants';

export class LegalEntityService {
    public static getContractLegalEntities(contractId: string, type: string) {
        const url = ApiUrls.LEGAL_ENTITIES_BY_CONTRACT.replace(':contractId', contractId);
        const body = {
            type: type
        }

        return Request.getWithParams(url, body);
    }
}