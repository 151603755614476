import React, { useEffect, useState } from "react";
import { Collapse} from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { ISearch, ISearchDetail } from "../../store/redux/types";
import { Contract } from "../../types";
import Breadcrumb from "../Utils/breadcrumb";
import CollapseToggle from "../Utils/buttons/CollapseToggle";
import "./detail.scss";
import DetailContainer from "./detailContainer";
import {bindActionCreators} from "redux";
import {MapActions, ReportActions, SearchActions} from "../../store/redux/actions";

interface IDetail {
  detail: ISearchDetail;
  searchData: any;
  contract: any;
  hideDetail: () => void;
  closeAll: () => void;
  initSearch?: () => void;
  setMapInfo?: any;
}

const Detail = (props: IDetail) => {
  const { t } = useTranslation();
  const { contract, detail , closeAll, initSearch, setMapInfo} = props;
  const [isHidden, setIsHidden] = useState(false);

  const closeContainerDetail = () => {
    closeAll();
    if (initSearch) {
      initSearch();
    }
    if (setMapInfo) {
      setMapInfo({
        highlight: {
          show: false,
        },
      });
    }
  }

  useEffect(() => {
    setIsHidden(false);
  }, [detail.shownDetailId, detail.shownEquipementId]);

  const shownItemDetails = detail.shownDetailItem;
  const getDetailTitle = () => {
    if (undefined !== shownItemDetails["asset:asset_id"]) {
      return t(shownItemDetails["index:name_ori"]) + " " + shownItemDetails["asset:asset_id"];
    } else if (undefined !== shownItemDetails["idPDC"]) {
      return t("pdc") + " " + shownItemDetails["idPDC"];
    }
    return "";
  };

  return (
    <>
      <div className="gaia-detail-result">
        {isHidden && <div onClick={() => setIsHidden(false)} className="gaia-detail-result--collapsed-body">
          <div className="gaia-detail-result--collapsed-body--title">
            {getDetailTitle()}
          </div>
        </div>
        }
        <Collapse in={!isHidden} timeout={0} dimension="width">
          <div className="gaia-detail-result--expanded-body">
            <Breadcrumb />
            <DetailContainer
              shownItem={detail.shownDetailItem}
              mapping={contract.assetMapping}
            />
          </div>
        </Collapse>
        <CollapseToggle collapseDirection={"left"} handler={setIsHidden} toggled={isHidden} showClose={true} show={true} closeHandler={closeContainerDetail}/>
      </div>
    </>
  );
};

const mapStateToProps = (state: { search: ISearch, contract: { currentContract: Contract } }): IDetail => {
  return {
    detail: state.search.detail,
    searchData: state.search.searchData,
    contract: state.contract.currentContract,
    hideDetail: ReportActions.hideDetail,
    closeAll: SearchActions.closeAll,
    setMapInfo: MapActions.setMapInfo,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  ...bindActionCreators({ ...ReportActions, ...SearchActions, ...MapActions }, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Detail);
