import { connectRouter } from "connected-react-router";
import { History } from "history";
import { combineReducers } from "redux";
import breadcrumbReducer from "./breadcrumb";
import contractReducer from "./contract";
import errorReducer from "./errors";
import filtersReducers from "./filters";
import mapReducer from "./map";
import permissionsReducer from "./permission";
import reportReducer from "./report";
import searchReducer from "./search";
import userReducer from "./user";

const rootReducer = (history: History) =>
  combineReducers({
    breadcrumb: breadcrumbReducer,
    contract: contractReducer,
    error: errorReducer,
    filters: filtersReducers,
    map: mapReducer,
    report: reportReducer,
    router: connectRouter(history),
    search: searchReducer,
    user: userReducer,
    permissions: permissionsReducer,
  });

export default rootReducer;
