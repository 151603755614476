import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { SzInput } from "@suezenv/react-theme-components";
import { useTranslation } from "react-i18next";
import { ApplicationConfigurationFormProps } from "../../../../types";

type ApplicationParameters = {
    btnLabel: string
};

const serviceName = "PHAREENLIGNE_PORTAL"


export const PHAREENLIGNE_PORTAL = (props: ApplicationConfigurationFormProps) => {
    const { serviceConfigurations, setServiceConfigurations, owner, ownerType } = props;
    const { t } = useTranslation();
    const [btnLabel, setBtnLabel] = useState<string>("undefined" !== typeof serviceConfigurations[serviceName] ? serviceConfigurations[serviceName].parameters?.btnLabel ?? "" : "");

    const parameters = useRef<ApplicationParameters>({
        btnLabel: btnLabel
    });

    useEffect(() => {
        parameters.current = {...parameters.current, btnLabel }
            if (
                "undefined" === typeof serviceConfigurations[serviceName] ||
                "undefined" !== typeof serviceConfigurations[serviceName] &&
                parameters.current.btnLabel !== serviceConfigurations[serviceName].parameters?.btnLabel
            ) {
                setServiceConfigurations({
                    ...serviceConfigurations, [serviceName]: {
                        name: serviceName,
                        owner: owner,
                        ownerType: ownerType,
                        parameters: {
                            btnLabel: parameters.current.btnLabel
                        }
                    }
                });
            }
    }, [btnLabel]);

    return <div>
        <SzInput
            value={btnLabel}
            name={`${serviceName}.btnLabel`}
            onChange={(e: ChangeEvent<HTMLInputElement>) => setBtnLabel(e.target.value)}
            label={t('admin:admin.services.configuration.form.btnLabel')}
            required={true}
        />
    </div>

}
