import { IIndice } from "./indice.interfaces";

export const categories: {[key: string]: string} = {
    ASSET: "ASSET",
    DOC: "DOC",
    GEO: "GEO",
    REPORT: "REPORT",
};

export const searchPrefix: {[key: string]: string} = {
    assets: "ASSET:",
    docs: "DOC:",
    map: "GEO:",
};

export const filterTypes: {[key: string]: string} = {
    facet: "facet",
    indice: "indice",
};

export const mapType2D: string = "2d";
export const mapType3D: string = "3d";


export const layers: { [key: string]: string } = {
    "Branchment": "asset:a_branch_line",
    "Collecteur": "asset:a_collec_line",
    "Tronçon_de_collecteur": "asset:a_collec_line",
    "Regard_de_visite": "asset:a_regard_point",
    "Regard_de_branchement": "asset:a_regbra_point",
    "Station_de_pompage": "asset:a_pompag_point",
    "Déversoir_d'orage": "asset:a_devers_point",
    "Chambre_à_sable": "asset:a_traphy_point",
    "Raccordement": "asset:a_raccor_point",
    "Capteur_de_métrologie": "asset:a_capteu_point",
    "Équipement_de_régulation": "asset:a_condeb_point",
    "Réservoir_de_chasse": "asset:a_chasse_point",
    "Bassin_versant": "asset:a_basver_polygon",
    "Curage": "asset:a_curage_polygon",
    "Inspection_télévisuelle_ou_visuelle": "asset:a_itv_polygon",
    "Emprise_de_document_ou_plan": "asset:a_docum_polygon"
};

export const sceneLayers: { [key: string]: any } = {
    "2D_-_Chemin_de_câbles_GAIA": ["asset:chemin_de_cables_gaia"],
    "3D_-_Chambre_à_sable": ["asset:chambre_a_sable"],
    "Barrage_à_poutrelles": ["asset:barrage_a_poutrelles"],
    "Bassin_enterré": ["asset:bassin_enterre"],
    "Bassin_à_ciel_ouvert": ["asset:bassin_a_ciel_ouvert"],
    "Bouche_d'engouffrement": ["asset:bouche_d_engouffrement"],
    "Chambre_à_sable": ["asset:chambre_a_sable"],
    "Chemin_de_câbles_GAIA": ["asset:chemin_de_cables_gaia"],
    "Conduite_de_branchement": ["asset:conduite_de_branchement"],
    "Pluviomètre": ["asset:pluviometre"],
    "Point_de_mesure": ["asset:point_de_mesure"],
    "Porte_à_flot": ["asset:porte_a_flot"],
    "Poste_satellite": ["asset:poste_satellite"],
    "Regard_avec_une_profondeur_connue": ["asset:regard_de_visite"],
    "Regard_avec_une_profondeur_par_défaut": ["asset:regard_de_visite"],
    "Regard_de_branchement_avec_une_profondeur_connue": ["asset:regard_de_branchement"],
    "Regard_de_branchement_avec_une_profondeur_par_défaut": ["asset:regard_de_branchement"],
    "Regard_Zsurf": ["asset:regard_de_visite"],
    "Regard_Zmnt": ["asset:regard_de_visite"],
    "Regard_de_branchement_Zmnt": ["asset:regard_de_branchement"],
    "Regard_de_branchement_Zsurf": ["asset:regard_de_branchement"],
    "Rejet_vers_le_SIAAP": ["asset:rejet_vers_le_siaap_ou_le_reseau_ville_de_paris"],
    "Rejet_vers_le_milieu_naturel": ["asset:rejet_vers_le_milieu_naturel"],
    "Seuil_fixe": ["asset:seuil_fixe"],
    "Station_EP": ["asset:station_ep"],
    "Station_EU": ["asset:station_eu"],
    "Tronçon de réseau - Collecteur": ["asset:troncon_de_reseau_-_collecteur"],
    "Tronçon de réseau - Raccordement": ["asset:troncon_de_reseau_-_raccordement"],
    "Tronçon de réseau - Galerie": ["asset:troncon_de_reseau_-_galerie"],
    "Vanne_asservie": ["asset:vanne_asservie"],
    "Vanne_non_asservie": ["asset:vanne_non_asservie"],
};

export const mimeMap: { [key: string]: string } = {
    "video/3gpp2": "3g2",
    "video/3gp": "3gp",
    "video/3gpp": "3gp",
    "application/x-compressed": "7zip",
    "audio/x-acc": "aac",
    "audio/ac3": "ac3",
    "application/postscript": "ai",
    "audio/x-aiff": "aif",
    "audio/aiff": "aif",
    "audio/x-au": "au",
    "video/x-msvideo": "avi",
    "video/msvideo": "avi",
    "video/avi": "avi",
    "application/x-troff-msvideo": "avi",
    "application/macbinary": "bin",
    "application/mac-binary": "bin",
    "application/x-binary": "bin",
    "application/x-macbinary": "bin",
    "image/bmp": "bmp",
    "image/x-bmp": "bmp",
    "image/x-bitmap": "bmp",
    "image/x-xbitmap": "bmp",
    "image/x-win-bitmap": "bmp",
    "image/x-windows-bmp": "bmp",
    "image/ms-bmp": "bmp",
    "image/x-ms-bmp": "bmp",
    "application/bmp": "bmp",
    "application/x-bmp": "bmp",
    "application/x-win-bitmap": "bmp",
    "application/cdr": "cdr",
    "application/coreldraw": "cdr",
    "application/x-cdr": "cdr",
    "application/x-coreldraw": "cdr",
    "image/cdr": "cdr",
    "image/x-cdr": "cdr",
    "zz-application/zz-winassoc-cdr": "cdr",
    "application/mac-compactpro": "cpt",
    "application/pkix-crl": "crl",
    "application/pkcs-crl": "crl",
    "application/x-x509-ca-cert": "crt",
    "application/pkix-cert": "crt",
    "text/css": "css",
    "text/x-comma-separated-values": "csv",
    "text/comma-separated-values": "csv",
    "application/vnd.msexcel": "csv",
    "application/x-director": "dcr",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
    "application/x-dvi": "dvi",
    "message/rfc822": "eml",
    "application/x-msdownload": "exe",
    "video/x-f4v": "f4v",
    "audio/x-flac": "flac",
    "video/x-flv": "flv",
    "image/gif": "gif",
    "application/gpg-keys": "gpg",
    "application/x-gtar": "gtar",
    "application/x-gzip": "gzip",
    "application/mac-binhex40": "hqx",
    "application/mac-binhex": "hqx",
    "application/x-binhex40": "hqx",
    "application/x-mac-binhex40": "hqx",
    "text/html": "html",
    "image/x-icon": "ico",
    "image/x-ico": "ico",
    "image/vnd.microsoft.icon": "ico",
    "text/calendar": "ics",
    "application/java-archive": "jar",
    "application/x-java-application": "jar",
    "application/x-jar": "jar",
    "image/jp2": "jp2",
    "video/mj2": "jp2",
    "image/jpx": "jp2",
    "image/jpm": "jp2",
    "image/jpeg": "jpeg",
    "image/pjpeg": "jpeg",
    "application/x-javascript": "js",
    "application/json": "json",
    "text/json": "json",
    "application/vnd.google-earth.kml+xml": "kml",
    "application/vnd.google-earth.kmz": "kmz",
    "text/x-log": "log",
    "audio/x-m4a": "m4a",
    "application/vnd.mpegurl": "m4u",
    "audio/midi": "mid",
    "application/vnd.mif": "mif",
    "video/quicktime": "mov",
    "video/x-sgi-movie": "movie",
    "audio/mpeg": "mp3",
    "audio/mpg": "mp3",
    "audio/mpeg3": "mp3",
    "audio/mp3": "mp3",
    "video/mp4": "mp4",
    "video/mpeg": "mpeg",
    "application/oda": "oda",
    "audio/ogg": "ogg",
    "video/ogg": "ogg",
    "application/ogg": "ogg",
    "application/x-pkcs10": "p10",
    "application/pkcs10": "p10",
    "application/x-pkcs12": "p12",
    "application/x-pkcs7-signature": "p7a",
    "application/pkcs7-mime": "p7c",
    "application/x-pkcs7-mime": "p7c",
    "application/x-pkcs7-certreqresp": "p7r",
    "application/pkcs7-signature": "p7s",
    "application/pdf": "pdf",
    "application/octet-stream": "pdf",
    "application/x-x509-user-cert": "pem",
    "application/x-pem-file": "pem",
    "application/pgp": "pgp",
    "application/x-httpd-php": "php",
    "application/php": "php",
    "application/x-php": "php",
    "text/php": "php",
    "text/x-php": "php",
    "application/x-httpd-php-source": "php",
    "image/png": "png",
    "image/x-png": "png",
    "application/powerpoint": "ppt",
    "application/vnd.ms-powerpoint": "ppt",
    "application/vnd.ms-office": "ppt",
    "application/msword": "doc",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation": "pptx",
    "application/x-photoshop": "psd",
    "image/vnd.adobe.photoshop": "psd",
    "audio/x-realaudio": "ra",
    "audio/x-pn-realaudio": "ram",
    "application/x-rar": "rar",
    "application/rar": "rar",
    "application/x-rar-compressed": "rar",
    "audio/x-pn-realaudio-plugin": "rpm",
    "application/x-pkcs7": "rsa",
    "text/rtf": "rtf",
    "text/richtext": "rtx",
    "video/vnd.rn-realvideo": "rv",
    "application/x-stuffit": "sit",
    "application/smil": "smil",
    "text/srt": "srt",
    "image/svg+xml": "svg",
    "application/x-shockwave-flash": "swf",
    "application/x-tar": "tar",
    "application/x-gzip-compressed": "tgz",
    "image/tiff": "tiff",
    "text/plain": "txt",
    "text/x-vcard": "vcf",
    "application/videolan": "vlc",
    "text/vtt": "vtt",
    "audio/x-wav": "wav",
    "audio/wave": "wav",
    "audio/wav": "wav",
    "application/wbxml": "wbxml",
    "video/webm": "webm",
    "audio/x-ms-wma": "wma",
    "application/wmlc": "wmlc",
    "video/x-ms-wmv": "wmv",
    "video/x-ms-asf": "wmv",
    "application/xhtml+xml": "xhtml",
    "application/excel": "xl",
    "application/msexcel": "xls",
    "application/x-msexcel": "xls",
    "application/x-ms-excel": "xls",
    "application/x-excel": "xls",
    "application/x-dos_ms_excel": "xls",
    "application/xls": "xls",
    "application/x-xls": "xls",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "xlsx",
    "application/vnd.ms-excel": "xlsx",
    "application/xml": "xml",
    "text/xml": "xml",
    "text/xsl": "xsl",
    "application/xspf+xml": "xspf",
    "application/x-compress": "z",
    "application/x-zip": "zip",
    "application/zip": "zip",
    "application/x-zip-compressed": "zip",
    "application/s-compressed": "zip",
    "multipart/x-zip": "zip",
    "text/x-scriptzsh": "zsh",
};


export const indicesMapping: {[key: string]: IIndice} = {
    a_patrimoine: {
        a_collec_line: {
            indiceName: "a_collec_line",
            title: "indices.patrimoine.a_collec_line",
        },
        a_branch_line: {
            indiceName: "a_branch_line",
            title: "indices.patrimoine.a_branch_line",
        },
        a_regard_point: {
            indiceName: "a_regard_point",
            title: "indices.patrimoine.a_regard_point",
        },
        a_regbra_point: {
            indiceName: "a_regbra_point",
            title: "indices.patrimoine.a_regbra_point",
        },
        a_pompag_point: {
            indiceName: "a_pompag_point",
            title: "indices.patrimoine.a_pompag_point",
        },
        a_devers_point: {
            indiceName: "a_devers_point",
            title: "indices.patrimoine.a_devers_point",
        },
        a_traphy_point: {
            indiceName: "a_traphy_point",
            title: "indices.patrimoine.a_traphy_point",
        },
        a_raccor_point: {
            indiceName: "a_raccor_point",
            title: "indices.patrimoine.a_raccor_point",
        },
        a_capteu_point: {
            indiceName: "a_capteu_point",
            title: "indices.patrimoine.a_capteu_point",
        },
        a_condeb_point: {
            indiceName: "a_condeb_point",
            title: "indices.patrimoine.a_condeb_point",
        },
        a_chasse_point: {
            indiceName: "a_chasse_point",
            title: "indices.patrimoine.a_chasse_point",
        },
        a_bassin_point: {
            indiceName: "a_bassin_point",
            title: "indices.patrimoine.a_bassin_point",
        },
        a_bouche_point: {
            indiceName: "a_bouche_point",
            title: "indices.patrimoine.a_bouche_point",
        },
        t_site_polygon: {
            indiceName: "t_site_polygon",
            title: "indices.patrimoine.t_site_polygon",
        },
        a_step_point: {
            indiceName: "a_step_point",
            title: "indices.patrimoine.a_step_point",
        },
        a_ventou_point: {
            indiceName: "a_ventou_point",
            title: "indices.patrimoine.a_ventou_point",
        },
        title: "indices.patrimoine.title",
    },
};
