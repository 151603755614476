import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { SzStepper, SzTypographie } from "@suezenv/react-theme-components";
import ContractsCreateStep1Manager from "../../../manager/contracts/create/ContractsCreateStep1Manager";
import ContractsCreateStep2Manager from "../../../manager/contracts/create/ContractsCreateStep2Manager";
import ContractsCreateStep3Manager from "../../../manager/contracts/create/ContractsCreateStep3Manager";
import { RouteComponentProps, withRouter } from "react-router";
import { AppUrls } from "../../../constants";
import { PostContractRequest } from "../../../types";
import { ContractsService } from "../../../services/ContractsService";

const AdminContractsCreate = (props: RouteComponentProps) => {
    const { t } = useTranslation();

    const [step, setStep] = useState(0);

    const defaultFormData: PostContractRequest = {
        contractName: "",
        description: "",
        startDate: new Date(),
        organizationName: "",
        admins: [],
        users: []
    };
    const [formData, setFormData] = useState<PostContractRequest>(defaultFormData);

    const stepperHeaders = [
        t('admin:admin.contracts.create.steps.contract'),
        t('admin:admin.contracts.create.steps.organization'),
        t('admin:admin.contracts.create.steps.members')
    ];

    const goToStepHandle = (step: number) => {
        const index = step - 1;
        setStep(index);
    }

    const sendForm = () => {
        ContractsService.postContract(formData)
            .then(() => {
                props.history.push(AppUrls.ADMIN_CONTRACTS_CREATE_SUCCESS);
            })
    }

    return <div className='container-fluid my-3 overflow-auto h-100 admin-contracts-create'>
        <div className="row">
            <div className="col">
                <SzTypographie variant="h1" className="mb-0">
                    {t('admin:admin.contracts.create.title')}
                </SzTypographie>
                <SzTypographie variant="caption" className="title-decoration">
                    {t('admin:admin.contracts.create.subtitle')}
                </SzTypographie>
            </div>
        </div>
        <div className="row">
            <div className="p-4">
                <SzStepper header={stepperHeaders} activeStep={step}>
                    <SzStepper.SzHeader/>
                    <SzStepper.SzStep className="bg-transparent my-3 p-0" index={0}>
                        <ContractsCreateStep1Manager
                            currentStep={step}
                            nextStepHandle={() => goToStepHandle(2)}
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </SzStepper.SzStep>
                    <SzStepper.SzStep className="bg-transparent my-3 p-0" index={1}>
                        <ContractsCreateStep2Manager
                            currentStep={step}
                            nextStepHandle={() => goToStepHandle(3)}
                            previousStepHandle={() => goToStepHandle(1)}
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </SzStepper.SzStep>
                    <SzStepper.SzStep className="bg-transparent my-3 p-0" index={2}>
                        <ContractsCreateStep3Manager
                            currentStep={step}
                            previousStepHandle={() => goToStepHandle(2)}
                            nextStepHandle={sendForm}
                            formData={formData}
                            setFormData={setFormData}
                        />
                    </SzStepper.SzStep>
                </SzStepper>
            </div>
        </div>
    </div>;
}

export default withRouter(AdminContractsCreate);
