import { API_ROUTES } from "../config/routes";
import Request from "./Request";

export class Smartshape {
    public static getNodesByEquipement(fileId: string, equipementId: string) {
        const url = `${API_ROUTES.SMARTSHAPE}/${fileId}/nodes/${equipementId}`;
        return Request.get(url, true)
            .then((res: any) => res.data);
    }

    public static getNodesById(fileId: string, nodeId: string) {
        const url = `${API_ROUTES.SMARTSHAPE}/${fileId}/${nodeId}`;
        return Request.get(url, true)
            .then((res: any) => res.data);
    }
}
