import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import { SzTab, SzTypographie } from "@suezenv/react-theme-components";
import { AppUrls } from "../../../constants";
import { withRouter } from "react-router";
import AdminContractsAll from "./all/index";
import AdminContractsOwn from "./own/index";
import AdminContractsToFinish from "./to_finish/index";

type AdminContractTab = {
    eventKey: string,
    title: string,
    route: string,
    component: JSX.Element
}

const AdminContracts = (props: any) => {
    const { t } = useTranslation();
    const selectTabHandle = (newTab: string) => {
        const matchedTab = tabs.filter((tab: AdminContractTab) => tab.eventKey === newTab)[0] || tabs[0];
        setTab(matchedTab);
        props.history.push(matchedTab.route);
    }

    const tabs: AdminContractTab[] = [
        {
            eventKey: "all",
            title: t('admin:admin.contracts.tabs.all'),
            route: AppUrls.ADMIN_CONTRACTS,
            component: <AdminContractsAll/>
        },
//         {
//             eventKey: "own",
//             title: t('admin:admin.contracts.tabs.own'),
//             route: AppUrls.ADMIN_CONTRACTS_OWN,
//             component: <AdminContractsOwn/>
//         },
//         {
//             eventKey: "toFinish",
//             title: t('admin:admin.contracts.tabs.toFinish'),
//             route: AppUrls.ADMIN_CONTRACTS_TOFINISH,
//             component: <AdminContractsToFinish/>
//         }
    ]

    const defaultTab = tabs.filter((tab: AdminContractTab) => tab.route === props.location.pathname)[0] || tabs[0];
    const [tab, setTab] = useState(defaultTab);

    return <div className='container-fluid my-3 overflow-auto h-100 admin'>
        <div className="row">
            <div className="col">
                <SzTypographie variant="h1" className="mb-0">
                    {t('admin:admin.contracts.list.title')}
                </SzTypographie>
                <SzTypographie variant="caption" className="title-decoration">
                    {t('admin:admin.contracts.list.subtitle')}
                </SzTypographie>
            </div>
        </div>
        <div className="row">
            <div className="col">
                <div className="box box-no-padding my-3 px-4">
                    <SzTab id="admin-tabs"
                           activeKey={tab.eventKey}
                           selectTabHandle={selectTabHandle}
                    >
                        {tabs.map((tab: { eventKey: string, title: string, component: JSX.Element }) => {
                            return <SzTab.SzTabContent
                                key={tab.eventKey}
                                eventKey={tab.eventKey}
                                title={tab.title}
                            >
                                {tab.component}
                            </SzTab.SzTabContent>
                        })}
                    </SzTab>
                </div>
            </div>
        </div>
    </div>;
}

export default withRouter(AdminContracts);
