import { SzIcon, SzTypographie } from "@suezenv/react-theme-components";
import React from "react";
import "./BlockHeader.scss";
import classnames from "classnames";

type BlockHeaderProps = {
    icon?: string;
    variant?: "reverse";
    title: string;
    subtitle: string;
    className?: string;
}

const BlockHeader = (props: BlockHeaderProps) => {
    const {
        className,
        subtitle,
        title,
        variant
    } = props;

    const BlockHeaderClasses = classnames(
        'block-header--container',
        className
    );

    const icon = () => {
        if (props.icon) {
            return <SzIcon variant={"line"} icon={props.icon} className={"block-header--icon"}/>;
        }
    }

    const titleBlock = () => {
        switch (variant) {
            case "reverse":
                return <SzTypographie variant={"caption"} color={"disabled"} className={"block-header--title"}>
                    {title}
                </SzTypographie>
            default:
                return <SzTypographie variant={"body"} weight={"bold"} className={"block-header--title pr-5 mb-0"}>
                    {title}
                </SzTypographie>
        }
    }

    const subtitleBlock = () => {
        switch (variant) {
            case "reverse":
                return <SzTypographie variant={"body"} weight={"bold"} className={"block-header--subtitle"}>
                    {subtitle}
                </SzTypographie>
            default:
                return <SzTypographie variant={"caption"} color={"disabled"} className={"block-header--subtitle"}>
                    {subtitle}
                </SzTypographie>
        }
    }

    return <div className={BlockHeaderClasses}>
        {icon()}
        <div className={"block-header--text-container"}>
            {titleBlock()}
            {subtitleBlock()}
        </div>
    </div>
}

export default BlockHeader;
