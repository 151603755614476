import {useMap} from "react-leaflet";
export function ResizeMap(mapInfoInterface: MapInfoInterface) {
    const map = useMap();
    const {mapInfo, setMapInfo} = mapInfoInterface;
    if (mapInfo.resize) {
        setTimeout(() => {
            map.invalidateSize();
            setMapInfo({
                resize: false,
            });
        }, 10);
    }

    return null;
}

interface MapInfoInterface {
    mapInfo: any,
    setMapInfo: (payload: any) => void,
    refreshing: boolean
}