import Request from './Request';
import { ApiUrls, Constants } from '../constants';

export class ServiceService {
    public static getAllServices(currentPage?: number, itemsPerPage?: number, category?: string[], noCategory?: boolean) {
        const body = {
            currentPage: currentPage ?? 1,
            itemsPerPage: itemsPerPage ?? Constants.NOTIFICATIONS_PER_PAGE,
            category: category,
            noCategory: noCategory
        }
        return Request.getWithParams(ApiUrls.SERVICE, body, false, true)
            .then(response => response);
    }
}
