import axios from "axios";
import { setupCache } from "axios-cache-adapter";
import { config } from "../config";
import { store } from "../store";
import { ERROR } from "../store/redux/constants";
import { Token as TokenService } from "./Token";

export enum HTTPMethods {
  GET = "GET",
  POST = "POST",
  PUT= "PUT",
}

class Request {
  public static cancel: any;
  private base: string;
  private CancelToken: any;
  private cache: any;

  constructor() {
    this.base = config.apiUrl as string;
    this.CancelToken = axios.CancelToken;
    this.cache = setupCache({
      maxAge: 15 * 60 * 1000,
    });
  }

  public async sendRequest(
    endpoint: string,
    method: keyof typeof HTTPMethods,
    body: any = {},
    cancelOption = false,
    cached = true,
  ): Promise<any> {
    if (TokenService.isJwtNotValid()) {
      return TokenService.refreshToken().then(() => this.sendRequest(endpoint, method, body, cancelOption, cached));
    } else {
      const request = this.prepareRequest(cancelOption, cached);
      switch (method) {
        case HTTPMethods.POST:
          return request.httpInstance.post(endpoint, JSON.stringify(body), request.param);
        case HTTPMethods.PUT:
          return request.httpInstance.put(endpoint, JSON.stringify(body), request.param);
        case HTTPMethods.GET:
        default:
          return request.httpInstance.get(endpoint, request.param);
      }
    }
  }

  public async get(endpoint: string, cancelOption = false, cached = true) {
    const request = this.prepareRequest(cancelOption, cached);
    if (TokenService.isJwtNotValid()) {
      return TokenService.refreshToken().then(() => request.httpInstance.get(endpoint, request.param));
    } else {
      return request.httpInstance.get(endpoint, request.param);
    }
  }

  public async getWithParams(endpoint: string, body: any, cancelOption: boolean = false, noInterceptor: boolean = false) {
      Request.cancel && Request.cancel();
      const httpInstance = noInterceptor ? this.getRawHttpInstance() : this.getHttpInstance();
      let param = {};
      if (cancelOption) {
          param = {
              cancelToken: new this.CancelToken(function executor(c: any) {
                  Request.cancel = c
              })
          }
      }
      const params = { params: { ...body, ...param } };
      return httpInstance.get(endpoint, params);
  }

  public async post(endpoint: string, body: any, cancelOption = false, cached = true) {
    const request = this.prepareRequest(cancelOption, cached);
    if (TokenService.isJwtNotValid()) {
      return TokenService.refreshToken().then(() => request.httpInstance.post(endpoint, body, request.param));
    } else {
      return request.httpInstance.post(endpoint, JSON.stringify(body), request.param);
    }
  }

  public async put(endpoint: string, body: any, cancelOption = false, cached = true) {
    const request = this.prepareRequest(cancelOption, cached);
    if (TokenService.isJwtNotValid()) {
      return TokenService.refreshToken().then(() => request.httpInstance.post(endpoint, body, request.param));
    } else {
      return request.httpInstance.put(endpoint, JSON.stringify(body), request.param);
    }
  }

    public async delete(endpoint: string, body: any, cancelOption: boolean = false, noInterceptor: boolean = false) {
        Request.cancel && Request.cancel();
        const httpInstance = noInterceptor ? this.getRawHttpInstance() : this.getHttpInstance();
        let param = {};
        if (cancelOption) {
            param = {
                cancelToken: new this.CancelToken(function executor(c: any) {
                    Request.cancel = c
                })
            }
        }
        const params = { params: { ...body, ...param } };
        return httpInstance.delete(endpoint, params);
    }

  public async refreshToken() {
    const request = this.prepareRequest(true, false);
    const body = JSON.stringify({
      refresh_token: localStorage.getItem("refreshToken"),
    });
    const endpoint = `/token/refresh`;
    const httpInstance = this.getHttpInstance();
    return httpInstance.post(endpoint, body, request.param);
  }

  public async checkJWT() {
    const endpoint = `/token/check`;
    const httpInstance = this.getHttpInstance();
    return httpInstance.get(endpoint);
  }

  public cancelRequest() {
    Request.cancel && Request.cancel();
  }

  private prepareRequest(cancelOption = false, cached = true) {
    this.cancelRequest();
    let param = {};
    if (cancelOption) {
      param = {
        cancelToken: new this.CancelToken(function executor(c: any) {
          Request.cancel = c;
        }),
      };
    }
    const httpInstance = this.getHttpInstance(cached);
    return {param, httpInstance};
  }

  private getHttpInstance(cached?: boolean) {
    const { base } = this;
    let adapter = this.cache.adapter;
    if ("undefined" !== typeof cached && !cached) {
      adapter = undefined;
    }
    const httpInstance = axios.create({
      adapter,
      baseURL: `${base}`,
      headers: {
        "Content-Type": "application/json;charset=utf-8",
        "authorization": `Bearer ${localStorage.getItem("tokenDigitalTwin")}`,
      },
    });

    httpInstance.interceptors.response.use(
      async (response) => {
        return response;
      },
      (error) => {
        if (error.response) {
          const {
            status,
            data: { message },
          } = error.response;
          const isJwtError = 401 === status;
          if (isJwtError) {
            store.dispatch({
               payload: { jwtCheck: true, status, message },
               type: ERROR.SET,
             });
          } else {
            store.dispatch({
              payload: { jwtCheck: false, status, message },
              type: ERROR.SET,
            });
          }
        } else {
          store.dispatch({
            payload: { jwtCheck: false, status: 0, message: error.message },
            type: ERROR.SET,
          });
        }

        return Promise.reject(error);
      },
    );
    return httpInstance;
  }

  private getRawHttpInstance() {
      const { base } = this;
      const httpInstance = axios.create({
          baseURL: `${base}`,
          headers: {
              "Content-Type": "application/json;charset=utf-8",
              "authorization": `Bearer ${localStorage.getItem(config.tokenKey)}`,
          },
      });

      httpInstance.interceptors.response.use(
          (response: any) => response,
          (error: { response: { config: any; status: any; data: { message: any; }; }; message: any; }) => {
              if (axios.isCancel(error)) {
                  return;
              }

              if (error.response) {
                  const {
                      config: { url },
                      status
                  } = error.response;
                  const isJwtError = 401 === status;
                  if (isJwtError && url.includes('token-check')) {
                      localStorage.clear();
                      //AuthenticateService.logout();
                  }
              }

              return Promise.reject(error);
          },
      );
      return httpInstance;
  }
}

export default new Request();
