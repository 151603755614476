import React from "react";
import { SzButton, SzData, SzSearchBar } from "@suezenv/react-theme-components";
import classnames from "classnames";
import { useTranslation } from "react-i18next";
import "./tabHeader.scss";

type tabHeaderProps = {
    className?: string,
    title: string,
    subtitle?: string,
    onBtnClick?: () => void,
    onSearch?: (keywords: string) => void,
}

const TabHeader = (props: tabHeaderProps) => {
    const { className, title, subtitle, onBtnClick, onSearch } = props;
    const { t } = useTranslation();

    const tabHeaderClasses = classnames(
        'tab-header d-flex justify-content-between',
        className
    );

    return <div className={tabHeaderClasses}>
        <SzData>
            <SzData.Text>{title}</SzData.Text>
            <SzData.Title>{subtitle}</SzData.Title>
        </SzData>
        <div className={"tab-header--actions d-flex justify-content-end"}>
            {onSearch &&
                <div className={"tab-header--actions--search-container mr-3"}>
                    <SzSearchBar placeholder={t('search')} onClick={onSearch} icon handleClear={() => onSearch("")}/>
                </div>
            }
            {onBtnClick &&
                <SzButton variant={"primary"} icon={"add-square"} onClick={onBtnClick}/>
            }
        </div>
    </div>
}

export default TabHeader;