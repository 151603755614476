import { Authenticate as AuthenticateService } from "../../../services/Authenticate";
import { User as UserService } from "../../../services/User";
import { USER } from "../constants";
import { AppActions, IUser, IUserMapPreferences } from "../types";

export enum UPDATE_LAYER_ACTION {
  ADD = "ADD",
  REMOVE = "REMOVE",
}

function login(user: IUser): AppActions {
  return {
    payload: {...user},
    type: USER.LOG_IN,
  };
}

function logout(user?: IUser): any {
  AuthenticateService.logout();
  return {
    payload: {...user},
    type: USER.LOG_OUT,
  };
}

export function getMapPreferences() {
  return (dispatch: any) => {
    return UserService.getMapPreferences().then((data: any) => {
      dispatch({
        payload: {
          baseMap: parseInt(data.mapBackground, 10),
          forcedLayers: data.forcedLayers,
          visibleLayers: data.visibleLayers,
          zoom: data.zoom,
          center: data.center,
        },
        type: USER.GET_MAP_PREFERENCES,
      });
      return data;
    }).then((data: any) => {
      return data;
    });
  };
}

export function setHomeMapPosition(position: [number, number, number]) {
  return (dispatch: any) => {
    dispatch({
      payload: position,
      type: USER.SET_HOME_MAP_POSITION,
    });
  };
}

export function setReportMapPosition(position: [number, number, number]) {
  return (dispatch: any) => {
    dispatch({
      payload: position,
      type: USER.SET_REPORT_MAP_POSITION,
    });
  };
}

export function setMapPreferences(mapPreferences: IUserMapPreferences) {
  return UserService.setMapPreferences(mapPreferences);
}

export function updateForcedLayers(forcedLayers: string[]) {
  UserService.setMapPreferences({forcedLayers});
}

export function updateVisibleLayers(visibleLayers: string[]) {
  UserService.setMapPreferences({visibleLayers});
}

export function setReportListType(listType: string) {
  return (dispatch: any) => {
    dispatch({
      payload: listType,
      type: USER.SET_REPORT_LIST_TYPE,
    });
  };
}

export default {
  getMapPreferences,
  login,
  logout,
  setHomeMapPosition,
  setMapPreferences,
  setReportMapPosition,
  updateForcedLayers,
  updateVisibleLayers,
  setReportListType,
};
